import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'captian-interview-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
