import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SingleSelectIns } from '../../../instructions/singleSelect.ins';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
})
export class SingleSelectComponent implements OnInit, OnDestroy {
  @Input() public instruction: SingleSelectIns;
  @Input() public fieldName: string;
  @Input() public fieldForm: FormControl;
  @Input() public disabledSelect: boolean;
  @Input() public disabledOptions: { [key: string]: boolean } = {};

  constructor() {}

  ngOnInit() {
    if (this.instruction.settings.colors) {
      this.fieldForm.valueChanges.subscribe((newValue: string) => {
        this.changeColor(newValue);
      });
    }
  }

  ngOnDestroy(): void {
    this.changeColor(this.instruction.defaults.value);
  }

  private changeColor(newValue: string) {
    const foundOption = this.instruction.options.find((option) => option.value === newValue);
    this.instruction.defaults.color = foundOption ? foundOption.color : 'transparent';
    // this.instruction.options.find((option) => option.value === newValue).color || 'transparent';
  }
}
