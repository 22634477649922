enum STATUSEXTRA {
  ANNOUNCED = 'ANNOUNCED',
  BERTHED = 'BERTHED',
  COMPLETED = 'COMPLETED',
  SAILED = 'SAILED',
  BILLED = 'BILLED',
  RECEIVED_INVOICE = 'FACTURAS RECIBIDAS',
  INVOICED = 'FACTURADO',
  SHIPPED = 'SHIPPED',
}

export default STATUSEXTRA;
