import { Injectable } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import {
  AreaModel,
  BuqueModel,
  BusinessLineModel,
  CommodityModel,
  contactInfo,
  ContactoModel,
  EmpresaModel,
  IareaEmpresa,
  IareaEmpresaTotalInfo,
  IboardingAgent,
  IbusinessLine,
  Icommodity,
  Iempresa,
  IempresaTotalFields,
  Ikim,
  IoperationType,
  Iport,
  Iterminal,
  IterminalTotalInfo,
  Ivessel,
  KimModel,
  PuertoModel,
  TerminalModel,
  TipoOperacionModel,
  UserModel,
} from '../../../interfaces/requests.interface';

@Injectable({
  providedIn: 'root',
})
export class ViajeDetalleService {
  /** Viaje actual */
  viaje: any = '';
  /** Id del viaje actual */
  viajeId: string = '';
  /** Observable de los viajes */
  viajesCambiados = new Subject<any[]>();

  constructor(private mainService: MainService, private router: Router) {}

  /**
   * Actualiza el id del viaje actual
   * @param viajeId Id del viaje actual
   */
  updateViajeId(viajeId: string) {
    this.viajeId = viajeId;
  }

  /**
   * Actualiza el viaje actual
   */
  updateViaje() {
    this.mainService.get(`api/viaje/${this.viajeId}`).subscribe((res) => {
      if (res.message) {
        this.router.navigate(['home/dashboard']);
        Swal.fire({
          title: '¡Error!',
          text: res.message,
          type: 'error',
        });
      }
      this.viaje = res;
      this.viajesCambiados.next(this.viaje);
    });
  }

  /**
   * Obtiene los contactos asociados a una área de eempresa
   * @param areaId El id del área (no de mongo)
   * @returns {Promise<ContactoModel[]>} Una lista de contactos
   */
  getContactsFromArea(areaId): Promise<ContactoModel[]> {
    return new Promise((resolve, reject) => {
      this.mainService.get(`api/contacto_empresa/area/${areaId}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  transformBuqueFields(buques: BuqueModel[]): Ivessel[] {
    return buques
      .map((buque) => {
        if (buque.activo && buque._id && buque.BUQ_ID && buque.BUQ_NOMBRE) {
          return {
            _id: buque._id,
            id: Number(buque.BUQ_ID),
            nombre: buque.BUQ_NOMBRE,
            email: buque.BUQ_CORREO,
            loa: buque.BUQ_ESLORA,
            dwt: buque.BUQ_DWT,
            gwt: buque.BUQ_GT,
          };
        }
      })
      .filter((buque) => buque !== undefined);
  }

  transformBusinessFields(empresas: EmpresaModel[]): Iempresa[] {
    return empresas
      .map((empresa) => {
        if (empresa.activo && empresa._id && empresa.EMP_ID && empresa.EMP_NOMBRE) {
          return {
            _id: empresa._id,
            id: Number(empresa.EMP_ID),
            nombre: empresa.EMP_NOMBRE,
          };
        }
      })
      .filter((empresa) => empresa !== undefined);
  }

  transformBusinessTotalInfo(empresas: EmpresaModel[]): IempresaTotalFields[] {
    return empresas
      .map((empresa) => {
        if (empresa.activo && empresa._id && empresa.EMP_ID && empresa.EMP_NOMBRE) {
          return {
            _id: empresa._id,
            id: Number(empresa.EMP_ID),
            nombre: empresa.EMP_NOMBRE,
            ciudad: empresa.EMP_CIUDAD,
            comentarios: empresa.EMP_COMENTARIOS,
            direccion: empresa.EMP_DIRECCION,
            locacion: empresa.EMP_LOCACION,
            pais: empresa.EMP_PAIS,
            telefono: empresa.EMP_TELEFONO,
            nit: empresa.EMP_NIT,
            tipo: empresa.tipo,
          };
        }
      })
      .filter((empresa) => empresa !== undefined);
  }

  transformAreasFields(areas: AreaModel[]): IareaEmpresa[] {
    return areas
      .map((area) => {
        if (area.activo && area._id && area.AEM_ID && area.nombre) {
          return {
            _id: area._id,
            id: Number(area.AEM_ID),
            nombre: area.nombre,
          };
        }
      })
      .filter((area) => area !== undefined);
  }

  transformAreasTotalInfo(areas: AreaModel[]): IareaEmpresaTotalInfo[] {
    return areas
      .map((area) => {
        if (area.activo && area._id && area.AEM_ID && area.nombre) {
          return {
            _id: area._id,
            id: Number(area.AEM_ID),
            nombre: area.nombre,
            empresa: area.empresa,
          };
        }
      })
      .filter((area) => area !== undefined);
  }

  transformContactsFields(contactos: ContactoModel[]): contactInfo[] {
    return contactos
      .map((contacto) => {
        if (contacto._id && contacto.CON_ID && contacto.nombre && contacto.correo) {
          return {
            nombre: contacto.nombre,
            correo: contacto.correo,
          };
        }
      })
      .filter((contacto) => contacto !== undefined);
  }

  transformPortsFields(puertos: PuertoModel[]): Iport[] {
    return puertos
      .map((puerto) => {
        if (puerto.activo && puerto._id && puerto.PUE_ID && puerto.PUE_NOMBRE) {
          return {
            _id: puerto._id,
            id: Number(puerto.PUE_ID),
            nombre: puerto.PUE_NOMBRE,
            email: puerto.email,
            direccion: puerto.PUE_DIRECCION,
            cod: puerto.PUE_COD,
            centroCoste: puerto.CENTRO_COSTE,
            centroBeneficio: puerto.CENTRO_BENEFICIO,
          };
        }
      })
      .filter((puerto) => puerto !== undefined);
  }

  transformTerminalsFields(terminales: TerminalModel[]): Iterminal[] {
    return terminales
      .map((terminal) => {
        if (terminal.activo && terminal._id && terminal.TER_ID && terminal.TER_NOMBRE) {
          return {
            _id: terminal._id,
            id: Number(terminal.TER_ID),
            nombre: terminal.TER_NOMBRE,
            privado: terminal.TER_PRIVADO,
            abreviatura: terminal.TER_ABREVIATURA,
          };
        }
      })
      .filter((terminal) => terminal !== undefined);
  }

  transformTerminalsTotalInfo(terminales: TerminalModel[]): IterminalTotalInfo[] {
    return terminales
      .map((terminal) => {
        if (terminal.activo && terminal._id && terminal.TER_ID && terminal.TER_NOMBRE && terminal.PUE_ID) {
          return {
            _id: terminal._id,
            id: Number(terminal.TER_ID),
            nombre: terminal.TER_NOMBRE,
            privado: terminal.TER_PRIVADO,
            abreviatura: terminal.TER_ABREVIATURA,
            puerto: terminal.PUE_ID,
          };
        }
      })
      .filter((terminal) => terminal !== undefined);
  }

  transformOperationsFields(tiposOperaciones: TipoOperacionModel[]): IoperationType[] {
    return tiposOperaciones
      .map((tipo) => {
        if (tipo.activo && tipo._id && tipo.TOP_ID && tipo.TOP_NOMBRE) {
          return {
            _id: tipo._id,
            id: Number(tipo.TOP_ID),
            nombre: tipo.TOP_NOMBRE,
          };
        }
      })
      .filter((tipo) => tipo !== undefined);
  }

  transformCommoditiesFields(commodities: CommodityModel[]): Icommodity[] {
    return commodities
      .map((com) => {
        if (com.activo && com._id && com.ID && com.COMODITY) {
          return {
            _id: com._id,
            id: Number(com.ID),
            nombre: com.COMODITY,
          };
        }
      })
      .filter((com) => com !== undefined);
  }

  transformKimsFields(kims: KimModel[]): Ikim[] {
    const activeKims: Ikim[] = [];
    kims.forEach((kim) => {
      if (kim.activo && kim._id && kim.KIM_ID && kim.KIM_NOMBRE) {
        activeKims.push({
          _id: kim._id,
          id: Number(kim.KIM_ID),
          nombre: kim.KIM_NOMBRE,
        });
      }
    });

    return activeKims;
  }

  transformBusinessLinesFields(businessLines: BusinessLineModel[]): IbusinessLine[] {
    return businessLines
      .map((bus) => {
        if (bus.activo && bus._id && bus.LIN_ID && bus.LIN_NOMBRE) {
          return {
            _id: bus._id,
            id: Number(bus.LIN_ID),
            nombre: bus.LIN_NOMBRE,
            regular: bus.LIN_REGULAR,
            tramp: bus.LIN_TRAMP,
          };
        }
      })
      .filter((bus) => bus !== undefined);
  }

  transformBoardingAgentsFields(boardingAgents: UserModel[]): IboardingAgent[] {
    return boardingAgents
      .map((agent) => {
        if (agent.activo && agent._id && agent.nombre && agent.email) {
          return {
            _id: agent._id,
            nombre: agent.nombre,
            apellido: agent.apellido,
            email: agent.email,
            telefono: agent.telefono,
            empresa: agent.empresa,
          };
        }
      })
      .filter((agent) => agent !== undefined);
  }
}
