import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../services/main.service';
import * as _ from 'lodash';

@Component({
  selector: 'rol-detalle',
  templateUrl: './rol-detalle.component.html',
  styleUrls: ['./rol-detalle.component.scss'],
})
export class RolDetalleComponent implements OnInit {
  /** Referencia al dialogo del detalle */
  @Input() dialogRef: any;
  /** Modo de la accion del detalle ('CREAR', 'EDITAR' o 'VER') */
  @Input() modo: string;
  /** Datos del rol seleccionado */
  @Input() rol: any;
  /** Evento que indica que se realizo algun cambio en la tabla areaEmpresas de la BD */
  @Output() cambioEnRol: EventEmitter<any> = new EventEmitter<any>();

  /** Formulario reactivo con los datos del rol*/
  public formulario!: FormGroup;
  /** Poibles modos de acciones para el detalle */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Indica que le modulo se esta cargando */
  public cargandoModulo = false;
  /** Indica si los permisos especificoss del viaje son seleccionables y visibles */
  public permisosEspecificosViajes = false;
  /** Indica si los permisos especificoss de las liquidaciones son seleccionables y visibles */
  public permisosEspecificosLiquidaciones = false;

  constructor(private mainService: MainService) {}

  ngOnInit() {
    this.inicializarFormulairoSegunModo();
  }

  /**
   * Inicializa el formulario reactivo segun el modo del detalle
   */
  inicializarFormulairoSegunModo() {
    switch (this.modo) {
      case this.modos[0]:
        this.inicializarFormularioModoCrear();
        break;
      case this.modos[1]:
        this.inicializarFormularioModoEditar();
        break;
      case this.modos[2]:
        this.inicializarFormualrioModoVer();
        break;
    }

    this.onCambioPermisosViajes();
    this.onCambioPermisosLiquidaciones();
  }

  /**
   * Inincializa los datos del formulario reactivo en modo crear
   */
  inicializarFormularioModoCrear() {
    this.formulario = new FormGroup({
      nombre: new FormControl({ value: '', disabled: false }, Validators.required),
      roles: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      viajes: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      anticipo: new FormControl({ value: '', disabled: false }),
      fechaCierrePuerto: new FormControl({ value: '', disabled: false }),
      fechaFacturacion: new FormControl({ value: '', disabled: false }),
      status: new FormControl({ value: '', disabled: false }),
      liquidaciones: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      facturasRecibidas: new FormControl({ value: '', disabled: false }),
      servicios: new FormControl({ value: '', disabled: false }),
      buques: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      analisisOperativo: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      proformas: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      tarifas: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      parametrosSistema: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      usuarios: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      empresas: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      areasEmpresas: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      contactosEmpresas: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      pqrs: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      productosViaje: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      productos: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      categoriasSof: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      remarksSof: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      medidas: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      husbandry: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      businessLines: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      noticias: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      notificaciones: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      cargas: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      agents: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      commodities: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      kims: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      tiposDeOperaciones: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      puertos: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      sofFormat: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      terminales: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
      survy: new FormControl({ value: 'NINGUNO', disabled: false }, Validators.required),
    });
  }

  /**
   * Inincializa los datos del formulario reactivo en modo editar
   */
  inicializarFormularioModoEditar() {
    this.formulario = new FormGroup({
      nombre: new FormControl(
        { value: this.rol && this.rol.nombre ? this.rol.nombre : '', disabled: false },
        Validators.required
      ),
      roles: new FormControl(
        { value: this.rol && this.rol.roles ? this.rol.roles : 'NINGUNO', disabled: false },
        Validators.required
      ),
      viajes: new FormControl(
        { value: this.rol && this.rol.viajes ? this.rol.viajes : 'NINGUNO', disabled: false },
        Validators.required
      ),
      anticipo: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraViajes &&
          this.rol.permisosEspecificosEscrituraViajes.anticipo
            ? this.rol.permisosEspecificosEscrituraViajes.anticipo
            : '',
        disabled: false,
      }),
      fechaCierrePuerto: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraViajes &&
          this.rol.permisosEspecificosEscrituraViajes.fechaCierrePuerto
            ? this.rol.permisosEspecificosEscrituraViajes.fechaCierrePuerto
            : '',
        disabled: false,
      }),
      fechaFacturacion: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraViajes &&
          this.rol.permisosEspecificosEscrituraViajes.fechaFacturacion
            ? this.rol.permisosEspecificosEscrituraViajes.fechaFacturacion
            : '',
        disabled: false,
      }),
      status: new FormControl({
        value:
          this.rol && this.rol.permisosEspecificosEscrituraViajes && this.rol.permisosEspecificosEscrituraViajes.status
            ? this.rol.permisosEspecificosEscrituraViajes.status
            : '',
        disabled: false,
      }),
      liquidaciones: new FormControl(
        { value: this.rol && this.rol.liquidaciones ? this.rol.liquidaciones : 'NINGUNO', disabled: false },
        Validators.required
      ),
      facturasRecibidas: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraLiquidaciones &&
          this.rol.permisosEspecificosEscrituraLiquidaciones.facturasRecibidas
            ? this.rol.permisosEspecificosEscrituraLiquidaciones.facturasRecibidas
            : '',
        disabled: false,
      }),
      servicios: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraLiquidaciones &&
          this.rol.permisosEspecificosEscrituraLiquidaciones.servicios
            ? this.rol.permisosEspecificosEscrituraLiquidaciones.servicios
            : '',
        disabled: false,
      }),
      buques: new FormControl(
        { value: this.rol && this.rol.buques ? this.rol.buques : 'NINGUNO', disabled: false },
        Validators.required
      ),
      analisisOperativo: new FormControl(
        { value: this.rol && this.rol.analisisOperativo ? this.rol.analisisOperativo : 'NINGUNO', disabled: false },
        Validators.required
      ),
      proformas: new FormControl(
        { value: this.rol && this.rol.proformas ? this.rol.proformas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      tarifas: new FormControl(
        { value: this.rol && this.rol.tarifas ? this.rol.tarifas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      parametrosSistema: new FormControl(
        { value: this.rol && this.rol.parametrosSistema ? this.rol.parametrosSistema : 'NINGUNO', disabled: false },
        Validators.required
      ),
      usuarios: new FormControl(
        { value: this.rol && this.rol.usuarios ? this.rol.usuarios : 'NINGUNO', disabled: false },
        Validators.required
      ),
      empresas: new FormControl(
        { value: this.rol && this.rol.empresas ? this.rol.empresas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      areasEmpresas: new FormControl(
        { value: this.rol && this.rol.areasEmpresas ? this.rol.areasEmpresas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      contactosEmpresas: new FormControl(
        { value: this.rol && this.rol.contactosEmpresas ? this.rol.contactosEmpresas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      pqrs: new FormControl(
        { value: this.rol && this.rol.pqrs ? this.rol.pqrs : 'NINGUNO', disabled: false },
        Validators.required
      ),
      productosViaje: new FormControl(
        { value: this.rol && this.rol.productosViaje ? this.rol.productosViaje : 'NINGUNO', disabled: false },
        Validators.required
      ),
      productos: new FormControl(
        { value: this.rol && this.rol.productos ? this.rol.productos : 'NINGUNO', disabled: false },
        Validators.required
      ),
      categoriasSof: new FormControl(
        { value: this.rol && this.rol.categoriasSof ? this.rol.categoriasSof : 'NINGUNO', disabled: false },
        Validators.required
      ),
      remarksSof: new FormControl(
        { value: this.rol && this.rol.categoriasSof ? this.rol.categoriasSof : 'NINGUNO', disabled: false },
        Validators.required
      ),
      medidas: new FormControl(
        { value: this.rol && this.rol.medidas ? this.rol.medidas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      husbandry: new FormControl(
        { value: this.rol && this.rol.husbandry ? this.rol.husbandry : 'NINGUNO', disabled: false },
        Validators.required
      ),
      businessLines: new FormControl(
        { value: this.rol && this.rol.businessLines ? this.rol.businessLines : 'NINGUNO', disabled: false },
        Validators.required
      ),
      noticias: new FormControl(
        { value: this.rol && this.rol.noticias ? this.rol.noticias : 'NINGUNO', disabled: false },
        Validators.required
      ),
      notificaciones: new FormControl(
        { value: this.rol && this.rol.notificaciones ? this.rol.notificaciones : 'NINGUNO', disabled: false },
        Validators.required
      ),
      cargas: new FormControl(
        { value: this.rol && this.rol.cargas ? this.rol.cargas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      agents: new FormControl(
        { value: this.rol && this.rol.agents ? this.rol.agents : 'NINGUNO', disabled: false },
        Validators.required
      ),
      commodities: new FormControl(
        { value: this.rol && this.rol.commodities ? this.rol.commodities : 'NINGUNO', disabled: false },
        Validators.required
      ),
      kims: new FormControl(
        { value: this.rol && this.rol.kims ? this.rol.kims : 'NINGUNO', disabled: false },
        Validators.required
      ),
      tiposDeOperaciones: new FormControl(
        { value: this.rol && this.rol.tiposDeOperaciones ? this.rol.tiposDeOperaciones : 'NINGUNO', disabled: false },
        Validators.required
      ),
      puertos: new FormControl(
        { value: this.rol && this.rol.puertos ? this.rol.puertos : 'NINGUNO', disabled: false },
        Validators.required
      ),
      sofFormat: new FormControl(
        { value: this.rol && this.rol.sofFormat ? this.rol.sofFormat : 'NINGUNO', disabled: false },
        Validators.required
      ),
      terminales: new FormControl(
        { value: this.rol && this.rol.terminales ? this.rol.terminales : 'NINGUNO', disabled: false },
        Validators.required
      ),
      survy: new FormControl(
        { value: this.rol && this.rol.survy ? this.rol.survy : 'NINGUNO', disabled: false },
        Validators.required
      ),
    });
  }

  /**
   * Inincializa los datos del formulario reactivo en modo ver
   */
  inicializarFormualrioModoVer() {
    this.formulario = new FormGroup({
      nombre: new FormControl(
        { value: this.rol && this.rol.nombre ? this.rol.nombre : '', disabled: false },
        Validators.required
      ),
      roles: new FormControl(
        { value: this.rol && this.rol.roles ? this.rol.roles : 'NINGUNO', disabled: false },
        Validators.required
      ),
      viajes: new FormControl(
        { value: this.rol && this.rol.viajes ? this.rol.viajes : 'NINGUNO', disabled: false },
        Validators.required
      ),
      anticipo: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraViajes &&
          this.rol.permisosEspecificosEscrituraViajes.anticipo
            ? this.rol.anticipo
            : '',
        disabled: false,
      }),
      fechaCierrePuerto: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraViajes &&
          this.rol.permisosEspecificosEscrituraViajes.fechaCierrePuerto
            ? this.rol.permisosEspecificosEscrituraViajes.fechaCierrePuerto
            : '',
        disabled: false,
      }),
      fechaFacturacion: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraViajes &&
          this.rol.permisosEspecificosEscrituraViajes.fechaFacturacion
            ? this.rol.permisosEspecificosEscrituraViajes.fechaFacturacion
            : '',
        disabled: false,
      }),
      status: new FormControl({
        value:
          this.rol && this.rol.permisosEspecificosEscrituraViajes && this.rol.permisosEspecificosEscrituraViajes.status
            ? this.rol.permisosEspecificosEscrituraViajes.status
            : '',
        disabled: false,
      }),
      liquidaciones: new FormControl(
        { value: this.rol && this.rol.liquidaciones ? this.rol.liquidaciones : 'NINGUNO', disabled: false },
        Validators.required
      ),
      facturasRecibidas: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraLiquidaciones &&
          this.rol.permisosEspecificosEscrituraLiquidaciones.facturasRecibidas
            ? this.rol.permisosEspecificosEscrituraLiquidaciones.facturasRecibidas
            : '',
        disabled: false,
      }),
      servicios: new FormControl({
        value:
          this.rol &&
          this.rol.permisosEspecificosEscrituraLiquidaciones &&
          this.rol.permisosEspecificosEscrituraLiquidaciones.servicios
            ? this.rol.permisosEspecificosEscrituraLiquidaciones.servicios
            : '',
        disabled: false,
      }),
      buques: new FormControl(
        { value: this.rol && this.rol.buques ? this.rol.buques : 'NINGUNO', disabled: false },
        Validators.required
      ),
      analisisOperativo: new FormControl(
        { value: this.rol && this.rol.analisisOperativo ? this.rol.analisisOperativo : 'NINGUNO', disabled: false },
        Validators.required
      ),
      proformas: new FormControl(
        { value: this.rol && this.rol.proformas ? this.rol.proformas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      tarifas: new FormControl(
        { value: this.rol && this.rol.tarifas ? this.rol.tarifas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      parametrosSistema: new FormControl(
        { value: this.rol && this.rol.parametrosSistema ? this.rol.parametrosSistema : 'NINGUNO', disabled: false },
        Validators.required
      ),
      usuarios: new FormControl(
        { value: this.rol && this.rol.usuarios ? this.rol.usuarios : 'NINGUNO', disabled: false },
        Validators.required
      ),
      empresas: new FormControl(
        { value: this.rol && this.rol.empresas ? this.rol.empresas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      areasEmpresas: new FormControl(
        { value: this.rol && this.rol.areasEmpresas ? this.rol.areasEmpresas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      contactosEmpresas: new FormControl(
        { value: this.rol && this.rol.contactosEmpresas ? this.rol.contactosEmpresas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      remarksSof: new FormControl(
        { value: this.rol && this.rol.contactosEmpresas ? this.rol.contactosEmpresas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      pqrs: new FormControl(
        { value: this.rol && this.rol.pqrs ? this.rol.pqrs : 'NINGUNO', disabled: false },
        Validators.required
      ),
      productosViaje: new FormControl(
        { value: this.rol && this.rol.productosViaje ? this.rol.productosViaje : 'NINGUNO', disabled: false },
        Validators.required
      ),
      productos: new FormControl(
        { value: this.rol && this.rol.productos ? this.rol.productos : 'NINGUNO', disabled: false },
        Validators.required
      ),
      categoriasSof: new FormControl(
        { value: this.rol && this.rol.categoriasSof ? this.rol.categoriasSof : 'NINGUNO', disabled: false },
        Validators.required
      ),
      medidas: new FormControl(
        { value: this.rol && this.rol.medidas ? this.rol.medidas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      husbandry: new FormControl(
        { value: this.rol && this.rol.husbandry ? this.rol.husbandry : 'NINGUNO', disabled: false },
        Validators.required
      ),
      businessLines: new FormControl(
        { value: this.rol && this.rol.businessLines ? this.rol.businessLines : 'NINGUNO', disabled: false },
        Validators.required
      ),
      noticias: new FormControl(
        { value: this.rol && this.rol.noticias ? this.rol.noticias : 'NINGUNO', disabled: false },
        Validators.required
      ),
      notificaciones: new FormControl(
        { value: this.rol && this.rol.notificaciones ? this.rol.notificaciones : 'NINGUNO', disabled: false },
        Validators.required
      ),
      cargas: new FormControl(
        { value: this.rol && this.rol.cargas ? this.rol.cargas : 'NINGUNO', disabled: false },
        Validators.required
      ),
      agents: new FormControl(
        { value: this.rol && this.rol.agents ? this.rol.agents : 'NINGUNO', disabled: false },
        Validators.required
      ),
      commodities: new FormControl(
        { value: this.rol && this.rol.commodities ? this.rol.commodities : 'NINGUNO', disabled: false },
        Validators.required
      ),
      kims: new FormControl(
        { value: this.rol && this.rol.kims ? this.rol.kims : 'NINGUNO', disabled: false },
        Validators.required
      ),
      tiposDeOperaciones: new FormControl(
        { value: this.rol && this.rol.tiposDeOperaciones ? this.rol.tiposDeOperaciones : 'NINGUNO', disabled: false },
        Validators.required
      ),
      puertos: new FormControl(
        { value: this.rol && this.rol.puertos ? this.rol.puertos : 'NINGUNO', disabled: false },
        Validators.required
      ),
      sofFormat: new FormControl(
        { value: this.rol && this.rol.sofFormat ? this.rol.sofFormat : 'NINGUNO', disabled: false },
        Validators.required
      ),
      terminales: new FormControl(
        { value: this.rol && this.rol.terminales ? this.rol.terminales : 'NINGUNO', disabled: false },
        Validators.required
      ),
      survy: new FormControl(
        { value: this.rol && this.rol.survy ? this.rol.survy : 'NINGUNO', disabled: false },
        Validators.required
      ),
    });
  }

  /**
   * Se asegura que los datos ingresados al formulario sean correctos y envia los datos a la BD
   */
  onEnviarFormulario(): void {
    this.cargandoModulo = true;
    if (this.formulario.invalid) {
      Swal.fire({
        title: `No se puede ${this.modo.toLocaleLowerCase()} el rol`,
        text: 'Hay campos requeridos vacíos o campos con valores incorrectos.',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      this.cargandoModulo = false;
    } else {
      const rol = this.contenidoFormularioAJsonBD();

      if (this.modo == this.modos[0]) {
        this.mainService.post('api/rol', rol).subscribe((result) => {
          if (result) {
            this.cambioEnRol.emit(result);

            Swal.fire('Éxito', 'Se agregó el rol exitosamente', 'success').then((result) => {
              if (result.value) {
                this.cerrarDialog();
              }
            });
          } else {
            Swal.fire('Error', 'No se pudo agregar el rol, verifique la información', 'warning');
          }

          this.cargandoModulo = false;
        });
      } else {
        this.mainService.put('api/rol/' + this.rol._id, rol).subscribe((result) => {
          if (result) {
            this.cambioEnRol.emit(result);

            Swal.fire('Éxito', 'Se actualizó el rol exitosamente', 'success').then((result) => {
              if (result.value) {
                this.cerrarDialog();
              }
            });
          } else {
            Swal.fire('Error', 'No se pudo actualizar el rol, verifique la información', 'warning');
          }
        });
      }
    }
  }

  /**
   * Transforma el contenido del formulario al formato del JSON del modelo de un rol en la BD
   */
  contenidoFormularioAJsonBD(): any {
    const contenidoFormulario = this.formulario.value;

    const rol = {
      nombre: contenidoFormulario.nombre,
      roles: contenidoFormulario.roles,
      viajes: contenidoFormulario.viajes,
      permisosEspecificosEscrituraViajes: {
        anticipo: contenidoFormulario.anticipo ? true : false,
        fechaCierrePuerto: contenidoFormulario.fechaCierrePuerto ? true : false,
        fechaFacturacion: contenidoFormulario.fechaFacturacion ? true : false,
        status: contenidoFormulario.status ? true : false,
      },
      liquidaciones: contenidoFormulario.liquidaciones,
      permisosEspecificosEscrituraLiquidaciones: {
        facturasRecibidas: contenidoFormulario.facturasRecibidas ? true : false,
        servicios: contenidoFormulario.servicios ? true : false,
      },
      buques: contenidoFormulario.buques,
      analisisOperativo: contenidoFormulario.analisisOperativo,
      proformas: contenidoFormulario.proformas,
      tarifas: contenidoFormulario.tarifas,
      parametrosSistema: contenidoFormulario.parametrosSistema,
      usuarios: contenidoFormulario.usuarios,
      empresas: contenidoFormulario.empresas,
      areasEmpresas: contenidoFormulario.areasEmpresas,
      contactosEmpresas: contenidoFormulario.contactosEmpresas,
      pqrs: contenidoFormulario.pqrs,
      productosViaje: contenidoFormulario.productosViaje,
      productos: contenidoFormulario.productos,
      categoriasSof: contenidoFormulario.categoriasSof,
      remarksSof: contenidoFormulario.remarksSof,
      medidas: contenidoFormulario.medidas,
      husbandry: contenidoFormulario.husbandry,
      businessLines: contenidoFormulario.businessLines,
      noticias: contenidoFormulario.noticias,
      notificaciones: contenidoFormulario.notificaciones,
      cargas: contenidoFormulario.cargas,
      agents: contenidoFormulario.agents,
      commodities: contenidoFormulario.commodities,
      kims: contenidoFormulario.kims,
      terminales: contenidoFormulario.terminales,
      puertos: contenidoFormulario.puertos,
      tiposDeOperaciones: contenidoFormulario.tiposDeOperaciones,
      sofFormat: contenidoFormulario.sofFormat,
      survy: contenidoFormulario.survy,
    };

    return rol;
  }

  /**
   * Cierra la ventana de dialogo del detalle
   */
  cerrarDialog() {
    this.dialogRef.close();
  }

  /**
   * Maneja el evento del cambio de permisos en los viajes
   */
  onCambioPermisosViajes(): void {
    if (this.formulario.value.viajes == 'ESCRITURA') this.permisosEspecificosViajes = true;
    else {
      this.permisosEspecificosViajes = false;
      this.formulario.controls['anticipo'].setValue('');
      this.formulario.controls['fechaCierrePuerto'].setValue('');
      this.formulario.controls['fechaFacturacion'].setValue('');
      this.formulario.controls['status'].setValue('');
    }
  }

  /**
   * Maneja el evento del cambio de permisos en las liquidaciones
   */
  onCambioPermisosLiquidaciones(): void {
    if (this.formulario.value.liquidaciones == 'ESCRITURA') this.permisosEspecificosLiquidaciones = true;
    else {
      this.permisosEspecificosLiquidaciones = false;
      this.formulario.controls['facturasRecibidas'].setValue('');
      this.formulario.controls['servicios'].setValue('');
    }
  }
}
