import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { MainService } from '../services/main.service';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  /** Configuracion de la tabla */
  settings: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'borrar', title: '<i class="nb-trash"></i>' },
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
      ],
    },
    columns: {
      nombre: {
        title: 'Nombre',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Datos de los roles en la BD */
  public roles: any = [];
  /** Tipos de modos para el detalle */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Modo actual para el detalle */
  public modo: String = 'CREAR';
  /** Dato seleccionado por el usuario*/
  public rol: any;
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = false;

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  /** Referencia al detalle*/
  @ViewChild('rolDetalle', { static: true }) rolDetalle: ElementRef;

  constructor(private dialogService: NbDialogService, private mainService: MainService, private router: Router, private timeService: TimeService) {}

  ngOnInit() {
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.roles === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.cargando = true;
        this.inicializarDatos();
      }
    });
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  inicializarDatos() {
    this.roles = [];

    this.mainService.get('api/rol?activo=true').subscribe((res) => {
      this.roles = res;
      this.cargando = false;
    });
  }

  /**
   * Inicializar creacion de un rol
   */
  onCrearRol() {
    this.rol = null;
    this.modo = this.modos[0];
    this.abrirRolDetalleDialog(this.rolDetalle);
  }

  /**
   * Abre el detalle de un rol
   * @param dialog Referencia al detalle
   */
  abrirRolDetalleDialog(dialog) {
    if (this.permisosUsuario.roles === 'LECTURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
    }
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Crear, Ver o editar
   */
  onCustom(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarRol(event);
        break;
      case 'editar':
        this.editarRol(event);
        break;
      case 'ver':
        this.verRol(event);
        break;
    }
  }

  /**
   * Borra una area de una empresa
   */
  borrarRol(event) {
    const usuarioActual = JSON.parse(localStorage.getItem('usuario'));
    if (event.data._id === '62a0f1d3fea5ec8088ebf094' && usuarioActual.tipo !== '62a0f1d3fea5ec8088ebf094') {
      Swal.fire({
        title: 'Error',
        text: 'No se puede eliminar el rol de administrador',
        type: 'error',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el rol?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.mainService.delete('api/rol/' + event.data._id).subscribe((res) => {
          if (res.message) {
            Swal.fire({
              title: 'Error',
              text: res.message,
              type: 'error',
            });
            return;
          }
          Swal.fire('Éxito', 'Rol eliminado con éxito', 'success');
          this.roles = [];
          this.inicializarDatos();
        });
      }
    });
  }

  /**
   * Inicializa la edicion de un area de una empresa
   * @param event Seleccion del area empresa que se quiere editar
   */
  editarRol(event) {
    const usuarioActual = JSON.parse(localStorage.getItem('usuario'));
    if (event.data._id === '62a0f1d3fea5ec8088ebf094' && usuarioActual.tipo !== '62a0f1d3fea5ec8088ebf094') {
      Swal.fire({
        title: 'Error',
        text: 'No se puede editar el rol de administrador',
        type: 'error',
      });
      return;
    }
    this.rol = event.data;
    this.modo = this.modos[1];
    this.abrirRolDetalleDialog(this.rolDetalle);
  }

  /**
   * Inicializa la visualización de un area de una empresa
   * @param event Seleccion del area empresa que se quiere editar
   */
  verRol(event) {
    this.rol = event.data;
    this.modo = this.modos[2];
    this.abrirRolDetalleDialog(this.rolDetalle);
  }
}
