import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../../../services/main.service';
import { ReportesService } from '../../reportes.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { VistaPreviaReporteZarpeComponent } from './componentes/vista-previa-reporte-zarpe/vista-previa-reporte-zarpe.component';
import { S3 } from 'aws-sdk';
import { S3Service } from '../../../../../../public/services/s3.service';
import { AlertService } from '../../../../../../services/alertService/alert-service.service';
import { TimeService } from '../../../../../../services/time/time.service';

@Component({
  selector: 'reporte-zarpe',
  templateUrl: './reporte-zarpe.component.html',
  styleUrls: ['./reporte-zarpe.component.scss'],
})
export class ReporteZarpeComponent implements OnInit {
  /** Viaje del reporte de zarpe */
  public viaje: any = {};
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Formulario reactivo del reporte de zarpe */
  public formulario: FormGroup;
  /** Archivos adjuntos en el reporte de zarpe */
  public archivosAdjuntos: any[] = [];
  /** Posibles estados en el Port Log */
  public estadosPortLog: string[] = ['CANCELED', 'CONFIRMED', 'ESTIMATED', 'NOT ESTIMATED'];
  public estadosPortLogToShow: { [key: string]: string } = {
    CANCELED: 'Canceled',
    CONFIRMED: 'Confirmed',
    ESTIMATED: 'Estimated',
    'NOT ESTIMATED': 'Not estimated',
  };
  /** Posibles errores en los datos ingresados por el usuario */
  public erroresDatosIngresados: string[] = ['NINGUNO', 'FECHA_INCOMPLETA', 'MAGNITUD_FECHAS_INCORRECTA'];
  /** Indica que los archivos adjuntos se estan cargando */
  public archivosAdjuntosCargandose: boolean = false;
  /** Arreglo con todas las áreas con sus correos */
  public empresasAreasConCorreos: any[] = [];
  /** Arreglo con los correos de los contactos asociados al viaje */
  public emailsContactosViaje: any[] = [];
  /** Arreglo con todas las áreas con sus correos */
  public areasConCorreos: any[] = [];
  /** Arreglo con todas las áreas de las empresas */
  public areasEmpresas: any[] = [];
  /** Email del puerto asociado */
  public emailPuerto: string = '';
  /** Arreglo con los posibles errores al intentar enviar un correo */
  public erroresEnvioCorreo: string[] = ['NINGUNO', 'SIN_ASUNTO', 'SIN_CONTACTOS'];
  /** Indica si se incluyen las actividades o no */
  public incluirActividades: boolean = true;
  /** Indica si se incluyen las tablas de arrival y sailing conditions o no */
  public incluirTablas: boolean = true;
  /** Arreglo ordenado de las actividades confirmadas*/
  public arregloOrdenadoActividadesConfirmadas: any;
  /** Arreglo ordenado de las actividades estimadas*/
  public arregloOrdenadoActividadesEstimadas: any;
  /** Arreglo ordenado de las actividades sof confirmadas */
  public arregloOrdenadoActividadesSofConfirmadas: any[] = [];
  /** Arreglo ordenado de las actividades sof estimadas */
  public arregloOrdenadoActividadesSofEstimadas: any[] = [];
  /** Indica los posibles reportes del modeulo */
  public posiblesReportesModulo: string[] = ['Fin Operaciones', 'Zarpe'];
  /** Indica si se esta en el reporte de zarpe o en el reporte de fin de operaciones*/
  public reporteActual: string = 'Cargando ...';

  /** Lista de correos con copia */
  public ccs: any[] = [];
  /** Nuevo correo para agregar a la copia */
  public nuevoCC: FormControl = new FormControl('', [
    Validators.email,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
  ]);
  /** Nuevo correo para agregar a la copia */
  public nuevoBCC: FormControl = new FormControl('', [
    Validators.email,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z-9.-]+\\.[a-zA-Z]{2,4}$'),
  ]);
  /** Lista de correos con copia oculta */
  public bccs: any[] = [];
  /** Indica si se está enviando un correo */
  public enviandoCorreo: boolean = false;
  /** Indica si desea enviar correo */
  public enviarCorreo: boolean = false;
  /** Referencia al archivo que se va a adjuntar */
  @ViewChild('archjivoAAdjuntar', { static: true }) archjivoAAdjuntar: ElementRef;
  /** Referencia al modal para + cambios antes de ir a vista previa */
  @ViewChild('guardarVistaPrevia', { static: false }) guardarVistaPrevia: TemplateRef<any>;
  public buqueCorreo: string;

  public usuarioActual = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;
  /** Indica el tipo de reporte que se está viendo (operaciones o zarpe) */
  public tipoReporte: string;

  private openDialog: any;
  private vistaPrevia: boolean;

  /** Imágenes del buque */
  public fotoBuque: string;
  public iconoBuque: string;

  /** bucket de s3 */
  private bucket: S3;
  /** URL del documento adjunto */
  private documentURL: string;

  nombreEmpresa: string;
  commodityNombre: string;
  buqueNombre: string;
  terminalNombre: string;
  puertoNombre: string;

  constructor(
    public router: Router,
    private mainService: MainService,
    private reportesService: ReportesService,
    private _location: Location,
    private dialogService: NbDialogService,
    private s3Service: S3Service,
    private alertService: AlertService,
    private timeService: TimeService
  ) {
    this.bucket = this.s3Service.getBucket();
  }

  ngOnInit() {
    this.inicializarFormualrioVacio();
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  async obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuarioActual.tipo}`).subscribe(async (res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.analisisOperativo === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.obtenerViaje();
      }
    });
  }

  public deaultSubjects: string[] = [];
  public setDefaultSubjects() {
    const nv = this.viaje.nv;
    const vesselName = this.viaje.buqueNombre;
    const portName = this.viaje.puertoNombre;
    const voyageNumber = this.viaje.voyageNumber ? `${this.viaje.voyageNumber + ' -'}` : '';

    if (this.tipoReporte === 'fin de operaciones') {
      this.deaultSubjects.push(`MV ${vesselName} ${voyageNumber} ${nv} - ${portName} - COMPLETED OPERATION REPORT`);
    } else {
      this.deaultSubjects.push(`MV ${vesselName} ${voyageNumber} ${nv} - ${portName} - SAILING REPORT`);
    }

    this.selectedSubject = this.deaultSubjects[0];
    this.setSubject(this.formulario.value.asuntoReporte);
  }

  public selectedSubject: string;
  public setSubject(subjectSelected?: string) {
    this.formulario.patchValue({
      asuntoReporte: subjectSelected || this.selectedSubject,
    });

    this.selectedSubject = subjectSelected || this.selectedSubject;
  }

  async obtenerViaje(): Promise<boolean> {
    this.cargando = true;
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    if (partesURL[2] === 'reporte-fin-operaciones') this.tipoReporte = 'fin de operaciones';
    if (partesURL[2] === 'reporte-zarpe') this.tipoReporte = 'de zarpe';

    return new Promise((resolve, reject) => {
      this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe(async (res) => {
        if (res.message) {
          Swal.fire({
            title: '¡Error!',
            text: res.message,
            type: 'error',
          });
          return this.router.navigate(['home/dashboard']);
        }
        this.viaje = res;
        this.setDefaultSubjects();

        this.emailPuerto = this.viaje.puertoCorreo;
        this.obtenerCorreosYEmpresasAsociados();

        const savedSubject = this.formulario.value.asuntoReporte;
        const savedFiles = this.archivosAdjuntos;
        if (this.viaje.sof.length > 0) {
          await this.obtenerActividadesSof(this.viaje.sof[0]);
        }
        await this.inicializarViajeEnFormatoRequerido();
        this.inicializarFormualrioConDatosViaje();
        this.obtenerActividadesEstimadasYConfirmadasOrdenadas();
        this.archivosAdjuntos = savedFiles;
        this.formulario.patchValue({ asuntoReporte: savedSubject });
        this.cargando = false;

        this.mixPortogAndSofActivities();

        resolve(true);
      });
    });
  }

  public showSOFActivities = false;
  public includeSOFActivities() {
    this.showSOFActivities = !this.showSOFActivities;
  }

  /**
   * Inicializa el viaje en el formato requerido para el componente de reporte de zarpe
   */
  async inicializarViajeEnFormatoRequerido() {
    await this.obtenerNombresAsociacionesRelevantesViaje();
    this.fechasEnFormatoFormulario();

    this.archivosAdjuntos =
      this.viaje.reporteArribo && this.viaje.reporteArribo.archivosAdjuntos
        ? this.viaje.reporteArribo.archivosAdjuntos
        : [];
  }

  /**
   * Obtiene los nombres de las asociaciones del viaje que son relevantes al componente de reporte de zarpe/fin de operaciones, también trae los iconos e imagenes del buque
   */
  obtenerNombresAsociacionesRelevantesViaje(): any {
    // Nombre del buque e imágenes
    if (this.viaje.vessel) {
      this.mainService.get(`api/buque?BUQ_ID=${this.viaje.vessel}`).subscribe((res) => {
        this.buqueCorreo = res.length > 0 ? res[0].BUQ_CORREO : undefined;
        this.iconoBuque = res.length > 0 ? res[0].BUQ_FOTO_ICONO : undefined;
        this.fotoBuque = res.length > 0 ? res[0].BUQ_FOTO_REAL : undefined;
        this.viaje.buqueNombre = res.length > 0 ? res[0].BUQ_NOMBRE : 'Not found';
        this.buqueNombre = res.length > 0 ? res[0].BUQ_NOMBRE : 'Not found';
      });
    } else {
      this.viaje.buqueNombre = 'Not registered';
      this.buqueNombre = 'Not registered';
    }
  }

  /**
   * Trae los correos de los contactos asociados al viaje
   */
  obtenerCorreosYEmpresasAsociados() {
    this.mainService.get(`api/empresa`).subscribe((areasEmpresas) => {
      this.areasEmpresas = areasEmpresas;
      this.getContacts();
    });
  }

  getContacts() {
    let arrayFinalSendgrid = [];

    for (let contacto of this.viaje.recieversShippersOthers) {
      const bd = this.mainService.get(`api/contacto_empresa/areaEmpresa/${contacto.areaId}`);
      bd.subscribe((contactosEmpresas) => {
        if (contactosEmpresas && contactosEmpresas[0]) {
          const posiblesEmpresas = contactosEmpresas.map((conEmpresa) => conEmpresa.nombreEmpresa).flat();
          const areasEmpresasFiltradas = posiblesEmpresas.filter((area, index) => {
            return posiblesEmpresas.indexOf(area) === index;
          });
          const posiblesAreas = contactosEmpresas.map((conEmpresa) => conEmpresa.nombreArea).flat();
          const areasAreasFiltradas = posiblesAreas.filter((area, index) => {
            return posiblesAreas.indexOf(area) === index;
          });
          for (let empresa of areasEmpresasFiltradas) {
            for (let area of areasAreasFiltradas) {
              const correos = contactosEmpresas
                .filter((conEmpresa) => {
                  return conEmpresa.nombreEmpresa[0] === empresa && conEmpresa.nombreArea[0] === area;
                })
                .map((contacto) => {
                  return { email: contacto.correo, nombre: contacto.nombre };
                });
              const empresaAreaContactos = {
                empresa,
                area,
                correos,
              };
              arrayFinalSendgrid.push(empresaAreaContactos);
              this.empresasAreasConCorreos = arrayFinalSendgrid;
            }
          }
        }
      });
    }
  }

  /**
   * Agrega los emails de un área a la lista de emails a mandar por sendgrid
   */
  addEmails(area, event) {
    if (event.target.checked) {
      this.emailsContactosViaje = this.emailsContactosViaje.includes(area.correos)
        ? this.emailsContactosViaje
        : this.emailsContactosViaje.concat(area);
    } else {
      this.emailsContactosViaje = this.emailsContactosViaje.filter((areaAgregada) => {
        return !area.correos.includes(...areaAgregada.correos);
      });
    }
  }

  /**
   * Añade o elimina de la lista de destinatarios el correo del buque
   */
  public addedVesselEmail = false;
  addBuqueCorreo(event) {
    const contactoBuque = {
      area: undefined,
      empresa: this.viaje.buqueNombre,
      correos: [
        {
          email: this.buqueCorreo,
          nombre: this.viaje.buqueNombre,
        },
      ],
    };
    if (event.target.checked) {
      this.addedVesselEmail = true;
      const existeContacto = this.emailsContactosViaje.find((contactoAgregado) => {
        return contactoAgregado.empresa === this.viaje.buqueNombre;
      });
      if (!existeContacto) {
        this.emailsContactosViaje.push(contactoBuque);
      }
    } else {
      this.addedVesselEmail = false;
      this.emailsContactosViaje = this.emailsContactosViaje.filter((contactoAgregado) => {
        return contactoAgregado.empresa != this.viaje.buqueNombre;
      });
    }
  }

  /**
   * Pone las fechas del viaje que son relevantes al componente de reporte de prospectos en formato del formulario
   */
  fechasEnFormatoFormulario(): void {
    // Completion OPS time
    if (this.viaje.portLog && this.viaje.portLog.completionOPSTime.fecha) {
      /** Completion OPS time en tipo Date */
      let completionOPSTimeDate = new Date(this.viaje.portLog.completionOPSTime.fecha);
      this.viaje.portLog.completionOPSTimeFecha = this.reportesService.obtenerFechaFormulario(completionOPSTimeDate);
      this.viaje.portLog.completionOPSTimeHora = this.reportesService.obtenerHoraFormulario(completionOPSTimeDate);
    }

    // Narcotics Inspections
    if (this.viaje.portLog && this.viaje.portLog.narcoticsInspections.fecha) {
      /** Narcotics Inspections time en tipo Date */
      let narcoticsInspectionsDate = new Date(this.viaje.portLog.narcoticsInspections.fecha);
      this.viaje.portLog.narcoticsInspectionsFecha =
        this.reportesService.obtenerFechaFormulario(narcoticsInspectionsDate);
      this.viaje.portLog.narcoticsInspectionsHora =
        this.reportesService.obtenerHoraFormulario(narcoticsInspectionsDate);
    }

    // Vessel Unmoored
    if (this.viaje.portLog && this.viaje.portLog.vesselUnmoored.fecha) {
      /** Vessel Unmoored en tipo Date */
      let vesselUnmooredDate = new Date(this.viaje.portLog.vesselUnmoored.fecha);
      this.viaje.portLog.vesselUnmooredFecha = this.reportesService.obtenerFechaFormulario(vesselUnmooredDate);
      this.viaje.portLog.vesselUnmooredHora = this.reportesService.obtenerHoraFormulario(vesselUnmooredDate);
    }

    // Dropping Outward Pilot
    if (this.viaje.portLog && this.viaje.portLog.droppingOutwardPilot.fecha) {
      /** Dropping Outward Pilot en tipo Date */
      let droppingOutwardPilotDate = new Date(this.viaje.portLog.droppingOutwardPilot.fecha);
      this.viaje.portLog.droppingOutwardPilotFecha =
        this.reportesService.obtenerFechaFormulario(droppingOutwardPilotDate);
      this.viaje.portLog.droppingOutwardPilotHora =
        this.reportesService.obtenerHoraFormulario(droppingOutwardPilotDate);
    }

    // Pilot on board
    if (this.viaje.portLog && this.viaje.portLog.pilotOnBoard.fecha) {
      /** Pilot on board en tipo Date */
      let pilotOnBoardDate = new Date(this.viaje.portLog.pilotOnBoard.fecha);
      this.viaje.portLog.pilotOnBoardFecha = this.reportesService.obtenerFechaFormulario(pilotOnBoardDate);
      this.viaje.portLog.pilotOnBoardHora = this.reportesService.obtenerHoraFormulario(pilotOnBoardDate);
    }

    // Sailing Time:
    if (this.viaje.portLog && this.viaje.portLog.sailingTime.fecha) {
      /** Sailing Time: en tipo Date */
      let sailingTimeDate = new Date(this.viaje.portLog.sailingTime.fecha);
      this.viaje.portLog.sailingTimeFecha = this.reportesService.obtenerFechaFormulario(sailingTimeDate);
      this.viaje.portLog.sailingTimeHora = this.reportesService.obtenerHoraFormulario(sailingTimeDate);
    }

    // ETA Next Port
    if (this.viaje.portLog && this.viaje.portLog.etaNextPort.fecha) {
      /** ETA Next Port en tipo Date */
      let etaNextPortDate = new Date(this.viaje.portLog.etaNextPort.fecha);
      this.viaje.portLog.etaNextPortFecha = this.reportesService.obtenerFechaFormulario(etaNextPortDate);
      this.viaje.portLog.etaNextPortHora = this.reportesService.obtenerHoraFormulario(etaNextPortDate);
    }
  }

  /**
   * Inicializa el formulario con los datos vacios
   * (Esta fucnion tiene como error prevebnir el error de que el formulario se renderice antes de obtener los datos del viaje)
   */
  inicializarFormualrioVacio(): void {
    this.formulario = new FormGroup({
      completionOPSTimeFecha: new FormControl({ value: '', disabled: false }),
      completionOPSTimeHora: new FormControl({ value: '', disabled: false }),
      completionOPSTimeEstatus: new FormControl({ value: '', disabled: false }),

      narcoticsInspectionsFecha: new FormControl({ value: '', disabled: false }),
      narcoticsInspectionsHora: new FormControl({ value: '', disabled: false }),
      narcoticsInspectionsEstatus: new FormControl({ value: '', disabled: false }),

      vesselUnmooredFecha: new FormControl({ value: '', disabled: false }),
      vesselUnmooredHora: new FormControl({ value: '', disabled: false }),
      vesselUnmooredEstatus: new FormControl({ value: '', disabled: false }),

      droppingOutwardPilotFecha: new FormControl({ value: '', disabled: false }),
      droppingOutwardPilotHora: new FormControl({ value: '', disabled: false }),
      droppingOutwardPilotEstatus: new FormControl({ value: '', disabled: false }),

      pilotOnBoardFecha: new FormControl({ value: '', disabled: false }),
      pilotOnBoardHora: new FormControl({ value: '', disabled: false }),
      pilotOnBoardEstatus: new FormControl({ value: '', disabled: false }),

      sailingTimeFecha: new FormControl({ value: '', disabled: false }),
      sailingTimeHora: new FormControl({ value: '', disabled: false }),
      sailingTimeEstatus: new FormControl({ value: '', disabled: false }),

      etaNextPortFecha: new FormControl({ value: '', disabled: false }),
      etaNextPortHora: new FormControl({ value: '', disabled: false }),
      etaNextPortEstatus: new FormControl({ value: '', disabled: false }),

      sailingFwd: new FormControl({ value: '', disabled: false }),
      sailingAft: new FormControl({ value: '', disabled: false }),
      sailingFo: new FormControl({ value: '', disabled: false }),
      sailingDo: new FormControl({ value: '', disabled: false }),
      sailingLo: new FormControl({ value: '', disabled: false }),
      sailingFw: new FormControl({ value: '', disabled: false }),
      tugBoatsOut: new FormControl({ value: '', disabled: false }),
      asuntoReporte: new FormControl({ value: '', disabled: false }),
      remark: new FormControl({ value: '', disabled: false }),
    });
  }

  /**
   * Inicializa el formulario con los datos del viaje
   */
  inicializarFormualrioConDatosViaje(): void {
    this.formulario = new FormGroup({
      completionOPSTimeFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.completionOPSTimeFecha
            ? this.viaje.portLog.completionOPSTimeFecha
            : '',
        disabled: false,
      }),
      completionOPSTimeHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.completionOPSTimeHora
            ? this.viaje.portLog.completionOPSTimeHora
            : '',
        disabled: false,
      }),
      completionOPSTimeEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.completionOPSTime.status
            ? this.viaje.portLog.completionOPSTime.status
            : '',
        disabled: false,
      }),
      narcoticsInspectionsFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.narcoticsInspectionsFecha
            ? this.viaje.portLog.narcoticsInspectionsFecha
            : '',
        disabled: false,
      }),
      narcoticsInspectionsHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.narcoticsInspectionsHora
            ? this.viaje.portLog.narcoticsInspectionsHora
            : '',
        disabled: false,
      }),
      narcoticsInspectionsEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.narcoticsInspections.status
            ? this.viaje.portLog.narcoticsInspections.status
            : '',
        disabled: false,
      }),
      vesselUnmooredFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.vesselUnmooredFecha ? this.viaje.portLog.vesselUnmooredFecha : '',
        disabled: false,
      }),
      vesselUnmooredHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.vesselUnmooredHora ? this.viaje.portLog.vesselUnmooredHora : '',
        disabled: false,
      }),
      vesselUnmooredEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.vesselUnmoored.status
            ? this.viaje.portLog.vesselUnmoored.status
            : '',
        disabled: false,
      }),
      droppingOutwardPilotFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.droppingOutwardPilotFecha
            ? this.viaje.portLog.droppingOutwardPilotFecha
            : '',
        disabled: false,
      }),
      droppingOutwardPilotHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.droppingOutwardPilotHora
            ? this.viaje.portLog.droppingOutwardPilotHora
            : '',
        disabled: false,
      }),
      droppingOutwardPilotEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.droppingOutwardPilot.status
            ? this.viaje.portLog.droppingOutwardPilot.status
            : '',
        disabled: false,
      }),
      pilotOnBoardFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.pilotOnBoardFecha ? this.viaje.portLog.pilotOnBoardFecha : '',
        disabled: false,
      }),
      pilotOnBoardHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.pilotOnBoardHora ? this.viaje.portLog.pilotOnBoardHora : '',
        disabled: false,
      }),
      pilotOnBoardEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.pilotOnBoard.status ? this.viaje.portLog.pilotOnBoard.status : '',
        disabled: false,
      }),
      sailingTimeFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.sailingTimeFecha ? this.viaje.portLog.sailingTimeFecha : '',
        disabled: false,
      }),
      sailingTimeHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.sailingTimeHora ? this.viaje.portLog.sailingTimeHora : '',
        disabled: false,
      }),
      sailingTimeEstatus: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.sailingTime.status ? this.viaje.portLog.sailingTime.status : '',
        disabled: false,
      }),
      etaNextPortFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.etaNextPortFecha ? this.viaje.portLog.etaNextPortFecha : '',
        disabled: false,
      }),
      etaNextPortHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.etaNextPortHora ? this.viaje.portLog.etaNextPortHora : '',
        disabled: false,
      }),
      etaNextPortEstatus: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.etaNextPort.status ? this.viaje.portLog.etaNextPort.status : '',
        disabled: false,
      }),
      sailingFwd: new FormControl({
        value: this.viaje.sailingFwd || this.viaje.sailingFwd == 0 ? this.viaje.sailingFwd : '',
        disabled: false,
      }),
      sailingAft: new FormControl({
        value: this.viaje.sailingAft || this.viaje.sailingAft == 0 ? this.viaje.sailingAft : '',
        disabled: false,
      }),
      sailingFo: new FormControl({
        value: this.viaje.sailingFo || this.viaje.sailingFo == 0 ? this.viaje.sailingFo : '',
        disabled: false,
      }),
      sailingDo: new FormControl({
        value: this.viaje.sailingDo || this.viaje.sailingDo == 0 ? this.viaje.sailingDo : '',
        disabled: false,
      }),
      sailingLo: new FormControl({
        value: this.viaje.sailingLo || this.viaje.sailingLo == 0 ? this.viaje.sailingLo : '',
        disabled: false,
      }),
      sailingFw: new FormControl({
        value: this.viaje.sailingFw || this.viaje.sailingFw == 0 ? this.viaje.sailingFw : '',
        disabled: false,
      }),
      tugBoatsOut: new FormControl({
        value: this.viaje.tugBoatsOut || this.viaje.tugBoatsOut == 0 ? this.viaje.tugBoatsOut : '',
        disabled: false,
      }),
      asuntoReporte: new FormControl({ value: '', disabled: false }),
      remark: new FormControl({ value: this.viaje.remark ? this.viaje.remark : '', disabled: false }),
    });
  }

  /**
   * Alista los datos para enviarlos a la BD
   */
  onGuardarReporte({ vistaPrev = false, enviarCorreo = false }): void {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    this.vistaPrevia = vistaPrev;
    this.enviarCorreo = enviarCorreo;
    this.cargando = true;
    const errorDatosIngresados = this.identificarErroresDatosIngresados();

    if (errorDatosIngresados !== this.erroresDatosIngresados[0])
      this.indicarErrorEnDatosIngresados(errorDatosIngresados);
    else {
      /** Objeto con los datos de salida para guardar en la BD */
      let datosSalida = {
        portLog: {
          eosp: this.viaje.portLog.eosp,
          norTendered: this.viaje.portLog.norTendered,
          pilotOnBoardAnchor: this.viaje.portLog.pilotOnBoardAnchor,
          pilotOnBoardBerthing: this.viaje.portLog.pilotOnBoardBerthing,
          droppedAnchor: this.viaje.portLog.droppedAnchor,
          firstLineAshore: this.viaje.portLog.firstLineAshore,
          berthingTime: this.viaje.portLog.berthingTime,
          portAutoritiesOnBoard: this.viaje.portLog.portAutoritiesOnBoard,
          freePractiqueGranted: this.viaje.portLog.freePractiqueGranted,
          commenceOPS: this.viaje.portLog.commenceOPS,
          completionOPSTime: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          narcoticsInspections: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          vesselUnmoored: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          droppingOutwardPilot: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          pilotOnBoard: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          sailingTime: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          etaNextPort: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
        },
        adConditions: {
          sailingFwd: null,
          sailingAft: null,
          sailingFo: null,
          sailingDo: null,
          sailingLo: null,
          sailingFw: null,
          tugBoatsOut: null,
        },
        prospectos: {
          atc: this.viaje.atc,
          atd: this.viaje.atd,
        },
        remark: null,
      };

      if (enviarCorreo && this.tipoReporte == 'fin de operaciones') datosSalida['statusExtra'] = 'COMPLETED';

      // Completion OPS time
      if (this.formulario.value.completionOPSTimeFecha && this.formulario.value.completionOPSTimeHora) {
        datosSalida.portLog.completionOPSTime.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.completionOPSTimeFecha,
          this.formulario.value.completionOPSTimeHora
        );
      }
      if (this.formulario.value.completionOPSTimeEstatus)
        datosSalida.portLog.completionOPSTime.status = this.formulario.value.completionOPSTimeEstatus;
      if (
        this.formulario.value.completionOPSTimeFecha &&
        this.formulario.value.completionOPSTimeHora &&
        this.formulario.value.completionOPSTimeEstatus == 'CONFIRMED'
      ) {
        datosSalida.prospectos.atc = datosSalida.portLog.completionOPSTime.fecha;
      }

      // Narcotics Inspections
      if (this.formulario.value.narcoticsInspectionsFecha && this.formulario.value.narcoticsInspectionsHora) {
        datosSalida.portLog.narcoticsInspections.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.narcoticsInspectionsFecha,
          this.formulario.value.narcoticsInspectionsHora
        );
      }
      if (this.formulario.value.narcoticsInspectionsEstatus)
        datosSalida.portLog.narcoticsInspections.status = this.formulario.value.narcoticsInspectionsEstatus;

      // Vessel Unmoored
      if (this.formulario.value.vesselUnmooredFecha && this.formulario.value.vesselUnmooredHora) {
        datosSalida.portLog.vesselUnmoored.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.vesselUnmooredFecha,
          this.formulario.value.vesselUnmooredHora
        );
      }
      if (this.formulario.value.vesselUnmooredEstatus)
        datosSalida.portLog.vesselUnmoored.status = this.formulario.value.vesselUnmooredEstatus;

      // Dropping Outward Pilot
      if (this.formulario.value.droppingOutwardPilotFecha && this.formulario.value.droppingOutwardPilotHora) {
        datosSalida.portLog.droppingOutwardPilot.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.droppingOutwardPilotFecha,
          this.formulario.value.droppingOutwardPilotHora
        );
      }
      if (this.formulario.value.droppingOutwardPilotEstatus)
        datosSalida.portLog.droppingOutwardPilot.status = this.formulario.value.droppingOutwardPilotEstatus;

      // Pilot on board
      if (this.formulario.value.pilotOnBoardFecha && this.formulario.value.pilotOnBoardHora) {
        datosSalida.portLog.pilotOnBoard.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.pilotOnBoardFecha,
          this.formulario.value.pilotOnBoardHora
        );
      }
      if (this.formulario.value.pilotOnBoardEstatus)
        datosSalida.portLog.pilotOnBoard.status = this.formulario.value.pilotOnBoardEstatus;

      // Sailing Time
      if (this.formulario.value.sailingTimeFecha && this.formulario.value.sailingTimeHora) {
        datosSalida.portLog.sailingTime.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.sailingTimeFecha,
          this.formulario.value.sailingTimeHora
        );
      }
      if (this.formulario.value.sailingTimeEstatus)
        datosSalida.portLog.sailingTime.status = this.formulario.value.sailingTimeEstatus;
      if (
        this.formulario.value.sailingTimeFecha &&
        this.formulario.value.sailingTimeHora &&
        this.formulario.value.sailingTimeEstatus == 'CONFIRMED'
      ) {
        datosSalida.prospectos.atd = datosSalida.portLog.sailingTime.fecha;
        if (enviarCorreo && this.tipoReporte != 'fin de operaciones') datosSalida['statusExtra'] = 'SHIPPED';
      }

      // ETA Next Port
      if (this.formulario.value.etaNextPortFecha && this.formulario.value.etaNextPortHora) {
        datosSalida.portLog.etaNextPort.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.etaNextPortFecha,
          this.formulario.value.etaNextPortHora
        );
      }
      if (this.formulario.value.etaNextPortEstatus)
        datosSalida.portLog.etaNextPort.status = this.formulario.value.etaNextPortEstatus;

      // Sailing Fwd
      if (this.formulario.value.sailingFwd || this.formulario.value.sailingFwd == 0) {
        datosSalida.adConditions.sailingFwd = this.formulario.value.sailingFwd;
      }

      // Sailing Aft
      if (this.formulario.value.sailingAft || this.formulario.value.sailingAft == 0) {
        datosSalida.adConditions.sailingAft = this.formulario.value.sailingAft;
      }

      // Sailing Fo
      if (this.formulario.value.sailingFo || this.formulario.value.sailingFo == 0) {
        datosSalida.adConditions.sailingFo = this.formulario.value.sailingFo;
      }

      // Sailing Do
      if (this.formulario.value.sailingDo || this.formulario.value.sailingDo == 0) {
        datosSalida.adConditions.sailingDo = this.formulario.value.sailingDo;
      }

      // Sailing Lo
      if (this.formulario.value.sailingLo || this.formulario.value.sailingLo == 0) {
        datosSalida.adConditions.sailingLo = this.formulario.value.sailingLo;
      }

      // Sailing FW
      if (this.formulario.value.sailingFw || this.formulario.value.sailingFw == 0) {
        datosSalida.adConditions.sailingFw = this.formulario.value.sailingFw;
      }

      // Tug Boats In
      if (this.formulario.value.tugBoatsOut || this.formulario.value.tugBoatsOut == 0) {
        datosSalida.adConditions.tugBoatsOut = this.formulario.value.tugBoatsOut;
      }

      // Remark
      if (this.formulario.value.remark) {
        datosSalida.remark = this.formulario.value.remark;
      }

      this.enviarDatosABD(datosSalida);
    }
  }

  /**
   * Identifica si hay errores en los datos ingresados por el usuario
   */
  identificarErroresDatosIngresados(): string {
    if (this.errorFechaIncompletaPresente()) return this.erroresDatosIngresados[1];
    if (this.errorMagnitudFechasIncorrecta()) return this.erroresDatosIngresados[2];

    return this.erroresDatosIngresados[0];
  }

  /**
   * Indica al usuario del error en los datos ingresados
   */
  indicarErrorEnDatosIngresados(error: string): void {
    if (error == this.erroresDatosIngresados[1]) {
      Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Todas las fechas deben tener tanto fecha como hora',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (error == this.erroresDatosIngresados[2]) {
      Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Se debe cumplir lo siguiente: (Completion OPS time < Sailing Time)',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    }

    this.cargando = false;
  }

  /**
   * Indica si el error de fecha incompleta esta presente
   */
  errorFechaIncompletaPresente(): boolean {
    // Completion OPS time
    if (this.formulario.value.completionOPSTimeFecha && !this.formulario.value.completionOPSTimeHora) return true;
    if (!this.formulario.value.completionOPSTimeFecha && this.formulario.value.completionOPSTimeHora) return true;

    // Narcotics Inspections
    // if (this.formulario.value.narcoticsInspectionsFecha && !this.formulario.value.narcoticsInspectionsHora) return true;
    // if (!this.formulario.value.narcoticsInspectionsFecha && this.formulario.value.narcoticsInspectionsHora) return true;

    // Vessel Unmoored
    if (this.formulario.value.vesselUnmooredFecha && !this.formulario.value.vesselUnmooredHora) return true;
    if (!this.formulario.value.vesselUnmooredFecha && this.formulario.value.vesselUnmooredHora) return true;

    // Dropping Outward Pilot
    if (this.formulario.value.droppingOutwardPilotFecha && !this.formulario.value.droppingOutwardPilotHora) return true;
    if (!this.formulario.value.droppingOutwardPilotFecha && this.formulario.value.droppingOutwardPilotHora) return true;

    // Pilot on board
    if (this.formulario.value.pilotOnBoardFecha && !this.formulario.value.pilotOnBoardHora) return true;
    if (!this.formulario.value.pilotOnBoardFecha && this.formulario.value.pilotOnBoardHora) return true;

    // Sailing Time
    if (this.formulario.value.sailingTimeFecha && !this.formulario.value.sailingTimeHora) return true;
    if (!this.formulario.value.sailingTimeFecha && this.formulario.value.sailingTimeHora) return true;

    // ETA Next Port
    if (this.formulario.value.etaNextPortFecha && !this.formulario.value.etaNextPortHora) return true;
    if (!this.formulario.value.etaNextPortFecha && this.formulario.value.etaNextPortHora) return true;

    return false;
  }

  /**
   * Indica si no se esta cumpliendo la condicion: completionOPSTime <= sailingTime
   */
  errorMagnitudFechasIncorrecta(): boolean {
    let completionOPSTime = 0;
    let sailingTime = 0;

    // Obtener las fechas en formato Date
    if (this.formulario.value.completionOPSTimeFecha && this.formulario.value.completionOPSTimeHora)
      completionOPSTime = this.reportesService.obtenerFechaUTC(
        this.formulario.value.completionOPSTimeFecha,
        this.formulario.value.completionOPSTimeHora
      );

    if (this.formulario.value.sailingTimeFecha && this.formulario.value.sailingTimeHora)
      sailingTime = this.reportesService.obtenerFechaUTC(
        this.formulario.value.sailingTimeFecha,
        this.formulario.value.sailingTimeHora
      );

    // Comparar fechas para asegurarse que completionOPSTime <= sailingTime
    if (completionOPSTime && sailingTime && completionOPSTime > sailingTime) return true;

    return false;
  }

  /**
   * Envia los datos ingresados por el usaurio a la BD
   * @param datosSalida Datos que van a persistir
   */
  enviarDatosABD(datosSalida): void {
    this.mainService
      .put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, datosSalida)
      .subscribe(async (data) => {
        await this.obtenerViaje();
        this.cargando = false;

        if (!this.enviarCorreo && !this.vistaPrevia) {
          this.alertService.simpleAlertConfirm('Se guardaron los datos exitosamente');
        }

        if (this.enviarCorreo) {
          this.onEnviarCorreoReporte();
          this.enviarCorreo = false;
          return;
        }

        if (this.vistaPrevia) {
          this.alertService.simpleAlertConfirm('Se guardaron los datos exitosamente').then((result) => {
            this.dialogService
              .open(VistaPreviaReporteZarpeComponent, {
                context: {
                  companyName: this.empresasAreasConCorreos.length !== 0 && this.empresasAreasConCorreos[0].empresa,
                  asunto: this.formulario.value.asuntoReporte,
                  parrafosRemarks: this.formulario.value.remark.split('\n'),
                  imagenBuque: this.fotoBuque,
                  iconBuque: this.iconoBuque,
                  attachments: this.archivosAdjuntos,
                  verCuadros: this.tipoReporte !== 'fin de operaciones',
                  sofConfirmadas: this.arregloOrdenadoActividadesSofConfirmadas,
                  sofEstimadas: this.arregloOrdenadoActividadesSofEstimadas,
                  correosCopia: this.ccs.map((cc) => cc.email),
                  correosCopiaOculta: this.bccs.map((bcc) => bcc.email),
                  showSOFActivities: this.showSOFActivities,
                  arregloOrdenadoActividadesConfirmadas: this.arregloOrdenadoActividadesConfirmadas,
                  arregloOrdenadoActividadesEstimadas: this.arregloOrdenadoActividadesEstimadas,
                  incluirActividades: this.incluirActividades,
                  incluirTablas: this.incluirTablas,
                },
              })
              .onClose.subscribe((act) => {
                this.vistaPrevia = false;
                this.obtenerViaje();
              });
          });

          return;
        }

        this.vistaPrevia = false;
        this.enviarCorreo = false;

        return;

        // Swal.fire({
        //   title: 'Se guardaron los datos exitosamente',
        //   type: 'success',
        //   showCancelButton: false,
        //   confirmButtonText: 'Continuar',
        // }).then((result) => {
        //   if (result.value) {
        //     if (this.vistaPrevia) {
        //       this.dialogService
        //         .open(VistaPreviaReporteZarpeComponent, {
        //           context: {
        //             companyName: this.empresasAreasConCorreos.length !== 0 && this.empresasAreasConCorreos[0].empresa,
        //             asunto: this.formulario.value.asuntoReporte,
        //             parrafosRemarks: this.formulario.value.remark.split('\n'),
        //             imagenBuque: this.fotoBuque,
        //             iconBuque: this.iconoBuque,
        //             attachments: this.archivosAdjuntos,
        //             verCuadros: this.tipoReporte !== 'fin de operaciones',
        //             sofConfirmadas: this.arregloOrdenadoActividadesSofConfirmadas,
        //             sofEstimadas: this.arregloOrdenadoActividadesSofEstimadas,
        //             correosCopia: this.ccs.map((cc) => cc.email),
        //             correosCopiaOculta: this.bccs.map((bcc) => bcc.email),
        //             showSOFActivities: this.showSOFActivities,
        //             arregloOrdenadoActividadesConfirmadas: this.arregloOrdenadoActividadesConfirmadas,
        //             arregloOrdenadoActividadesEstimadas: this.arregloOrdenadoActividadesEstimadas,
        //             incluirActividades: this.incluirActividades,
        //             incluirTablas: this.incluirTablas,
        //           },
        //         })
        //         .onClose.subscribe((act) => {
        //           this.vistaPrevia = false;
        //           this.obtenerViaje();
        //         });
        //     } else if (this.enviarCorreo) {
        //       this.onEnviarCorreoReporte();
        //       this.enviarCorreo = false;
        //     } else {
        //       this.vistaPrevia = false;
        //       this.enviarCorreo = false;
        //     }
        //   }
        // });
      });
  }

  public mixPortogAndSofActivities() {
    let newArr = this.arregloOrdenadoActividadesConfirmadas
      .concat(this.arregloOrdenadoActividadesSofConfirmadas || [])
      .sort((a: any, b: any) => {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

    this.arregloOrdenadoActividadesConfirmadas = newArr;

    newArr = this.arregloOrdenadoActividadesEstimadas
      .concat(this.arregloOrdenadoActividadesSofEstimadas || [])
      .sort((a: any, b: any) => {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

    this.arregloOrdenadoActividadesEstimadas = newArr;
  }

  /**
   * Crea el sof asociado al viaje
   */
  onCrearSof(): void {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    this.cargando = true;

    let nuevoSof = {
      voyage: this.viaje.voyageNumber,
      terminal: this.viaje.terminalId,
      nv: this.viaje.nv,
      via_id: this.viaje._id,
      history: [],
    };

    this.mainService.post('api/sof', nuevoSof).subscribe(
      (res) => {
        this.mainService.put(`api/analisis-operativo-viaje/${this.viaje._id}`, { sof: [res._id] }).subscribe(
          (res) => {
            this.viaje = res;
            Swal.fire('Éxito', 'Se creó el viaje exitosamente', 'success');

            this.cargando = false;
          },
          (err) => {
            Swal.fire('Error', 'No se pudo asociar el SOF al viaje', 'error');

            this.cargando = false;
          }
        );
      },
      (err) => {
        Swal.fire('Error', 'No se pudo crear el SOF', 'error');

        this.cargando = false;
      }
    );
  }

  /**
   * Redirigue al detalle del sof
   */
  onVerSof(): void {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    this.router.navigate([
      `home/reporte-${this.reporteActual === 'Fin Operaciones' ? 'fin-operaciones' : 'zarpe'}/ver-sof/${
        this.viaje.recalada.sof[0]
      }/${this.viaje.recalada._id}`,
    ]);
  }

  /**
   * Agrega un nuevo correo a la lista de correos con copia o con copia oculta
   */
  agregarExtraCorreo(extra, lista) {
    if (extra.valid) {
      if (
        this.ccs.find((cc) => cc.email.toLowerCase() === extra.value.toLowerCase()) ||
        this.bccs.find((bcc) => bcc.email.toLowerCase() === extra.value.toLowerCase())
      ) {
        Swal.fire({
          title: '¡Error!',
          text: 'No se puede agregar un correo que ya se encuentre en la lista.',
          type: 'error',
        });
        return;
      }
      if (lista === 'cc') {
        this.ccs.push({ email: extra.value });
        this.nuevoCC.setValue('');
      } else {
        this.bccs.push({ email: extra.value });
        this.nuevoBCC.setValue('');
      }
    }
  }

  /**
   * Activa el modal para guardar cambios antes de ir a la vista previa
   */
  activarModalVistaPrevia() {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    this.openDialog = this.dialogService.open(this.guardarVistaPrevia, { context: '' });
  }

  /**
   * Redirigue a pagina con la visa previa del correo
   */
  onIrVistaPreviaCorreo() {
    this.openDialog.close();
    this.onGuardarReporte({ vistaPrev: true });
  }

  /**
   * Procede a guardar y posteriormente a enviar el correo
   */
  onGuardarInfoEnviarCorreo() {
    this.onGuardarReporte({ enviarCorreo: true });
  }

  /**
   * Elimina un contacto de la lista de contactos agregados en copia
   */
  eliminarContactoCopia(index: number): void {
    this.ccs.splice(index, 1);
  }

  /**
   * Elimina un contacto de la lista de contactos agregados en copia oculta
   */
  eliminarContactoCopiaOculta(index: number): void {
    this.bccs.splice(index, 1);
  }

  /**
   * Valida si no se cumple alguno de los requerimientos para mandar correo desde el reporte de zarpe
   * @returns {boolean} Si falla o no
   */
  validationsFailedForSailing(): boolean {
    return (
      this.permisosUsuario.analisisOperativo !== 'ESCRITURA' ||
      this.formulario.value.completionOPSTimeEstatus !== 'CONFIRMED' ||
      !this.formulario.value.completionOPSTimeFecha ||
      !this.formulario.value.completionOPSTimeHora ||
      // this.formulario.value.narcoticsInspectionsEstatus !== 'CONFIRMED' ||
      // !this.formulario.value.narcoticsInspectionsFecha ||
      // !this.formulario.value.narcoticsInspectionsHora ||
      this.formulario.value.vesselUnmooredEstatus !== 'CONFIRMED' ||
      !this.formulario.value.vesselUnmooredFecha ||
      !this.formulario.value.vesselUnmooredHora ||
      this.formulario.value.droppingOutwardPilotEstatus !== 'CONFIRMED' ||
      !this.formulario.value.droppingOutwardPilotFecha ||
      !this.formulario.value.droppingOutwardPilotHora ||
      this.formulario.value.pilotOnBoardEstatus !== 'CONFIRMED' ||
      !this.formulario.value.pilotOnBoardFecha ||
      !this.formulario.value.pilotOnBoardHora ||
      this.formulario.value.sailingTimeEstatus !== 'CONFIRMED' ||
      !this.formulario.value.sailingTimeFecha ||
      !this.formulario.value.sailingTimeHora ||
      this.formulario.value.etaNextPortEstatus !== 'CONFIRMED' ||
      !this.formulario.value.etaNextPortFecha ||
      !this.formulario.value.etaNextPortHora
    );
  }

  /**
   * Valida si no se cumple alguno de los requerimientos para mandar correo desde el reporte fin de operaciones
   * @returns {boolean} Si falla o no
   */
  validationsFailedForEndOfOperations(): boolean {
    return (
      this.permisosUsuario.analisisOperativo !== 'ESCRITURA' ||
      this.formulario.value.completionOPSTimeEstatus !== 'CONFIRMED' ||
      !this.formulario.value.completionOPSTimeFecha ||
      !this.formulario.value.completionOPSTimeHora
    );
  }

  /**
   * Envia el correo con el repore
   */
  onEnviarCorreoReporte(): void {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    if (this.tipoReporte === 'fin de operaciones' && this.validationsFailedForEndOfOperations()) {
      return;
    } else if (this.tipoReporte !== 'fin de operaciones' && this.validationsFailedForSailing()) {
      return;
    }
    this.cargando = true;
    if (this.encontrarErroresEnvioCorreo() == this.erroresEnvioCorreo[0]) {
      this.enviandoCorreo = true;
      const emailsToSend = [];

      const emails = [];

      for (let destinatario of this.emailsContactosViaje) {
        delete destinatario.nombre;

        const html = this.generarHTMLCorreo(destinatario.empresa);

        const msg = {
          to: destinatario.correos,
          cc: this.ccs,
          bcc: this.bccs,
          html: html,
          attachments: this.archivosAdjuntos,
          fromEmail: this.emailPuerto,
          subject: this.formulario.value.asuntoReporte,
        };

        emails.push(msg);
      }

      this.changeSAPStatus({isHidden: true});
      this.sendEmail(emails);
    }
  }

  /**
   * Cambia las fechas de SAP de arribo y zarpe, cada vez que envía un correo, esto no se validó si es posible por SAP (actualizar más de una vez) pero se cree que sí
   */
  changeSAPStatus({isHidden  = false}) {
    if (
      this.formulario.value.sailingTimeEstatus === 'CONFIRMED' &&
      this.formulario.value.sailingTimeFecha &&
      this.formulario.value.sailingTimeHora
    ) {
      const sailingAndArrivalDates = {
        arribo: this.viaje.portLog.eosp.fecha.split('T')[0],
        zarpe: this.formulario.value.sailingTimeFecha,
        nv: this.viaje.nv,
      };
      this.mainService.post(`api/nuevo-viaje/actualizar-fechas-sap`, sailingAndArrivalDates).subscribe((response) => {
        if (response.success && !isHidden) {
          Swal.fire({
            title: 'Éxito',
            text: 'Se ha actualizado SAP exitosamente',
            type: 'success',
          });
          this.cargando = false;
        }
      });
    }
  }

  generarHTMLCorreo(companyName: string): string {
    const spacerItems = '<br><br>';
    const spacerItem = '<br>';
    const spacerHeader = '<br><br><br>';

    for (let archivoAdjunto of this.archivosAdjuntos) {
      delete archivoAdjunto.path;
    }
    let imagenBuque;
    if ((this.fotoBuque && this.fotoBuque != 'NULL') || (this.iconoBuque && this.iconoBuque != 'NULL')) {
      imagenBuque = this.fotoBuque || this.iconoBuque;
    }
    let html = `<!DOCTYPE html>
		<html lang="en">
		<head>
			<meta charset="UTF-8">
			<meta http-equiv="X-UA-Compatible" content="IE=edge">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
		</head>
		<body style="color:black !important;">`;

    html += '<table style="border-collapse: collapse;">';

    // header
    if (companyName) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">TO:</td><td>${companyName}</td></tr>`;
    }

    if (this.viaje.commodityNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">CARGO:</td><td>${this.viaje.commodityNombre}</td></tr>`;
    }

    if (this.viaje.buqueNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">VESSEL:</td><td>${
        this.viaje.buqueNombre
      } ${this.viaje.voyageNumber? this.viaje.voyageNumber: '' && '- ' + this.viaje.voyageNumber} - ${this.viaje.nv}</td></tr>`;
    }

    if (this.viaje.terminalNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">TERMINAL:</td><td>${this.viaje.terminalNombre}</td></tr>`;
    }

    if (this.viaje.puertoNombre) {
      html += `<tr><td style="padding-right: 0.5rem">PORT:</td><td>${this.viaje.puertoNombre}</td></tr>`;
    }

    html += '</table>';
    html += spacerHeader;

    // image

    if (this.iconoBuque && this.iconoBuque != 'NULL') {
      html += `<img src=${this.iconoBuque} style="width:100%;max-width:400px;height:auto;">`;
      html += spacerItems;
    }

    if (this.fotoBuque && this.fotoBuque != 'NULL') {
      html += `<img src=${this.fotoBuque} style="width:100%;max-width:400px;height:auto;">`;
      html += spacerItems;
    }

    if (
      this.arregloOrdenadoActividadesConfirmadas &&
      this.arregloOrdenadoActividadesConfirmadas.length > 0 &&
      this.incluirActividades
    ) {
      let newArrConfirmadas = this.arregloOrdenadoActividadesConfirmadas;

      html += '<p style="margin: 0;">Please find bellow follow up report of the above mentioned vessel</p>';
      html += spacerItem;

      html += '<table style="table-layout:fixed; max-width:400px;"><tbody>';
      newArrConfirmadas.forEach((actividadConfirmada) => {
        html += `<tr><td style="text-align:left !important;font-weight:400!important;">${
          actividadConfirmada.nombre || actividadConfirmada.type
        }</td><td style="white-space: nowrap;text-align:left !important;font-weight:400!important;text-decoration:none!important;color:black!important;">${
          actividadConfirmada.fechaVisual
        }</td></tr>`;
      });
      html += '</tbody></table>';
      html += spacerItems;

      if (this.arregloOrdenadoActividadesEstimadas && this.arregloOrdenadoActividadesEstimadas.length > 0) {
        let newArr = this.arregloOrdenadoActividadesEstimadas
          .concat(this.arregloOrdenadoActividadesSofEstimadas || [])
          .sort((a, b) => {
            return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
          });
        html +=
          '<table style="table-layout:fixed; max-width:600px;"><tbody><tr><td style="color:black!important;text-align:left!important;" colspan="2">ESTIMATED FOLLOWING SCHEDULE FOR THIS VESSEL</td></tr>';
        newArr.forEach((actividadEstimada) => {
          html += `<tr><td colspan="1"style="text-align:left!important;font-weight:400!important;">Estimated - ${
            actividadEstimada.nombre || actividadEstimada.description
          }</td><td colspan="1"style="text-align:left!important;font-weight:400!important;text-decoration:none!important;color:black!important;">${
            actividadEstimada.fechaVisual
          }</td></tr>`;
        });
        html += '</tbody></table>';
        html += spacerItems;
      }
    }

    if (this.tipoReporte !== 'fin de operaciones' && this.incluirTablas) {
      html +=
        '<table style="table-layout:fixed; border-collapse: collapse;">' +
        '<tr>' +
        '<th colspan="4" style="text-align: center; border: 1px solid; padding: 10px">ARRIVAL CONDITIONS</th>' +
        '</tr>' +
        '<tr>' +
        '<td colspan="2" style="text-align: center; border: 1px solid">BUNKER</td>' +
        '<td colspan="2" style="text-align: center; border: 1px solid">DRAFT</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">FO</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveFo || this.viaje.arriveFo == 0 ? this.viaje.arriveFo : '-'
        } MT</td>` +
        '<td style="padding: 10px; border: 1px solid">FWD</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveFwd || this.viaje.arriveFwd == 0 ? this.viaje.arriveFwd : '-'
        } M</td>` +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">DO</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveDo || this.viaje.arriveDo == 0 ? this.viaje.arriveDo : '-'
        } MT</td>` +
        '<td style="padding: 10px; border: 1px solid">AFT</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveAft || this.viaje.arriveAft == 0 ? this.viaje.arriveAft : '-'
        } M</td>` +
        '</tr>' +
        '	<tr>' +
        '<td style="padding: 10px; border: 1px solid">FW</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveFw || this.viaje.arriveFw == 0 ? this.viaje.arriveFw : '-'
        } MT</td>` +
        '<td style="padding: 10px; border: 1px solid">LO</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveLo || this.viaje.arriveLo == 0 ? this.viaje.arriveLo : '-'
        } CMB</td>` +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">TUGBOATS IN</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.tugBoatsIn || this.viaje.tugBoatsIn == 0 ? this.viaje.tugBoatsIn : '-'
        }</td>` +
        '</tr>' +
        '</table>' +
        spacerItems +
        '<table style="table-layout:fixed; border-collapse: collapse;">' +
        '<tr>' +
        '<th colspan="4" style="text-align: center; border: 1px solid; padding: 10px">SAILING CONDITIONS</th>' +
        '</tr>' +
        '<tr>' +
        '<td colspan="2" style="text-align: center; border: 1px solid">BUNKER</td>' +
        '<td colspan="2" style="text-align: center; border: 1px solid">DRAFT</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">FO</td>' +
        `<td style="padding: 10px; border: 1px solid">${this.viaje.sailingFo ? this.viaje.sailingFo : '-'} MT</td>` +
        '<td style="padding: 10px; border: 1px solid">FWD</td>' +
        `<td style="padding: 10px; border: 1px solid">${this.viaje.sailingFwd ? this.viaje.sailingFwd : '-'}M</td>` +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">DO</td>' +
        `<td style="padding: 10px; border: 1px solid">${this.viaje.sailingDo ? this.viaje.sailingDo : '-'} MT</td>` +
        '<td style="padding: 10px; border: 1px solid">AFT</td>' +
        `<td style="padding: 10px; border: 1px solid">${this.viaje.sailingAft ? this.viaje.sailingAft : '-'}M</td>` +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">FW</td>' +
        `<td style="padding: 10px; border: 1px solid">${this.viaje.sailingFw ? this.viaje.sailingFw : '-'} MT</td>` +
        '<td style="padding: 10px; border: 1px solid">LO</td>' +
        `<td style="padding: 10px; border: 1px solid">${this.viaje.sailingLo ? this.viaje.sailingLo : '-'} CMB</td>` +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">TUGBOATS OUT</td>' +
        `<td style="padding: 10px; border: 1px solid">${this.viaje.tugBoatsOut ? this.viaje.tugBoatsOut : '-'}</td>` +
        '</tr>' +
        '</table>';
      html += spacerItems;
    }

    html += '<p style="font-weight: bold;">Summary</p>';
    html += spacerItem;
    html += this.reportesService.obtenerTextoHTML(this.viaje.remark); // pending
    html += spacerItems;

    html += '<p style="margin: 0;">We will keep you posted</p>';
    html += spacerItems;

    html += '<p style="margin: 0;">Best regards,</p>';
    html += spacerItem;

    if (this.viaje.boardingAgentNombre) {
      html += `<p style="margin: 0;">${this.viaje.boardingAgentNombre}</p>`;
      html += `<p style="margin: 0;">BOARDING AGENT</p>`;
    }

    html += `<p style="margin: 0;">${this.viaje.port.direccion}</p>`;
    html += `<p style="margin: 0;">${this.viaje.port.nombre}</p>`;

    html += `<p style="margin: 0;">Mobile: ${
      this.viaje.boardingAgent && this.viaje.boardingAgent.telefono ? this.viaje.boardingAgent.telefono : 'Not defined'
    }</p>`;

    html += `<p style="margin: 0;">
        Email:
        <a href=${this.viaje.port ? 'mailto:' + this.viaje.port.email : 'Not defined'}>
        ${this.viaje.port ? this.viaje.port.email : 'Not defined'};
        </a>
      </p>`;

    html += `<p style="margin: 0;">
      Web Site:
      <a href="www.navescolombia.com">www.navescolombia.com</a>
    </p>`;

    html += `<p style="text-align: justify;">The information transmitted by this email is confidential and intended solely for use by their (s) recipient (s). His play, read or use is prohibited to any person or entity other, without prior written permission. If you received this in error, please notify the sender immediately and delete it from your system. You may not copy, print or distribute this email or its attachments, or use for any purpose nor disclose its contents to anyone. The opinions, conclusions and other information contained in this email, not related to official business NAVES SAS shall be construed as personal and in no way endorsed by the company. Although Naves SAS has done its best to ensure that this message and any attachments are free from viruses and defects that can potentially affect computers or systems that receive, not responsible for the possible transmission of viruses or malicious programs via this channel, and therefore the recipient's responsibility to confirm the existence of such elements when received and open it. Naves Colombia Neither nor any of its divisions or departments are responsible for possible damages or changes arising from the receipt or use of this message.</p>`;

    html += spacerItem;

    html += `<p style="text-align: justify;">This message has been sent by an automated system, please do not respond to this mail and will not be reviewed
    by any staff because it is an automatic process of sending mail.</p>`;

    html += '</tbody></table></body></html>';
    return html;
  }

  sendEmail(emails) {
    this.mainService.post(`api/sendEmail`, { emails, puerto: this.viaje.puertoNombre }).subscribe((result) => {
      this.emailsContactosViaje = [];
      this.addedVesselEmail = false;
      this.enviandoCorreo = false;
      this.alertService.simpleAlertConfirm('Reporte enviado y SAP actualizado exitosamente');

      const reporteEnviado = {
        tipoReporte: this.reporteActual == this.posiblesReportesModulo[1] ? 'ZARPE' : 'FIN_OPERACIONES',
        fechaDeEnvio: Date.now(),
      };

      if (result.success) {
        this.viaje.reportesEnviados
          ? this.viaje.reportesEnviados.push(reporteEnviado)
          : (this.viaje.reportesEnviados = [reporteEnviado]);
        if (
          this.viaje.statusExtra == 'BERTHED' &&
          this.viaje.atc &&
          this.reporteActual == this.posiblesReportesModulo[0]
        ) {
          this.viaje.statusExtra = 'COMPLETED';
        }
        if (
          this.viaje.statusExtra == 'COMPLETED' &&
          this.viaje.atd &&
          this.reporteActual == this.posiblesReportesModulo[1]
        ) {
          this.viaje.statusExtra = 'SHIPPED';
        }

        this.mainService
          .put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, {
            reporteEnviado,
          })
          .subscribe((res) => {
            this.obtenerViaje();
          });
      } else {
        Swal.fire({
          title: 'Se generó un problema al enviar el correo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.cargando = false;
      }
    });
  }

  /**
   * Encuentra si hay algun error al intentar enviar un correo
   */
  encontrarErroresEnvioCorreo(): string {
    if (!this.formulario.value.asuntoReporte) {
      Swal.fire({
        title: 'El reporte no tiene asunto',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
      return this.erroresEnvioCorreo[1];
    }

    if (this.emailsContactosViaje.length == 0) {
      Swal.fire({
        title: 'El viaje no tiene Receivers, Shippers o Others',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
      return this.erroresEnvioCorreo[2];
    }

    return this.erroresEnvioCorreo[0];
  }

  /**
   * Maneja la seleccion de archivos a adjuntar
   */
  onSeleccionarArchivoAAdjuntar(event) {
    this.archivosAdjuntosCargandose = true;
    const archivoSeleccionado = event.target.files[0];
    if (archivoSeleccionado !== undefined) {
      // Obtener la información necesaria
      const { params, options } = this.s3Service.getInfoForUpload('adjuntoCorreos', archivoSeleccionado);
      // Subir a s3
      this.bucket.upload(params, options, (err, data) => {
        if (err) {
          this.s3Service.showErrorUploading();
        } else {
          this.documentURL = data.Location.toString();
          this.reportesService
            .archivoABase64(event.target.files[0])
            .then((result) => {
              const nuevoArchivo = {
                content: result,
                filename: event.target.files[0].name,
                type: 'application/pdf',
                disposition: 'attachment',
                path: this.documentURL,
              };
              this.archivosAdjuntos.push(nuevoArchivo);
              this.documentURL = '';
              this.archivosAdjuntosCargandose = false;
              this.archjivoAAdjuntar.nativeElement.value = '';
            })
            .catch(() => {
              Swal.fire({
                title: 'Se generó un problema al adjuntar el archivo',
                type: 'error',
                showCancelButton: false,
                confirmButtonText: 'Continuar',
              });
            });
        }
      });
    }
  }

  /**
   * Borra un arhivo de los archivos adjuntos
   * @param indiceArchivo Indice del archivo en los archivos adjuntos
   */
  onBorrarArchivoAdjunto(indiceArchivo: number): void {
    this.archivosAdjuntos.splice(indiceArchivo, 1);
  }

  /**
   * Cambia el estado de la incluison de actividades
   */
  onCambioIncluirActividades() {
    this.incluirActividades = !this.incluirActividades;
  }

  onCambioIncluirTablas() {
    this.incluirTablas = !this.incluirTablas;
  }

  /**
   * Obtiene las actividades confirmadas y estimadas ordenadas cronologicamente
   */
  obtenerActividadesEstimadasYConfirmadasOrdenadas() {
    if (Object.keys(this.viaje.portLog).length > 0) {
      let activdadesOrdenadas = [];
      for (const property in this.viaje.portLog) {
        if (this.viaje.portLog[property].fecha) {
          let actividad = {
            nombre: this.viaje.portLog[property].nombre,
            fecha: this.viaje.portLog[property].fecha,
            status: this.viaje.portLog[property].status,
            fechaVisual: '',
          };

          activdadesOrdenadas.push(actividad);
        }
      }

      activdadesOrdenadas.forEach((actividad) => {
        actividad.fechaVisual = this.reportesService.obtenerFechaVisualReportes(new Date(actividad.fecha));
      });

      activdadesOrdenadas.sort(function (a, b) {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

      this.arregloOrdenadoActividadesConfirmadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status == 'CONFIRMED'
      );
      this.arregloOrdenadoActividadesEstimadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status == 'ESTIMATED'
      );
    } else {
      this.arregloOrdenadoActividadesConfirmadas = [];
      this.arregloOrdenadoActividadesEstimadas = [];
    }
  }

  obtenerActividadesSof(idSof) {
    return new Promise((resolve, reject) => {
      if (idSof) {
        this.mainService.get(`api/sof/${idSof}/${this.viaje.recaladaId}`).subscribe((res) => {
          const actividadesSof = res.history || [];
          const actividadesOrdenadasSof = [];
          for (let actividad of actividadesSof) {
            const formattedDate = this.timeService.combineDatesAndHours(actividad.date, actividad.from)
            actividadesOrdenadasSof.push({
              category: actividad.category,
              type: actividad.type,
              nombre: actividad.type,
              status: actividad.status,
              fecha: formattedDate,
              description: actividad.description,
            });
          }
          actividadesOrdenadasSof.forEach((actividad) => {
            actividad.fechaVisual = this.reportesService.obtenerFechaVisualReportes(new Date(actividad.fecha));
          });
          actividadesOrdenadasSof.sort(function (a, b) {
            return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
          });
          this.arregloOrdenadoActividadesSofConfirmadas = actividadesOrdenadasSof.filter(
            (actividad) => actividad.status == 'Confirmed'
          );
          this.arregloOrdenadoActividadesSofEstimadas = actividadesOrdenadasSof.filter(
            (actividad) => actividad.status == 'Estimated'
          );
          resolve(true);
        });
      }
    });
  }

  /**
   * Devuelve al usuario a la pagina anerior
   */
  onDevolverse() {
    Swal.fire({
      title: '¿Deseas regresar?',
      text: 'Se perderá la información diligenciada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.router.navigate([`home/reportes/${this.viaje._id}/${this.viaje.recalada._id}`]);
      }
    });
  }
}
