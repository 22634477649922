import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MainService } from '../../../services/main.service';
import { DateAndTimeService } from '../../../services/date-and-time.service';
import * as S3 from 'aws-sdk/clients/s3';
import * as _ from 'lodash';
import { ViajeDetalleService } from './viaje-detalle.service';
import { Location } from '@angular/common';
import { UtilsService } from '../../../services/utils.service';
import { ViajesServiceService } from '../../../services/viajes/viajes-service.service';
import * as moment from 'moment';
import {
  AreaModel,
  BuqueModel,
  BusinessLineModel,
  CommodityModel,
  contactInfo,
  ContactoModel,
  EmpresaModel,
  IareaEmpresa,
  IareaEmpresaTotalInfo,
  IboardingAgent,
  IbusinessLine,
  Icommodity,
  Iempresa,
  IempresaTotalFields,
  Ikim,
  InfoCompletaNuevoViaje,
  IoperationType,
  Iport,
  IrecieverShipperOther,
  IrecieverShipperOtherMoreInfo,
  Iterminal,
  IterminalTotalInfo,
  Ivessel,
  KimModel,
  nuevoViaje,
  PuertoModel,
  TerminalModel,
  TipoOperacionModel,
  UserModel,
} from '../../../interfaces/requests.interface';
import { singleSelectsIns } from '../../../../instructions/singleSelect.ins';
import { debounceTime } from 'rxjs/operators';
import STATUSEXTRA from '../../../../constants/statusExtra.const';

@Component({
  selector: 'app-viaje-detalle',
  templateUrl: './viaje-detalle.component.html',
  styleUrls: ['./viaje-detalle.component.scss'],
  providers: [],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViajeDetalleComponent implements OnInit {
  // instructions for fields
  public insStatusExtra = singleSelectsIns.statusExtra;
  public insStatus = singleSelectsIns.status;

  /** Indica si el modo actual es crear */
  public createMode: boolean = false;
  /** Indica si el modo actual es de ver y editar */
  public viewAndEditMode: boolean = false;
  /** Indica si es necesario crear una nueva recalada */
  public createRecaladaMode: boolean = false;
  /** Titulo de la carta */
  public title: string = '';
  /** Indica si la ventana se encuentra minimizada */
  public minimize: boolean = false;
  /** Indica si se hizo un cambio en los datos del formulario*/
  public dataChargedInForm: boolean = false;
  /** Viaje actual */
  public viaje: nuevoViaje = {
    _id: undefined,
    nv: undefined,
    _viaId: undefined,
    vessel: undefined,
    port: undefined,
    boardingAgent: undefined,
    businessLine: undefined,
    kim: undefined,
    commodity: undefined,
    disponentOwner: undefined,
    appointment: undefined,
    charterer: undefined,
    broker: undefined,
    recaladas: undefined,
    tieneProforma: undefined,
    status: undefined,
    voyageNumber: undefined,
    activo: undefined,
    instruction: undefined,
  };

  public infoViaje: any = {};

  public prospectosExtra: any = {};

  /** Indica si el viaje debe poder actualizarse, es decir, que no haya sido cancelado */
  public status: string = undefined;
  /** Indica si algo se encuentra cargando en el modulo */
  public loading: boolean = false;
  public processingVoyage: boolean = false;
  /** Indic el paso actual en el stepper */
  public step: number = 1;
  /** Datos con los recevers shippers y other */
  public rsos: IrecieverShipperOtherMoreInfo[] = [];
  /** Formulario reactivo del viaje */
  public viajeForm: FormGroup;
  /** Formulario reactivo de campos adicionales del viaje */
  public additionalFormFields: FormGroup;
  /** Empresas en la BD */
  public empresas: Iempresa[] = [];
  /** Empresas con todos sus campos en la BD */
  public empresasInfoCompleta: IempresaTotalFields[] = [];
  /** Buques en la BD */
  public vessels: Ivessel[] = [];
  /** Commodities en la BD */
  public commodities: Icommodity[] = [];
  /** Kims en la BD */
  public kims: Ikim[] = [];
  /** Puertos en la BD */
  public ports: Iport[] = [];
  /** Terminales en la BD */
  public terminals: Iterminal[] = [];
  //** Terminales con toda la información */
  public terminalsTotalInfo: IterminalTotalInfo[] = [];
  /** Terminales filtradas por el puerto actualmente seleccionado */
  public terminalsByPort: Iterminal[] = [];
  /** Tipos de operaciones traidos de la BD */
  public operationTypes: IoperationType[] = [];
  /** Lineas de negocio traidos de la BD */
  public businessLines: IbusinessLine[] = [];
  /** Boarding Agents traidos de la BD */
  public boardingAgents: IboardingAgent[] = [];
  /** Areas de empresas traidas de BD */
  public areasEmpresas: IareaEmpresa[] = [];
  /** Areas de empresas filtradas por empresa seleccionada*/
  public areasEmpresasFiltradas: IareaEmpresaTotalInfo[] = [];
  /** Áreas de empresas con toda la información */
  public areasEmpresasTotalInfo: IareaEmpresaTotalInfo[] = [];
  /** Contactos de area de empresa con información necesaria para RSO */
  public contactosTotalInfo: contactInfo[] = [];
  public usersFacturador: UserModel[] = [];
  /** Partes en el area de texto de comentarios de las empresas */
  public commentsParts: string[] = ['[DISPONENT OWNER]\n', '\n[APPOINTMENT]\n', '\n[CHARTERER]\n', '\n[BROKER]\n'];
  /** Comentarios de las empresas seleccionadas */
  public comments: string = this.commentsParts[0].concat(
    this.commentsParts[1],
    this.commentsParts[2],
    this.commentsParts[3]
  );
  /** Archivo seleccionado en el equipo del usuario */
  public selectedFile: any;
  /** Buquet de S3 para almazenar datos */
  public bucket: S3 = new S3({
    accessKeyId: 'AKIAUT7IXVID77RSYJ5F',
    secretAccessKey: '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',
    region: 'us-east-1',
  });
  /** URL de las instrucciones guardadas en s# */
  public instructionUrl: string = '';
  /** NV del viaje actual */
  public nv: number;
  /** VIA_ID del viaje actual */
  public VIA_ID: number;
  /** Indica que se estan cargando los buques*/
  public buquesCargandose: boolean = true;
  /** Indica que se estan cargando las empresas*/
  public empresasCargandose: boolean = true;
  /** Indica que se estan cargando los puertos */
  public puertosCargandose: boolean = true;
  /** Indica que se estan cargando las terminales*/
  public terminalesCargandose: boolean = true;
  /** Indica que se estan cargando los tipos de operacion*/
  public tiposOperacionCargandose: boolean = true;
  /** Indica que se estan cargando los commodities*/
  public commoditiesCargandose: boolean = true;
  /** Indica que se estan cargando los kims*/
  public kimsCargandose: boolean = true;
  /** Indica que se estan cargando las business lines*/
  public businessLinesCargandose: boolean = true;
  /** Indica que se estan cargando las areas de las empresas*/
  public areasEmpresasCargandose: boolean = false;
  /** Indica que se estan cargando los contacos de las empresas*/
  public contactosEmpresasCargandose: boolean = true;
  /** Indica que se estan cargando los boarding agents*/
  public boardingAgentsCargandose: boolean = true;
  /** Informacion del usuario actual */
  public usuarioActual: any = JSON.parse(localStorage.getItem('usuario'));
  /** Indica los permisos que tiene el usuario */
  public permisosUsuario: any;
  /** Indica si ha sido actualizado */
  public actualizado: boolean = false;
  /** Indica si aun esta cargando la información */
  public isLoading: boolean = false;
  /** Parte de arriva de la pagina */
  @ViewChild('top', { static: true }) top: ElementRef;
  /** Referencia al elemento para devolverse */
  @ViewChild('volver', { static: true }) volver: ElementRef;
  /** Icono para minimizar el detalle */
  @ViewChild('minimizeIcon', { static: true }) minimizeIcon: ElementRef;
  /** Visualizacion que se esta en el paso 1 del stepper */
  @ViewChild('bullet1', { static: true }) bullet1: ElementRef;
  /** Visualizacion que se esta en el paso 2 del stepper */
  @ViewChild('bullet2', { static: true }) bullet2: ElementRef;
  /** Visualizacion que se esta en el paso 3 del stepper */
  @ViewChild('bullet3', { static: true }) bullet3: ElementRef;
  /** Visualizacion que se esta en el paso 4 del stepper */
  @ViewChild('bullet4', { static: true }) bullet4: ElementRef;

  /** Conteniido del formulario */
  @ViewChild('formContent', { static: true }) formContent: ElementRef;
  /** Referencia al filtro por empresa de Reciever shipeers y others */
  @ViewChild('rsoFiltroEmpresa', { static: true }) rsoFiltroEmpresa: ElementRef;
  /** Referencia al filtro del tipo de area de los Receivers, Shippers y Others */
  @ViewChild('rsoFiltroArea', { static: true }) rsoFiltroArea: ElementRef;
  /** Referencia al select que de los RSOS */
  @ViewChild('rsoType', { static: true }) rsoType: ElementRef;
  /** Referencia al area de texto con los comentarios */
  @ViewChild('domComments', { static: true }) domComments: ElementRef;
  /** Referencia al archivo con las instrucciones */
  @ViewChild('instructionFile', { static: true }) instructionFile: ElementRef;
  /** Referencia al link de descarga del archivo de instrucciones */
  @ViewChild('downloadInstructionLink', { static: true }) downloadInstructionLink: ElementRef;
  /** Referencia al textarea de remarks, esto debido a que escribir se hace muy lento con un formControl */
  @ViewChild('remark', { static: false }) remark: ElementRef;
  @ViewChild('voyageNumber', { static: false }) voyageNumber: ElementRef;
  @ViewChild('observations', { static: false }) observations: ElementRef;
  // Id del viaje
  viajeId: string;
  constructor(
    public router: Router,
    private mainService: MainService,
    private viajeDetalleService: ViajeDetalleService,
    private _location: Location,
    public dateAndTimeService: DateAndTimeService,
    private utilsService: UtilsService,
    private viajesService: ViajesServiceService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.viajeForm = new FormGroup({
      nv: new FormControl(),
      _id: new FormControl(),
      _viaId: new FormControl(),
      vessel: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
        email: new FormControl(),
        loa: new FormControl(),
        dwt: new FormControl(),
        gwt: new FormControl(),
      }),
      port: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
        email: new FormControl(),
        direccion: new FormControl(),
        cod: new FormControl(),
        centroCoste: new FormControl(),
        centroBeneficio: new FormControl(),
      }),
      boardingAgent: new FormGroup({
        _id: new FormControl(),
        nombre: new FormControl(),
        apellido: new FormControl(),
        email: new FormControl(),
        telefono: new FormControl(),
        empresa: new FormControl(),
      }),
      businessLine: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
        regular: new FormControl(),
        tramp: new FormControl(),
      }),
      kim: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
      }),
      commodity: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
      }),
      disponentOwner: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
      }),
      appointment: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
      }),
      charterer: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
      }),
      broker: new FormGroup({
        _id: new FormControl(),
        id: new FormControl(),
        nombre: new FormControl(),
      }),
      recaladas: new FormArray([
        new FormGroup({
          _id: new FormControl(),
          nv: new FormControl(),
          _viajeId: new FormControl(),
          terminal: new FormGroup({
            _id: new FormControl(),
            id: new FormControl(),
            nombre: new FormControl(),
            privado: new FormControl(),
            abreviatura: new FormControl(),
          }),
          operationType: new FormGroup({
            _id: new FormControl(),
            id: new FormControl(),
            nombre: new FormControl(),
          }),
          recieversShippersOthers: new FormArray([]),
          prospectos: new FormGroup({
            eta: new FormControl(),
            etaSinHora: new FormControl(),
            etaAMPM: new FormControl(),
            etb: new FormControl(),
            etbSinHora: new FormControl(),
            etbAMPM: new FormControl(),
            etc: new FormControl(),
            etcSinHora: new FormControl(),
            etcAMPM: new FormControl(),
            etd: new FormControl(),
            etdSinHora: new FormControl(),
            etdAMPM: new FormControl(),
          }),
          adConditions: new FormGroup({
            arriveFwd: new FormControl(),
            arriveAft: new FormControl(),
            arriveFo: new FormControl(),
            arriveDo: new FormControl(),
            arriveLo: new FormControl(),
            arriveFw: new FormControl(),
            sailingFwd: new FormControl(),
            sailingAft: new FormControl(),
            sailingFo: new FormControl(),
            sailingDo: new FormControl(),
            sailingLo: new FormControl(),
            sailingFw: new FormControl(),
            tugBoatsIn: new FormControl(),
            tugBoatsOut: new FormControl(),
          }),
          informacionExtra: new FormGroup({
            facturador: new FormControl(),
            anticipo: new FormControl(),
            fechaAnticipo: new FormControl(),
            load: new FormControl(),
            statusExtra: new FormControl('ANNOUNCED'),
            fechaRecepcionBogota: new FormControl(),
            fechaEnvioFDA: new FormControl(),
            facturaRecobro: new FormControl(),
            facturaIngreso: new FormControl(),
            fechaCierrePuerto: new FormControl(),
            fechaFacturacion: new FormControl(),
            formaEnvioFDA: new FormControl(),
            boardingAgent: new FormControl(),
            observaciones: new FormControl(),
          }),
          reportesEnviados: new FormArray([
            // new FormGroup({
            // 	tipoReporte: new FormControl(),
            // 	fechaDeEnvio: new FormControl(),
            // }),
          ]),
          productos: new FormArray([]),
          listaDeChequeo: new FormGroup({
            instruccionesNominacion: new FormControl(),
            situacionRestriccionPuerto: new FormControl(),
            caladoPuerto: new FormControl(),
            otrasRestricciones: new FormControl(),
            isps: new FormControl(),
            caladoArriboZarpe: new FormControl(),
            secuenciaCargueDescargue: new FormControl(),
            distribucionCargaEnTierra: new FormControl(),
            tiposProductosYRitmos: new FormControl(),
            prospectosYProgramacion: new FormControl(),
            almacenamientoYTransporte: new FormControl(),
            oblsODraftBls: new FormControl(),
            shipperDeclaration: new FormControl(),
            msds: new FormControl(),
            matesReceipt: new FormControl(),
            cargoManifest: new FormControl(),
            dian: new FormControl(),
            capitania: new FormControl(),
            ica: new FormControl(),
            sanidad: new FormControl(),
            migracion: new FormControl(),
            muellaje: new FormControl(),
            poilotos: new FormControl(),
            remolacadores: new FormControl(),
            estibadores: new FormControl(),
            serviciosNave: new FormControl(),
            productividadRealVsProgramada: new FormControl(),
            sofYLaytime: new FormControl(),
            cuellosDeBotella: new FormControl(),
            pda: new FormControl(),
            ordenesDeServicios: new FormControl(),
            closeToReal: new FormControl(),
            fda: new FormControl(),
          }),
          portLog: new FormGroup({
            eosp: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            norTendered: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            pilotOnBoardAnchor: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            pilotOnBoardBerthing: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            droppedAnchor: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            firstLineAshore: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            berthingTime: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            portAutoritiesOnBoard: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            freePractiqueGranted: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            commenceOPS: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            completionOPSTime: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            narcoticsInspections: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            vesselUnmoored: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            droppingOutwardPilot: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            pilotOnBoard: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            sailingTime: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
            etaNextPort: new FormGroup({
              nombre: new FormControl(),
              fecha: new FormControl(),
              status: new FormControl(),
            }),
          }),
          sof: new FormControl([]),
          remark: new FormControl(null),
          archivoDepartureDocuments: new FormControl(null),
          archivoDischargeLoandingPlan: new FormControl(null),
          proforma: new FormControl(null),
          liquidacion: new FormGroup({
            hoursAnchorage: new FormControl(),
            hoursDockage: new FormControl(),
            load: new FormControl(),
            serviciosLiquidacion: new FormArray([
              new FormGroup({
                cantidad: new FormControl(),
                valor: new FormControl(),
                facturaRecibida: new FormControl(),
                tarifa: new FormControl(),
              }),
            ]),
          }),
          activo: new FormControl(),
        }),
      ]),
      tieneProforma: new FormControl(),
      status: new FormControl('CONFIRMED', Validators.required),
      voyageNumber: new FormControl(),
      activo: new FormControl(),
    });

    this.additionalFormFields = new FormGroup({
      etaDate: new FormControl(null),
      etaHora: new FormControl(null),
      etaMinuto: new FormControl(null),
      etbDate: new FormControl(null),
      etbHora: new FormControl(null),
      etbMinuto: new FormControl(null),
      etcDate: new FormControl(null),
      etcHora: new FormControl(null),
      etcMinuto: new FormControl(null),
      etdDate: new FormControl(null),
      etdHora: new FormControl(null),
      etdMinuto: new FormControl(null),
      ataDate: new FormControl(null),
      ataHora: new FormControl(null),
      ataMinuto: new FormControl(null),
      atbDate: new FormControl(null),
      atbHora: new FormControl(null),
      atbMinuto: new FormControl(null),
      atcDate: new FormControl(null),
      atcHora: new FormControl(null),
      atcMinuto: new FormControl(null),
      atdDate: new FormControl(null),
      atdHora: new FormControl(null),
      atdMinuto: new FormControl(null),

      empresaRSO: new FormControl(null),
      areaRSO: new FormControl(null),
      rsoType: new FormControl(null),
    });

    this.viajeForm.get('recaladas.0.informacionExtra.statusExtra').valueChanges.subscribe((newValue) => {
      this.viaje.recaladas[0].informacionExtra.statusExtra = newValue;
      this.viaje = { ...this.viaje };
    });
  }

  /**
   * Inicializa el detalle segun el modo
   */
  ngOnInit() {
    this.mainService.get(`api/rol/${this.usuarioActual.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.analisisOperativo === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      }
    });

    this.startSubscribers();
    this.startInformation();
  }

  public startSubscribers() {
    const terminalGroup = (this.viajeForm.get('recaladas') as FormArray).at(0).get('terminal') as FormGroup;
    terminalGroup.get('id').valueChanges.subscribe(() => {
      if (typeof terminalGroup.get('id').value === 'string') {
        this.onChangeTerminal();
      }
    });
  }

  public startInformation() {
    const urlSeparated = this.router.url.split('/');
    switch (urlSeparated[3]) {
      case 'create':
        this.createMode = true;
        this.createRecaladaMode = false;
        this.viewAndEditMode = false;
        this.title = 'Crear nuevo viaje';
        this.minimizeIcon.nativeElement.style.display = 'none';
        this.stopAllSpinners();
        break;
      case 'view-and-edit':
        this.viewAndEditMode = true;
        this.createRecaladaMode = false;
        this.createMode = false;
        this.title = 'Información del viaje';
        this.onChangeMinimize();
        break;
      case 'create-recalada':
        this.createRecaladaMode = true;
        this.createMode = true;
        this.viewAndEditMode = false;
        this.title = 'Nueva recalada';
        this.minimizeIcon.nativeElement.style.display = 'none';
        this.stopAllSpinners();
        break;
      default:
        this.router.navigate(['home/viajes']);
    }
    this.initializeStepper();
    this.loadUsersFacturador();

    if (urlSeparated[3] === 'create-recalada') {
      this.initializeForm();
      this.getAllInfo();
      this.disabledInputsNoRecaladas();
      return;
    }

    if (this.createMode) {
      this.getAllInfo();
    } else {
      this.initializeForm();
    }
  }

  setStartAndMinimize() {
    this.startInformation()
    this.onChangeMinimize()
  }

  disabledInputsNoRecaladas(disabled = true) {
    const inputsToDisabled = [
      'appointment',
      'vessel',
      'voyageNumber',
      'boardingAgent',
      'port',
      'kim',
      'status',
      'businessLine',
      'commodity',
      'disponentOwner',
      'charterer',
      'broker',
    ];
    inputsToDisabled.forEach((inputToDisabled) => {
      if (disabled) return this.viajeForm.get(inputToDisabled).disable();
      this.viajeForm.get(inputToDisabled).enable();
    });
  }

  getNewNumbers() {
    return new Promise((resolve, reject) => {
      this.mainService.get('api/global_variable/nv').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.viaje.nv = 30732; //Primer valor del NV
          // Objeto que se enviara a la BD
          const nvGlobalVariable = {
            key: 'nv',
            value: this.nv,
          };
          this.mainService.post('api/global_variable', nvGlobalVariable).subscribe(() => {
            return;
          });
        } else {
          this.viaje.nv = res.value;
        }
      });
    });
  }

  getAllInfo() {
    this.isLoading = true;
    this.viajesService
      .getAllInfo()
      .then((info: InfoCompletaNuevoViaje) => {
        this.vessels = this.viajeDetalleService.transformBuqueFields(info.buques);
        this.empresas = this.viajeDetalleService.transformBusinessFields(info.empresas);
        this.empresasInfoCompleta = this.viajeDetalleService.transformBusinessTotalInfo(info.empresas);
        this.areasEmpresas = this.viajeDetalleService.transformAreasFields(info.areas);
        this.areasEmpresasTotalInfo = this.viajeDetalleService.transformAreasTotalInfo(info.areas);
        this.ports = this.viajeDetalleService.transformPortsFields(info.puertos);
        this.terminals = this.viajeDetalleService.transformTerminalsFields(info.terminales);
        this.operationTypes = this.viajeDetalleService.transformOperationsFields(info.tiposOperaciones);
        this.commodities = this.viajeDetalleService.transformCommoditiesFields(info.commodities);
        this.kims = this.viajeDetalleService.transformKimsFields(info.kims);
        this.businessLines = this.viajeDetalleService.transformBusinessLinesFields(info.businessLines);
        this.boardingAgents = this.viajeDetalleService.transformBoardingAgentsFields(info.boardingAgents);
        this.onChangePort();
        this.isLoading = false;
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          title: 'Error',
          text: 'Hubo un error al obtener la información del viaje',
          type: 'error',
        });
      });
  }

  /**
   * Inicializa el stepper del formulario
   */
  initializeStepper() {
    // Visualizador de primer paso como elemento nativo
    const bullet1 = this.bullet1.nativeElement;
    bullet1.style.backgroundColor = '#0F2B52';
    bullet1.style.color = 'white';
    bullet1.style.border = '1px solid #0F2B52';

    this.top.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }

  /**
   * Inicializa el formulario segun el modo
   */
  initializeForm() {
    if (this.viewAndEditMode || this.createRecaladaMode) {
      this.loading = true;
      let idViaje = this.route.snapshot.paramMap.get('id');
      this.getViaje(idViaje);
    }
    this.loading = false;
  }

  get vf() {
    return this.viajeForm.controls;
  }

  get recaladas() {
    return this.viajeForm.get('recaladas') as FormArray;
  }

  getViaje(idViaje: string) {
    this.viajesService
      .getViajeById(idViaje)
      .then((res: nuevoViaje) => {
        this.status = res.status;
        this.viaje = res;
        this.loading = false;

        if (this.viewAndEditMode) {
          const idRecalada = this.router.url.split('/')[5];
          const foundRecalada = res.recaladas.find((recalada) => recalada._id === idRecalada);
          res.recaladas = [];
          res.recaladas[0] = foundRecalada;
        }

        if (res.recaladas[0] && res.recaladas[0].prospectos) {
          this.infoViaje.eta = this.formatedDate(res.recaladas[0].prospectos.eta);
          this.infoViaje.etaHora = this.formatedHour(res.recaladas[0].prospectos.eta);
          this.infoViaje.etaMinuto = this.formatedMinute(res.recaladas[0].prospectos.eta);
          this.infoViaje.etbDate = this.formatedDate(res.recaladas[0].prospectos.etb);
          this.infoViaje.etbHora = this.formatedHour(res.recaladas[0].prospectos.etb);
          this.infoViaje.etbMinuto = this.formatedMinute(res.recaladas[0].prospectos.etb);
          this.infoViaje.etcDate = this.formatedDate(res.recaladas[0].prospectos.etc);
          this.infoViaje.etcHora = this.formatedHour(res.recaladas[0].prospectos.etc);
          this.infoViaje.etcMinuto = this.formatedMinute(res.recaladas[0].prospectos.etc);
          this.infoViaje.etdDate = this.formatedDate(res.recaladas[0].prospectos.etd);
          this.infoViaje.etdHora = this.formatedHour(res.recaladas[0].prospectos.etd);
          this.infoViaje.etdMinuto = this.formatedMinute(res.recaladas[0].prospectos.etd);

          if (!this.createRecaladaMode) {
            this.rsos = res.recaladas[0].recieversShippersOthers;
          }
        }
        this.setArraysUniqueValues();
        this.setValuesInForm();
      })
      .catch((err: any) => {
        Swal.fire({
          title: 'Error',
          text: 'No se pudo obtener el viaje',
          type: 'error',
          confirmButtonText: 'Aceptar',
        });
      });
  }

  setArraysUniqueValues() {
    if (this.viaje.vessel) this.vessels.push(this.viaje.vessel);
    if (this.viaje.boardingAgent) this.boardingAgents.push(this.viaje.boardingAgent);
    if (this.viaje.port) this.ports.push(this.viaje.port);
    if (this.viaje.kim) this.kims.push(this.viaje.kim);
    if (this.viaje.commodity) this.commodities.push(this.viaje.commodity);
    if (this.viaje.businessLine) this.businessLines.push(this.viaje.businessLine);
    if (this.viaje.disponentOwner) this.empresas.push(this.viaje.disponentOwner);
    if (this.viaje.appointment) this.empresas.push(this.viaje.appointment);
    if (this.viaje.charterer) this.empresas.push(this.viaje.charterer);
    if (this.viaje.broker) this.empresas.push(this.viaje.broker);

    this.empresas = this.empresas.filter((empresa, index) => {
      return (
        index ===
        this.empresas.findIndex((emp) => {
          return emp._id === empresa._id;
        })
      );
    });

    const recalada = this.viaje.recaladas[0];
    if (recalada) {
      if (recalada.operationType) this.operationTypes.push(recalada.operationType);
      if (recalada.terminal) this.terminals.push(recalada.terminal);
      if (recalada.recieversShippersOthers) this.rsos.concat(recalada.recieversShippersOthers);
    }
  }

  formatedDate(date: any) {
    return date === null ? '' : moment(date).format('YYYY-MM-DD');
  }

  formatedHour(hour: any) {
    return hour === null ? '' : moment(hour).format('HH');
  }

  formatedMinute(minute: any) {
    return minute === null ? '' : moment(minute).format('mm');
  }

  /**
   * Devuelve al usuario a la pagina anterior
   */
  onDevolverse() {
    Swal.fire({
      title: '¿Deseas regresar?',
      text: 'Se perderá la información diligenciada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this._location.back();
      }
    });
  }

  onChangeVessel() {
    const vesselGroup = this.viajeForm.get('vessel') as FormGroup;
    const vesselId = vesselGroup.get('id').value;
    vesselGroup.get('id').setValue(Number(vesselId));
    const vessel = this.vessels.find((ves) => ves.id === Number(vesselId));
    if (!vessel) {
      Swal.fire('Error', 'No se encontró la información del buque', 'error');
      return;
    }
    vesselGroup.patchValue({
      _id: vessel._id,
      nombre: vessel.nombre,
      email: vessel.email,
      loa: vessel.loa,
      dwt: vessel.dwt,
      gwt: vessel.gwt,
    });
  }

  onChangeBoardingAgent() {
    const boardingAgentGroup = this.viajeForm.get('boardingAgent') as FormGroup;
    const boardinAgentId = boardingAgentGroup.get('_id').value;
    const boardingAgent = this.boardingAgents.find((boardingAgent) => boardingAgent._id === boardinAgentId);
    if (!boardingAgent) {
      Swal.fire('Error', 'No se encontró la información del boarding agent', 'error');
      return;
    }
    boardingAgentGroup.patchValue({
      nombre: boardingAgent.nombre,
      apellido: boardingAgent.apellido,
      email: boardingAgent.email,
      telefono: boardingAgent.telefono,
      empresa: boardingAgent.empresa,
    });
  }

  onChangeKim() {
    const kimGroup = this.viajeForm.get('kim') as FormGroup;
    const kimId = kimGroup.get('id').value;
    kimGroup.get('id').setValue(Number(kimId));
    const kim = this.kims.find((k) => k.id === Number(kimId));
    if (!kim) {
      Swal.fire('Error', 'No se encontró la información del kim', 'error');
      return;
    }
    kimGroup.patchValue({
      _id: kim._id,
      nombre: kim.nombre,
    });
  }

  onChangeCommodity() {
    const commodityGroup = this.viajeForm.get('commodity') as FormGroup;
    const commodityId = commodityGroup.get('id').value;
    commodityGroup.get('id').setValue(Number(commodityId));
    const commodity = this.commodities.find((com) => com.id === Number(commodityId));
    if (!commodity) {
      Swal.fire('Error', 'No se encontró la información del commodity', 'error');
      return;
    }
    commodityGroup.patchValue({
      _id: commodity._id,
      nombre: commodity.nombre,
    });
  }

  onChangeBusinessLine() {
    const businessGroup = this.viajeForm.get('businessLine') as FormGroup;
    const businessId = businessGroup.get('id').value;
    businessGroup.get('id').setValue(Number(businessId));
    const business = this.businessLines.find((bl) => bl.id === Number(businessId));
    if (!business) {
      Swal.fire('Error', 'No se encontró la información del business line', 'error');
      return;
    }
    businessGroup.patchValue({
      _id: business._id,
      nombre: business.nombre,
      regular: business.regular,
      tramp: business.tramp,
    });
  }

  /**
   * Visualiza el primer paso del formulario
   */
  verPrimerStep() {
    // Elemento nativo del contenido del formulairo
    const content = this.formContent.nativeElement;
    content.style.marginLeft = '0%';

    this.step = 1;

    // Elemento nativo del visualizador del primer paso del formulario
    const bullet1 = this.bullet1.nativeElement;
    bullet1.style.backgroundColor = '#0F2B52';
    bullet1.style.color = 'white';
    bullet1.style.border = '1px solid #0F2B52';
    bullet1.style.opacity = '1';

    // Elemento nativo del visualizador del segundo paso del formulario
    const bullet2 = this.bullet2.nativeElement;
    bullet2.style.backgroundColor = 'white';
    bullet2.style.color = 'black';
    bullet2.style.border = '1px solid #d2d2d2';
    bullet2.style.opacity = '1';

    // Elemento nativo del visualizador del tercer paso del formulario
    const bullet3 = this.bullet3.nativeElement;
    bullet3.style.backgroundColor = 'white';
    bullet3.style.color = 'black';
    bullet3.style.border = '1px solid #d2d2d2';
    bullet3.style.opacity = '1';

    // Elemento nativo del visualizador del cuarto paso del formulario
    const bullet4 = this.bullet4.nativeElement;
    bullet4.style.backgroundColor = 'white';
    bullet4.style.color = 'black';
    bullet4.style.border = '1px solid #d2d2d2';
    bullet4.style.opacity = '1';
  }

  /**
   * Visualiza el segundo paso del formulario
   */
  verSegundoStep() {
    // Elemento nativo del contenido del formulario
    const content = this.formContent.nativeElement;
    content.style.marginLeft = '-100%';

    this.step = 2;

    // Elemento nativo del visualizador del primer paso del formulario
    const bullet1 = this.bullet1.nativeElement;
    bullet1.style.backgroundColor = '#0F2B52';
    bullet1.style.color = 'white';
    bullet1.style.border = '1px solid #0F2B52';
    bullet1.style.opacity = '0.5';

    // Elemento nativo del visualizador del segundo paso del formulario
    const bullet2 = this.bullet2.nativeElement;
    bullet2.style.backgroundColor = '#0F2B52';
    bullet2.style.color = 'white';
    bullet2.style.border = '1px solid #0F2B52';
    bullet2.style.opacity = '1';

    // Elemento nativo del visualizador del tercer paso del formulario
    const bullet3 = this.bullet3.nativeElement;
    bullet3.style.backgroundColor = 'white';
    bullet3.style.color = 'black';
    bullet3.style.border = '1px solid #d2d2d2';
    bullet3.style.opacity = '1';

    // Elemento nativo del visualizador del cuarto paso del formulario
    const bullet4 = this.bullet4.nativeElement;
    bullet4.style.backgroundColor = 'white';
    bullet4.style.color = 'black';
    bullet4.style.border = '1px solid #d2d2d2';
    bullet4.style.opacity = '1';

    this.top.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }

  /**
   * Visualiza el tercer paso del formulario
   */
  verTercerStep() {
    // Elemento nativo del contenido del formulario
    const content = this.formContent.nativeElement;
    content.style.marginLeft = '-200%';

    this.step = 3;

    // Elemento nativo del visualizador del primer paso del formulario
    const bullet1 = this.bullet1.nativeElement;
    bullet1.style.backgroundColor = '#0F2B52';
    bullet1.style.color = 'white';
    bullet1.style.border = '1px solid #0F2B52';
    bullet1.style.opacity = '0.5';

    // Elemento nativo del visualizador del segundo paso del formulario
    const bullet2 = this.bullet2.nativeElement;
    bullet2.style.backgroundColor = '#0F2B52';
    bullet2.style.color = 'white';
    bullet2.style.border = '1px solid #0F2B52';
    bullet2.style.opacity = '0.5';

    // Elemento nativo del visualizador del tercer paso del formulario
    const bullet3 = this.bullet3.nativeElement;
    bullet3.style.backgroundColor = '#0F2B52';
    bullet3.style.color = 'white';
    bullet3.style.border = '1px solid #0F2B52';
    bullet3.style.opacity = '1';

    // Elemento nativo del visualizador del cuarto paso del formulario
    const bullet4 = this.bullet4.nativeElement;
    bullet4.style.backgroundColor = 'white';
    bullet4.style.color = 'black';
    bullet4.style.border = '1px solid #d2d2d2';
    bullet4.style.opacity = '1';

    this.top.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }

  /**
   * Visualiza el cuarto paso del formulario
   */
  verCuartoStep() {
    // Elemento nativo del contenido del formulario
    const content = this.formContent.nativeElement;
    content.style.marginLeft = '-300%';

    this.step = 4;

    // Elemento nativo del visualizador del primer paso del formulario
    const bullet1 = this.bullet1.nativeElement;
    bullet1.style.backgroundColor = '#0F2B52';
    bullet1.style.color = 'white';
    bullet1.style.border = '1px solid #0F2B52';
    bullet1.style.opacity = '0.5';

    // Elemento nativo del visualizador del segundo paso del formulario
    const bullet2 = this.bullet2.nativeElement;
    bullet2.style.backgroundColor = '#0F2B52';
    bullet2.style.color = 'white';
    bullet2.style.border = '1px solid #0F2B52';
    bullet2.style.opacity = '0.5';

    // Elemento nativo del visualizador del tercer paso del formulario
    const bullet3 = this.bullet3.nativeElement;
    bullet3.style.backgroundColor = '#0F2B52';
    bullet3.style.color = 'white';
    bullet3.style.border = '1px solid #0F2B52';
    bullet3.style.opacity = '0.5';

    // Elemento nativo del visualizador del cuarto paso del formulario
    const bullet4 = this.bullet4.nativeElement;
    bullet4.style.backgroundColor = '#0F2B52';
    bullet4.style.color = 'white';
    bullet4.style.border = '1px solid #0F2B52';
    bullet4.style.opacity = '1';

    this.top.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    this.observations.nativeElement.value = this.viaje.recaladas[0].informacionExtra.observaciones;
  }

  changeValueObservations(event) {
    const recaladasForm = (this.viajeForm.get('recaladas') as FormArray).at(0).get('informacionExtra');
    recaladasForm.patchValue({
      observaciones: event.target.value,
    });
  }

  changeValueRemark(event) {
    const recaladasForm = (this.viajeForm.get('recaladas') as FormArray).at(0);
    recaladasForm.patchValue({
      remark: event.target.value,
    });
  }

  changeValueVoyage(event) {
    this.viajeForm.patchValue({
      voyageNumber: event.target.value,
    });
  }

  /**
   * Maneja el cambio en los filtros de los Receivers, Shipppers y Others
   */
  async onChangeFiltroRSO() {
    await this.onCargarRSOInfo();

    const areaEmpresaId = this.additionalFormFields.get('areaRSO') as FormControl;
    areaEmpresaId.setValue(null);
  }

  async onChangeFiltroAreaRSO() {
    await this.onCargarContactosEmpresas();
  }

  setCurrentTerminals() {
    const portId = this.viajeForm.get('port').get('id').value;
    this.terminalsByPort = this.terminalsTotalInfo.filter((t) => {
      if (portId) {
        return t.puerto == Number(portId);
      }
    });
  }

  liquidationChanging() {
    this.startInformation();
  }

  /**
   * Adiciona un Receiver Shipper o Other
   */
  async addRSO() {
    // Ids del área y empresa seleccionados en el formulario
    const empresaField = this.additionalFormFields.get('empresaRSO');
    const empresaId = empresaField.value;
    const areaField = this.additionalFormFields.get('areaRSO');
    const areaId = areaField.value;

    const rsoField = this.additionalFormFields.get('rsoType');
    const rsoType = rsoField.value;

    if (!empresaId || !areaId || !rsoType) {
      Swal.fire('Error', 'No se han completado todos los campos requeridos', 'error');
      return;
    }
    if (this.contactosTotalInfo.length === 0) {
      Swal.fire('Error', 'Esta área no tiene contactos asignados', 'error');
      return;
    }

    const empresa = this.empresas.find((empresa) => empresa.id === Number(empresaId));
    const area = this.areasEmpresasFiltradas.find((area) => area.id === Number(areaId));

    if (!empresa || !area) {
      Swal.fire('Error', 'No se ha encontrado toda la información', 'error');
      return;
    }
    const newRSO = {
      _idEmpresa: empresa._id,
      _idArea: area._id,
      empresaId: empresa.id,
      areaId: area.id,
      nombreEmpresa: empresa.nombre,
      nombreArea: area.nombre,
      contactos: this.contactosTotalInfo,
      type: rsoType,
    };
    this.rsos.push(newRSO);
    const recalada = (this.viajeForm.get('recaladas') as FormArray).at(0);
    const recieversShippersOthers = recalada.get('recieversShippersOthers') as FormArray;

    const rsoGroup = new FormGroup({
      _idEmpresa: new FormControl(empresa._id),
      _idArea: new FormControl(area._id),
      empresaId: new FormControl(empresa.id),
      areaId: new FormControl(area.id),
      nombreEmpresa: new FormControl(empresa.nombre),
      nombreArea: new FormControl(area.nombre),
      contactos: new FormControl(this.contactosTotalInfo),
      type: new FormControl(rsoType),
    });

    recieversShippersOthers.push(rsoGroup);

    // Resetting fields
    empresaField.reset();
    areaField.reset();
    rsoField.reset();
    this.areasEmpresasFiltradas = [];
  }

  /**
   * Elimina un Receiver, Shipper o Other del viaje
   * @param rsoId ID del Receiver, Shipper o Other que se desea eliminar
   */
  deleteRSO(rsoId) {
    this.rsos.splice(rsoId, 1);
    const recalada = (this.viajeForm.get('recaladas') as FormArray).at(0) as FormGroup;
    const rsos = recalada.get('recieversShippersOthers') as FormArray;
    rsos.removeAt(rsoId);
  }

  /**
   * Indica si no se esta cumpliendo la condición: fecha1 <= fecha2 <= fecha3 <= fecha4
   * @param fecha1 Objeto con fecha, hora y minuto
   * @param fecha2 Objeto con fecha, hora y minuto
   * @param fecha3 Objeto con fecha, hora y minuto
   * @param fecha4 Objeto con fecha, hora y minuto
   * @return true si las cuatro fechas no estan en orden cronologico
   */
  errorMagnitudFechasIncorrecta(fecha1, fecha2, fecha3, fecha4): boolean {
    let f1 = 0;
    let f2 = 0;
    let f3 = 0;
    let f4 = 0;

    // Obtener las fechas en formato Date
    if (fecha1.fecha && fecha1.hora && fecha1.minuto)
      f1 = this.obtenerFechaUTC(fecha1.fecha, fecha1.hora, fecha1.minuto);

    if (fecha2.fecha && fecha2.hora && fecha2.minuto)
      f2 = this.obtenerFechaUTC(fecha2.fecha, fecha2.hora, fecha2.minuto);

    if (fecha3.fecha && fecha3.hora && fecha3.minuto)
      f3 = this.obtenerFechaUTC(fecha3.fecha, fecha3.hora, fecha3.minuto);

    if (fecha4.fecha && fecha4.hora && fecha4.minuto)
      f4 = this.obtenerFechaUTC(fecha4.fecha, fecha4.hora, fecha4.minuto);

    // Comparar fechas para asegurarse que f1 <= f2 <= f3 <= f4
    if (f1 && f2 && f1 > f2) return true;
    if (f1 && f3 && f1 > f3) return true;
    if (f1 && f4 && f1 > f4) return true;

    if (f2 && f3 && f2 > f3) return true;
    if (f2 && f4 && f2 > f4) return true;

    if (f3 && f4 && f3 > f4) return true;

    return false;
  }

  /**
   * Recibe el string de fecha y hora del input y devuelve la fecha UTC
   * @param fecha String con fecha en formato del input date
   * @param hora String con la hora en formato input time
   */
  obtenerFechaUTC(fecha: string, hora: string, minuto): number {
    const fechaPartes = `${fecha}`.split('-');
    return Date.UTC(
      Number(fechaPartes[0]),
      Number(fechaPartes[1]) - 1,
      Number(fechaPartes[2]),
      Number(hora),
      Number(minuto)
    );
  }

  joinProspectos() {
    const viaje = this.viajeForm.value;
    const additionInfo = this.additionalFormFields.value;
    const prospects = ['eta', 'etb', 'etc', 'etd'];

    prospects.forEach((prospect) => {
      if (additionInfo[`${prospect}Date`]) {
        viaje.recaladas[0].prospectos[prospect] = additionInfo[`${prospect}Date`];
        if (additionInfo[`${prospect}Hora`] > 12) {
          viaje.recaladas[0].prospectos[`${prospect}AMPM`] = 'PM';
          viaje.recaladas[0].prospectos[`${prospect}SinHora`] = false;
        } else {
          viaje.recaladas[0].prospectos[`${prospect}AMPM`] = 'AM';
          viaje.recaladas[0].prospectos[`${prospect}SinHora`] = true;
        }
      }
    });
  }

  /**
   * Verifica que los datos ingresados sean correctos y los envia a la BD
   */
  onSubmit() {
    if (this.processingVoyage) return;

    const prospectosExtra = this.additionalFormFields.value;

    const eta = {
      fecha: prospectosExtra.etaDate,
      hora: prospectosExtra.etaHora || '00',
      minuto: prospectosExtra.etaMinuto || '00',
    };

    const etb = {
      fecha: prospectosExtra.etbDate,
      hora: prospectosExtra.etbHora || '00',
      minuto: prospectosExtra.etbMinuto || '00',
    };

    const etc = {
      fecha: prospectosExtra.etcDate,
      hora: prospectosExtra.etcHora || '00',
      minuto: prospectosExtra.etcMinuto || '00',
    };

    const etd = {
      fecha: prospectosExtra.etdDate,
      hora: prospectosExtra.etdHora || '00',
      minuto: prospectosExtra.etdMinuto || '00',
    };

    const ata = {
      fecha: prospectosExtra.ataDate || '00',
      hora: prospectosExtra.ataHora || '00',
      minuto: prospectosExtra.ataMinuto,
    };

    const atb = {
      fecha: prospectosExtra.atbDate,
      hora: prospectosExtra.atbHora || '00',
      minuto: prospectosExtra.atbMinuto || '00',
    };

    const atc = {
      fecha: prospectosExtra.atcDate,
      hora: prospectosExtra.atcHora || '00',
      minuto: prospectosExtra.atcMinuto || '00',
    };

    const atd = {
      fecha: prospectosExtra.atdDate,
      hora: prospectosExtra.atdHora || '00',
      minuto: prospectosExtra.atdMinuto || '00',
    };

    if (this.errorMagnitudFechasIncorrecta(eta, etb, etc, etd)) {
      return Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Se debe cumplir lo siguiente: (ETA < ETB < ETC < ETD)',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    }

    if (this.errorMagnitudFechasIncorrecta(ata, atb, atc, atd)) {
      return Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Se debe cumplir lo siguiente: (ATA < ATB < ATC < ATD)',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    }

    this.joinProspectos();

    if (this.permisosUsuario.viajes == 'LECTURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (this.viajeForm.invalid) {
      Swal.fire({
        title: 'No se puede crear el viaje',
        text: 'Hay campos requeridos vacíos o campos con valores incorrectos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else {
      // Contenido del formualrio
      const formContent = this.viajeForm.value;

      if (formContent.recaladas[0].liquidacion && !formContent.recaladas[0].liquidacion.load)
        delete formContent.recaladas[0].liquidacion;

      // Contenido del formulario en formato JSON
      let viaje: nuevoViaje = JSON.parse(JSON.stringify(formContent));

      // Change status extra based of invoicer informaiton
      const extraInformation = viaje.recaladas[0].informacionExtra;
      if (
        extraInformation.statusExtra === STATUSEXTRA.RECEIVED_INVOICE &&
        extraInformation.facturador &&
        extraInformation.fechaFacturacion
      ) {
        extraInformation.statusExtra = STATUSEXTRA.INVOICED;
      }

      if (extraInformation.facturador === 'null') extraInformation.facturador = null

      viaje.recaladas[0].recieversShippersOthers = this.rsos;
      viaje.instruction = this.instructionUrl;

      if (this.createRecaladaMode) {
        this.disabledInputsNoRecaladas(false);
        viaje = JSON.parse(JSON.stringify(this.viajeForm.value));
      }

      this.formatViajeOutput(viaje);
      this.filtrarPorPermisosEspecificos(viaje);

      if (this.createRecaladaMode) {
        delete viaje.recaladas[0]._id;
        viaje.recaladas[0].nv = viaje.nv;
        return this.newRecalada(viaje);
      }

      if (this.createMode) {
        function removeProperties(object) {
          const { _id, viajeId, nv, _viaId, ...restObject } = object;
          return restObject;
        }

        this.loading = true;
        // viaje = this.setValuesForArrivalAndDepartureConditions(viaje);
        viaje.activo = true;
        viaje = removeProperties(viaje);
        viaje.recaladas[0] = removeProperties(viaje.recaladas[0]);
        this.postViaje(viaje);
      } else if (this.status === 'CANCELED') {
        Swal.fire({
          title: '¡Error!',
          text: 'No se puede actualizar un viaje cancelado.',
          type: 'error',
        });
      } else {
        // update the viaje
        this.loading = true;
        this.processingVoyage = true;
        this.mainService.put(`api/nuevo-viaje/${this.viaje._id}`, { viaje, recalada: viaje.recaladas[0] }).subscribe(
          (result) => {
            this.actualizado = true;
            this.viaje = result;
            this.loading = false;
            Swal.fire({
              title: 'Se actualizó el viaje exitosamente',
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'Continuar',
            });

            this.processingVoyage = false;

            this.startInformation();
          },
          (err) => {
            Swal.fire('Error!', 'Viaje no pudo ser actualizado', 'error');
            this.processingVoyage = false;
          }
        );
      }
    }
  }

  onChangeInvoicer() {
    const extraInformation = (this.viajeForm.get('recaladas') as FormArray).at(0).get('informacionExtra') as FormGroup;
    extraInformation.patchValue({
      fechaFacturacion: null,
      statusExtra: STATUSEXTRA.RECEIVED_INVOICE,
    });
  }

  onChangeInvoiceDate() {
    const extraInformation = (this.viajeForm.get('recaladas') as FormArray).at(0).get('informacionExtra') as FormGroup;
    const fechaFacturacion = extraInformation.value.fechaFacturacion;
    if (fechaFacturacion) return;

    extraInformation.patchValue({
      statusExtra: STATUSEXTRA.RECEIVED_INVOICE,
      facturador: null
    });
  }

  postViaje(viaje) {
    this.processingVoyage = true;
    this.viajesService.postViaje(viaje).then(
      (res: any) => {
        Swal.fire('Éxito', 'Viaje creado exitosamente', 'success');
        this.router.navigate([`home/viaje/view-and-edit/${res._id}/${res.recaladas[0]._id}`]);
      },
      (err) => {
        Swal.fire('Error', 'Error al momento de crear el viaje. Revise los campos', 'error');
        this.processingVoyage = false;
      }
    );
  }

  newRecalada(viajeWithRecalada) {
    this.viajesService.postRecalada(viajeWithRecalada).then(
      (res) => {
        Swal.fire('Éxito', 'Recalada creada exitosamente', 'success');
        this.router.navigate(['home/viajes']);
      },
      (err) => {
        if (this.createRecaladaMode) this.disabledInputsNoRecaladas();
        Swal.fire('Error', 'Error al momento de crear el viaje. Revise los campos', 'error');
      }
    );
  }

  /**
   * Asigna los parámetros que se envían al viaje a partir del valor del campo, esto no se hace automático porque se agrega el updateOn: blur
   */
  setValuesForArrivalAndDepartureConditions(viaje) {
    viaje.arriveFwd = this.viajeForm.value.arriveFwd.value;
    viaje.arriveAft = this.viajeForm.value.arriveAft.value;
    viaje.arriveFo = this.viajeForm.value.arriveFo.value;
    viaje.arriveDo = this.viajeForm.value.arriveDo.value;
    viaje.arriveLo = this.viajeForm.value.arriveLo.value;
    viaje.arriveFw = this.viajeForm.value.arriveFw.value;
    viaje.sailingFwd = this.viajeForm.value.sailingFwd.value;
    viaje.sailingAft = this.viajeForm.value.sailingAft.value;
    viaje.sailingFo = this.viajeForm.value.sailingFo.value;
    viaje.sailingDo = this.viajeForm.value.sailingDo.value;
    viaje.sailingLo = this.viajeForm.value.sailingLo.value;
    viaje.sailingFw = this.viajeForm.value.sailingFw.value;
    return viaje;
  }

  /**
   * No permite enviar datos a la BD si no se tienen los permisos especifcios adecuados
   * @param viaje Datos de salida de un viaje
   */
  filtrarPorPermisosEspecificos(viaje: any): void {
    // Permiso de anticipo
    if (!this.permisosUsuario.permisosEspecificosEscrituraViajes.anticipo) {
      delete viaje['anticipo'];
      delete viaje['fechaAnticipo'];
    }

    // Fecha cierre puerto
    if (!this.permisosUsuario.permisosEspecificosEscrituraViajes.fechaCierrePuerto) {
      delete viaje['fechaCierrePuerto'];
    }

    // Fecha de facturacion
    if (!this.permisosUsuario.permisosEspecificosEscrituraViajes.fechaFacturacion) {
      delete viaje['fechaFacturacion'];
    }

    // Status extra
    if (!this.permisosUsuario.permisosEspecificosEscrituraViajes.status) {
      delete viaje['statusExtra'];
    }
  }

  /**
   * Formatea el viaje en la forma de la BD
   * @param viaje Datos del viaje
   */
  formatViajeOutput(viaje: nuevoViaje) {
    viaje.recaladas[0].activo = true;
    viaje.recaladas[0].informacionExtra.boardingAgent = viaje.boardingAgent._id;

    Object.keys(viaje.recaladas[0].listaDeChequeo).forEach((chequeo) => {
      viaje.recaladas[0].listaDeChequeo[chequeo] = false;
    });

    // Port Log
    if (viaje.recaladas && viaje.recaladas[0].portLog) {
      const portlog = viaje.recaladas[0].portLog;
      for (let key in portlog) {
        if (!portlog[key]) {
          portlog[key] = {};
        }
      }
    }
    const prospectosExtra = this.additionalFormFields.value;
    // Fechas
    if (prospectosExtra.etaDate) {
      // Partes de la fecha del ETA
      const etaDate = prospectosExtra.etaDate.split('-');
      viaje.recaladas[0].prospectos.eta = new Date(
        Date.UTC(
          parseInt(etaDate[0]),
          parseInt(etaDate[1]) - 1,
          parseInt(etaDate[2]),
          parseInt(prospectosExtra.etaHora),
          parseInt(prospectosExtra.etaMinuto)
        )
      );
    }

    if (prospectosExtra.etbDate) {
      // Partes de la fecha del ETB
      const etbDate = prospectosExtra.etbDate.split('-');
      viaje.recaladas[0].prospectos.etb = new Date(
        Date.UTC(
          parseInt(etbDate[0]),
          parseInt(etbDate[1]) - 1,
          parseInt(etbDate[2]),
          parseInt(prospectosExtra.etbHora),
          parseInt(prospectosExtra.etbMinuto)
        )
      );
    }

    if (prospectosExtra.etcDate) {
      // Partes de la fecha del ETC
      const etcDate = prospectosExtra.etcDate.split('-');
      viaje.recaladas[0].prospectos.etc = new Date(
        Date.UTC(
          parseInt(etcDate[0]),
          parseInt(etcDate[1]) - 1,
          parseInt(etcDate[2]),
          parseInt(prospectosExtra.etcHora),
          parseInt(prospectosExtra.etcMinuto)
        )
      );
    }

    if (prospectosExtra.etdDate) {
      // Partes de la fecha del ETD
      const etdDate = prospectosExtra.etdDate.split('-');
      viaje.recaladas[0].prospectos.etd = new Date(
        Date.UTC(
          parseInt(etdDate[0]),
          parseInt(etdDate[1]) - 1,
          parseInt(etdDate[2]),
          parseInt(prospectosExtra.etdHora),
          parseInt(prospectosExtra.etdMinuto)
        )
      );
    }

    if (prospectosExtra.ataDate) {
      // Partes de la fecha del ATA
      const ataDate = prospectosExtra.ataDate.split('-');
      viaje.recaladas[0].prospectos.ata = new Date(
        Date.UTC(
          parseInt(ataDate[0]),
          parseInt(ataDate[1]) - 1,
          parseInt(ataDate[2]),
          parseInt(prospectosExtra.ataHora),
          parseInt(prospectosExtra.ataMinuto)
        )
      );

      //Actualiza el Port Log
      viaje.recaladas[0].portLog.eosp.nombre = 'EOSP';
      viaje.recaladas[0].portLog.eosp.fecha = viaje.recaladas[0].prospectos.ata;
      viaje.recaladas[0].portLog.eosp.status = 'CONFIRMED';

      //Actualizar el estado del viaje
      if (viaje.recaladas[0].informacionExtra.statusExtra == 'ANNOUNCED' && this.viaje.recaladas[0].reportesEnviados) {
        const reportesDeArriboEnviados = this.viaje.recaladas[0].reportesEnviados.filter(
          (reporte) => reporte.tipoReporte == 'ARRIBO'
        );
        if (reportesDeArriboEnviados.length > 0) viaje.recaladas[0].informacionExtra.statusExtra = 'BERTHED';
      }
    }

    if (prospectosExtra.atbDate) {
      // Partes de la fecha del ATB
      const atbDate = prospectosExtra.atbDate.split('-');
      viaje.recaladas[0].prospectos.atb = new Date(
        Date.UTC(
          parseInt(atbDate[0]),
          parseInt(atbDate[1]) - 1,
          parseInt(atbDate[2]),
          parseInt(prospectosExtra.atbHora),
          parseInt(prospectosExtra.atbMinuto)
        )
      );

      //Actualiza el Port Log
      viaje.recaladas[0].portLog.berthingTime.nombre = 'Berthing Time';
      viaje.recaladas[0].portLog.berthingTime.fecha = viaje.recaladas[0].prospectos.atb;
      viaje.recaladas[0].portLog.berthingTime.status = 'CONFIRMED';
    }

    if (prospectosExtra.atcDate) {
      // Partes de la fecha del ATC
      const atcDate = prospectosExtra.atcDate.split('-');
      viaje.recaladas[0].prospectos.atc = new Date(
        Date.UTC(
          parseInt(atcDate[0]),
          parseInt(atcDate[1]) - 1,
          parseInt(atcDate[2]),
          parseInt(prospectosExtra.atcHora),
          parseInt(prospectosExtra.atcMinuto)
        )
      );

      //Actualiza el Port Log
      viaje.recaladas[0].portLog.completionOPSTime.nombre = 'Completion OPS time';
      viaje.recaladas[0].portLog.completionOPSTime.fecha = new Date(viaje.recaladas[0].prospectos.atc);
      viaje.recaladas[0].portLog.completionOPSTime.status = 'CONFIRMED';

      //Actualizar el estado del viaje
      if (viaje.recaladas[0].informacionExtra.statusExtra == 'BERTHED' && this.viaje.recaladas[0].reportesEnviados) {
        const reportesDeFinOperacionEnviados = this.viaje.recaladas[0].reportesEnviados.filter(
          (reporte) => reporte.tipoReporte == 'FIN_OPERACIONES'
        );
        if (reportesDeFinOperacionEnviados.length > 0) viaje.recaladas[0].informacionExtra.statusExtra = 'COMPLETED';
      }
    }

    if (prospectosExtra.atdDate) {
      // Partes de la fecha del ATD
      const atdDate = prospectosExtra.atdDate.split('-');
      viaje.recaladas[0].prospectos.atd = new Date(
        Date.UTC(
          parseInt(atdDate[0]),
          parseInt(atdDate[1]) - 1,
          parseInt(atdDate[2]),
          parseInt(prospectosExtra.atdHora),
          parseInt(prospectosExtra.atdMinuto)
        )
      );

      //Actualiza el Port Log
      viaje.recaladas[0].portLog.sailingTime.nombre = 'Sailing Time';
      viaje.recaladas[0].portLog.sailingTime.fecha = viaje.recaladas[0].prospectos.atd;
      viaje.recaladas[0].portLog.sailingTime.status = 'CONFIRMED';

      //Actualizar el estado del viaje
      if (viaje.recaladas[0].informacionExtra.statusExtra == 'COMPLETED') {
        const reportesDeZarpeEnviados = this.viaje.recaladas[0].reportesEnviados.filter(
          (reporte) => reporte.tipoReporte == 'ZARPE'
        );
        if (reportesDeZarpeEnviados.length > 0) viaje.recaladas[0].informacionExtra.statusExtra = 'SHIPPED';
      }
    }

    if (viaje.recaladas[0].informacionExtra.fechaAnticipo) {
      // Partes de la fecha de anticiopo
      const fecha = new Date(viaje.recaladas[0].informacionExtra.fechaAnticipo);
      const fechaAnticipo = fecha.toJSON().split('-');
      viaje.recaladas[0].informacionExtra.fechaAnticipo = new Date(
        Date.UTC(parseInt(fechaAnticipo[0]), parseInt(fechaAnticipo[1]) - 1, parseInt(fechaAnticipo[2]))
      );
    } else {
      delete viaje.recaladas[0].informacionExtra.fechaAnticipo;
    }

    if (viaje.recaladas[0].informacionExtra.fechaCierrePuerto) {
      // Partes de la fecha del cierre de puerto
      const fecha = new Date(viaje.recaladas[0].informacionExtra.fechaCierrePuerto);
      const fechaCierrePuerto = fecha.toJSON().split('-');
      viaje.recaladas[0].informacionExtra.fechaCierrePuerto = new Date(
        Date.UTC(parseInt(fechaCierrePuerto[0]), parseInt(fechaCierrePuerto[1]) - 1, parseInt(fechaCierrePuerto[2]))
      );
    } else {
      delete viaje.recaladas[0].informacionExtra.fechaCierrePuerto;
    }

    if (viaje.recaladas[0].informacionExtra.fechaFacturacion) {
      // Partes de la fecha de factuacion
      const fecha = new Date(viaje.recaladas[0].informacionExtra.fechaFacturacion);
      const fechaFacturacion = fecha.toJSON().split('-');
      viaje.recaladas[0].informacionExtra.fechaFacturacion = new Date(
        Date.UTC(parseInt(fechaFacturacion[0]), parseInt(fechaFacturacion[1]) - 1, parseInt(fechaFacturacion[2]))
      );
    } else {
      delete viaje.recaladas[0].informacionExtra.fechaFacturacion;
    }

    if (!viaje.boardingAgent._id) {
      delete viaje.boardingAgent;
    }
  }

  async onSetCommentsByBusiness() {
    if (this.empresasInfoCompleta.length <= 1) {
      await this.onCargarEmpresas();
    }
    const disponentOwnerGroup = this.viajeForm.get('disponentOwner') as FormGroup;
    const appointmentGroup = this.viajeForm.get('appointment') as FormGroup;
    const chartererGroup = this.viajeForm.get('charterer') as FormGroup;
    const brokerGroup = this.viajeForm.get('broker') as FormGroup;
    const ids = [];
    ids.push(disponentOwnerGroup && disponentOwnerGroup.get('id') ? disponentOwnerGroup.get('id').value : null);
    ids.push(appointmentGroup && appointmentGroup.get('id') ? appointmentGroup.get('id').value : null);
    ids.push(chartererGroup && chartererGroup.get('id') ? chartererGroup.get('id').value : null);
    ids.push(brokerGroup && brokerGroup.get('id') ? brokerGroup.get('id').value : null);
    for (let i = 0; i < ids.length; i++) {
      if (ids[i]) {
        const business = this.empresasInfoCompleta.find((b) => {
          return b.id == ids[i];
        });
        if (!business) {
          Swal.fire('Error', 'No se encontró la información de la empresa', 'error');
          return;
        }
        if (business && business.comentarios) {
          this.commentsParts[i] = this.commentsParts[i].concat(business.comentarios).concat('\n');
        } else {
          this.commentsParts[i] = this.commentsParts[i]
            .concat('>>> La empresa no tienen ningún comentario <<<')
            .concat('\n');
        }
        this.comments = this.commentsParts[0].concat(
          this.commentsParts[1],
          this.commentsParts[2],
          this.commentsParts[3]
        );
        // Elemento nativo del campo de texto con comentarios de las empresas
        const domComments = this.domComments.nativeElement;
        domComments.value = this.comments;
      }
    }
  }

  /**
   * Maneja el cambio en la seleccion de algna empresa con un rol en el viaje
   */
  async onChangedBusinessOption(businessRole: string, positionArrayCommentParts: number) {
    // Entrada del usuario
    const businessGroup = this.viajeForm.get(businessRole) as FormGroup;
    const businessId = businessGroup.get('id').value;
    businessGroup.get('id').setValue(Number(businessId));
    // Empresa seleccionada
    if (this.empresasInfoCompleta.length <= 1) {
      await this.onCargarEmpresas();
    }
    const business = this.empresasInfoCompleta.find((b) => {
      return b.id == businessId;
    });
    if (!business) {
      Swal.fire('Error', 'No se encontró la información de la empresa', 'error');
      return;
    }
    businessGroup.patchValue({
      _id: business._id,
      nombre: business.nombre,
    });
    if (business && business.comentarios) {
      this.commentsParts[positionArrayCommentParts] = this.commentsParts[positionArrayCommentParts]
        .concat(business.comentarios)
        .concat('\n');
    } else {
      this.commentsParts[positionArrayCommentParts] = this.commentsParts[positionArrayCommentParts]
        .concat('>>> La empresa no tienen ningún comentario <<<')
        .concat('\n');
    }
    this.comments = this.commentsParts[0].concat(this.commentsParts[1], this.commentsParts[2], this.commentsParts[3]);

    // Elemento nativo del campo de texto con comentarios de las empresas
    const domComments = this.domComments.nativeElement;
    domComments.value = this.comments;
  }

  /**
   * Maneja el cambio de puerto para filtrar las terminales
   */
  async onChangePort() {
    // Form port
    const portGroup = this.viajeForm.get('port') as FormGroup;
    // Entrada del usuario
    const portId = portGroup.get('id').value;
    if (!portId) return;
    portGroup.get('id').setValue(Number(portId));
    // Puerto seleccionado por el usuario
    const selectedPort = this.ports.find((p) => {
      return p.id == Number(portId);
    });

    portGroup.patchValue({
      _id: selectedPort._id,
      nombre: selectedPort.nombre,
      email: selectedPort.email,
      direccion: selectedPort.direccion,
      cod: selectedPort.cod,
      centroCoste: selectedPort.centroCoste,
      centroBeneficio: selectedPort.centroBeneficio,
    });

    // Form terminales
    const terminalGroup = (this.viajeForm.get('recaladas') as FormArray).at(0).get('terminal') as FormGroup;
    if (this.terminalsTotalInfo.length <= 1) {
      // New terminals
      await this.onCargarTerminalForm();
    }
    this.setCurrentTerminals();
    terminalGroup.get('id').setValue(null);
  }

  /**
   * Sube el archivo de instruicciones a S3 y guarda el URL
   */
  onUploadInstructionFile() {
    if (this.instructionUrl) {
      Swal.fire({
        title: '¿Deseas cambiar el archivo de instrucciones?',
        text: 'Se perderá la información diligenciada',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          // Elemento nativo del archivo de instrucciones
          const instructionFile = this.instructionFile.nativeElement;
          instructionFile.click();
        }
      });
    } else {
      // Elemento nativo del archivo de instrucciones
      const instructionFile = this.instructionFile.nativeElement;
      instructionFile.click();
    }
  }

  onChangeTerminal() {
    const terminalGroup = (this.viajeForm.get('recaladas') as FormArray).at(0).get('terminal') as FormGroup;
    const idTerminal = terminalGroup.get('id').value;
    if (!idTerminal) return;
    terminalGroup.get('id').setValue(Number(idTerminal));
    const terminalFound = this.terminals.find((terminal) => terminal.id == Number(idTerminal));
    if (terminalFound) {
      terminalGroup.patchValue({
        _id: terminalFound._id,
        nombre: terminalFound.nombre,
        privado: terminalFound.privado,
        abreviatura: terminalFound.abreviatura,
      });
    }
  }

  onChangeOperationType() {
    const recalada = (this.viajeForm.get('recaladas') as FormArray).at(0) as FormGroup;
    const operationGroup = recalada.get('operationType') as FormGroup;
    const operationId = operationGroup.get('id').value;
    operationGroup.get('id').setValue(Number(operationId));

    const operation = this.operationTypes.find((op) => op.id === Number(operationId));
    if (!operation) {
      Swal.fire('Error', 'No se encontró la información del operation type', 'error');
      return;
    }
    operationGroup.patchValue({
      _id: operation._id,
      nombre: operation.nombre,
    });
  }

  /**
   * Maneja la seleccion del archivo de instrucciones en el equipo del usuario
   */
  public onInstructionFileSelect(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile !== undefined) {
      // Archivo seleccionado por el usuario
      const file = this.selectedFile;
      // Nombre con el ID del archivo para s3
      const nombre = this.makeid();
      // Parametros del buket de s3
      const params = {
        Bucket: 'naves-recursos',
        ACL: 'public-read',
        Key: 'instruccionViaje/' + nombre + '.pdf',
        ContentType: file.type,
        Body: file,
      };
      // Opciones de compresión de S3
      const options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
      };

      // Uploader de S3
      const uploader = this.bucket.upload(params, options, (err, data) => {
        if (err) {
          console.log(err);

          Swal.fire({
            title: '¡Error!',
            text: 'Se generó un error al subir el archivo.',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'Continuar',
          });
        } else {
          this.instructionUrl = data.Location.toString();

          return true;
        }
      });

      uploader.on('httpUploadProgress', (event) => {
        if (event.loaded == event.total) {
        }
      });

      Swal.fire('Exito!', 'El archivo se subió exitosamente', 'success');
    }
  }

  /**
   * Genera un ID
   */
  makeid() {
    return this.utilsService.generateRandomId();
  }

  /**
   * Descarga el archivo de instrucciones
   */
  onDownloadInstructionFileFile() {
    // Elemento antivo del link al archivo de instrucciones
    const downloadInstructionLink = this.downloadInstructionLink.nativeElement;
    downloadInstructionLink.click();
  }

  /**
   * Cambia la minimizacion del detalle
   */
  onChangeMinimize() {
    if (this.loading) return;
    if (!this.dataChargedInForm && this.minimize) {
      this.setValuesInForm();
      this.stopAllSpinners();
      this.onCargarTerminalForm();
    }
    this.minimize = !this.minimize;

    // Elemento nativo del icono de minimización
    const minimizeIcon = this.minimizeIcon.nativeElement;
    if (this.minimize) {
      minimizeIcon.style.transform = 'rotate(90deg)';
    } else {
      minimizeIcon.style.transform = 'rotate(270deg)';
      this.remark.nativeElement.value = this.viaje.recaladas[0].remark;
      this.voyageNumber.nativeElement.value = this.viaje.voyageNumber;
    }
  }

  /**
   * Pone los datos en el formulario
   */
  setValuesInForm() {
    this.dataChargedInForm = this.viaje.kim ? true : false;
    this.formatViajeInput();
    this.viajeForm.patchValue({
      _id: this.viaje._id || null,
      nv: this.viaje.nv,
      nvView: this.viaje.nv ? `${this.viaje.nv} (${this.viaje.recaladas[0].index})` : null,
      _viaId: this.viaje._viaId || null,
      vessel: this.viaje.vessel || {},
      port: this.viaje.port || {},
      boardingAgent: this.viaje.boardingAgent || {},
      businessLine: this.viaje.businessLine || {},
      kim: this.viaje.kim || {},
      commodity: this.viaje.commodity || {},
      disponentOwner: this.viaje.disponentOwner || {},
      appointment: this.viaje.appointment || {},
      charterer: this.viaje.charterer || {},
      broker: this.viaje.broker || {},
      tieneProforma: this.viaje.tieneProforma || null,
      status: this.viaje.status || null,
      voyageNumber: this.viaje.voyageNumber || null,
      instruction: this.viaje.instruction || null,
      activo: this.viaje.activo,
    });

    const recaladasFormArray = this.viajeForm.get('recaladas') as FormArray;
    const recaladasFormGroup = recaladasFormArray.at(0) as FormGroup;
    const recalada = this.viaje.recaladas[0];

    if (this.createRecaladaMode) return;

    this.terminalsByPort.push(recalada.terminal);

    recaladasFormGroup.patchValue({
      _id: recalada._id,
      nv: recalada.nv,
      _viajeId: recalada._viajeId,
      terminal: recalada.terminal || {},
      operationType: recalada.operationType || {},
      prospectos: recalada.prospectos || {},
      adConditions: recalada.adConditions || {},
      informacionExtra: recalada.informacionExtra || {},
      listaDeChequeo: recalada.listaDeChequeo || {},
      portLog: recalada.portLog || {},
      remark: recalada.remark,
      archivoDepartureDocuments: recalada.archivoDepartureDocuments,
      archivoDischargeLoandingPlan: recalada.archivoDischargeLoandingPlan,
      proforma: recalada.proforma,
      liquidacion: recalada.liquidacion,
      activo: recalada.activo,
      // arrays
      recieversShippersOthers:
        recalada.recieversShippersOthers && recalada.recieversShippersOthers.length > 0
          ? recalada.recieversShippersOthers
          : [],
      reportesEnviados:
        recalada.reportesEnviados && recalada.reportesEnviados.length > 0 ? recalada.reportesEnviados : [],
      productos: recalada.productos && recalada.productos.length > 0 ? recalada.productos : [],
      sof: recalada.sof,
    });

    //Prospects
    this.additionalFormFields.patchValue({
      etaDate: this.prospectosExtra.etaDate,
      etaHora: this.prospectosExtra.etaHora,
      etaMinuto: this.prospectosExtra.etaMinuto,
      etbDate: this.prospectosExtra.etbDate,
      etbHora: this.prospectosExtra.etbHora,
      etbMinuto: this.prospectosExtra.etbMinuto,
      etcDate: this.prospectosExtra.etcDate,
      etcHora: this.prospectosExtra.etcHora,
      etcMinuto: this.prospectosExtra.etcMinuto,
      etdDate: this.prospectosExtra.etdDate,
      etdHora: this.prospectosExtra.etdHora,
      etdMinuto: this.prospectosExtra.etdMinuto,

      ataDate: this.prospectosExtra.ataDate,
      ataHora: this.prospectosExtra.ataHora,
      ataMinuto: this.prospectosExtra.ataMinuto,
      atbDate: this.prospectosExtra.atbDate,
      atbHora: this.prospectosExtra.atbHora,
      atbMinuto: this.prospectosExtra.atbMinuto,
      atcDate: this.prospectosExtra.atcDate,
      atcHora: this.prospectosExtra.atcHora,
      atcMinuto: this.prospectosExtra.atcMinuto,
      atdDate: this.prospectosExtra.atdDate,
      atdHora: this.prospectosExtra.atdHora,
      atdMinuto: this.prospectosExtra.atdMinuto,
    });

    this.onSetCommentsByBusiness();

    //Instructions
    this.instructionUrl = this.viaje.instruction;
  }

  stopAllSpinners() {
    this.buquesCargandose = false;
    this.puertosCargandose = false;
    this.kimsCargandose = false;
    this.empresasCargandose = false;
    this.terminalesCargandose = false;
    this.commoditiesCargandose = false;
    this.empresasCargandose = false;
    this.areasEmpresasCargandose = false;
    this.businessLinesCargandose = false;
    this.boardingAgentsCargandose = false;
    this.tiposOperacionCargandose = false;
    this.contactosEmpresasCargandose = false;
  }

  /**
   * Formatea el viaje para estar en el formato del formulario
   */
  formatViajeInput() {
    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos && this.viaje.recaladas[0].prospectos.eta) {
      this.viaje.recaladas[0].prospectos.eta = new Date(this.viaje.recaladas[0].prospectos.eta);

      this.prospectosExtra.etaDate = `${this.viaje.recaladas[0].prospectos.eta.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.eta.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].prospectos.eta.getUTCDate())}`;

      this.prospectosExtra.etaHora = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.eta.getUTCHours()
      )}`;

      this.prospectosExtra.etaMinuto = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.eta.getUTCMinutes()
      )}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos && this.viaje.recaladas[0].prospectos.etb) {
      this.viaje.recaladas[0].prospectos.etb = new Date(this.viaje.recaladas[0].prospectos.etb);
      this.prospectosExtra.etbDate = `${this.viaje.recaladas[0].prospectos.etb.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etb.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].prospectos.etb.getUTCDate())}`;
      this.prospectosExtra.etbHora = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etb.getUTCHours()
      )}`;
      this.prospectosExtra.etbMinuto = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etb.getUTCMinutes()
      )}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos && this.viaje.recaladas[0].prospectos.etc) {
      this.viaje.recaladas[0].prospectos.etc = new Date(this.viaje.recaladas[0].prospectos.etc);
      this.prospectosExtra.etcDate = `${this.viaje.recaladas[0].prospectos.etc.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etc.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].prospectos.etc.getUTCDate())}`;
      this.prospectosExtra.etcHora = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etc.getUTCHours()
      )}`;
      this.prospectosExtra.etcMinuto = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etc.getUTCMinutes()
      )}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos && this.viaje.recaladas[0].prospectos.etd) {
      this.viaje.recaladas[0].prospectos.etd = new Date(this.viaje.recaladas[0].prospectos.etd);
      this.prospectosExtra.etdDate = `${this.viaje.recaladas[0].prospectos.etd.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etd.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].prospectos.etd.getUTCDate())}`;
      this.prospectosExtra.etdHora = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etd.getUTCHours()
      )}`;
      this.prospectosExtra.etdMinuto = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.etd.getUTCMinutes()
      )}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos && this.viaje.recaladas[0].prospectos.ata) {
      this.viaje.recaladas[0].prospectos.ata = new Date(this.viaje.recaladas[0].prospectos.ata);
      this.prospectosExtra.ataDate = `${this.viaje.recaladas[0].prospectos.ata.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.ata.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].prospectos.ata.getUTCDate())}`;
      this.prospectosExtra.ataHora = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.ata.getUTCHours()
      )}`;
      this.prospectosExtra.ataMinuto = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.ata.getUTCMinutes()
      )}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos && this.viaje.recaladas[0].prospectos.atb) {
      this.viaje.recaladas[0].prospectos.atb = new Date(this.viaje.recaladas[0].prospectos.atb);
      this.prospectosExtra.atbDate = `${this.viaje.recaladas[0].prospectos.atb.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atb.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].prospectos.atb.getUTCDate())}`;
      this.prospectosExtra.atbHora = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atb.getUTCHours()
      )}`;
      this.prospectosExtra.atbMinuto = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atb.getUTCMinutes()
      )}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos && this.viaje.recaladas[0].prospectos.atc) {
      this.viaje.recaladas[0].prospectos.atc = new Date(this.viaje.recaladas[0].prospectos.atc);
      this.prospectosExtra.atcDate = `${this.viaje.recaladas[0].prospectos.atc.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atc.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].prospectos.atc.getUTCDate())}`;
      this.prospectosExtra.atcHora = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atc.getUTCHours()
      )}`;
      this.prospectosExtra.atcMinuto = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atc.getUTCMinutes()
      )}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos && this.viaje.recaladas[0].prospectos.atd) {
      this.viaje.recaladas[0].prospectos.atd = new Date(this.viaje.recaladas[0].prospectos.atd);
      this.prospectosExtra.atdDate = `${this.viaje.recaladas[0].prospectos.atd.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atd.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].prospectos.atd.getUTCDate())}`;
      this.prospectosExtra.atdHora = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atd.getUTCHours()
      )}`;
      this.prospectosExtra.atdMinuto = `${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].prospectos.atd.getUTCMinutes()
      )}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].informacionExtra.fechaAnticipo) {
      this.viaje.recaladas[0].informacionExtra.fechaAnticipo = new Date(
        this.viaje.recaladas[0].informacionExtra.fechaAnticipo
      );
      this.viaje.recaladas[0].informacionExtra.fechaAnticipo = `${this.viaje.recaladas[0].informacionExtra.fechaAnticipo.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].informacionExtra.fechaAnticipo.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].informacionExtra.fechaAnticipo.getUTCDate())}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].informacionExtra.fechaCierrePuerto) {
      this.viaje.recaladas[0].informacionExtra.fechaCierrePuerto = new Date(
        this.viaje.recaladas[0].informacionExtra.fechaCierrePuerto
      );
      this.viaje.recaladas[0].informacionExtra.fechaCierrePuerto = `${this.viaje.recaladas[0].informacionExtra.fechaCierrePuerto.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].informacionExtra.fechaCierrePuerto.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].informacionExtra.fechaCierrePuerto.getUTCDate())}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].informacionExtra.fechaFacturacion) {
      this.viaje.recaladas[0].informacionExtra.fechaFacturacion = new Date(
        this.viaje.recaladas[0].informacionExtra.fechaFacturacion
      );
      this.viaje.recaladas[0].informacionExtra.fechaFacturacion = `${this.viaje.recaladas[0].informacionExtra.fechaFacturacion.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].informacionExtra.fechaFacturacion.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].informacionExtra.fechaFacturacion.getUTCDate())}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].informacionExtra.fechaRecepcionBogota) {
      this.viaje.recaladas[0].informacionExtra.fechaRecepcionBogota = new Date(
        this.viaje.recaladas[0].informacionExtra.fechaRecepcionBogota
      );
      this.viaje.recaladas[0].informacionExtra.fechaRecepcionBogota = `${this.viaje.recaladas[0].informacionExtra.fechaRecepcionBogota.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].informacionExtra.fechaRecepcionBogota.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].informacionExtra.fechaRecepcionBogota.getUTCDate())}`;
    }

    if (this.viaje.recaladas[0] && this.viaje.recaladas[0].informacionExtra.fechaEnvioFDA) {
      this.viaje.recaladas[0].informacionExtra.fechaEnvioFDA = new Date(
        this.viaje.recaladas[0].informacionExtra.fechaEnvioFDA
      );
      this.viaje.recaladas[0].informacionExtra.fechaEnvioFDA = `${this.viaje.recaladas[0].informacionExtra.fechaEnvioFDA.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
        this.viaje.recaladas[0].informacionExtra.fechaEnvioFDA.getUTCMonth() + 1
      )}-${this.toMinimumTwoDigitNumber(this.viaje.recaladas[0].informacionExtra.fechaEnvioFDA.getUTCDate())}`;
    }
  }

  /**
   * Coge un numero y lo vuelve en un string que tenga mínimo dos digitos
   * @param numero Numero que se quiere obtener el sitring de 2 diitos
   */
  toMinimumTwoDigitNumber(number) {
    if (number < 10) return `0${number}`;
    else return `${number}`;
  }

  /**
   * Maneja el evento de cambiar la fecha de facturacion
   */
  onCambioFechaDeFacturacion() {
    const recalada = (this.viajeForm.get('recaladas') as FormArray).at(0) as FormGroup;
    const informacionExtra = recalada.get('informacionExtra');
    informacionExtra.get('statusExtra').setValue('FACTURADO');
  }

  /**
   * Metodo que trae los buques de la BD
   */
  onCargarBuques(): void {
    this.buquesCargandose = true;

    this.mainService.get('api/buque?activo=true').subscribe(
      (res: BuqueModel[]) => {
        this.vessels = this.viajeDetalleService.transformBuqueFields(res);
        this.buquesCargandose = false;
      },
      (err) => {
        console.error(err);
        Swal.fire('Error', err.message, 'error');
      }
    );
  }

  /**
   * Metodo que trae las empresas de la BD
   */
  onCargarEmpresas(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.empresasCargandose = true;

      this.mainService.get('api/empresa_sql?activo=true').subscribe(
        (res: EmpresaModel[]) => {
          this.empresas = this.viajeDetalleService.transformBusinessFields(res);
          this.empresasInfoCompleta = this.viajeDetalleService.transformBusinessTotalInfo(res);
          this.empresasCargandose = false;
          resolve();
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  async onCargarRSOInfo() {
    await this.onCargarEmpresas();
    await this.onCargarAreasEmpresas();
  }

  /**
   * Metodo que trae los puertos de la BD
   */
  onCargarPuertos(): void {
    this.puertosCargandose = true;
    this.mainService.get('api/puerto?activo=true').subscribe((res) => {
      this.ports = this.viajeDetalleService.transformPortsFields(res);
      //Hay que quitar el puerto ALL de las opciones, este tiene el ID 0
      this.ports = this.ports.filter((port) => port && port.id !== 0);
      this.puertosCargandose = false;
    });
  }

  /**
   * Metodo que trae las terminales de la BD
   */
  async onCargarTerminales(): Promise<void> {
    this.terminalesCargandose = true;
    await this.onCargarTerminalesPromise();
    this.setCurrentTerminals();
  }

  onCargarTerminalesPromise(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.mainService.get('api/terminal?activo=true').subscribe(
        (res) => {
          this.terminals = this.viajeDetalleService.transformTerminalsFields(res);
          this.terminalsTotalInfo = this.viajeDetalleService.transformTerminalsTotalInfo(res);
          //Hay que quitar la terminal ALL de las opciones, esta tiene el ID 0
          this.terminals = this.terminals.filter((terminal) => terminal && terminal.id !== 0);
          this.terminalesCargandose = false;
          resolve();
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  async onCargarTerminalForm() {
    await this.onCargarTerminalesPromise();
    this.setCurrentTerminals();
  }

  /**
   * Metodo que trae los tipos de operacion de la BD
   */
  onCargarTiposOperaciones(): void {
    this.tiposOperacionCargandose = true;

    this.mainService.get('api/operacion?activo=true').subscribe((res: TipoOperacionModel[]) => {
      this.operationTypes = this.viajeDetalleService.transformOperationsFields(res);
      this.tiposOperacionCargandose = false;
    });
  }

  /**
   * Metodo que trae los commodities de la BD
   */
  onCargarCommodities(): void {
    this.commoditiesCargandose = true;

    this.mainService.get('api/commodity?activo=true').subscribe((res: CommodityModel[]) => {
      this.commodities = this.viajeDetalleService.transformCommoditiesFields(res);

      this.commoditiesCargandose = false;
    });
  }

  /**
   * Metodo que trae los kims de la BD
   */
  onCargarKims(): void {
    this.kimsCargandose = true;

    this.mainService.get('api/kim?activo=true').subscribe((res) => {
      this.kims = this.viajeDetalleService.transformKimsFields(res);
      this.kimsCargandose = false;
    });
  }

  /**
   * Metodo que trae las business lines de la BD
   */
  onCargarBusinessLines(): void {
    this.businessLinesCargandose = true;
    this.mainService.get('api/bussines_line?activo=true').subscribe((res) => {
      this.businessLines = this.viajeDetalleService.transformBusinessLinesFields(res);
      this.businessLinesCargandose = false;
    });
  }

  /**
   * Metodo que trae las areas de las empresas de la BD
   */
  onCargarAreasEmpresas(): Promise<void> {
    const empresaId = this.additionalFormFields.get('empresaRSO').value;
    if (empresaId) {
      return new Promise((resolve, reject) => {
        this.mainService.get(`api/area_empresa/empresa/${empresaId}`).subscribe(
          (res: AreaModel[]) => {
            this.areasEmpresas = this.viajeDetalleService.transformAreasFields(res);
            this.areasEmpresasTotalInfo = this.viajeDetalleService.transformAreasTotalInfo(res);
            this.areasEmpresasFiltradas = this.areasEmpresasTotalInfo.slice();
            this.areasEmpresasCargandose = false;
            resolve();
          },
          (err) => {
            console.error(err);
            reject(err);
          }
        );
      });
    }
  }

  onCargarContactosEmpresas(): Promise<void> {
    const areaEmpresaId = this.additionalFormFields.get('areaRSO').value;
    this.additionalFormFields.get('areaRSO').setValue(Number(areaEmpresaId));
    if (areaEmpresaId) {
      return new Promise((resolve, reject) => {
        this.mainService.get(`api/contacto_empresa/areaEmpresa/${areaEmpresaId}`).subscribe(
          (res: ContactoModel[]) => {
            this.contactosTotalInfo = this.viajeDetalleService.transformContactsFields(res);
            resolve();
          },
          (err) => {
            console.error(err);
            reject(err);
          }
        );
      });
    }
  }

  /**
   * Metodo que trae los boarding adents de la BD
   */
  onCargarBoardingAgents(): void {
    this.boardingAgentsCargandose = true;
    this.mainService.get('api/user/tipo/operario').subscribe((res: UserModel[]) => {
      this.boardingAgents = this.viajeDetalleService.transformBoardingAgentsFields(res);
      this.boardingAgentsCargandose = false;
    });
  }

  loadUsersFacturador(): void {
    this.mainService.get('api/users/tipo?tipo=Facturador').subscribe((res: UserModel[]) => {
      this.usersFacturador = res;
    });
  }
}

