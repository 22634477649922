import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MultiSelectIns } from '../../../instructions/multipleSelect.ins';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
})
export class MultipleSelectComponent implements OnInit, OnDestroy {
  @Input() public instruction!: MultiSelectIns;
  @Input() public fieldName: string;
  @Input() public fieldForm: FormControl;
  @Input() public ngModel: any;
  @Input() public ngModelProperty: string;
  @Input() public disabledSelect: boolean;
  @Input() public disabledOptions: { [key: string]: boolean } = {};

  fallBack = [''];

  constructor() {}

  ngOnInit() {
    this.fallBack = this.instruction.defaults.value;

    if (this.ngModel) {
      this.ngModel[this.ngModelProperty] = this.instruction.defaults.value;
    }
  }

  ngOnDestroy(): void {
    this.instruction.defaults.value = [''];
  }
}
