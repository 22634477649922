import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { S3 } from 'aws-sdk';
import { S3Service } from '../../../../../services/s3.service';
import { MainService } from '../../../../../services/main.service';
import Swal from 'sweetalert2';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'listado-novedades-viaje',
  templateUrl: './listado-novedades-viaje.component.html',
  styleUrls: ['./listado-novedades-viaje.component.scss']
})
export class ListadoNovedadesViajeComponent implements OnInit {
	enviado: boolean = false;
	public novedades: any[] = [];
	novedad: any = {};
	documentURL: string;
	/** Configuracion de la tabla */
	settings = {};
	activeDialog: any;

	private id: string;
	public dataUsuarios: any[] = [];
	private bucket: S3;

	/** Permisos de edición */
	@Input() available: boolean;

	@ViewChild('editNovedad', { static: false }) editNovedad: TemplateRef<any>;
	@ViewChild('createNovedad', { static: false }) createNovedad: TemplateRef<any>;
	@ViewChild('imagenAAdjuntar', { static: true }) imagenAAdjuntar: ElementRef;

  constructor(
		private route: ActivatedRoute,
		private mainService: MainService,
		private s3Service: S3Service,
		private dialogService: NbDialogService
	) {
		this.bucket = this.s3Service.getBucket();
	}

  ngOnInit() {
		this.id = this.route.snapshot.params['id'];
		this.getNovedad();
		this.getUsers().then(() => {
			this.setupTable();
		});
	}

	public getNovedad(){
		this.mainService.get(`api/novedad/barco/${this.id}`).subscribe(res => {
			this.novedades = res;
		})
	}

	public getUsers(){
		return new Promise((resolve, reject) => {
			this.mainService.get(`api/user?activo=true`).subscribe(res => {
				this.dataUsuarios = res;
				resolve(true)
			})
		})
	}

	public setupTable(){
		this.settings = {
			actions: {
				delete: false, edit: false, add: false,
				custom: [
					{ name: 'edit', title: '<i class="nb-compose"></i> ' },
					{ name: 'delete', title: '<i class="nb-trash"></i> ' },
				],
			},
			columns: {
				contenido: { title: 'Contenido' },
				operario: {
					title: 'Operario',
					valuePrepareFunction: (value) => this.getOperatorsById(value),
					filterFunction: (value?: any, search?: any) => this.filterOperators(value, search)
				},
				imagen: {
					title: 'Recurso',
					type: 'html',
					valuePrepareFunction: (value) => value ? `<a href="${value}" target="_blank">Ver recurso</a>` : '',
					filter: false
				}
			}
		};
	}

	public getOperatorsById(id){
		if(this.dataUsuarios){
			const encontrado = this.dataUsuarios.find(usuario => usuario._id == id)
			if(encontrado){
				return encontrado.nombre + ' ' + encontrado.apellido
			}
		} else {
			return
		}
	}

	public filterOperators(operatorId, search){
		if(this.dataUsuarios){
			// Primero filtra a nivel general, solo busca si hay un producto con ese commodity
      const iguales = this.dataUsuarios.filter(user => {
        return (user._id == operatorId)
      })
      if(iguales.length > 0){
        // Si lo hay, busca si el nombre coincide con la búsqueda
        const productosCoinciden = iguales.filter(user => {
          return user.nombre.toLowerCase().includes(search.toLowerCase()) || user.apellido.toLowerCase().includes(search.toLowerCase())
        })
        if(productosCoinciden.length > 0){
          return true;
        }
      }
		}
	}

	public onUploadFile(event){
		const archivoSeleccionado = event.target.files[0];
		if (archivoSeleccionado !== undefined) {
			// Obtener la información necesaria
			const { params, options } = this.s3Service.getInfoForUpload('adjuntoCorreos',archivoSeleccionado);
			// Subir a s3
			this.bucket.upload(params, options, (err, data) => {
				if (this.activeDialog && this.activeDialog.closed) return

				if(err){
					this.s3Service.showErrorUploading();
				} else {
					this.documentURL = data.Location.toString();
					this.s3Service.showSuccessUploading();
				}
			})
		}
  }

	public onAdjuntarArchivo(campo){
    let archivoAAdjuntar;
		archivoAAdjuntar = this.imagenAAdjuntar.nativeElement;
		archivoAAdjuntar.click();
  }

	public abrirCreateNovedad(){
		this.activeDialog = this.dialogService.open(this.createNovedad, { context: '' }).onClose.subscribe(() => this.resetFields());
	}
	
	public crearNovedad() {
		if (!this.validateNovedad()) return

		try {
			this.enviado = true;
			if (this.novedad.contenido != "" && this.novedad.imagen != "" && this.novedad.operario != "" && this.novedad.barco != "") {
				const data = {
					contenido: this.novedad.contenido,
					operario: this.novedad.operario,
					barco: this.id,
					imagen: this.documentURL
				}
				this.mainService.post('api/novedad', data).subscribe(res => {
					if (res) {	
						Swal.fire(
							'Éxito',
							'Se agregó la novedad exitosamente',
							'success'
						);
						const element: HTMLElement = document.getElementById('btn-closeNovedad');
						element.click();
						this.getNovedad();
						this.resetFields();
					} else {
						Swal.fire(
							'Error',
							'No se pudo agregar la novedad, verifique la información',
							'warning'
						);
					}
				});
			} else {
				Swal.fire(
					'Error',
					'No se pudo agregar la novedad, verifique la información',
					'warning'
				);
			}
		} catch (err) {
			console.error(err);
		}
	}

	closeDialog(){
		this.activeDialog.close();
	}

	resetFields(){
		this.novedad = {}
		this.documentURL = undefined;
	}

	public onCustom(event) {
		switch (event.action) {
			case 'edit':
				this.onEditarNovedad(event);
				break;
			case 'delete':
				this.onEliminarNovedad(event)
				break;
			case 'detail':
				//TODO
				break;
		}
	}

	onEditarNovedad(event) {
		if(!this.available){
			Swal.fire({
				title: '¡Error!',
				text: 'No se puede editar una novedad.',
				type: 'error'
			});
			return;
		}
		this.novedad = {}
		this.novedad = event.data;
		this.documentURL = event.data.imagen
		this.activeDialog = this.dialogService.open(this.editNovedad, { context: '' }).onClose.subscribe(() => this.resetFields());
	}

	onEliminarNovedad(event) {
		if(!this.available){
			Swal.fire({
				title: '¡Error!',
				text: 'No se puede eliminar una novedad.',
				type: 'error'
			});
			return;
		}
		Swal.fire({
			title: '<strong>¿Deseas eliminar la novedad?</strong>',
			type: 'warning',
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
		}).then((result) => {
			if (result.value) {

				let novedadEliminar = event.data;
				this.mainService.delete('api/novedad/' + novedadEliminar._id).subscribe(res => {
					if (res) {
						Swal.fire(
							'Éxito',
							'novedad eliminada con éxito',
							'success'
						);
						this.getNovedad();
						console.log(29232323)
						this.activeDialog.close();
					}
				});
			}
		});
	}

	public actualizarNovedad(){
		if (!this.validateNovedad()) return

		if (this.novedad.contenido != "" && this.novedad.imagen != "" && this.novedad.operario != "") {
			const data = this.novedad;
			data.imagen = this.documentURL;
			this.mainService.put('api/novedad/' + data._id, data).subscribe(result => {
				if (result) {
					Swal.fire(
						'Éxito',
						'Se actualizó la novedad exitosamente',
						'success'
					);
					const element: HTMLElement = document.getElementById('btn-closeNovedad');
					element.click();
					this.getNovedad();
					this.resetFields();
				} else {
					Swal.fire(
						'Error',
						'No se pudo actualizar la novedad, verifique la información',
						'warning'
					);
				}
			})
		} else {
			Swal.fire(
				'Error',
				'No se pudo actualizar la novedad, verifique la información',
				'warning'
			);
		}
	}

	public validateNovedad(){
		if (this.novedad.contenido && this.novedad.contenido.trim() === ''){
			delete this.novedad.contenido
		}
		
		if (!this.novedad.operario || !this.novedad.contenido) {
			Swal.fire(
				'Error',
				'No se pudo agregar la novedad, faltan campos.',
				'warning'
				);
				
				return false
			}
			
		return true
	}
}
