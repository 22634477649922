import { Component, OnInit } from '@angular/core';

import {Location} from '@angular/common';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../../services/auth.service';
import { MainService } from '../../services/main.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-notificacion-ver',
  templateUrl: './notificacion-ver.component.html',
  styleUrls: ['./notificacion-ver.component.scss']
})
export class NotificacionVerComponent implements OnInit {

  id: any;
  public notificacion: any={};
  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    this.onCargar()
  }

  onCargar(){
    this.notificacion={};
    console.log("Cargando");
    this.mainService.get('api/notificacion/'+ this.id).subscribe(res =>{   
      this.notificacion = res;
      this.notificacion.nombreCompleto = (this.notificacion.usuario.nombre + ' ' || '') + (this.notificacion.usuario.apellido || '') 
      console.log(this.notificacion);
    });
  }

  back(){
    this._location.back();
  }
}
