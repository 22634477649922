import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { MainService } from '../../services/main.service';

@Component({
	selector: 'app-terminal-ver',
	templateUrl: './terminal-ver.component.html',
	styleUrls: ['./terminal-ver.component.scss']
})
export class TerminalVerComponent implements OnInit {

	puertos: any =[];
	enviado: boolean = false;
	id: any;
	public terminal: any = {};
	public data: any;

	constructor(
		private toastrService: NbToastrService,
		private _location: Location,
		private route: ActivatedRoute,
		private mainService: MainService,) {
	}

	ngOnInit() {
		this.initializePuertos()
		this.id = this.route.snapshot.params['id'];
		this.onCargar()
	}

	initializePuertos(){
		//Ports
    this.mainService.get('api/puerto').subscribe(res =>{
			this.puertos = res;
      this.puertos = _.orderBy(this.puertos, ['PUE_NOMBRE']);
    });
	}

	onCargar() {
		this.terminal = {};
		this.mainService.get('api/terminal/' + this.id).subscribe(res => {
			this.terminal = res;
		});
	}

	back() {
		this._location.back();
	}

	/** Toas **/
	showToast(position, status, titulo, mensaje) {
		this.toastrService.show(
			mensaje,
			titulo,
			{ position, status });
	}

}
