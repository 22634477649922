import { Injectable } from '@angular/core';
import * as moment from 'moment';

interface HoursMinutes {
  hours: number;
  minutes: number;
}

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor() {}

  public diffDateInHoursMinutes(fromDate: string, toDate: string) {
    const momentFrom = moment.utc(fromDate);
    const momentTo = moment.utc(toDate);

    const duration = moment.duration(momentTo.diff(momentFrom));

    const totalMinutesDifference = duration.asMinutes();
    const totalHours =
      totalMinutesDifference >= 0 ? Math.floor(totalMinutesDifference / 60) : Math.ceil(totalMinutesDifference / 60);
    const remainingMinutes = Math.round(totalMinutesDifference % 60);

    return {
      hours: totalHours,
      minutes: remainingMinutes,
    };
  }

  public diffTimeInHoursMinutes(fromTime, toTime) {
    const [fromHours, fromMinutes] = fromTime.split(':').map(Number);
    const [toHours, toMinutes] = toTime.split(':').map(Number);

    const totalMinutesFrom = fromHours * 60 + fromMinutes;
    const totalMinutesTo = toHours * 60 + toMinutes;

    let totalMinutesDifference = totalMinutesTo - totalMinutesFrom;

    if (totalMinutesDifference < 0) {
      totalMinutesDifference += 24 * 60; // Add a day's worth of minutes
    }

    const totalHours = Math.floor(totalMinutesDifference / 60);
    const remainingMinutes = totalMinutesDifference % 60;

    return {
      hours: totalHours,
      minutes: remainingMinutes,
    };
  }

  public diffTimeInHours(fromDate: string, toDate: string) {
    const momentFrom = moment.utc(fromDate);
    const momentTo = moment.utc(toDate);

    const duration = moment.duration(momentTo.diff(momentFrom));
    return duration.asHours();
  }

  public combineDatesAndHours(date: string, hours: string) {
    hours = `${hours || '00:00'}:00`;
    date = moment(date).format('YYYY-MM-DD');

    const combinedDateTime = `${date}T${hours}.000Z`;
    const momentCombined = moment.utc(combinedDateTime);

    return momentCombined.format();
  }

  public sumUpHoursAndMinutes(hoursToSumUp: HoursMinutes[]): HoursMinutes {
    const result = hoursToSumUp.reduce(
      (acc, current) => {
        // Calculate the total hours and minutes for the current object
        const totalHours = acc.hours + current.hours;
        const totalMinutes = acc.minutes + current.minutes;

        // If the total minutes exceed 60, adjust the hours and minutes
        if (totalMinutes >= 60) {
          acc.hours += Math.floor(totalMinutes / 60);
          acc.minutes = totalMinutes % 60;
        } else {
          acc.minutes = totalMinutes;
        }

        acc.hours += totalHours; // Add total hours to the accumulator

        return acc;
      },
      { hours: 0, minutes: 0 }
    );

    return result;
  }

  public minusHoursAndMinutes(first: HoursMinutes, second: HoursMinutes): HoursMinutes {
    let differenceHours = first.hours - second.hours;
    let differenceMinutes = first.minutes - second.minutes;

    if (differenceMinutes < 0) {
      differenceHours--;
      differenceMinutes += 60;
    }

    return {
      hours: differenceHours,
      minutes: differenceMinutes,
    };
  }

  public minusHoursAndMinutesIntoHours(first: HoursMinutes, second: HoursMinutes): number {
    let differenceHours = first.hours - second.hours;
    let differenceMinutes = first.minutes - second.minutes;

    if (differenceMinutes < 0) {
      differenceHours--;
      differenceMinutes += 60;
    }

    // Convert the remaining minutes to hours and add it to the difference in hours
    differenceHours += differenceMinutes / 60;

    return differenceHours;
  }

  public formatHoursMinutesToString(hours: number, minutes: number) {
    return `${hours} HRS ${minutes} MNS`;
  }

  public getIndividualHours(date: string) {
    const yourDate = moment(date);

    return {
      hours: Number(yourDate.format('HH')),
      minutes: Number(yourDate.format('mm')),
    };
  }

  public sumIndividualHoursAndMinutes(dateTime) {
    const hours = dateTime.hours;
    const minutes = dateTime.minutes;

    // Add the minutes to the hours and return as a decimal
    const totalHours = hours + minutes / 60;

    return totalHours;
  }

  public convertStringDateToDate(date: string, hours: string, minutes: string) {
    const hoursMinutes = `${hours}:${minutes}:00`;
    date = moment(date).format('YYYY-MM-DD');

    const combinedDateTime = `${date}T${hoursMinutes}.000Z`;
    return combinedDateTime;
  }

  public getSplitDate(date: Date) {
    const momentDate = moment.utc(date);
    return {
      date: momentDate.format('YYYY-MM-DD'),
      hour: momentDate.format('HH'),
      minutes: momentDate.format('mm'),
    };
  }

  public convertMinutesToHoursMinutes(totalHours: number): HoursMinutes {
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);

    return {
      hours: hours,
      minutes: minutes,
    };
  }

  // focus on smart angular table

  public filterByDate(dateToFilter: string, filter: string) {
    if (!dateToFilter) return false;
    dateToFilter = moment.utc(dateToFilter).format('DD-MM-YYYY HH:mm');
    return dateToFilter.includes(filter);
  }

  public filterByWord(wordToFilter: string, filter: string) {
    if (!wordToFilter) return false;
    return wordToFilter.includes(filter);
  }

  public formatStandardDate(date: string) {
    if (!date) return;
    return moment.utc(date).format('DD-MM-YYYY HH:mm');
  }
}
