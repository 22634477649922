import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as S3 from 'aws-sdk/clients/s3';
import { TimeService } from '../../services/time/time.service';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
/* Services */
import { MainService } from '../services/main.service';
import { UtilsService } from '../services/utils.service';
import { AlertService } from '../../services/alertService/alert-service.service';
import { multiSelectsIns } from '../../instructions/multipleSelect.ins';
import { IMG_EXTENSIONS_DICT } from './const/img-extension-dict.const';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss'],
})
export class NoticiasComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  public instructions = {
    companyType: multiSelectsIns.companyType,
  };

  public selectedFile: any;
  bucket: S3 = new S3({
    accessKeyId: 'AKIAUT7IXVID77RSYJ5F',
    secretAccessKey: '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',
    region: 'us-east-1',
  });

  datosEdit = {
    _id: '',
    titulo: '',
    resumen: '',
    texto: '',
    imagen: '',
    companies: [],
  };

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      titulo: {
        title: 'Título',
      },
      resumen: {
        title: 'Resumen',
      },
      texto: {
        title: 'Texto',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  enviado: boolean = false;
  titulo: any;
  resumen: any;
  texto: any;
  imagen: any;
  public user: any;
  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private utilsService: UtilsService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.noticias === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /** Para modal **/
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  onEditar(dialog, event) {
    this.selectedCompanyType = undefined;
    this.areaSelected = [];

    if (this.permisosUsuario.noticias !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }

    this.selectedCompanyType = undefined;
    this.selectedCompanyId = undefined;
    this.companiesByType = [];

    this.enviado = false;
    this.datosEdit = Object.assign({}, event.data);
    this.selectedCompanies = this.datosEdit.companies;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  crearNoticia() {
    this.selectedCompanies = [];
    this.datosEdit.companies = [];
    this.selectedCompanyType = undefined;
    this.selectedCompanyId = undefined;
    this.companiesByType = [];
    this.areaSelected = [];

    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/

  public crear() {
    if (this.titulo != undefined && this.resumen != undefined && this.texto != undefined) {
      let noticia = {
        titulo: this.titulo,
        resumen: this.resumen,
        texto: this.texto,
        imagen: this.imagen,
        companies: this.datosEdit.companies.map((company) => company._id),
      };
      this.mainService.post('api/noticia', noticia).subscribe((result) => {
        this.enviado = false;
        if (result) {
          this.datosEdit = result;
          this.sendMessage();
          Swal.fire('Éxito', 'Se agregó la noticia exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.titulo = '';
          this.resumen = '';
          this.texto = '';
          this.imagen = '';
          this.selectedCompanies = [];
          this.datosEdit.companies = [];

          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo agregar la noticia, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar la noticia, verifique la información', 'warning');
    }
  }

  onVer(event) {
    this.router.navigate(['home/noticia-ver/' + event.data._id]);
  }

  onActualizar() {
    if (
      this.datosEdit.titulo != '' &&
      this.datosEdit.resumen != '' &&
      this.datosEdit.texto != '' &&
      this.datosEdit.imagen != ''
    ) {
      let noticia = {
        _id: this.datosEdit._id,
        titulo: this.datosEdit.titulo,
        resumen: this.datosEdit.resumen,
        texto: this.datosEdit.texto,
        imagen: this.datosEdit.imagen,
        companies: this.datosEdit.companies.map((company) => company._id),
      };
      let data = noticia;
      this.mainService.put('api/noticia/' + data._id, data).subscribe((result) => {
        if (result) {
          this.sendMessage();
          Swal.fire('Éxito', 'Se actualizó la noticia exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo actualizar la noticia, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar la noticia, verifique la información', 'warning');
    }
  }

  onCargar() {
    let x = this;
    this.data = [];
    this.mainService.get('api/noticia').subscribe((res) => {
      x.data = res;
      this.data = new LocalDataSource(this.data);
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.noticias !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar la noticia?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let noticia = event.data;
        this.spinerGuardar = true;
        this.mainService.delete('api/noticia/' + noticia._id).subscribe((res) => {
          if (res) {
            this.spinerGuardar = false;
            Swal.fire('Éxito', 'Noticia eliminada con éxito', 'success');
            this.onCargar();
          }
        });
      }
    });
  }

  actualStateOnUpload: number = 0;
  isLoading = false;

  public onFileSelect(event, editar) {
    this.actualStateOnUpload = 0;
    this.isLoading = true;

    this.selectedFile = event.target.files[0];
    let self = this;

    if (this.selectedFile !== undefined) {
      var file = this.selectedFile;
      let nombre = this.utilsService.generateRandomId();

      var params = {
        Bucket: 'naves-recursos',
        ACL: 'public-read',
        // Key: 'novedad/' + nombre + '.jpg',
        Key: 'novedad/' + nombre + '.' + file.name.split('.').pop(),
        ContentType: file.type,
        Body: file,
      };

      const options = {
        // Part Size of 10mb
        // partSize: 10 * 1024 * 1024,
        queueSize: 1,
        // Give the owner of the bucket full control
      };

      const uploader = this.bucket.upload(params, options, function (err, data) {
        if (err) {
          console.error(err);
          return;
        }
        if (editar) {
          self.datosEdit.imagen = data.Location.toString();
        } else {
          self.imagen = data.Location.toString();
        }
        return true;
      });

      /**
       * Use arrow function to get the proper context
       */
      uploader.on('httpUploadProgress', (event) => {
        const calc = Math.floor((event.loaded / event.total) * 100);
        this.actualStateOnUpload = calc;
      });
    }
  }

  abrirFile() {
    const element: HTMLElement = document.getElementById('archivo');
    element.click();
  }

  public companyTypes = ['PROVEEDOR', 'CLIENTE', 'CARGO OWNER'];
  public selectedCompanyType: string;

  public findCompaniesBasedType() {
    this.getCompaniesByType();
  }

  public companiesByType = [];
  public selectedCompanies = [];
  public selectedCompanyId;

  private getCompaniesByType() {
    this.mainService.get(`api/empresa_sql?activo=true&tipo=${this.selectedCompanyType}`).subscribe((res) => {
      this.companiesByType = res;
      this.setCompanyCollection();
      if (this.companiesByType.length === 0) this.selectedCompanyId = undefined;
    });
  }

  public addCompaniesToList() {
    const foundCompany = this.companiesByType.find((company) => company._id == this.selectedCompanyId);
    if (this.companyAlreadyInCollection(foundCompany._id)) return;
    this.datosEdit.companies.push(foundCompany);
  }

  public removeCompaniesFromList(companyId: string) {
    this.datosEdit.companies = this.datosEdit.companies.filter((company) => company._id !== companyId);
  }

  public async sendMessage() {
    if (this.datosEdit.companies.length === 0) {
      this.alertService.optionsAlertWarning('No tienes contactos para enviar');
      return;
    }

    const contact = await this.collectAllCompaniesContacts();

    if (contact.length === 0) {
      this.alertService.simpleAlertWarning('No existen contactos en las áreas seleccionadas');
      return;
    }

    const emailContact = this.getContactEmails(contact);
    const msgHTML = await this.generateHTMLMessage();

    const promises = emailContact.map((correo) => {
      const msg = {
        to: [correo],
        html: msgHTML,
        cc: [],
        bcc: [],
        attachments: [],
        fromEmail: 'navesinforma@navescolombia.com',
        subject: this.datosEdit.titulo,
      };
      return this.mainService.post(`api/sendEmail`, { emails: [msg] }).toPromise();
    });

    Promise.all(promises).then((values) => {
      this.alertService.simpleAlertConfirm('Noticia enviada');
    });
  }

  public async collectAllCompaniesContacts() {
    const contacts = [];
    for (const company of this.datosEdit.companies) {
      const gotContacts = await this.getContactsByCompany(company._id);
      contacts.push(...gotContacts);
    }

    return contacts;
  }

  public getContactsByCompany(idCompany) {
    let selectedAreas = [];
    if (this.areaSelected.length === 0) {
      selectedAreas = this.instructions.companyType.options.map((option) => option.content);
    } else {
      selectedAreas = [...this.areaSelected];
    }

    return this.mainService
      .get(`api/contacto_empresa/empresa/${idCompany}/tipoarea?areaType=${selectedAreas}`)
      .toPromise();
  }

  public getContactEmails(contacts) {
    return contacts.map((contact) => contact.correo);
  }

  private async generateHTMLMessage() {
    let html = `<!DOCTYPE html>
		<html lang="en">
		<head>
			<meta charset="UTF-8">
			<meta http-equiv="X-UA-Compatible" content="IE=edge">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
		</head>
		<body>`;

    html += `<p>${this.datosEdit.titulo}</p>`;
    html += `<p>${this.datosEdit.texto}</p>`;
    html += `<p>${this.datosEdit.resumen}</p>`;

    if (this.isImage(this.datosEdit.imagen)) {
      html += `<img src="${this.datosEdit.imagen}" width="800" alt="${this.datosEdit.titulo}">`;
    } else {
      // Si no es una imagen, crear un enlace para descargar
      html += `<a href="${this.datosEdit.imagen}" download="${this.datosEdit.titulo}">Descargar archivo</a>`;
    }

    // if (this.datosEdit.imagen) {
    //   console.log('Archivo a enviar: ', this.datosEdit.imagen);

    //   html += `<img src="${this.datosEdit.imagen}" width="800" alt="${this.datosEdit.titulo}">`;
    // }

    html += '</tbody></table></body></html>';
    return html;
  }

  isImage(url: string): boolean {
    const fileExtension = url.substring(url.lastIndexOf('.')).toLowerCase();
    return IMG_EXTENSIONS_DICT.includes(fileExtension);
  }

  public selectAllCompanies() {
    this.companiesByType.forEach((company) => {
      if (this.companyAlreadyInCollection(company._id)) return;
      this.datosEdit.companies.push(company);
    });

    this.selectedCompanies = this.datosEdit.companies;
  }

  public companyAlreadyInCollection(companyId: string) {
    return this.datosEdit.companies.some((company) => company._id === companyId);
  }

  public areaSelected = [];
  filterContactsByArea() {}

  setCompany(data: string) {
    this.selectedCompanyId = data;
    this.addCompaniesToList();
  }

  public dataCollectionToCompany = [];
  setCompanyCollection() {
    this.dataCollectionToCompany = [];
    this.companiesByType.forEach((company) => {
      this.dataCollectionToCompany.push({
        key: company._id,
        value: company.EMP_NOMBRE,
      });
    });
  }
}
