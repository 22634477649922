import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaptianInterviewComponent } from './pages/captian-interview/captian-interview.component';

import { DashboardComponent } from './pages/captian-interview/sections/dashboard/dashboard.component';
import { SurvyComponent } from './pages/captian-interview/sections/survy/survy.component';
import { QuestionComponent } from './pages/captian-interview/sections/question/question.component';
import { CaptianInterviewRoutingModule } from './captian-interview-routing.module';

import { SharedModule } from '../shared/shared.module';

const pageComponents = [CaptianInterviewComponent];
const sectionComponents = [DashboardComponent, SurvyComponent, QuestionComponent];

@NgModule({
  declarations: [...pageComponents, ...sectionComponents],
  imports: [CommonModule, SharedModule, CaptianInterviewRoutingModule],
})
export class CaptianInterviewModule {}
