import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';
import { UtilsService } from '../services/utils.service';

type AOA = any[][];

@Component({
  selector: 'app-puerto',
  templateUrl: './puerto.component.html',
  styleUrls: ['./puerto.component.scss'],
})
export class PuertoComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  /*****/

  /** Puerto actualmente escogido */
  puerto: any = {};

  /** Indica si hay algo cargandose en el componente */
  public cargando: boolean = false;

  /** Configuracion de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      PUE_ID: {
        title: 'ID',
      },
      PUE_NOMBRE: {
        title: 'Nombre',
      },
      PUE_COD: {
        title: 'COD',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  datos: any = [];
  /** Indica si los datos no fueron enviados */
  enviado: boolean = false;

  /** Usuario actual */
  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private utilsService: UtilsService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.puertos === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /**
   * Maneja las acciones de la tabla
   * @param event Evento con la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  /**
   * Inicializa la edicion de un puerto
   * @param dialog Referencia al dialogo del detalle
   * @param event Evento cn los datos del puerto aeditar
   */
  onEditar(dialog, event) {
    if (this.permisosUsuario.puertos !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.puerto = {};
    this.puerto = event.data;
    this.puerto._id = event.data._id;
    this.dialogService
      .open(dialog, { context: 'this is some additional data passed to dialog' })
      .onClose.subscribe(() => {
        this.puerto = {};
      });
  }

  /**
   * Abre el dialogo para la edicion de un puerto
   * @param edit Dialogo del detalle
   */
  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * INicializa la creacin de un puerto
   */
  crearPuerto() {
    this.puerto.PUE_ID = '';
    this.openDialogSave(this.dialog);
  }

  /**
   * Abre el dialogo para la creacion de un puerto
   * @param dialog Dialogo de l detalle
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.cargando = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.cargando = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Envia los datos a la BD
   */
  public crear() {
    this.enviado = false;
    if (
      this.puerto.PUE_NOMBRE &&
      this.puerto.PUE_DIRECCION &&
      this.puerto.PUE_COD &&
      this.puerto.CENTRO_COSTE &&
      this.puerto.CENTRO_BENEFICIO &&
      this.puerto.email &&
      this.utilsService.validateEmail(this.puerto.email)
    ) {
      this.mainService.get('api/global_variable/PUE_ID').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.puerto.PUE_ID = 43; //Primer valor del ID
          const puertoIdGlobalVariable = {
            key: 'PUE_ID',
            value: this.puerto.PUE_ID,
          };
          this.mainService.post('api/global_variable', puertoIdGlobalVariable).subscribe((result) => {});
        } else {
          this.puerto.PUE_ID = res.value;
        }

        this.mainService.put('api/global_variable/PUE_ID', {}).subscribe((result) => {});
        this.enviado = false;
        this.mainService.post('api/puerto', this.puerto).subscribe((result) => {
          if (result) {
            Swal.fire('Éxito', 'Se agregó el puerto exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();

            this.puerto = {};
          } else {
            Swal.fire('Error', 'No se pudo agregar el puerto, verifique la información', 'warning');
          }
        });
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar el puerto, verifique la información', 'warning');
    }
  }

  onVer(event) {
    this.router.navigate(['home/puerto-ver/' + event.data._id]);
  }

  onActualizar() {
    if (
      this.puerto.PUE_NOMBRE &&
      this.puerto.PUE_DIRECCION &&
      this.puerto.PUE_COD &&
      this.puerto.CENTRO_COSTE &&
      this.puerto.CENTRO_BENEFICIO &&
      this.puerto.email &&
      this.utilsService.validateEmail(this.puerto.email)
    ) {
      let puertoActualizar = this.puerto;
      let data = puertoActualizar;

      this.mainService.put('api/puerto/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó el puerto exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
          this.puerto = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar el puerto, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar el puerto, verifique la información', 'warning');
    }
  }

  /**
   * Carga los datos de la BD
   */
  onCargar() {
    this.cargando = true;

    let x = this;
    this.data = [];
    this.mainService.get('api/puerto?activo=true').subscribe((res) => {
      x.data = res;
      this.cargando = false;
    });
  }

  /**
   * Elimina un puerto de la BD
   * @event Evento con los datos del puerto a eliminar
   */
  onEliminar(event) {
    if (this.permisosUsuario.puertos !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el puerto?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let puertoEliminar = event.data;
        this.cargando = true;
        this.mainService
          .delete(`api/puerto/${puertoEliminar._id}?puertoId=${puertoEliminar.PUE_ID}`)
          .subscribe((res) => {
            if (res) {
              this.cargando = false;
              if (res.message) {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  type: 'error',
                });
                return;
              }
              Swal.fire('Éxito', 'Puerto eliminado con éxito', 'success');
              this.onCargar();
            }
          });
      }
    });
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.datos = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

      for (let i = 1; i < this.datos.length; i++) {
        /* let x = {}; */
        let excel = {
          PUE_ID: this.datos[i][0],
          PUE_NOMBRE: this.datos[i][1],
          PUE_TEXTO: this.datos[i][2],
          PUE_DIRECCION: this.datos[i][3],
          PUE_COD: this.datos[i][4],
          CENTRO_COSTE: this.datos[i][5],
          CENTRO_BENEFICIO: this.datos[i][6],
        };
        this.mainService.post('api/puerto', excel).subscribe((res) => {});
      }
    };
    this.onCargar();
    reader.readAsBinaryString(target.files[0]);
  }

  abrirExcel() {
    const element: HTMLElement = document.getElementById('archivo');
    element.click();
  }
}
