import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { MainService } from '../../services/main.service';


@Component({
	selector: 'app-carga-ver',
	templateUrl: './carga-ver.component.html',
	styleUrls: ['./carga-ver.component.scss']
})
export class CargaVerComponent implements OnInit {

	enviado: boolean = false;
	id: any;
	public carga: any = {};
	public data: any;
	commodities: any = [];

	constructor(
		private toastrService: NbToastrService,
		private _location: Location,
		private route: ActivatedRoute,
		private mainService: MainService,) {

	}

	ngOnInit() {
		this.initializeCommodities();
		this.id = this.route.snapshot.params['id'];
		this.onCargar()

	}

	initializeCommodities(){
		//Commodities
    this.mainService.get('api/commodity').subscribe(res =>{
			this.commodities = res;
      this.commodities = _.orderBy(this.commodities, ['PUE_NOMBRE']);
    });
	}

	onCargar() {

		this.carga = {};

		this.mainService.get('api/carga/' + this.id).subscribe(res => {
			this.carga = res;
		});
	}

	back() {
		this._location.back();
	}

	/** Toas **/

	showToast(position, status, titulo, mensaje) {
		this.toastrService.show(
			mensaje,
			titulo,
			{ position, status });
	}

}
