import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid'
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  /**
	 * Indica si un correo electronico es valido o no
	 * @param email String con el correo electronico que se quiere Validators.requir
	 * @return Boolean indicando si el correo electronico es valido o no
	 */
  validateEmail(email){
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

	/**
	 * Genera un UUID random con la librería UUID 
	 * @returns String con el id generado
	 */
	generateRandomId(): string {
		return uuidv4();
	}

}
