import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../../../services/main.service';
import { ReportesService } from '../../reportes.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { VistaPreviaReporeArriboComponent } from './componentes/vista-previa-repore-arribo/vista-previa-repore-arribo.component';
import { S3Service } from '../../../../../services/s3.service';
import { S3 } from 'aws-sdk';

import { TimeService } from '../../../../../../services/time/time.service';
import { AlertService } from '../../../../../../services/alertService/alert-service.service';

@Component({
  selector: 'reporte-arribo',
  templateUrl: './reporte-arribo.component.html',
  styleUrls: ['./reporte-arribo.component.scss'],
})
export class ReporteArriboComponent implements OnInit {
  /** Viaje del reporte de arribo */
  public viaje: any = {};
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Formulario reactivo del reporte de arribo */
  public formulario: FormGroup;
  /** Archivos adjuntos en el reporte de arribo */
  public archivosAdjuntos: any[] = [];
  /** Lista de correos con copia */
  public ccs: any[] = [];
  /** Nuevo correo para agregar a la copia */
  public nuevoCC: FormControl = new FormControl('', [
    Validators.email,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
  ]);
  /** Nuevo correo para agregar a la copia */
  public nuevoBCC: FormControl = new FormControl('', [
    Validators.email,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z-9.-]+\\.[a-zA-Z]{2,4}$'),
  ]);
  /** Lista de correos con copia oculta */
  public bccs: any[] = [];
  /** Posibles estados en el Port Log */
  public estadosPortLog: string[] = ['CANCELED', 'CONFIRMED', 'ESTIMATED', 'NOT ESTIMATED'];

  public estadosPortLogToShow: { [key: string]: string } = {
    CANCELED: 'Canceled',
    CONFIRMED: 'Confirmed',
    ESTIMATED: 'Estimated',
    'NOT ESTIMATED': 'Not estimated',
  };

  /** Posibles errores en los datos ingresados por el usuario */
  public erroresDatosIngresados: string[] = [
    'NINGUNO',
    'FECHA_INCOMPLETA',
    'MAGNITUD_FECHAS_INCORRECTA',
    'FECHAS_DESORDENADAS',
  ];
  /** Indica que los archivos adjuntos se estan cargando */
  public archivosAdjuntosCargandose: boolean = false;
  /** Arreglo con los correos de los contactos asociados al viaje */
  public emailsContactosViaje: any[] = [];
  /** Arreglo con todas las áreas con sus correos */
  public areasConCorreos: any[] = [];
  /** Arreglo con todas las áreas de las empresas */
  public areasEmpresas: any[] = [];
  /** Email del puerto asociado */
  public emailPuerto: string = '';
  /** Arreglo con todas las áreas con sus correos */
  public empresasAreasConCorreos: any[] = [];
  /** Arreglo con los posibles errores al intentar enviar un correo */
  public erroresEnvioCorreo: string[] = ['NINGUNO', 'SIN_ASUNTO', 'SIN_CONTACTOS'];
  /** Indica si se incluyen las tablas de FO, LO, DO, etc */
  public incluirTablas: boolean = true;
  /** Indica si se incluyen las actividades o no */
  public incluirActividades: boolean = true;
  /** Arreglo ordenado de las actividades confirmadas*/
  public arregloOrdenadoActividadesConfirmadas: any;
  /** Arreglo ordenado de las actividades estimadas*/
  public arregloOrdenadoActividadesEstimadas: any;
  /** Indica si se incluyen las actividades o no */

  /** Referencia al archivo que se va a adjuntar */
  @ViewChild('archjivoAAdjuntar', { static: true }) archjivoAAdjuntar: ElementRef;
  /** Dialogo de guardar para abrir vista previa */
  @ViewChild('guardarVistaPrevia', { static: false }) guardarVistaPrevia: TemplateRef<any>;

  /** Referencia a las actividades del SOF */
  arregloOrdenadoActividadesSofConfirmadas: any[] = [];
  arregloOrdenadoActividadesSofEstimadas: any[] = [];

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  public activeDialog: any;
  public vistaPrevia: boolean;
  public buqueCorreo: any;

  /** Imágenes del buque */
  public fotoBuque: string;
  public iconoBuque: string;

  /** Bucket de s3 */
  private bucket: S3;
  /** url del documento cargado */
  private documentURL: string;
  /** Indica si se está enviando correo */
  public enviandoCorreo: boolean;
  /** Indica si desea enviar correo */
  public enviarCorreo: boolean = false;

  constructor(
    public router: Router,
    private mainService: MainService,
    private reportesService: ReportesService,
    private _location: Location,
    private dialogService: NbDialogService,
    private s3Service: S3Service,
    private timeService: TimeService,
    private alertService: AlertService
  ) {
    this.bucket = this.s3Service.getBucket();
  }

  ngOnInit() {
    this.inicializarFormualrioVacio();
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe(async (res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.analisisOperativo === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.obtenerViaje();
      }
    });
  }

  /**
   * A traves de la URL obtiene el viaje del reporte de arribo e inicializa el viaje en el formato requerido para
   * el componente de reporte de arribo
   */
  async obtenerViaje(): Promise<boolean> {
    this.cargando = true;
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');
    return new Promise(async (resolve, reject) => {
      this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe(async (res) => {
        if (res.message) {
          Swal.fire({
            title: '¡Error!',
            text: res.message,
            type: 'error',
          });
          return this.router.navigate(['home/dashboard']);
        }
        this.viaje = res;
        this.emailPuerto = this.viaje.puertoCorreo;

        this.obtenerCorreosYEmpresasAsociados();

        const savedSubject = this.formulario.value.asuntoReporte;
        const savedFiles = this.archivosAdjuntos;
        if (this.viaje.sof.length > 0) {
          await this.obtenerActividadesSof(this.viaje.sof[0]);
        }
        await this.inicializarViajeEnFormatoRequerido();
        this.inicializarFormualrioConDatosViaje();
        this.obtenerActividadesEstimadasYConfirmadasOrdenadas();
        this.archivosAdjuntos = savedFiles;
        this.formulario.patchValue({ asuntoReporte: savedSubject });
        this.cargando = false;
        this.mixPortogAndSofActivities();
        this.setDefaultSubjects();
        resolve(true);
      });
    });
  }

  /**
   * Inicializa el viaje en el formato requerido para el componente de reporte de arribo
   */
  async inicializarViajeEnFormatoRequerido() {
    this.viaje.buqueNombre = 'Cargando ...';
    await this.obtenerNombresAsociacionesRelevantesViaje();
    this.fechasEnFormatoFormulario();
    this.archivosAdjuntos =
      this.viaje.reporteArribo && this.viaje.reporteArribo.archivosAdjuntos
        ? this.viaje.reporteArribo.archivosAdjuntos
        : [];
  }

  /**
   * Obtiene los nombres de las asociaciones del viaje que son relevantes al componente de reporte de arribo, también trae los iconos e imagenes del buque
   */
  async obtenerNombresAsociacionesRelevantesViaje(): Promise<boolean[]> {
    // Nombre del buque e imágenes
    const promesaVessel = new Promise<boolean>((resolve, reject) => {
      if (this.viaje.vessel) {
        this.mainService.get(`api/buque?BUQ_ID=${this.viaje.vessel}`).subscribe((res) => {
          this.buqueCorreo = res.length > 0 ? res[0].BUQ_CORREO : undefined;
          this.viaje.buqueNombre = res.length > 0 ? res[0].BUQ_NOMBRE : 'Not found';
          this.iconoBuque = res.length > 0 ? res[0].BUQ_FOTO_ICONO : undefined;
          this.fotoBuque = res.length > 0 ? res[0].BUQ_FOTO_REAL : undefined;
          resolve(true);
        });
      } else {
        this.viaje.buqueNombre = 'Not registered';
        resolve(true);
      }
    });

    return Promise.all([promesaVessel]);
  }

  /**
   * Añade o elimina de la lista de destinatarios el correo del buque
   */
  public addedVesselEmail = false;
  addBuqueCorreo(event) {
    const contactoBuque = {
      area: undefined,
      empresa: this.viaje.buqueNombre,
      correos: [
        {
          email: this.buqueCorreo,
          nombre: this.viaje.buqueNombre,
        },
      ],
    };
    if (event.target.checked) {
      this.addedVesselEmail = true;
      const existeContacto = this.emailsContactosViaje.find((contactoAgregado) => {
        return contactoAgregado.empresa === this.viaje.buqueNombre;
      });
      if (!existeContacto) {
        this.emailsContactosViaje.push(contactoBuque);
      }
    } else {
      this.addedVesselEmail = false;
      this.emailsContactosViaje = this.emailsContactosViaje.filter((contactoAgregado) => {
        return contactoAgregado.empresa != this.viaje.buqueNombre;
      });
    }
  }

  /**
   * Trae los correos de los contactos asociados al viaje
   */
  obtenerCorreosYEmpresasAsociados() {
    // this.emailsContactosViaje = [];
    this.mainService.get(`api/empresa`).subscribe((areasEmpresas) => {
      this.areasEmpresas = areasEmpresas;
      this.getContacts();
    });
  }

  /**
   * Ordena los contactos para que la persona los seleccione y luego se puedan mandar por correo
   */
  getContacts() {
    const arrayFinalSendgrid = [];
    this.viaje.recieversShippersOthers.forEach((contacto) => {
      this.mainService.get(`api/contacto_empresa/areaEmpresa/${contacto.areaId}`).subscribe((contactosEmpresas) => {
        if (contactosEmpresas && contactosEmpresas[0]) {
          const posiblesEmpresas = contactosEmpresas.map((conEmpresa) => conEmpresa.nombreEmpresa).flat();
          const areasEmpresasFiltradas = posiblesEmpresas.filter((area, index) => {
            return posiblesEmpresas.indexOf(area) === index;
          });
          const posiblesAreas = contactosEmpresas.map((conEmpresa) => conEmpresa.nombreArea).flat();
          const areasAreasFiltradas = posiblesAreas.filter((area, index) => {
            return posiblesAreas.indexOf(area) === index;
          });
          for (let empresa of areasEmpresasFiltradas) {
            for (let area of areasAreasFiltradas) {
              const correos = contactosEmpresas
                .filter((conEmpresa) => {
                  return conEmpresa.nombreEmpresa[0] === empresa && conEmpresa.nombreArea[0] === area;
                })
                .map((contacto) => {
                  return { email: contacto.correo, nombre: contacto.nombre };
                });
              const empresaAreaContactos = {
                empresa,
                area,
                correos,
              };
              arrayFinalSendgrid.push(empresaAreaContactos);
              this.empresasAreasConCorreos = arrayFinalSendgrid;
            }
          }
        }
      });
    });
  }

  obtenerActividadesSof(idSof): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (idSof) {
        this.mainService.get(`api/sof/${idSof}/${this.viaje.recaladaId}`).subscribe((res) => {
          const actividadesSof = res.history || [];
          const actividadesOrdenadasSof = [];
          for (let actividad of actividadesSof) {
            const formattedDate = this.timeService.combineDatesAndHours(actividad.date, actividad.from)
            actividadesOrdenadasSof.push({
              category: actividad.category,
              type: actividad.type,
              nombre: actividad.type,
              status: actividad.status,
              fecha: formattedDate,
              description: actividad.description,
            });
          }
          actividadesOrdenadasSof.forEach((actividad) => {
            actividad.fechaVisual = this.reportesService.obtenerFechaVisualReportes(new Date(actividad.fecha));
          });
          actividadesOrdenadasSof.sort(function (a, b) {
            return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
          });
          this.arregloOrdenadoActividadesSofConfirmadas = actividadesOrdenadasSof.filter(
            (actividad) => actividad.status == 'Confirmed'
          );
          this.arregloOrdenadoActividadesSofEstimadas = actividadesOrdenadasSof.filter(
            (actividad) => actividad.status == 'Estimated'
          );
          resolve(true);
        });
      }
    });
  }

  /**
   * Agrega los emails de un área a la lista de emails a mandar por sendgrid
   */
  addEmails(area, event) {
    if (event.target.checked) {
      this.emailsContactosViaje = this.emailsContactosViaje.includes(area.correos)
        ? this.emailsContactosViaje
        : this.emailsContactosViaje.concat(area);
    } else {
      this.emailsContactosViaje = this.emailsContactosViaje.filter((areaAgregada) => {
        return !area.correos.includes(...areaAgregada.correos);
      });
    }
  }

  /**
   * Pone las fechas del viaje que son relevantes al componente de reporte de prospectos en formato del formulario
   */
  fechasEnFormatoFormulario(): void {
    // EOSP
    if (this.viaje.portLog && this.viaje.portLog.eosp.fecha) {
      /** EOSP en tipo Date */
      let eospDate = new Date(this.viaje.portLog.eosp.fecha);
      this.viaje.portLog.eospFecha = this.reportesService.obtenerFechaFormulario(eospDate);
      this.viaje.portLog.eospHora = this.reportesService.obtenerHoraFormulario(eospDate);
    }

    // Nor Tendered
    if (this.viaje.portLog && this.viaje.portLog.norTendered.fecha) {
      /** Nor Tendered en tipo Date */
      let norTenderedDate = new Date(this.viaje.portLog.norTendered.fecha);
      this.viaje.portLog.norTenderedFecha = this.reportesService.obtenerFechaFormulario(norTenderedDate);
      this.viaje.portLog.norTenderedHora = this.reportesService.obtenerHoraFormulario(norTenderedDate);
    }

    // Pilot on Board for Anchor
    if (this.viaje.portLog && this.viaje.portLog.pilotOnBoardAnchor.fecha) {
      /** Pilot on Board for Anchor en tipo Date */
      let pilotOnBoardAnchorDate = new Date(this.viaje.portLog.pilotOnBoardAnchor.fecha);
      this.viaje.portLog.pilotOnBoardAnchorFecha = this.reportesService.obtenerFechaFormulario(pilotOnBoardAnchorDate);
      this.viaje.portLog.pilotOnBoardAnchorHora = this.reportesService.obtenerHoraFormulario(pilotOnBoardAnchorDate);
    }

    // Pilot on Board for Berthing
    if (this.viaje.portLog && this.viaje.portLog.pilotOnBoardBerthing.fecha) {
      /** Pilot on Board for Berthing en tipo Date */
      let pilotOnBoardBerthingDate = new Date(this.viaje.portLog.pilotOnBoardBerthing.fecha);
      this.viaje.portLog.pilotOnBoardBerthingFecha =
        this.reportesService.obtenerFechaFormulario(pilotOnBoardBerthingDate);
      this.viaje.portLog.pilotOnBoardBerthingHora =
        this.reportesService.obtenerHoraFormulario(pilotOnBoardBerthingDate);
    }

    // Dropped Anchor
    if (this.viaje.portLog && this.viaje.portLog.droppedAnchor.fecha) {
      /** Dropped Anchor en tipo Date */
      let droppedAnchorDate = new Date(this.viaje.portLog.droppedAnchor.fecha);
      this.viaje.portLog.droppedAnchorFecha = this.reportesService.obtenerFechaFormulario(droppedAnchorDate);
      this.viaje.portLog.droppedAnchorHora = this.reportesService.obtenerHoraFormulario(droppedAnchorDate);
    }

    // First Line Ashore
    if (this.viaje.portLog && this.viaje.portLog.firstLineAshore.fecha) {
      /** First Line Ashore en tipo Date */
      let firstLineAshoreDate = new Date(this.viaje.portLog.firstLineAshore.fecha);
      this.viaje.portLog.firstLineAshoreFecha = this.reportesService.obtenerFechaFormulario(firstLineAshoreDate);
      this.viaje.portLog.firstLineAshoreHora = this.reportesService.obtenerHoraFormulario(firstLineAshoreDate);
    }

    // Berthing Time
    if (this.viaje.portLog && this.viaje.portLog.berthingTime.fecha) {
      /** Berthing Time en tipo Date */
      let berthingTimeDate = new Date(this.viaje.portLog.berthingTime.fecha);
      this.viaje.portLog.berthingTimeFecha = this.reportesService.obtenerFechaFormulario(berthingTimeDate);
      this.viaje.portLog.berthingTimeHora = this.reportesService.obtenerHoraFormulario(berthingTimeDate);
    }

    // Port Autorities On Board
    if (this.viaje.portLog && this.viaje.portLog.portAutoritiesOnBoard.fecha) {
      /** Port Autorities On Board en tipo Date */
      let portAutoritiesOnBoardDate = new Date(this.viaje.portLog.portAutoritiesOnBoard.fecha);
      this.viaje.portLog.portAutoritiesOnBoardFecha =
        this.reportesService.obtenerFechaFormulario(portAutoritiesOnBoardDate);
      this.viaje.portLog.portAutoritiesOnBoardHora =
        this.reportesService.obtenerHoraFormulario(portAutoritiesOnBoardDate);
    }

    // Free Practique Granted
    if (this.viaje.portLog && this.viaje.portLog.freePractiqueGranted.fecha) {
      /** Free Practique Granted en tipo Date */
      let freePractiqueGrantedDate = new Date(this.viaje.portLog.freePractiqueGranted.fecha);
      this.viaje.portLog.freePractiqueGrantedFecha =
        this.reportesService.obtenerFechaFormulario(freePractiqueGrantedDate);
      this.viaje.portLog.freePractiqueGrantedHora =
        this.reportesService.obtenerHoraFormulario(freePractiqueGrantedDate);
    }

    // Commence OPS
    if (this.viaje.portLog && this.viaje.portLog.commenceOPS.fecha) {
      /** Commence OPS en tipo Date */
      let commenceOPSDate = new Date(this.viaje.portLog.commenceOPS.fecha);
      this.viaje.portLog.commenceOPSFecha = this.reportesService.obtenerFechaFormulario(commenceOPSDate);
      this.viaje.portLog.commenceOPSHora = this.reportesService.obtenerHoraFormulario(commenceOPSDate);
    }

    // Completion OPS time
    if (this.viaje.portLog && this.viaje.portLog.completionOPSTime.fecha) {
      /** Completion OPS time en tipo Date */
      let completionOPSTimeDate = new Date(this.viaje.portLog.completionOPSTime.fecha);
      this.viaje.portLog.completionOPSTimeFecha = this.reportesService.obtenerFechaFormulario(completionOPSTimeDate);
      this.viaje.portLog.completionOPSTimeHora = this.reportesService.obtenerHoraFormulario(completionOPSTimeDate);
    }

    // Pilot on board
    if (this.viaje.portLog && this.viaje.portLog.pilotOnBoard.fecha) {
      /** Pilot on board en tipo Date */
      let pilotOnBoardDate = new Date(this.viaje.portLog.pilotOnBoard.fecha);
      this.viaje.portLog.pilotOnBoardFecha = this.reportesService.obtenerFechaFormulario(pilotOnBoardDate);
      this.viaje.portLog.pilotOnBoardHora = this.reportesService.obtenerHoraFormulario(pilotOnBoardDate);
    }

    // Sailing Time:
    if (this.viaje.portLog && this.viaje.portLog.sailingTime.fecha) {
      /** Sailing Time: en tipo Date */
      let sailingTimeDate = new Date(this.viaje.portLog.sailingTime.fecha);
      this.viaje.portLog.sailingTimeFecha = this.reportesService.obtenerFechaFormulario(sailingTimeDate);
      this.viaje.portLog.sailingTimeHora = this.reportesService.obtenerHoraFormulario(sailingTimeDate);
    }
  }

  /**
   * Inicializa el formulario con los datos vacios
   * (Esta fucnion tiene como error prevebnir el error de que el formulario se renderice antes de obtener los datos del viaje)
   */
  inicializarFormualrioVacio(): void {
    this.formulario = new FormGroup({
      eospFecha: new FormControl({ value: '', disabled: false }),
      eospHora: new FormControl({ value: '', disabled: false }),
      eospEstatus: new FormControl({ value: '', disabled: false }),
      norTenderedFecha: new FormControl({ value: '', disabled: false }),
      norTenderedHora: new FormControl({ value: '', disabled: false }),
      norTenderedEstatus: new FormControl({ value: '', disabled: false }),
      pilotOnBoardAnchorFecha: new FormControl({ value: '', disabled: false }),
      pilotOnBoardAnchorHora: new FormControl({ value: '', disabled: false }),
      pilotOnBoardAnchorEstatus: new FormControl({ value: '', disabled: false }),
      pilotOnBoardBerthingFecha: new FormControl({ value: '', disabled: false }),
      pilotOnBoardBerthingHora: new FormControl({ value: '', disabled: false }),
      pilotOnBoardBerthingEstatus: new FormControl({ value: '', disabled: false }),
      droppedAnchorFecha: new FormControl({ value: '', disabled: false }),
      droppedAnchorHora: new FormControl({ value: '', disabled: false }),
      droppedAnchorEstatus: new FormControl({ value: '', disabled: false }),
      firstLineAshoreFecha: new FormControl({ value: '', disabled: false }),
      firstLineAshoreHora: new FormControl({ value: '', disabled: false }),
      firstLineAshoreEstatus: new FormControl({ value: '', disabled: false }),
      berthingTimeFecha: new FormControl({ value: '', disabled: false }),
      berthingTimeHora: new FormControl({ value: '', disabled: false }),
      berthingTimeEstatus: new FormControl({ value: '', disabled: false }),
      portAutoritiesOnBoardFecha: new FormControl({ value: '', disabled: false }),
      portAutoritiesOnBoardHora: new FormControl({ value: '', disabled: false }),
      portAutoritiesOnBoardEstatus: new FormControl({ value: '', disabled: false }),
      freePractiqueGrantedFecha: new FormControl({ value: '', disabled: false }),
      freePractiqueGrantedHora: new FormControl({ value: '', disabled: false }),
      freePractiqueGrantedEstatus: new FormControl({ value: '', disabled: false }),
      commenceOPSFecha: new FormControl({ value: '', disabled: false }),
      commenceOPSHora: new FormControl({ value: '', disabled: false }),
      commenceOPSEstatus: new FormControl({ value: '', disabled: false }),
      completionOPSTimeFecha: new FormControl({ value: '', disabled: false }),
      completionOPSTimeHora: new FormControl({ value: '', disabled: false }),
      completionOPSTimeEstatus: new FormControl({ value: '', disabled: false }),
      pilotOnBoardFecha: new FormControl({ value: '', disabled: false }),
      pilotOnBoardHora: new FormControl({ value: '', disabled: false }),
      pilotOnBoardEstatus: new FormControl({ value: '', disabled: false }),
      sailingTimeFecha: new FormControl({ value: '', disabled: false }),
      sailingTimeHora: new FormControl({ value: '', disabled: false }),
      sailingTimeEstatus: new FormControl({ value: '', disabled: false }),
      arriveFwd: new FormControl({ value: '', disabled: false }),
      arriveAft: new FormControl({ value: '', disabled: false }),
      arriveFo: new FormControl({ value: '', disabled: false }),
      arriveDo: new FormControl({ value: '', disabled: false }),
      arriveLo: new FormControl({ value: '', disabled: false }),
      arriveFw: new FormControl({ value: '', disabled: false }),
      tugBoatsIn: new FormControl({ value: '', disabled: false }),
      asuntoReporte: new FormControl({ value: '', disabled: false }),
      remark: new FormControl({ value: '', disabled: false }),
    });
  }

  /**
   * Inicializa el formulario con los datos del viaje
   */
  inicializarFormualrioConDatosViaje(): void {
    this.formulario = new FormGroup({
      eospFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.eospFecha ? this.viaje.portLog.eospFecha : '',
        disabled: false,
      }),
      eospHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.eospHora ? this.viaje.portLog.eospHora : '',
        disabled: false,
      }),
      eospEstatus: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.eosp.status ? this.viaje.portLog.eosp.status : '',
        disabled: false,
      }),
      norTenderedFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.norTenderedFecha ? this.viaje.portLog.norTenderedFecha : '',
        disabled: false,
      }),
      norTenderedHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.norTenderedHora ? this.viaje.portLog.norTenderedHora : '',
        disabled: false,
      }),
      norTenderedEstatus: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.norTendered.status ? this.viaje.portLog.norTendered.status : '',
        disabled: false,
      }),
      pilotOnBoardAnchorFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.pilotOnBoardAnchorFecha
            ? this.viaje.portLog.pilotOnBoardAnchorFecha
            : '',
        disabled: false,
      }),
      pilotOnBoardAnchorHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.pilotOnBoardAnchorHora
            ? this.viaje.portLog.pilotOnBoardAnchorHora
            : '',
        disabled: false,
      }),
      pilotOnBoardAnchorEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.pilotOnBoardAnchor.status
            ? this.viaje.portLog.pilotOnBoardAnchor.status
            : '',
        disabled: false,
      }),
      pilotOnBoardBerthingFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.pilotOnBoardBerthingFecha
            ? this.viaje.portLog.pilotOnBoardBerthingFecha
            : '',
        disabled: false,
      }),
      pilotOnBoardBerthingHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.pilotOnBoardBerthingHora
            ? this.viaje.portLog.pilotOnBoardBerthingHora
            : '',
        disabled: false,
      }),
      pilotOnBoardBerthingEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.pilotOnBoardBerthing.status
            ? this.viaje.portLog.pilotOnBoardBerthing.status
            : '',
        disabled: false,
      }),
      droppedAnchorFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.droppedAnchorFecha ? this.viaje.portLog.droppedAnchorFecha : '',
        disabled: false,
      }),
      droppedAnchorHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.droppedAnchorHora ? this.viaje.portLog.droppedAnchorHora : '',
        disabled: false,
      }),
      droppedAnchorEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.droppedAnchor.status ? this.viaje.portLog.droppedAnchor.status : '',
        disabled: false,
      }),
      firstLineAshoreFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.firstLineAshoreFecha ? this.viaje.portLog.firstLineAshoreFecha : '',
        disabled: false,
      }),
      firstLineAshoreHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.firstLineAshoreHora ? this.viaje.portLog.firstLineAshoreHora : '',
        disabled: false,
      }),
      firstLineAshoreEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.firstLineAshore.status
            ? this.viaje.portLog.firstLineAshore.status
            : '',
        disabled: false,
      }),
      berthingTimeFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.berthingTimeFecha ? this.viaje.portLog.berthingTimeFecha : '',
        disabled: false,
      }),
      berthingTimeHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.berthingTimeHora ? this.viaje.portLog.berthingTimeHora : '',
        disabled: false,
      }),
      berthingTimeEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.berthingTime.status ? this.viaje.portLog.berthingTime.status : '',
        disabled: false,
      }),
      portAutoritiesOnBoardFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.portAutoritiesOnBoardFecha
            ? this.viaje.portLog.portAutoritiesOnBoardFecha
            : '',
        disabled: false,
      }),
      portAutoritiesOnBoardHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.portAutoritiesOnBoardHora
            ? this.viaje.portLog.portAutoritiesOnBoardHora
            : '',
        disabled: false,
      }),
      portAutoritiesOnBoardEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.portAutoritiesOnBoard.status
            ? this.viaje.portLog.portAutoritiesOnBoard.status
            : '',
        disabled: false,
      }),
      freePractiqueGrantedFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.freePractiqueGrantedFecha
            ? this.viaje.portLog.freePractiqueGrantedFecha
            : '',
        disabled: false,
      }),
      freePractiqueGrantedHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.freePractiqueGrantedHora
            ? this.viaje.portLog.freePractiqueGrantedHora
            : '',
        disabled: false,
      }),
      freePractiqueGrantedEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.freePractiqueGranted.status
            ? this.viaje.portLog.freePractiqueGranted.status
            : '',
        disabled: false,
      }),
      commenceOPSFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.commenceOPSFecha ? this.viaje.portLog.commenceOPSFecha : '',
        disabled: false,
      }),
      commenceOPSHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.commenceOPSHora ? this.viaje.portLog.commenceOPSHora : '',
        disabled: false,
      }),
      commenceOPSEstatus: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.commenceOPS.status ? this.viaje.portLog.commenceOPS.status : '',
        disabled: false,
      }),
      completionOPSTimeFecha: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.completionOPSTimeFecha
            ? this.viaje.portLog.completionOPSTimeFecha
            : '',
        disabled: false,
      }),
      completionOPSTimeHora: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.completionOPSTimeHora
            ? this.viaje.portLog.completionOPSTimeHora
            : '',
        disabled: false,
      }),
      completionOPSTimeEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.completionOPSTime.status
            ? this.viaje.portLog.completionOPSTime.status
            : '',
        disabled: false,
      }),
      pilotOnBoardFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.pilotOnBoardFecha ? this.viaje.portLog.pilotOnBoardFecha : '',
        disabled: false,
      }),
      pilotOnBoardHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.pilotOnBoardHora ? this.viaje.portLog.pilotOnBoardHora : '',
        disabled: false,
      }),
      pilotOnBoardEstatus: new FormControl({
        value:
          this.viaje.portLog && this.viaje.portLog.pilotOnBoard.status ? this.viaje.portLog.pilotOnBoard.status : '',
        disabled: false,
      }),
      sailingTimeFecha: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.sailingTimeFecha ? this.viaje.portLog.sailingTimeFecha : '',
        disabled: false,
      }),
      sailingTimeHora: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.sailingTimeHora ? this.viaje.portLog.sailingTimeHora : '',
        disabled: false,
      }),
      sailingTimeEstatus: new FormControl({
        value: this.viaje.portLog && this.viaje.portLog.sailingTime.status ? this.viaje.portLog.sailingTime.status : '',
        disabled: false,
      }),
      arriveFwd: new FormControl({
        value: this.viaje.arriveFwd || this.viaje.arriveFwd == 0 ? this.viaje.arriveFwd : '',
        disabled: false,
      }),
      arriveAft: new FormControl({
        value: this.viaje.arriveAft || this.viaje.arriveAft == 0 ? this.viaje.arriveAft : '',
        disabled: false,
      }),
      arriveFo: new FormControl({
        value: this.viaje.arriveFo || this.viaje.arriveFo == 0 ? this.viaje.arriveFo : '',
        disabled: false,
      }),
      arriveDo: new FormControl({
        value: this.viaje.arriveDo || this.viaje.arriveDo == 0 ? this.viaje.arriveDo : '',
        disabled: false,
      }),
      arriveLo: new FormControl({
        value: this.viaje.arriveLo || this.viaje.arriveLo == 0 ? this.viaje.arriveLo : '',
        disabled: false,
      }),
      arriveFw: new FormControl({
        value: this.viaje.arriveFw || this.viaje.arriveFw == 0 ? this.viaje.arriveFw : '',
        disabled: false,
      }),
      tugBoatsIn: new FormControl({
        value: this.viaje.tugBoatsIn || this.viaje.tugBoatsIn == 0 ? this.viaje.tugBoatsIn : '',
        disabled: false,
      }),
      asuntoReporte: new FormControl({ value: '', disabled: false }),
      remark: new FormControl({ value: this.viaje.remark ? this.viaje.remark : '', disabled: false }),
    });
  }

  /**
   * Alista los datos para enviarlos a la BD
   */
  async onGuardarReporte({ vistaPrev = false, enviarCorreo = false }) {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    this.vistaPrevia = vistaPrev;
    this.enviarCorreo = enviarCorreo;
    this.cargando = true;

    const errorDatosIngresados = this.identificarErroresDatosIngresados();
    if (errorDatosIngresados !== this.erroresDatosIngresados[0])
      this.indicarErrorEnDatosIngresados(errorDatosIngresados);
    else {
      /** Objeto con los datos de salida para guardar en la BD */
      let datosSalida = {
        portLog: {
          eosp: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          norTendered: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          pilotOnBoardAnchor: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          pilotOnBoardBerthing: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          droppedAnchor: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          firstLineAshore: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          berthingTime: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          portAutoritiesOnBoard: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          freePractiqueGranted: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          commenceOPS: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          completionOPSTime: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          narcoticsInspections: this.viaje.portLog.narcoticsInspections,
          vesselUnmoored: this.viaje.portLog.vesselUnmoored,
          droppingOutwardPilot: this.viaje.portLog.droppingOutwardPilot,
          pilotOnBoard: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          sailingTime: {
            fecha: null,
            status: this.estadosPortLog[3],
          },
          etaNextPort: this.viaje.portLog.etaNextPort,
        },
        adConditions: {
          arriveFwd: null,
          arriveAft: null,
          arriveFo: null,
          arriveDo: null,
          arriveLo: null,
          arriveFw: null,
          tugBoatsIn: null,
        },
        remark: null,
        prospectos: {
          ata: this.viaje.ata,
          atb: this.viaje.atb,
          atc: this.viaje.atc,
          atd: this.viaje.atd,
        },
      };

      // EOSP
      if (this.formulario.value.eospFecha && this.formulario.value.eospHora) {
        datosSalida.portLog.eosp.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.eospFecha,
          this.formulario.value.eospHora
        );
      }
      if (this.formulario.value.eospEstatus) datosSalida.portLog.eosp.status = this.formulario.value.eospEstatus;
      if (
        this.formulario.value.eospFecha &&
        this.formulario.value.eospHora &&
        datosSalida.portLog.eosp.status == 'CONFIRMED'
      ) {
        datosSalida.prospectos.ata = datosSalida.portLog.eosp.fecha;
      }

      // Nor Tendered
      if (this.formulario.value.norTenderedFecha && this.formulario.value.norTenderedHora) {
        datosSalida.portLog.norTendered.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.norTenderedFecha,
          this.formulario.value.norTenderedHora
        );
      }
      if (this.formulario.value.norTenderedEstatus)
        datosSalida.portLog.norTendered.status = this.formulario.value.norTenderedEstatus;

      // Pilot on Board for Anchor
      if (this.formulario.value.pilotOnBoardAnchorFecha && this.formulario.value.pilotOnBoardAnchorHora) {
        datosSalida.portLog.pilotOnBoardAnchor.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.pilotOnBoardAnchorFecha,
          this.formulario.value.pilotOnBoardAnchorHora
        );
      }
      if (this.formulario.value.pilotOnBoardAnchorEstatus)
        datosSalida.portLog.pilotOnBoardAnchor.status = this.formulario.value.pilotOnBoardAnchorEstatus;

      // Pilot on Board for Berthing
      if (this.formulario.value.pilotOnBoardBerthingFecha && this.formulario.value.pilotOnBoardBerthingHora) {
        datosSalida.portLog.pilotOnBoardBerthing.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.pilotOnBoardBerthingFecha,
          this.formulario.value.pilotOnBoardBerthingHora
        );
      }
      if (this.formulario.value.pilotOnBoardBerthingEstatus)
        datosSalida.portLog.pilotOnBoardBerthing.status = this.formulario.value.pilotOnBoardBerthingEstatus;

      // Dropped Anchor
      if (this.formulario.value.droppedAnchorFecha && this.formulario.value.droppedAnchorHora) {
        datosSalida.portLog.droppedAnchor.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.droppedAnchorFecha,
          this.formulario.value.droppedAnchorHora
        );
      }
      if (this.formulario.value.droppedAnchorEstatus)
        datosSalida.portLog.droppedAnchor.status = this.formulario.value.droppedAnchorEstatus;

      // First Line Ashore
      if (this.formulario.value.firstLineAshoreFecha && this.formulario.value.firstLineAshoreHora) {
        datosSalida.portLog.firstLineAshore.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.firstLineAshoreFecha,
          this.formulario.value.firstLineAshoreHora
        );
      }
      if (this.formulario.value.firstLineAshoreEstatus)
        datosSalida.portLog.firstLineAshore.status = this.formulario.value.firstLineAshoreEstatus;

      // Berthing Time
      if (this.formulario.value.berthingTimeFecha && this.formulario.value.berthingTimeHora) {
        datosSalida.portLog.berthingTime.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.berthingTimeFecha,
          this.formulario.value.berthingTimeHora
        );
      }
      if (this.formulario.value.berthingTimeEstatus)
        datosSalida.portLog.berthingTime.status = this.formulario.value.berthingTimeEstatus;
      if (
        this.formulario.value.berthingTimeFecha &&
        this.formulario.value.berthingTimeHora &&
        this.formulario.value.berthingTimeEstatus == 'CONFIRMED'
      ) {
        datosSalida.prospectos.atb = datosSalida.portLog.berthingTime.fecha;
      }

      // Port Autorities On Board
      if (this.formulario.value.portAutoritiesOnBoardFecha && this.formulario.value.portAutoritiesOnBoardHora) {
        datosSalida.portLog.portAutoritiesOnBoard.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.portAutoritiesOnBoardFecha,
          this.formulario.value.portAutoritiesOnBoardHora
        );
      }
      if (this.formulario.value.portAutoritiesOnBoardEstatus)
        datosSalida.portLog.portAutoritiesOnBoard.status = this.formulario.value.portAutoritiesOnBoardEstatus;

      // Free Practique Granted
      if (this.formulario.value.freePractiqueGrantedFecha && this.formulario.value.freePractiqueGrantedHora) {
        datosSalida.portLog.freePractiqueGranted.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.freePractiqueGrantedFecha,
          this.formulario.value.freePractiqueGrantedHora
        );
      }
      if (this.formulario.value.freePractiqueGrantedEstatus)
        datosSalida.portLog.freePractiqueGranted.status = this.formulario.value.freePractiqueGrantedEstatus;

      // Commence OPS
      if (this.formulario.value.commenceOPSFecha && this.formulario.value.commenceOPSHora) {
        datosSalida.portLog.commenceOPS.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.commenceOPSFecha,
          this.formulario.value.commenceOPSHora
        );
      }
      if (this.formulario.value.commenceOPSEstatus)
        datosSalida.portLog.commenceOPS.status = this.formulario.value.commenceOPSEstatus;

      // Completion OPS time
      if (this.formulario.value.completionOPSTimeFecha && this.formulario.value.completionOPSTimeHora) {
        datosSalida.portLog.completionOPSTime.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.completionOPSTimeFecha,
          this.formulario.value.completionOPSTimeHora
        );
      }
      if (this.formulario.value.completionOPSTimeEstatus)
        datosSalida.portLog.completionOPSTime.status = this.formulario.value.completionOPSTimeEstatus;
      if (
        this.formulario.value.completionOPSTimeFecha &&
        this.formulario.value.completionOPSTimeHora &&
        this.formulario.value.completionOPSTimeEstatus == 'CONFIRMED'
      ) {
        datosSalida.prospectos.atc = datosSalida.portLog.completionOPSTime.fecha;
      }

      // Pilot on board
      if (this.formulario.value.pilotOnBoardFecha && this.formulario.value.pilotOnBoardHora) {
        datosSalida.portLog.pilotOnBoard.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.pilotOnBoardFecha,
          this.formulario.value.pilotOnBoardHora
        );
      }
      if (this.formulario.value.pilotOnBoardEstatus)
        datosSalida.portLog.pilotOnBoard.status = this.formulario.value.pilotOnBoardEstatus;

      // Sailing Time
      if (this.formulario.value.sailingTimeFecha && this.formulario.value.sailingTimeHora) {
        datosSalida.portLog.sailingTime.fecha = this.reportesService.obtenerFechaUTC(
          this.formulario.value.sailingTimeFecha,
          this.formulario.value.sailingTimeHora
        );
      }
      if (this.formulario.value.sailingTimeEstatus)
        datosSalida.portLog.sailingTime.status = this.formulario.value.sailingTimeEstatus;
      if (
        this.formulario.value.sailingTimeFecha &&
        this.formulario.value.sailingTimeHora &&
        this.formulario.value.sailingTimeEstatus == 'CONFIRMED'
      ) {
        datosSalida.prospectos.atd = datosSalida.portLog.sailingTime.fecha;
      }

      // Arrive Fwd
      if (this.formulario.value.arriveFwd || this.formulario.value.arriveFwd == 0) {
        datosSalida.adConditions.arriveFwd = this.formulario.value.arriveFwd;
      }

      // Arrive Aft
      if (this.formulario.value.arriveAft || this.formulario.value.arriveAft == 0) {
        datosSalida.adConditions.arriveAft = this.formulario.value.arriveAft;
      }

      // Arrive Fo
      if (this.formulario.value.arriveFo || this.formulario.value.arriveFo == 0) {
        datosSalida.adConditions.arriveFo = this.formulario.value.arriveFo;
      }

      // Arrive Do
      if (this.formulario.value.arriveDo || this.formulario.value.arriveDo == 0) {
        datosSalida.adConditions.arriveDo = this.formulario.value.arriveDo;
      }

      // Arrive Lo
      if (this.formulario.value.arriveLo || this.formulario.value.arriveLo == 0) {
        datosSalida.adConditions.arriveLo = this.formulario.value.arriveLo;
      }

      // Arrive FW
      if (this.formulario.value.arriveFw || this.formulario.value.arriveFw == 0) {
        datosSalida.adConditions.arriveFw = this.formulario.value.arriveFw;
      }

      // Tug Boats In
      if (this.formulario.value.tugBoatsIn || this.formulario.value.tugBoatsIn == 0) {
        datosSalida.adConditions.tugBoatsIn = this.formulario.value.tugBoatsIn;
      }

      // Remark
      if (this.formulario.value.remark) {
        datosSalida.remark = this.formulario.value.remark;
      }
      await this.enviarDatosABD(datosSalida);
    }
  }

  /**
   * Identifica si hay errores en los datos ingresados por el usuario
   */
  identificarErroresDatosIngresados(): string {
    if (this.errorFechaIncompletaPresente()) return this.erroresDatosIngresados[1];
    if (this.errorMagnitudFechasIncorrecta()) return this.erroresDatosIngresados[2];
    if (this.errorFechasDesordenadas()) return this.erroresDatosIngresados[3];
    return this.erroresDatosIngresados[0];
  }

  /**
   * Agrega un nuevo correo a la lista de correos con copia o con copia oculta
   */
  agregarExtraCorreo(extra, lista) {
    if (extra.valid) {
      if (
        this.ccs.find((cc) => cc.email.toLowerCase() === extra.value.toLowerCase()) ||
        this.bccs.find((bcc) => bcc.email.toLowerCase() === extra.value.toLowerCase())
      ) {
        Swal.fire({
          title: '¡Error!',
          text: 'No se puede agregar un correo que ya se encuentre en la lista.',
          type: 'error',
        });
        return;
      }
      if (lista === 'cc') {
        this.ccs.push({ email: extra.value });
        this.nuevoCC.setValue('');
      } else {
        this.bccs.push({ email: extra.value });
        this.nuevoBCC.setValue('');
      }
    }
  }

  /**
   * Indica al usuario del error en los datos ingresados
   */
  indicarErrorEnDatosIngresados(error: string): void {
    if (error == this.erroresDatosIngresados[1]) {
      Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Todas las fechas deben tener tanto fecha como hora',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (error == this.erroresDatosIngresados[2]) {
      Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Se debe cumplir lo siguiente: (EOSP < Nor Tendered < Pilot on Board for Anchor < Pilot on Board for Berthing < Dropped Anchor < First Line Ashore < Berthing Time < Port Autorities On Board <  Free Practique Granted < Commence OPS < Completion OPS time < Pilot On board < Sailing Time)',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    } else if (error === this.erroresDatosIngresados[3]) {
      Swal.fire({
        title: 'No se puede guardar los cambios',
        text: 'Las fechas deben ir en orden ascendentente',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
    }

    this.cargando = false;
  }

  /**
   * Indica si el error de fecha incompleta esta presente
   */
  errorFechaIncompletaPresente(): boolean {
    // EOSP
    if (this.formulario.value.eospFecha && !this.formulario.value.eospHora) return true;
    if (!this.formulario.value.eospFecha && this.formulario.value.eospHora) return true;

    // Nor Tendered:
    if (this.formulario.value.norTenderedFecha && !this.formulario.value.norTenderedHora) return true;
    if (!this.formulario.value.norTenderedFecha && this.formulario.value.norTenderedHora) return true;

    // Pilot on Board for Anchor:
    if (this.formulario.value.pilotOnBoardAnchorFecha && !this.formulario.value.pilotOnBoardAnchorHora) return true;
    if (!this.formulario.value.pilotOnBoardAnchorFecha && this.formulario.value.pilotOnBoardAnchorHora) return true;

    // Pilot on Board for Berthing:
    if (this.formulario.value.pilotOnBoardBerthingFecha && !this.formulario.value.pilotOnBoardBerthingHora) return true;
    if (!this.formulario.value.pilotOnBoardBerthingFecha && this.formulario.value.pilotOnBoardBerthingHora) return true;

    // Dropped Anchor:
    if (this.formulario.value.droppedAnchorFecha && !this.formulario.value.droppedAnchorHora) return true;
    if (!this.formulario.value.droppedAnchorFecha && this.formulario.value.droppedAnchorHora) return true;

    // First Line Ashore
    if (this.formulario.value.firstLineAshoreFecha && !this.formulario.value.firstLineAshoreHora) return true;
    if (!this.formulario.value.firstLineAshoreFecha && this.formulario.value.firstLineAshoreHora) return true;

    // Berthing Time
    if (this.formulario.value.berthingTimeFecha && !this.formulario.value.berthingTimeHora) return true;
    if (!this.formulario.value.berthingTimeFecha && this.formulario.value.berthingTimeHora) return true;

    // Port Autorities On Board
    if (this.formulario.value.portAutoritiesOnBoardFecha && !this.formulario.value.portAutoritiesOnBoardHora)
      return true;
    if (!this.formulario.value.portAutoritiesOnBoardFecha && this.formulario.value.portAutoritiesOnBoardHora)
      return true;

    // Free Practique Granted
    if (this.formulario.value.freePractiqueGrantedFecha && !this.formulario.value.freePractiqueGrantedHora) return true;
    if (!this.formulario.value.freePractiqueGrantedFecha && this.formulario.value.freePractiqueGrantedHora) return true;

    // Commence OPS
    if (this.formulario.value.commenceOPSFecha && !this.formulario.value.commenceOPSHora) return true;
    if (!this.formulario.value.commenceOPSFecha && this.formulario.value.commenceOPSHora) return true;

    // Completion OPS time
    if (this.formulario.value.completionOPSTimeFecha && !this.formulario.value.completionOPSTimeHora) return true;
    if (!this.formulario.value.completionOPSTimeFecha && this.formulario.value.completionOPSTimeHora) return true;

    // Pilot on board
    if (this.formulario.value.pilotOnBoardFecha && !this.formulario.value.pilotOnBoardHora) return true;
    if (!this.formulario.value.pilotOnBoardFecha && this.formulario.value.pilotOnBoardHora) return true;

    // Sailing Time
    if (this.formulario.value.sailingTimeFecha && !this.formulario.value.sailingTimeHora) return true;
    if (!this.formulario.value.sailingTimeFecha && this.formulario.value.sailingTimeHora) return true;

    return false;
  }

  /**
   * Indica si no se esta cumpliendo la condicion: eosp <= berthingTime <= completionOPSTime <= sailingTime
   */
  // errorMagnitudFechasIncorrecta(): boolean {
  //   let eosp = 0;
  //   let berthingTime = 0;
  //   let completionOPSTime = 0;
  //   let sailingTime = 0;

  //   // Obtener las fechas en formato Date
  //   if (this.formulario.value.eospFecha && this.formulario.value.eospHora)
  //     eosp = this.reportesService.obtenerFechaUTC(this.formulario.value.eospFecha, this.formulario.value.eospHora);

  //   if (this.formulario.value.berthingTimeFecha && this.formulario.value.berthingTimeHora)
  //     berthingTime = this.reportesService.obtenerFechaUTC(
  //       this.formulario.value.berthingTimeFecha,
  //       this.formulario.value.berthingTimeHora
  //     );

  //   if (this.formulario.value.completionOPSTimeFecha && this.formulario.value.completionOPSTimeHora)
  //     completionOPSTime = this.reportesService.obtenerFechaUTC(
  //       this.formulario.value.completionOPSTimeFecha,
  //       this.formulario.value.completionOPSTimeHora
  //     );

  //   if (this.formulario.value.sailingTimeFecha && this.formulario.value.sailingTimeHora)
  //     sailingTime = this.reportesService.obtenerFechaUTC(
  //       this.formulario.value.sailingTimeFecha,
  //       this.formulario.value.sailingTimeHora
  //     );

  //   console.log(eosp);

  //   // Comparar fechas para asegurarse que eosp <= berthingTime <= completionOPSTime <= sailingTime
  //   if (eosp && berthingTime && eosp > berthingTime) return true;
  //   if (eosp && completionOPSTime && eosp > completionOPSTime) return true;
  //   if (eosp && sailingTime && eosp > sailingTime) return true;

  //   if (berthingTime && completionOPSTime && berthingTime > completionOPSTime) return true;
  //   if (berthingTime && sailingTime && berthingTime > sailingTime) return true;

  //   if (completionOPSTime && sailingTime && completionOPSTime > sailingTime) return true;

  //   return false;
  // }

  errorMagnitudFechasIncorrecta(): boolean {
    const events = [
      'eosp',
      'norTendered',
      'pilotOnBoardAnchor',
      'pilotOnBoardBerthing',
      'droppedAnchor',
      'firstLineAshore',
      'berthingTime',
      'portAutoritiesOnBoard',
      'freePractiqueGranted',
      'commenceOPS',
      'completionOPSTime',
      'pilotOnBoard',
      'sailingTime',
    ];

    let previousEventTime = 0;

    for (const event of events) {
      const fecha = this.formulario.value[event + 'Fecha'];
      const hora = this.formulario.value[event + 'Hora'];

      // Check if both fecha and hora are available for the event
      if (fecha && hora) {
        const eventTime = this.reportesService.obtenerFechaUTC(fecha, hora);

        // Compare the current event time with the previous event time
        if (previousEventTime !== 0 && eventTime < previousEventTime) {
          return true; // Events are not in the correct order
        }

        // Update the previous event time for the next comparison
        previousEventTime = eventTime;
      }
    }

    // If all events are in the correct order or some events are missing, return false
    return false;
  }

  errorFechasDesordenadas(): boolean {
    let hayError = false;
    const fechas = [
      this.formulario.controls.eospFecha.value,
      this.formulario.controls.norTenderedFecha.value,
      this.formulario.controls.pilotOnBoardAnchorFecha.value,
      this.formulario.controls.pilotOnBoardBerthingFecha.value,
      this.formulario.controls.droppedAnchorFecha.value,
      this.formulario.controls.firstLineAshoreFecha.value,
      this.formulario.controls.berthingTimeFecha.value,
      this.formulario.controls.portAutoritiesOnBoardFecha.value,
      this.formulario.controls.freePractiqueGrantedFecha.value,
      this.formulario.controls.commenceOPSFecha.value,
      this.formulario.controls.completionOPSTimeFecha.value,
      this.formulario.controls.pilotOnBoardFecha.value,
    ];
    for (let i = 0; i < fechas.length; i++) {
      if (i === 0 || i === 5 || i === 6 || i > 8) {
        if (
          fechas[i] != undefined &&
          fechas[i] != '' &&
          fechas[i + 1] != undefined &&
          fechas[i + 1] != '' &&
          !hayError
        ) {
          if (new Date(fechas[i]) > new Date(fechas[i + 1])) {
            hayError = true;
          }
        }
      }
      if (i === 0 || i === 2 || i === 3 || i === 6) {
        if (
          fechas[i] != undefined &&
          fechas[i] != '' &&
          fechas[i + 2] != undefined &&
          fechas[i + 2] != '' &&
          !hayError
        ) {
          if (new Date(fechas[i]) > new Date(fechas[i + 2])) {
            hayError = true;
          }
        }
      }
      if (i === 0 || i === 4) {
        if (
          fechas[i] != undefined &&
          fechas[i] != '' &&
          fechas[i + 3] != undefined &&
          fechas[i + 3] != '' &&
          !hayError
        ) {
          if (new Date(fechas[i]) > new Date(fechas[i + 3])) {
            hayError = true;
          }
        }
      }
      if (i === 4) {
        if (
          fechas[i] != undefined &&
          fechas[i] != '' &&
          fechas[i + 4] != undefined &&
          fechas[i + 4] != '' &&
          !hayError
        ) {
          if (new Date(fechas[i]) > new Date(fechas[i + 4])) {
            hayError = true;
          }
        }
      }
    }
    return hayError;
  }

  /**
   * Envia los datos ingresados por el usaurio a la BD
   * @param datosSalida Datos que van a persistir
   */
  async enviarDatosABD(datosSalida) {
    this.mainService
      .put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, datosSalida)
      .subscribe(async () => {
        await this.obtenerViaje();
        this.cargando = false;

        if (!this.enviarCorreo && !this.vistaPrevia) {
          this.alertService.simpleAlertConfirm('Se guardaron los datos exitosamente');
        }

        if (this.enviarCorreo) {
          this.onEnviarCorreoReporte();
          this.enviarCorreo = false;
          return;
        }

        if (this.vistaPrevia) {
          this.alertService.simpleAlertConfirm('Se guardaron los datos exitosamente').then((result) => {
            this.activeDialog.close();
            this.activeDialog = this.dialogService
              .open(VistaPreviaReporeArriboComponent, {
                context: {
                  companyName: this.empresasAreasConCorreos.length !== 0 && this.empresasAreasConCorreos[0].empresa,
                  asunto: this.formulario.value.asuntoReporte,
                  parrafosRemarks: this.formulario.value.remark.split('\n'),
                  imagenBuque: this.fotoBuque,
                  iconBuque: this.iconoBuque,
                  attachments: this.archivosAdjuntos,
                  sofConfirmadas: this.arregloOrdenadoActividadesSofConfirmadas,
                  sofEstimadas: this.arregloOrdenadoActividadesSofEstimadas,
                  correosCopia: this.ccs.map((cc) => cc.email),
                  correosCopiaOculta: this.bccs.map((bcc) => bcc.email),
                  anchoredInformation: this.anchoredInformation,
                  showAnchoredInformation: this.showAnchoredInformation,
                  showSOFActivities: this.showSOFActivities,
                  incluirTablas: this.incluirTablas,
                  incluirActividades: this.incluirActividades,
                  arregloOrdenadoActividadesConfirmadas: this.arregloOrdenadoActividadesConfirmadas,
                  arregloOrdenadoActividadesEstimadas: this.arregloOrdenadoActividadesEstimadas,
                },
              })
              .onClose.subscribe((act) => {
                this.vistaPrevia = false;
                this.obtenerViaje();
              });
          });

          return;
        }

        // Swal.fire({
        //   title: 'Se guardaron los datos exitosamente',
        //   type: 'success',
        //   showCancelButton: false,
        //   confirmButtonText: 'Continuar',
        // }).then((res) => {
        //   if (res.value) {
        //     if (this.vistaPrevia) {

        //     } else if (this.enviarCorreo) {
        //       this.onEnviarCorreoReporte();
        //       this.enviarCorreo = false;
        //     } else {
        //       this.vistaPrevia = false;
        //       this.enviarCorreo = false;
        //     }
        //   }
        // });

        this.vistaPrevia = false;
        this.enviarCorreo = false;

        return;
      });
  }

  /**
   * Crea el sof asociado al viaje
   */
  onCrearSof(): void {
    if (this.viaje.status === 'CANCELED') return;

    this.cargando = true;
    let nuevoSof = {
      voyage: this.viaje.voyageNumber,
      terminal: this.viaje.terminalId,
      nv: this.viaje.nv,
      via_id: this.viaje._id,
      history: [],
      idRecalada: this.viaje.recalada._id,
    };

    this.mainService.post('api/sof', nuevoSof).subscribe(
      (res) => {
        this.mainService.put(`api/recalada/${this.viaje.recalada._id}`, { sof: [res._id] }).subscribe(
          (res) => {
            this.obtenerViaje();
            Swal.fire('Éxito', 'Se creó el sof exitosamente', 'success');

            this.cargando = false;
          },
          (err) => {
            Swal.fire('Error', 'No se pudo asociar el SOF al viaje', 'error');

            this.cargando = false;
          }
        );
      },
      (err) => {
        Swal.fire('Error', 'No se pudo crear el SOF', 'error');

        this.cargando = false;
      }
    );
  }

  /**
   * Redirigue al detalle del sof
   */
  onVerSof(): void {
    if (this.viaje.status === 'CANCELED') {
      return;
    }
    this.router.navigate([`home/reporte-arribo/ver-sof/${this.viaje.recalada.sof[0]}/${this.viaje.recalada._id}`]);
  }
  /**
   * Abre el modal para aceptar guardar cambios antes de ir a la vista previa
   */
  activarModalVistaPrevia() {
    this.getAnchoredInfo();
    this.activeDialog = this.dialogService.open(this.guardarVistaPrevia, { context: '' });
  }

  /**
   * Redirigue a pagina con la visa previa del correo
   */
  onIrVistaPreviaCorreo() {
    this.onGuardarReporte({ vistaPrev: true });
  }

  /**
   * Procede a guardar y posteriormente a enviar el correo
   */
  onGuardarInfoEnviarCorreo() {
    this.onGuardarReporte({ enviarCorreo: true });
  }

  public anchoredInformation: string = undefined;
  public showAnchoredInformation: boolean = false;
  public includeAnchored(): void {
    this.showAnchoredInformation = !this.showAnchoredInformation;
    this.getAnchoredInfo();
  }

  public getAnchoredInfo() {
    const { eospFecha, eospHora, pilotOnBoardBerthingFecha, pilotOnBoardBerthingHora } = this.formulario.value;
    if (!eospFecha || !pilotOnBoardBerthingFecha) return;

    const combineEOSP = this.timeService.combineDatesAndHours(eospFecha, eospHora);
    const combinePOBB = this.timeService.combineDatesAndHours(pilotOnBoardBerthingFecha, pilotOnBoardBerthingHora);

    const dateHours = this.timeService.diffDateInHoursMinutes(combineEOSP, combinePOBB);
    if (dateHours.hours <= 0 && dateHours.minutes <= 0) {
      this.anchoredInformation = undefined;
      return;
    }

    this.anchoredInformation = this.timeService.formatHoursMinutesToString(dateHours.hours, dateHours.minutes);
  }

  public showSOFActivities: boolean = false;
  public includeSOFActivities() {
    this.showSOFActivities = !this.showSOFActivities;
  }

  /**
   * Envia el correo con el repore
   */
  onEnviarCorreoReporte(): void {
    this.enviandoCorreo = true;
    if (this.permisosUsuario.analisisOperativo !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      this.enviandoCorreo = false;
      return;
    }
    this.cargando = true;

    if (this.encontrarErroresEnvioCorreo() == this.erroresEnvioCorreo[0]) {
      this.enviandoCorreo = true;
      const emailsToSend = [];

      const emails = [];

      for (let destinatario of this.emailsContactosViaje) {
        delete destinatario.nombre;

        const html = this.generarHTMLCorreo(destinatario.empresa);

        const msg = {
          to: destinatario.correos,
          cc: this.ccs,
          bcc: this.bccs,
          html: html,
          attachments: this.archivosAdjuntos,
          fromEmail: this.emailPuerto,
          subject: this.formulario.value.asuntoReporte,
        };

        emails.push(msg);
      }

      this.mainService.post(`api/sendEmail`, { emails, puerto: this.viaje.puertoNombre }).subscribe(async (result) => {
        console.log('Nombre puerto', this.viaje.puertoNombre);
        this.emailsContactosViaje = [];
        this.enviandoCorreo = false;
        this.addedVesselEmail = false;

        this.alertService.simpleAlertConfirm('Reporte enviado y datos guardados exitosamente');

        const reporteEnviado = {
          tipoReporte: 'ARRIBO',
          fechaDeEnvio: Date.now(),
        };
        if (result.success) {
          this.viaje.reportesEnviados
            ? this.viaje.reportesEnviados.push(reporteEnviado)
            : (this.viaje.reportesEnviados = [reporteEnviado]);

          if (this.viaje.recalada.informacionExtra.statusExtra == 'ANNOUNCED' && this.viaje.recalada.prospectos.ata)
            this.viaje.statusExtra = 'BERTHED';

          this.mainService
            .put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, {
              reporteEnviado,
              statusExtra: this.viaje.statusExtra,
            })
            .subscribe((res) => {
              this.obtenerViaje();
            });
        } else {
          Swal.fire({
            title: 'Se generó un problema al enviar el correo',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'Continuar',
          });
          this.cargando = false;
        }
      });
    } else {
      this.enviandoCorreo = false;
    }
  }

  public mixPortogAndSofActivities() {
    let newArr = this.arregloOrdenadoActividadesConfirmadas
      .concat(this.arregloOrdenadoActividadesSofConfirmadas || [])
      .sort((a: any, b: any) => {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

    this.arregloOrdenadoActividadesConfirmadas = newArr;

    newArr = this.arregloOrdenadoActividadesEstimadas
      .concat(this.arregloOrdenadoActividadesSofEstimadas || [])
      .sort((a: any, b: any) => {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

    this.arregloOrdenadoActividadesEstimadas = newArr;
  }

  /**
   * Encuentra si hay algun error al intentar enviar un correo
   */
  encontrarErroresEnvioCorreo(): string {
    if (!this.formulario.value.asuntoReporte) {
      Swal.fire({
        title: 'El reporte no tiene asunto',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      this.cargando = false;
      return this.erroresEnvioCorreo[1];
    }

    if (this.emailsContactosViaje.length == 0) {
      Swal.fire({
        title: 'El viaje no tiene Receivers, Shippers o Others',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargando = false;
      return this.erroresEnvioCorreo[2];
    }

    return this.erroresEnvioCorreo[0];
  }

  generarHTMLCorreo(companyName: string): string {
    const spacerItems = '<br><br>';
    const spacerItem = '<br>';
    const spacerHeader = '<br><br><br>';

    for (let archivoAdjunto of this.archivosAdjuntos) {
      delete archivoAdjunto.path;
    }

    let html = `<!DOCTYPE html>
		<html lang="en">
		<head>
			<meta charset="UTF-8">
			<meta http-equiv="X-UA-Compatible" content="IE=edge">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
		</head>
		<body style="color:black !important;">`;

    html += '<table style="border-collapse: collapse;">';

    // header
    if (companyName) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">TO:</td><td>${companyName}</td></tr>`;
    }

    if (this.viaje.commodityNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">CARGO:</td><td>${this.viaje.commodityNombre}</td></tr>`;
    }

    if (this.viaje.buqueNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">VESSEL:</td><td>${
        this.viaje.buqueNombre
      } ${this.viaje.voyageNumber? this.viaje.voyageNumber: '' && '- ' + this.viaje.voyageNumber} - ${this.viaje.nv}</td></tr>`;
    }

    if (this.viaje.terminalNombre) {
      html += `<tr style="padding-bottom: 8px;"><td style="padding-right: 0.5rem">TERMINAL:</td><td>${this.viaje.terminalNombre}</td></tr>`;
    }

    if (this.viaje.puertoNombre) {
      html += `<tr><td style="padding-right: 0.5rem">PORT:</td><td>${this.viaje.puertoNombre}</td></tr>`;
    }

    html += '</table>';
    html += spacerHeader;

    // image

    if (this.iconoBuque && this.iconoBuque != 'NULL') {
      html += `<img src=${this.iconoBuque} style="width:100%;max-width:400px;height:auto;">`;
      html += spacerItems;
    }

    if (this.fotoBuque && this.fotoBuque != 'NULL') {
      html += `<img src=${this.fotoBuque} style="width:100%;max-width:400px;height:auto;">`;
      html += spacerItems;
    }
    if (this.incluirActividades) {
      html += '<p style="margin: 0;">Please find bellow follow up report of the above mentioned vessel</p>';
      html += spacerItem;

      let newArr = this.arregloOrdenadoActividadesConfirmadas;

      html += '<table style="table-layout:fixed; max-width:400px;"><tbody>';
      newArr.forEach((actividadConfirmada) => {
        html += `<tr><td style="text-align:left !important;font-weight:400!important;">${
          actividadConfirmada.nombre || actividadConfirmada.type
        }</td><td style="white-space: nowrap;text-align:left !important;font-weight:400!important;text-decoration:none!important;color:black!important;">${
          actividadConfirmada.fechaVisual
        }</td></tr>`;
      });
      html += '</tbody></table>';

      if (this.arregloOrdenadoActividadesEstimadas && this.arregloOrdenadoActividadesEstimadas.length > 0) {
        html += spacerItems;

        let newArrEstimated = this.arregloOrdenadoActividadesEstimadas
          .concat(this.arregloOrdenadoActividadesSofEstimadas || [])
          .sort((a, b) => {});
        html +=
          '<table style="table-layout:fixed; max-width:600px;""><tbody><tr><td colspan="2" style="text-align: left !important">ESTIMATED FOLLOWING SCHEDULE FOR THIS VESSEL</td></tr>';
        newArrEstimated.forEach((actividadEstimada) => {
          html += `<tr><td colspan="1" style="text-align:left !important;font-weight:400!important;">Estimated - ${
            actividadEstimada.nombre ||
            (' ' + actividadEstimada.type + ' ' || ' ') + ' - ' + actividadEstimada.description
          }</td><td colspan="1" style="text-align:left !important;font-weight:400!important;text-decoration:none!important;color:black!important; white-space: nowrap;">${
            actividadEstimada.fechaVisual
          }</td></tr>`;
        });
        html += '</tbody></table>';
      }

      html += spacerItems;
    }

    if (this.incluirTablas) {
      html +=
        '<table style="border-collapse: collapse;">' +
        '<tr>' +
        '<th colspan="4" style="text-align: center; border: 1px solid; padding: 10px">ARRIVAL CONDITIONS</th>' +
        '</tr>' +
        '<tr>' +
        '<td colspan="2" style="text-align: center; border: 1px solid">BUNKER</td>' +
        '<td colspan="2" style="text-align: center; border: 1px solid">DRAFT</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">FO</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveFo || this.viaje.arriveFo == 0 ? this.viaje.arriveFo : '-'
        } MT</td>` +
        '<td style="padding: 10px; border: 1px solid">FWD</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveFwd || this.viaje.arriveFwd == 0 ? this.viaje.arriveFwd : '-'
        } M</td>` +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">DO</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveDo || this.viaje.arriveDo == 0 ? this.viaje.arriveDo : '-'
        } MT</td>` +
        '<td style="padding: 10px; border: 1px solid">AFT</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveAft || this.viaje.arriveAft == 0 ? this.viaje.arriveAft : '-'
        } M</td>` +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">FW</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveFw || this.viaje.arriveFw == 0 ? this.viaje.arriveFw : '-'
        } MT</td>` +
        '<td style="padding: 10px; border: 1px solid">LO</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.arriveLo || this.viaje.arriveLo == 0 ? this.viaje.arriveLo : '-'
        } CMB</td>` +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 10px; border: 1px solid">TUGBOATS IN</td>' +
        `<td style="padding: 10px; border: 1px solid">${
          this.viaje.tugBoatsIn || this.viaje.tugBoatsIn == 0 ? this.viaje.tugBoatsIn : '-'
        }</td>` +
        '</tr>' +
        '</table>';

      html += spacerItems;
    }

    if (this.showAnchoredInformation && this.anchoredInformation) {
      html += `<p style="margin: 0">ANCHORED</p>`;
      html += spacerItem;
      html += `<p style="margin: 0">${this.anchoredInformation}</p>`;
      html += spacerItems;
    }

    html += '<p style="margin: 0; font-weight: bold">Summary</p>';
    html += spacerItem;
    html += this.reportesService.obtenerTextoHTML(this.viaje.remark); // pending
    html += spacerItems;

    html += '<p style="margin: 0;">We will keep you posted</p>';
    html += spacerItems;

    html += '<p style="margin: 0;">Best regards,</p>';
    html += spacerItem;

    if (this.viaje.boardingAgentNombre) {
      html += `<p style="margin: 0;">${this.viaje.boardingAgentNombre}</p>`;
      html += `<p style="margin: 0;">BOARDING AGENT</p>`;
    }

    html += `<p style="margin: 0;">${this.viaje.port.direccion}</p>`;
    html += `<p style="margin: 0;">${this.viaje.port.nombre}</p>`;

    html += `<p style="margin: 0;">Mobile: ${
      this.viaje.boardingAgent && this.viaje.boardingAgent.telefono ? this.viaje.boardingAgent.telefono : 'Not defined'
    }</p>`;

    html += `<p style="margin: 0;">
        Email:
        <a href=${this.viaje.port ? 'mailto:' + this.viaje.port.email : 'Not defined'}>
        ${this.viaje.port ? this.viaje.port.email : 'Not defined'};
        </a>
      </p>`;

    html += `<p style="margin: 0;">
      Web Site:
      <a href="www.navescolombia.com">www.navescolombia.com</a>
    </p>`;

    html += `<p style="text-align: justify;">The information transmitted by this email is confidential and intended solely for use by their (s) recipient (s). His play, read or use is prohibited to any person or entity other, without prior written permission. If you received this in error, please notify the sender immediately and delete it from your system. You may not copy, print or distribute this email or its attachments, or use for any purpose nor disclose its contents to anyone. The opinions, conclusions and other information contained in this email, not related to official business NAVES SAS shall be construed as personal and in no way endorsed by the company. Although Naves SAS has done its best to ensure that this message and any attachments are free from viruses and defects that can potentially affect computers or systems that receive, not responsible for the possible transmission of viruses or malicious programs via this channel, and therefore the recipient's responsibility to confirm the existence of such elements when received and open it. Naves Colombia Neither nor any of its divisions or departments are responsible for possible damages or changes arising from the receipt or use of this message.</p>`;

    html += spacerItem;

    html += `<p style="text-align: justify;">This message has been sent by an automated system, please do not respond to this mail and will not be reviewed
    by any staff because it is an automatic process of sending mail.</p>`;

    html += '</tbody></table></body></html>';
    return html;
  }

  public selectedSubject: string;
  public setSubject(subjectSelected?: string) {
    this.formulario.patchValue({
      asuntoReporte: subjectSelected || this.selectedSubject,
    });

    this.selectedSubject = subjectSelected || this.selectedSubject;
  }

  public deaultSubjects: string[] = [];
  public setDefaultSubjects() {
    this.deaultSubjects = [];

    const nv = this.viaje.nv;
    const vesselName = this.viaje.buqueNombre;
    const portName = this.viaje.puertoNombre;

    const voyageNumber = this.viaje.voyageNumber ? `${this.viaje.voyageNumber + ' -'}` : '';

    this.deaultSubjects.push(
      `MV ${vesselName} ${voyageNumber} ${nv} - ${portName} - ARRIVAL REPORT`,
      `MV ${vesselName} ${voyageNumber} ${nv} - ${portName} - COMMENCED OPERATION REPORT`,
      `MV ${vesselName} ${voyageNumber} ${nv} - ${portName} - RELEVANT INFORMATION`
    );

    this.selectedSubject = this.deaultSubjects[0];
    this.setSubject(this.formulario.value.asuntoReporte);
  }

  /**
   * Maneja la seleccion de archivos a adjuntar
   */
  onSeleccionarArchivoAAdjuntar(event) {
    this.archivosAdjuntosCargandose = true;
    const archivoSeleccionado = event.target.files[0];
    if (archivoSeleccionado !== undefined) {
      // Obtener la información necesaria
      const { params, options } = this.s3Service.getInfoForUpload('adjuntoCorreos', archivoSeleccionado);
      // Subir a s3
      this.bucket.upload(params, options, (err, data) => {
        if (err) {
          this.s3Service.showErrorUploading();
        } else {
          this.documentURL = data.Location.toString();
          this.reportesService
            .archivoABase64(event.target.files[0])
            .then((result) => {
              const nuevoArchivo = {
                content: result,
                filename: event.target.files[0].name,
                type: 'application/pdf',
                disposition: 'attachment',
                path: this.documentURL,
              };
              this.archivosAdjuntos.push(nuevoArchivo);
              this.documentURL = '';
              this.archivosAdjuntosCargandose = false;
              this.archjivoAAdjuntar.nativeElement.value = '';
            })
            .catch(() => {
              Swal.fire({
                title: 'Se generó un problema al adjuntar el archivo',
                type: 'error',
                showCancelButton: false,
                confirmButtonText: 'Continuar',
              });
            });
        }
      });
    }
  }

  /**
   * Borra un arhivo de los archivos adjuntos
   * @param indiceArchivo Indice del archivo en los archivos adjuntos
   */
  onBorrarArchivoAdjunto(indiceArchivo: number): void {
    this.archivosAdjuntos.splice(indiceArchivo, 1);
  }

  /**
   * Cambia el estado de la incluison de actividades
   */
  onCambioIncluirActividades() {
    this.incluirActividades = !this.incluirActividades;
  }

  /**
   * Cambia el estado de la incluison de tablas
   */
  onCambioIncluirTablas() {
    this.incluirTablas = !this.incluirTablas;
  }

  /**
   * Elimina un contacto de la lista de contactos agregados en copia
   */
  eliminarContactoCopia(index: number): void {
    this.ccs.splice(index, 1);
  }

  /**
   * Elimina un contacto de la lista de contactos agregados en copia oculta
   */
  eliminarContactoCopiaOculta(index: number): void {
    this.bccs.splice(index, 1);
  }

  /**
   * Obtiene las actividades confirmadas y estimadas ordenadas cronologicamente
   */
  obtenerActividadesEstimadasYConfirmadasOrdenadas() {
    if (Object.keys(this.viaje.portLog).length > 0) {
      let activdadesOrdenadas = [];
      for (const property in this.viaje.portLog) {
        if (this.viaje.portLog[property].fecha) {
          let actividad = {
            nombre: this.viaje.portLog[property].nombre,
            fecha: this.viaje.portLog[property].fecha,
            status: this.viaje.portLog[property].status,
            fechaVisual: '',
          };

          activdadesOrdenadas.push(actividad);
        }
      }

      activdadesOrdenadas.forEach((actividad) => {
        actividad.fechaVisual = this.reportesService.obtenerFechaVisualReportes(new Date(actividad.fecha));
      });

      activdadesOrdenadas.sort(function (a, b) {
        return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      });

      this.arregloOrdenadoActividadesConfirmadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status.toLowerCase() == 'confirmed'
      );
      this.arregloOrdenadoActividadesEstimadas = activdadesOrdenadas.filter(
        (actividad) => actividad.status.toLowerCase() == 'estimated'
      );
    } else {
      this.arregloOrdenadoActividadesConfirmadas = [];
      this.arregloOrdenadoActividadesEstimadas = [];
    }
  }

  /**
   * Devuelve al usuario a la pagina anerior
   */
  onDevolverse() {
    Swal.fire({
      title: '¿Deseas regresar?',
      text: 'Se perderá la información diligenciada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.router.navigate([`home/reportes/${this.viaje._id}/${this.viaje.recalada._id}`]);
      }
    });
  }

  validateDateForStatus(field: string, statusField: string) {
    if (this.formulario.get(field) ? !this.formulario.get(field).value : true) {
      if (this.formulario.get(statusField)) {
        this.formulario.get(statusField).setValue('');
      }
    }
  }
}
