import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NbDialogService, NbToastrService} from '@nebular/theme';

import {ActivatedRoute, Router, Params} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';
import { create } from 'domain';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements OnInit {

  settings = {
    
    actions: { 
      delete: false,
    edit: false,
    add: false, 
      custom: [
        {name: 'detalle', title: '<i class="nb-compose"></i> '},
      ],
    },
    columns: {
      nv: {
        title: 'nv'
      },
      vessel: {
        title: 'Barco'
      },
      port: {
        title: 'Puerto'
      },
      eta: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc'
      },
      empresa: {
        title: 'Empresa',
        valuePrepareFunction: (data) => {
          return data.razonSocial
        }
      },
    }
  };
  
  usuario:any;
  cargo:any
  password:any;
  public user: any;
  public data: any;
  public source: LocalDataSource;
  
  constructor(private toastrService: NbToastrService,
              public router: Router,
              private dialogService: NbDialogService,
              private rutaActiva: ActivatedRoute,
              private mainService: MainService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.data = [];
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.onCargar();
  }

  onCargar() {
    this.mainService.get('api/barco').subscribe(res => {
      res.forEach(element => {
        element.eta = (moment(element.eta).format('YYYY-MMM-DD '))
      });
      this.data = res
      this.data = _.orderBy(this.data, ['createdAt'],['desc']);
      this.data = new LocalDataSource(this.data);
      console.log("Cargo",this.data);
    });
  }

  onCustom(event) {
    switch (event.action) {
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  
  onVer(event){
    
    this.router.navigate(['home/statement-ver/'+event.data._id]);
  }
}
