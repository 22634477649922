import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
​
@Injectable()
export class AdminGuard implements CanActivate{
    
    constructor(public authService: AuthService, public router: Router) {
​
    }

    canActivate() {
        let usuario = JSON.parse(localStorage.getItem('usuario'));
        if(usuario.tipo==="administrador") {
            return true;
        } else {
            return false;
        }
    }
​
}