import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { NbDialogService } from '@nebular/theme';
import { TimeService } from '../../../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

@Component({
  selector: 'proformas-con-nominacion',
  templateUrl: './proformas-con-nominacion.component.html',
  styleUrls: ['./proformas-con-nominacion.component.scss'],
})
export class ProformasConNominacionComponent implements OnInit {
  /** Referencia al dialogo del detalle de la proforma */
  dialogRef: any;
  /** Todas las proformas en la BD */
  public todasLasProformas: any = [];
  /** Proformas con nominacion en la BD */
  public proformasConNominacion: any = [];
  /** Configuracion de la tabla*/
  settings: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'viewAndEdit', title: '<i class="nb-compose"></i>' },
      ],
    },
    columns: {
      nv: {
        title: 'NV',
      },
      nombreBuque: {
        title: 'Buque',
      },
      nombrePuerto: {
        title: 'Puerto',
      },
      nombreTerminal: {
        title: 'Terminal',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Indica si actualmente se encuentra en modo de crear */
  public modoCreacion: boolean = false;
  /** Proforma actualmente seleccionada */
  public proforma: any = '';
  /** Indica si algo se encuentra cargandose en la BD */
  public cargando: boolean = false;
  /** Buques en la BD */
  public buques: any = [];
  /** Terminales en la BD */
  public terminales: any = [];
  /** Puertos en la BD */
  public puertos: any = [];
  /** Viajes en la BD */
  public viajes: any = [];
  /** Viajes de la proforma actualmente seleccionada */
  public viaje: any = [];

  @Input('permisosUsuario') permisosUsuario: any;
  /** Referencia al detalle de la proforma */
  @ViewChild('proformaDetalle', { static: true }) proformaDetalle: ElementRef;

  constructor(private dialogService: NbDialogService, private mainService: MainService, private timeService: TimeService) {}

  ngOnInit() {
    this.initializeData();
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  initializeData() {
    this.cargando = true;
    this.proformasConNominacion = [];

    this.mainService.get('api/nuevo-viaje?activo=true').subscribe((resul) => {
      this.viajes = resul;

      this.mainService.get('api/buque?activo=true').subscribe((resul) => {
        this.buques = JSON.parse(JSON.stringify(resul));

        this.mainService.get('api/terminal?activo=true').subscribe((resul) => {
          this.terminales = resul;

          this.mainService.get('api/puerto?activo=true').subscribe((resul) => {
            this.puertos = resul;

            this.mainService.get('api/proforma?activo=true').subscribe((res) => {
              this.todasLasProformas = res;
              this.todasLasProformas.forEach((element) => {
                const viajeEncontrado = this.viajes.find((viaje) => viaje.nv == element.nv);
                if (element.nv && viajeEncontrado) {
                  const buqueAsociado = this.buques.find(({ BUQ_ID }) => BUQ_ID == element.vessel);
                  element.nombreBuque = buqueAsociado.BUQ_NOMBRE;
                  const puertoAsociado = this.puertos.find(({ PUE_ID }) => PUE_ID == element.port);
                  element.nombrePuerto = puertoAsociado.PUE_NOMBRE;
                  const armadorAsociado = this.terminales.find(({ TER_ID }) => TER_ID == element.terminal);
                  element.nombreTerminal = armadorAsociado.TER_NOMBRE;
                  // const viajeAsociado = this.viajes.find(({  }))
                  this.proformasConNominacion.push(element);
                }
              });
              this.proformasConNominacion = _.orderBy(this.proformasConNominacion, ['createdAt'], ['desc']);
              this.cargando = false;
            });
          });
        });
      });
    });
  }

  /**
   * Reacciona al evento de abrir el detalle de la proforma
   */
  onProformaDetalle() {
    this.openProformaDetalleDialog(this.proformaDetalle);
  }

  /**
   * Abre el dialogo con el detalle de la proforma
   * @param dialog Dialogo del detalle de la proforma
   */
  openProformaDetalleDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las diferentes aciones
   * @param event Evento con la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'delete':
        this.onDelete(event);
        break;
      case 'viewAndEdit':
        this.viewAndEdit(event);
        break;
    }
  }

  /**
   * Elimina una proforma
   * @param event Evento con los datos de la proforma a eliminar
   */
  onDelete(event) {
    if (this.permisosUsuario.proformas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar la proforma?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.mainService.delete('api/proforma/' + event.data.nv).subscribe((res) => {
            if (res.message) {
              Swal.fire({
                title: 'Éxito',
                text: res.message,
                type: 'error',
              });
              return;
            }
            Swal.fire('Éxito', 'Proforma eliminada con éxito', 'success');
            this.proformasConNominacion = [];
            this.initializeData();
          });
        }
      });
    }
  }

  /**
   * Inicializa la visializacion o edicion de una proforma con nominacion
   * @param event Evento con los datos de la proforma
   */
  viewAndEdit(event) {
    if (this.permisosUsuario.proformas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.proforma = event.data;
      this.viaje = this.viajes.find((viaje) => viaje.nv == this.proforma.nv);
      this.openProformaDetalleDialog(this.proformaDetalle);
    }
  }
}
