import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Location } from '@angular/common';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer } from '@angular/platform-browser';

import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

import * as _ from 'lodash';
const jsPDF = require('jspdf');

require('jspdf-autotable');

/* Services */
import { MainService } from '../../../services/main.service';
import { DateAndTimeService } from '../../../services/date-and-time.service';
import { PDFService } from '../../../../services/pdfService/pdf-service.service';
import { FormatSofService } from '../../../../services/formatSof/format-sof.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sof-ver',
  templateUrl: './sof-ver.component.html',
  styleUrls: ['./sof-ver.component.scss'],
})
export class SofVerComponent implements OnInit, AfterViewInit {
  public idSof: string;
  public idRecalada: string;

  public history: any = [];
  public currentRemarks: any = [];

  private allRemarksPDF: any = [];
  private priorityRemarksPDF: any = [];

  public generatePdf: boolean = true;
  public ship: any = {};
  public currentRemarksFilter: any = [];
  public categoryRemarks: any = [];
  public remarkSeleccionado: any = {};
  public statementOfFacts: any = {};
  public newIncident: any = {};
  public openEdit: boolean = false;
  public isStoppedSelected: boolean = false;
  public terminalEncontrada: string = 'Cargando...';
  public excelSettings = {
    spanish: false,
  };
  private selectedNodeEditor;
  public downloadingPDF: boolean = false;
  public downloadingExcel: boolean = false;

  public sofFormats = [];
  private selectedSofFormat;
  public selectedSofFormatId = null;
  public sailingTime = null;

  /** Representa las horas totales de los remarks con categoría "STOPPED OPERATIONS" */

  public settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      dateView: { title: 'Date' },
      hoursType: { title: 'Hours Type' },
      from: { title: 'From' },
      to: { title: 'To' },
      description: {
        title: 'Description',
        width: '200px',
      },
      type: { title: 'Type' },
      category: {
        title: 'Category',
      },
      priority: { title: 'Priority' },
    },
  };

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: `${environment.baseUrl}api/aws/s3`,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [],
  };

  isReport: boolean = undefined;
  isGeneralReport: boolean = undefined;
  reportType: string;

  public permisosUsuario: any;
  public usuario = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
    private pdfService: PDFService,
    private formatSofService: FormatSofService,
    public dateAndTimeService: DateAndTimeService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.idSof = this.route.snapshot.params['id'];
    this.idRecalada = this.route.snapshot.params['idRecalada'];
    this.generatePdf = true;
    this.determineRoute();
    this.obtenerPermisos();
    this.getAllSOFFormats();
  }

  ngAfterViewInit(): void {
    const placeHolders = document.querySelectorAll('.angular-editor-placeholder');
    placeHolders.forEach((placeHolder) => placeHolder.remove());

    const editorsAngular = document.querySelectorAll('.angular-editor');
    editorsAngular.forEach((editorAngular) => {
      const containerButtons = editorAngular.querySelector('.angular-editor-toolbar-set');
      const clonedContainerButtons = containerButtons.cloneNode(true) as any;

      const button = clonedContainerButtons.firstChild;
      button.firstChild.classList.remove('fa-undo');
      button.firstChild.classList.add('fa-table');

      clonedContainerButtons.lastChild.remove();

      const rowsInput = document.createElement('input');
      const colsInput = document.createElement('input');

      rowsInput.setAttribute('type', 'number');
      colsInput.setAttribute('type', 'number');

      rowsInput.style.width = '50px';
      rowsInput.style.height = '28px';

      colsInput.style.width = '50px';
      colsInput.style.height = '28px';

      colsInput.style.cssFloat = 'left';
      rowsInput.style.cssFloat = 'left';

      clonedContainerButtons.appendChild(rowsInput);
      clonedContainerButtons.appendChild(colsInput);

      button.addEventListener('click', () => {
        if (!this.selectedNodeEditor) return;
        const table = this.tableGenerator(parseInt(colsInput.value), parseInt(rowsInput.value));

        if (
          this.selectedNodeEditor.classList &&
          this.selectedNodeEditor.classList.contains('angular-editor-textarea')
        ) {
          this.selectedNodeEditor.appendChild(table);
          return;
        }

        this.selectedNodeEditor.parentElement.appendChild(table);
      });

      editorAngular.querySelector('.angular-editor-toolbar').appendChild(clonedContainerButtons);
    });

    this.setObserverForTextAreas();

    const headerOptions = document.querySelectorAll('.ae-picker-item');
    headerOptions.forEach((headerOption) => {
      if (headerOption.textContent == ' Standard ' || headerOption.textContent == ' Heading 7 ') headerOption.remove();
    });
  }

  public async getAllSOFFormats() {
    try {
      const SOFFormats = await this.mainService.get('api/sof-formats?active=true').toPromise();
      this.sofFormats = SOFFormats;
    } catch (err) {}
  }

  private getSelectedFormatOrder() {
    if (!this.selectedSofFormatId) return null;
    const { header, order, footer } = this.sofFormats.find((sofFormat) => sofFormat._id === this.selectedSofFormatId);
    return [header, order, footer];
  }

  public formatRichText(indexRichText: number) {
    const richTexts = ['masterRemark', 'agencyRemark', 'terminalRemark'];
    const containerRich = document.querySelectorAll('.angular-editor-textarea')[indexRichText];
    const images = containerRich.querySelectorAll('img');

    images.forEach((image, index) => {
      if (image.style.width === '710px') return;
      image.style.width = '710px';
      this.statementOfFacts[richTexts[indexRichText]] = containerRich.innerHTML;
    });
  }

  private loopRichText(callback) {
    const richTexts = ['masterRemark', 'agencyRemark', 'terminalRemark'];
    const containersRich = document.querySelectorAll('.angular-editor-textarea');
    containersRich.forEach((richText, index) => {
      callback(richText, richTexts, index);
    });
  }

  setRichText() {
    this.loopRichText((richText, richTexts, index) => {
      richText.innerHTML = this.statementOfFacts[richTexts[index]] || '';
    });
  }

  saveRichText() {
    this.loopRichText((richText, richTexts, index) => {
      this.statementOfFacts[richTexts[index]] = richText.innerHTML;
    });
  }

  tableGenerator(rows: number, columns: number) {
    if (rows <= 0 || columns <= 0) return;

    const table = document.createElement('table');

    for (let iRows = 0; iRows < rows; iRows++) {
      const newRow = document.createElement('tr');

      for (let iCols = 0; iCols < columns; iCols++) {
        const newCol = document.createElement('td');
        newCol.style.border = '1px solid black';
        newCol.style.padding = '5px';
        newCol.style.width = '100px';
        newCol.style.height = '30px';

        newRow.appendChild(newCol);
      }

      table.appendChild(newRow);
    }

    return table;
  }

  setObserverForTextAreas() {
    const editorBoxes = document.querySelectorAll('.angular-editor-textarea');
    editorBoxes.forEach((editorBox) => {
      editorBox.innerHTML = '';
      editorBox.addEventListener('mouseup', () => {
        const selection = window.getSelection() as any;
        this.selectedNodeEditor = selection.baseNode;
      });
    });
  }

  determineRoute() {
    const partesUrl = this.router.url.split('/');
    switch (partesUrl[2]) {
      case 'reporte-zarpe':
        this.isReport = true;
        this.reportType = 'Reporte zarpe';
        break;
      case 'reporte-fin-operaciones':
        this.isReport = true;
        this.reportType = 'Reporte fin de operaciones';
        break;
      case 'reportes':
        this.isGeneralReport = true;
        break;
      case 'reporte-arribo':
        this.isReport = true;
        this.reportType = 'Reporte arribo';
        break;
      case 'viaje':
        this.isReport = false;
        this.reportType = 'Viaje';
        break;
    }
  }

  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.analisisOperativo === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.loadSof();
      }
    });
  }

  public onUpdateSof() {
    if(this.sailingTime.fecha) {
      this.showInfoSailingTime('Sailing Time establecido no se puede modificar la información')
    } else {
      this.saveRichText();
      this.mainService.put(`api/sof/${this.idSof}`, this.statementOfFacts).subscribe(
        (result) => {
          Swal.fire('Éxito', 'Se actualizó el statements of facts', 'success');
        },
        (err) => {
          Swal.fire('Error', 'Ocurrio un error actualizando el SOF', 'warning');
        }
      );
    }
  }

  public onSetDate(event) {
    this.newIncident.date = this.dateAndTimeService.convertirFechaFormatoFormularioAFormatoBD(
      event.target.value,
      '00',
      '00'
    );
  }

  /**
   * Carga los campos relacionados al SOF
   * @param idSof id del SOF, encontrado en la URL
   */
  public loadSof() {
    this.generatePdf = false;
    this.statementOfFacts = {};

    this.mainService.get(`api/sof/${this.idSof}/${this.idRecalada}`).subscribe((result) => {
      if (result && result.message) {
        Swal.fire({
          title: '¡Error!',
          text: result.message,
          type: 'error',
        });
        return this.router.navigate(['home/dashboard']);
      }
      this.statementOfFacts = result;
      this.searchTerminal();
      this.loadShip(this.statementOfFacts.nv);
      this.history = this.statementOfFacts.history ? this.statementOfFacts.history : [];
      this.history.forEach((element) => (element.dateView = moment(element.date).format('DD-MMM-YYYY')));
      this.history = _.orderBy(this.history, ['date'], ['asc']);

      this.setRichText();
    });
    this.mainService.get(`api/type_remarks?activo=true`).subscribe((result) => {
      this.currentRemarks = result.sort((a, b) => {
        const aName = a.description.toLowerCase();
        const bName = b.description.toLowerCase();

        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      });
    });

    this.mainService.get(`api/category_sof?activo=true`).subscribe((result) => {
      this.categoryRemarks = result.sort((a, b) => {
        const aName = a.description.toLowerCase();
        const bName = b.description.toLowerCase();

        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      });
    });
  }

  private async getRecalada() {
    try {
      const recalada = this.mainService.get(`api/recalada/${this.idRecalada}`).toPromise();
      return recalada;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Busca la terminal que se encuentra en el SOF
   */
  private searchTerminal() {
    this.mainService.get(`api/terminal?TER_ID=${this.statementOfFacts.terminal}`).subscribe((terminal) => {
      this.terminalEncontrada = terminal ? terminal[0].TER_NOMBRE : 'No se encontró la terminal asociada';
    });
  }

  public onFilterCurrentRemarks() {
    this.currentRemarksFilter = this.currentRemarks.filter((obj) => obj.category == this.newIncident.categoryId);
    let category = this.categoryRemarks.filter((obj) => obj._id == this.newIncident.categoryId);
  }

  public onGetTypesByCategory(event) {
    this.remarkSeleccionado = this.categoryRemarks.find((category) => category._id === event);
    this.isStoppedSelected =
      this.remarkSeleccionado.description === 'STOPPED OPERATIONS' ||
      this.remarkSeleccionado.description === 'STOPPED HOURS';

    this.newIncident.hoursType = this.isStoppedSelected ? 'Stopped' : 'Worked';
    this.newIncident.priority = this.remarkSeleccionado.priority;
    this.newIncident.category = this.remarkSeleccionado.description;

    this.currentRemarksFilter = this.currentRemarks.filter((remark) => {
      if (remark.category) {
        return remark.category._id === event;
      }
    });

    this.newIncident.typeId = this.currentRemarksFilter[0]._id;
  }

  public onChangesTypes(idType) {
    const typeSelected = this.currentRemarksFilter.find((remarkToFind) => remarkToFind._id === idType);
    this.newIncident.type = typeSelected.description;
    this.newIncident.typeSpanish = typeSelected.descriptionSpanish;
  }

  public loadShip(nv) {
    this.mainService.get(`api/analisis-operativo-viaje/${nv}`).subscribe(async (shipData) => {
      const vessel = await this.mainService.get(`api/buque/${shipData.vessel._id}`).toPromise();

      this.statementOfFacts.voyage = shipData.voyageNumber;

      this.ship = {
        vessel: shipData.vessel.nombre ? shipData.vessel.nombre : '- - -',
        vesselFlag: vessel.BUQ_BANDERA,
        port: shipData.port.nombre ? shipData.port.nombre : '- - -',
        terminal: this.statementOfFacts.terminal ? this.statementOfFacts.terminal : '- - -',
        voyageNumber: this.statementOfFacts.voyage ? this.statementOfFacts.voyage : '- - -',
        disponentOwner: shipData.disponentOwner.nombre ? shipData.disponentOwner.nombre : '- - -',
        products: shipData.recaladas[0].productos ? shipData.recaladas[0].productos : [],
        commodity: shipData.commodity.nombre,
      };

      const recalada = shipData.recaladas.find((recalada) => recalada._id == this.statementOfFacts.idRecalada);
      shipData.recaladas[0] = recalada;

      if (shipData.recaladas[0].adConditions) {
        this.ship.arriveBunkerFo = shipData.recaladas[0].adConditions.arriveFo || '- - -';
        this.ship.arriveBunkerDo = shipData.recaladas[0].adConditions.arriveDo || '- - -';
        this.ship.arriveBunkerFw = shipData.recaladas[0].adConditions.arriveFw || '- - -';
        this.ship.arriveDraftFwd = shipData.recaladas[0].adConditions.arriveFwd || '- - -';
        this.ship.arriveDraftAft = shipData.recaladas[0].adConditions.arriveAft || '- - -';
        this.ship.arriveDraftLo = shipData.recaladas[0].adConditions.arriveLo || '- - -';
        this.ship.tugBoatsIn = shipData.recaladas[0].adConditions.tugBoatsIn || '-';
        this.ship.sailingBunkerFo = shipData.recaladas[0].adConditions.sailingFo || '- - -';
        this.ship.sailingBunkerDo = shipData.recaladas[0].adConditions.sailingDo || '- - -';
        this.ship.sailingBunkerFw = shipData.recaladas[0].adConditions.sailingFw || '- - -';
        this.ship.sailingDraftLo = shipData.recaladas[0].adConditions.sailingLo || '- - -';
        this.ship.sailingDraftFwd = shipData.recaladas[0].adConditions.sailingFwd || '- - -';
        this.ship.sailingDraftAft = shipData.recaladas[0].adConditions.sailingAft || '- - -';
        this.ship.tugBoatsOut = shipData.recaladas[0].adConditions.tugBoatsOut || '-';
      } else {
        this.ship.arriveBunkerFo = '- - -';
        this.ship.arriveDraft = '- - -';
        this.ship.arriveBunkerDo = '- - -';
        this.ship.arriveAft = '- - -';
        this.ship.arriveBunkerFw = '- - -';
        this.ship.arriveFwd = '- - -';
        this.ship.sailingBunkerFo = '- - -';
        this.ship.sailingDraft = '- - -';
        this.ship.sailingBunkerDo = '- - -';
        this.ship.sailingAft = '- - -';
        this.ship.sailingBunkerFw = '- - -';
        this.ship.sailingFwd = '- - -';
        this.ship.tugBoatsIn = '-';
        this.ship.tugBoatsOut = '-';
      }
      this.sailingTime = shipData.recaladas[0].portLog.sailingTime
    });
  }

  public back() {
    this._location.back();
  }

  private async getStoppedHoursInfo() {
    return await this.mainService.get(`api/sof/all-stopped-hours/${this.idSof}`).toPromise();
  }

  private portLogCategories = {
    arrive: [
      'EOSP',
      'Nor Tendered',
      'Pilot on Board for Anchor',
      'Pilot on Board for Anchor',
      'Pilot on Board for Berthing',
      'Dropped Anchor',
      'First Line Ashore',
      'Berthing Time',
      'Port Autorities On Board',
      'Free Practique Granted',
      'Commence OPS',
      'Completion OPS Time',
      'Pilot On board',
      'Sailing Time',
    ],
    departure: [
      'Completion OPS time',
      'Narcotics Inspections',
      'Vessel Unmoored',
      'Pilot On board',
      'Dropping Outward Pilot',
      'Sailing Time',
      'ETA Next Port',
    ],
  };

  private async organizeDataForPDF() {
    const recalada = await this.getRecalada();
    this.ship.terminal = recalada.terminal.nombre;

    if (document.getElementById('customRemarks')) document.getElementById('customRemarks').remove();

    const globalDiv = document.createElement('div');
    globalDiv.id = 'customRemarks';
    globalDiv.style.width = '760px';
    // globalDiv.style.display = 'none';

    if (this.statementOfFacts.masterRemark) {
      const div = document.createElement('div');
      div.id = 'master';
      div.innerHTML = this.statementOfFacts.masterRemark;
      globalDiv.appendChild(div);
    }

    if (this.statementOfFacts.agencyRemark) {
      const div = document.createElement('div');
      div.id = 'agency';
      div.innerHTML = this.statementOfFacts.agencyRemark;
      globalDiv.appendChild(div);
    }

    if (this.statementOfFacts.terminalRemark) {
      const div = document.createElement('div');
      div.id = 'terminal';
      div.innerHTML = this.statementOfFacts.terminalRemark;
      globalDiv.appendChild(div);
    }

    document.body.appendChild(globalDiv);

    const organizedDataForPDF = {
      stoppedHoursInfo: await this.getStoppedHoursInfo(),
      priorityRemarksPDF: [],
      allRemarksPDF: [],
      portLogAll: [],
      portLogArribo: [],
      portLogZarpe: [],
      arriveDeparture: {
        arriveBunkerFo: `${this.ship.arriveBunkerFo} MT`,
        arriveBunkerDo: `${this.ship.arriveBunkerDo} MT`,
        arriveBunkerFw: `${this.ship.arriveBunkerFw} MT`,
        arriveDraftFwd: `${this.ship.arriveDraftFwd} M`,
        arriveDraftAft: `${this.ship.arriveDraftAft} M`,
        arriveDraftLo: `${this.ship.arriveDraftLo} M`,
        tugBoatsIn: `${this.ship.tugBoatsIn}`,

        sailingBunkerFo: `${this.ship.sailingBunkerFo} MT`,
        sailingBunkerFw: `${this.ship.sailingBunkerFw} MT`,
        sailingBunkerDo: `${this.ship.sailingBunkerDo} MT`,
        sailingDraftFwd: `${this.ship.sailingDraftFwd} M`,
        sailingDraftAft: `${this.ship.sailingDraftAft} M`,
        sailingDraftLo: `${this.ship.sailingDraftLo} M`,
        tugBoatsOut: `${this.ship.tugBoatsOut}`,
      },
      ship: this.ship,
      customsRemarks: {
        agency: '',
        master: '',
        terminal: '',
      },
    };

    const images = Array.from(document.querySelectorAll('#customRemarks img'));
    console.log(images);
    const proms = images.map((im: any) => new Promise((res) => (im.onload = () => res([im.width, im.height]))));

    // list all image widths and heights _after_ the images have loaded:
    await Promise.all(proms);

    const remarksElements = ['master', 'agency', 'terminal'];
    for (const remarkElement of remarksElements) {
      const globalDiv = document.getElementById('customRemarks');
      const elementHTML = globalDiv.querySelector(`#${remarkElement}`) as HTMLElement;
      if (!elementHTML) continue;

      const dataUrl = await domtoimage.toPng(elementHTML);
      const img = new Image() as any;
      img.src = dataUrl;

      await new Promise((resolve) => {
        img.onload = resolve;
      });

      organizedDataForPDF.customsRemarks[remarkElement] = dataUrl;
    }

    console.log(organizedDataForPDF.customsRemarks);

    Object.values(recalada.portLog).forEach((portLog: any) => {
      portLog.fecha = portLog.fecha ? moment.utc(portLog.fecha).format('DD/MM/YYYY HH:mm') : '';
      if (portLog.status !== 'CONFIRMED') return;

      if (this.portLogCategories.departure.includes(portLog.nombre)) organizedDataForPDF.portLogArribo.push(portLog);
      if (this.portLogCategories.arrive.includes(portLog.nombre)) organizedDataForPDF.portLogZarpe.push(portLog);
      organizedDataForPDF.portLogAll.push(portLog);
    });    

    if (this.statementOfFacts.history.length === 0) return organizedDataForPDF;

    const datesCounters = {
      all: this.statementOfFacts.history[0].dateView,
      priority: this.statementOfFacts.history[0].dateView,
      collectionRangeHour: '',
    };

    function addReportByDay(remark, remarkAhead, remarkCollection, currentDate) {
      let reportSummary = { reportDay: true };

      if (remark.type.includes('RAIN')) {
        datesCounters.collectionRangeHour = `${datesCounters.collectionRangeHour} ${remark.from} - ${remark.to} | `;
      }

      let collectionRangeHour = datesCounters.collectionRangeHour;
      reportSummary = {
        reportDay: true,
        ...organizedDataForPDF.stoppedHoursInfo.totalHorusReportRainByDay[remark.dateView],
        collectionRangeHour: collectionRangeHour,
      };

      if (!remarkAhead || remark.dateView !== remarkAhead.dateView) {
        remarkCollection.push(reportSummary);
        datesCounters[currentDate] = remark.dateView;
        datesCounters.collectionRangeHour = '';
      }
    }

    this.statementOfFacts.history.forEach((remark, index) => {
      if (this.excelSettings.spanish) {
        remark.categoryCopy = `${remark.category} (${remark.categorySpanish})`;
        remark.typeCopy = `${remark.type} (${remark.typeSpanish})`;
      } else {
        remark.categoryCopy = remark.category;
        remark.typeCopy = remark.type;
      }

      if (remark.priority === 'Alta') {
        this.priorityRemarksPDF.push(remark);
      }

      this.allRemarksPDF.push(remark);
    });


    this.allRemarksPDF.forEach((remark, index) => {
      organizedDataForPDF.allRemarksPDF.push(remark);
      addReportByDay(remark, this.allRemarksPDF[index + 1], organizedDataForPDF.allRemarksPDF, datesCounters.all);
    });

    this.priorityRemarksPDF.forEach((remark, index) => {
      organizedDataForPDF.priorityRemarksPDF.push(remark);
      addReportByDay(
        remark,
        this.priorityRemarksPDF[index + 1],
        organizedDataForPDF.priorityRemarksPDF,
        datesCounters.priority
      );
    });

    return organizedDataForPDF;
  }

  public async onCreatePdf() {
    this.downloadingPDF = true;
    const organizedDataForPDF = await this.organizeDataForPDF();

    const selectedSofFormatOrder = this.getSelectedFormatOrder();
    selectedSofFormatOrder && this.formatSofService.setBuildOrder(selectedSofFormatOrder);
    const contentOfPDF = this.formatSofService.generateContentForPDF(organizedDataForPDF);

    this.pdfService.buildPDF(
      contentOfPDF,
      `${this.ship.vessel} - ${this.statementOfFacts.nv} - ${this.ship.port} - ${this.terminalEncontrada}`
    );

    this.downloadingPDF = false;

    this.priorityRemarksPDF = [];
    this.allRemarksPDF = [];
  }

  public onCreateExcel() {
    this.downloadingExcel = true;
    const query = this.excelSettings.spanish ? '?language=spanish' : '';
    this.mainService.download(`api/documento/excel/sof/${this.idSof}${query}`).subscribe((blob) => {
      saveAs(
        blob,
        `${this.ship.vessel} - ${this.statementOfFacts.nv} - ${this.ship.port} - ${this.statementOfFacts.terminal}.xlsx`
      );

      this.downloadingExcel = false;
    });
  }

  public showInfoSailingTime(message: string) {
    Swal.fire({
      title: message,
      type: 'info',
      showCancelButton: false,
      confirmButtonText: 'Continuar',
    });
  }

  public onAddRemark() {
    console.log('Print:', this.isMajorSailingDate());
    if(this.isMajorSailingDate()) {
      this.showInfoSailingTime('La fecha debe ser menor que Sailing Time');
      return
    }
    if (
      this.newIncident.categoryId &&
      this.newIncident.typeId &&
      this.newIncident.fromHora &&
      this.newIncident.fromMinuto &&
      this.newIncident.toHora &&
      this.newIncident.toMinuto &&
      this.newIncident.status &&
      this.newIncident.hoursType &&
      this.newIncident.date
    ) {
      const from: number = Number(`${this.newIncident.fromHora}.${this.newIncident.fromMinuto}`);
      const to: number = Number(`${this.newIncident.toHora}.${this.newIncident.toMinuto}`);

      if (from > to) {
        Swal.fire('Advertencia', 'Las horas ingresadas no son correctas', 'warning');
      } else {
        this.newIncident.from = `${this.newIncident.fromHora}:${this.newIncident.fromMinuto}`;

        delete this.newIncident.fromHora;
        delete this.newIncident.fromMinuto;

        this.newIncident.to = `${this.newIncident.toHora}:${this.newIncident.toMinuto}`;

        delete this.newIncident.toHora;
        delete this.newIncident.toMinuto;

        const [descriptionCategory, descriptionSpanishCategory] = this.getNames(
          this.newIncident.categoryId,
          'categoryRemarks'
        );

        const [descriptionType, descriptionSpanishType] = this.getNames(
          this.newIncident.typeId,
          'currentRemarksFilter'
        );

        this.newIncident.category = descriptionCategory;
        this.newIncident.categorySpanish = descriptionSpanishCategory;

        this.newIncident.type = descriptionType;
        this.newIncident.typeSpanish = descriptionSpanishType;

        this.history.push(this.newIncident);

        this.saveRichText();

        this.mainService.put(`api/sof/${this.idSof}`, { history: this.history }).subscribe((res) => {
          this.loadSof();
          Swal.fire('Éxito', 'Se actualizó el statements of facts', 'success');
          this.newIncident = {};
        });
      }
    } else {
      Swal.fire('Error', 'Hay campos obligatorios vacíos', 'warning');
    }
  }

  /**
   * Obtiene el nombre de la categoría a partir del id de esta
   * @param categoryId el id de la categoría
   * @return el nombre de la categoría
   */
  public getCategoryName(categoryId) {
    const categoriaEncontrada = this.categoryRemarks.find((category) => {
      return category._id === categoryId;
    });
    return { description: categoriaEncontrada.description, descriptionSpanish: categoriaEncontrada.descriptionSpanish };
  }

  public getNames(elementId, collection) {
    const itemFound = this[collection].find((item) => {
      return item._id === elementId;
    });
    return [itemFound.description, itemFound.descriptionSpanish];
  }

  public onDeleteRemark(data) {
    if(this.sailingTime.fecha) {
      this.showInfoSailingTime('Sailing Time establecido no se puede modificar la información')
      return
    }
    if (this.permisosUsuario.analisisOperativo !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el contenido?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.dismiss) {
        return;
      }

      if (result.value) {
        let index = this.history.findIndex((remark) => remark._id == data._id);
        this.history.splice(index, 1);
        this.mainService.put(`api/sof/${this.idSof}`, { history: this.history }).subscribe((res) => {
          this.loadSof();
          Swal.fire('Éxito', 'Se actualizó el statements of facts', 'success');
        });
      } else {
        Swal.fire('Error', 'Problema eliminando la observacion', 'warning');
      }
    });
  }

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  public onEditRemark() {
    if(this.isMajorSailingDate()) {
      this.showInfoSailingTime('La fecha debe ser menor que Sailing Time');
      return
    }
    if (
      this.newIncident.categoryId &&
      this.newIncident.typeId &&
      this.newIncident.fromHora &&
      this.newIncident.fromMinuto &&
      this.newIncident.toHora &&
      this.newIncident.toMinuto &&
      this.newIncident.status &&
      this.newIncident.hoursType &&
      this.newIncident.date
    ) {
      const from: number = Number(`${this.newIncident.fromHora}.${this.newIncident.fromMinuto}`);
      const to: number = Number(`${this.newIncident.toHora}.${this.newIncident.toMinuto}`);

      if (from > to) {
        Swal.fire('Error', 'Las horas ingresadas no son correctas', 'warning');
      } else {
        this.newIncident.from = `${this.newIncident.fromHora}:${this.newIncident.fromMinuto}`;
        delete this.newIncident.fromHora;
        delete this.newIncident.fromMinuto;
        this.newIncident.to = `${this.newIncident.toHora}:${this.newIncident.toMinuto}`;
        delete this.newIncident.toHora;
        delete this.newIncident.toMinuto;

        let index = this.history.findIndex((remark) => remark._id == this.newIncident._id);
        this.history.splice(index, 1);
        this.history.push(this.newIncident);

        this.history;

        this.saveRichText();

        this.mainService.put(`api/sof/${this.idSof}`, { history: this.history }).subscribe((res) => {
          this.loadSof();
          this.openEdit = false;
          Swal.fire('Éxito', 'Se actualizó el statements of facts', 'success');
          this.newIncident = {};
        });
      }
    } else {
      Swal.fire('Error', 'Hay campos obligatorios vacios', 'warning');
    }
  }

  public onCancelEdit() {
    this.newIncident = {};
    this.openEdit = false;
  }

  isMajorSailingDate() {
    if (this.sailingTime.fecha) {
      const sailingDate = new Date(this.sailingTime.fecha.slice(0, -1));
      const date = new Date(this.newIncident.date);
      date.setHours(this.newIncident.toHora);
      date.setMinutes(this.newIncident.toMinuto);
      if (date < sailingDate) return false
      return true
    }
    return true;
  }

  public onOpenRemark(remark) {
    if (this.permisosUsuario.analisisOperativo !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }

    this.newIncident = JSON.parse(JSON.stringify(remark));

    if (this.newIncident && this.newIncident.from) {
      const fromPartes = this.newIncident.from.split(':');
      this.newIncident.fromHora = fromPartes[0];
      this.newIncident.fromMinuto = fromPartes[1];
    }
    delete this.newIncident.from;

    if (this.newIncident && this.newIncident.to) {
      const toPartes = this.newIncident.to.split(':');
      this.newIncident.toHora = toPartes[0];
      this.newIncident.toMinuto = toPartes[1];
    }
    delete this.newIncident.to;

    this.newIncident.dateFormulario =
      this.newIncident && this.newIncident.date
        ? this.dateAndTimeService.convertirFechaAFormatoFormulario(this.newIncident.date)
        : '';

    this.onGetTypesByCategory(this.newIncident.categoryId);

    this.openEdit = true;
  }

  public onCustomActions(event) {
    switch (event.action) {
      case 'edit':
        this.onOpenRemark(event.data);
        break;
      case 'delete':
        this.onDeleteRemark(event.data);
        break;
    }
  }
}
