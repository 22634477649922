import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../../services/auth.service';
import { MainService } from '../../services/main.service';
import { of } from 'rxjs';


@Component({
	selector: 'app-bussines-line-ver',
	templateUrl: './bussines-line-ver.component.html',
	styleUrls: ['./bussines-line-ver.component.scss']
})
export class BussinesLineVerComponent implements OnInit {

	enviado: boolean = false;
	id: any;
	public bussinesLine: any = {};
	public data: any;

	constructor(
		private toastrService: NbToastrService,
		private _location: Location,
		private route: ActivatedRoute,
		private mainService: MainService,) {

	}

	ngOnInit() {

		this.id = this.route.snapshot.params['id'];
		this.onCargar()

	}

	onCargar() {

		this.bussinesLine = {};
		this.bussinesLine.LIN_REGULAR = false;
		this.bussinesLine.LIN_TRAMP = false;

		this.mainService.get('api/bussines_line/' + this.id).subscribe(res => {
			this.bussinesLine = res;
		});
	}

	back() {
		this._location.back();
	}

	/** Toas **/

	showToast(position, status, titulo, mensaje) {
		this.toastrService.show(
			mensaje,
			titulo,
			{ position, status });
	}




}
