export interface MultiSelectIns {
  label: string;
  defaults: {
    placeholder: string;
    value: string[];
    color?: string;
  };
  settings: {
    colors?: boolean;
  };
  options: {
    value: string;
    content: string;
    color?: string;
  }[];
}

export interface MultiSelectsIns {
  [key: string]: MultiSelectIns;
}

export const multiSelectsIns: MultiSelectsIns = {
  companyType: {
    label: 'Tipo *',
    defaults: {
      placeholder: 'Seleccione tipo de empresa',
      value: [''],
    },
    settings: {},
    options: [
      {
        value: 'BROKER/AGENT/OTHERS',
        content: 'BROKER/AGENT/OTHERS',
      },
      {
        value: 'CARGO OWNER',
        content: 'CARGO OWNER',
      },
      {
        value: 'CHARTERER',
        content: 'CHARTERER',
      },
      {
        value: 'CLIENTE',
        content: 'CLIENTE',
      },
      {
        value: 'OWNER',
        content: 'OWNER',
      },
      {
        value: 'PROVEEDOR',
        content: 'PROVEEDOR',
      },
      {
        value: 'RECEIVER',
        content: 'RECEIVER',
      },
    ],
  },
  areaCompanyType: {
    label: 'Tipo',
    defaults: {
      value: [''],
      placeholder: 'Selecciona el tipo de área',
    },
    settings: {},
    options: [
      {
        value: 'BROKER/AGENT/OTHERS',
        content: 'BROKER/AGENT/OTHERS',
      },
      {
        value: 'CARGO OWNER',
        content: 'CARGO OWNER',
      },
      {
        value: 'CHARTERER',
        content: 'CHARTERER',
      },
      {
        value: 'CLIENTE',
        content: 'CLIENTE',
      },
      {
        value: 'OWNER',
        content: 'OWNER',
      },
      {
        value: 'PROVEEDOR',
        content: 'PROVEEDOR',
      },
      {
        value: 'RECEIVER',
        content: 'RECEIVER',
      },
    ],
  },
};
