import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

type AOA = any[][];

@Component({
  selector: 'app-carga',
  templateUrl: './carga.component.html',
  styleUrls: ['./carga.component.scss'],
})
export class CargaComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  /*****/

  /** Carga actual */
  carga: any = {};

  /** Configuracion de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      CAR_ID: {
        title: 'ID',
      },
      CAR_NOMBRE: {
        title: 'Nombre',
      },
      COMODITY_NOMBRE: {
        title: 'Commodity',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
    },
  };

  datos: any = [];
  /** Commodities en la BD */
  commodities: any = [];
  /** Indica si los datos fueorn enviados */
  enviado: boolean = false;
  /** Indica si algo esta cargandose en el modulo */
  public cargando: boolean = false;
  /** Indica si los commodities se estan cargando */
  public commoditiesCargandose: boolean = false;

  /** Usuario actual */
  public usuario: any;
  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService
  ) {}

  /**
   * Inicializa el usuario actual y los datos de la BD
   */
  ngOnInit() {
    this.data = [];
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.cargas === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
        this.obtenerCommoditiesBD();
      }
    });
  }

  /**
   * Maneja las acciones
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  /**
   * Inicializa la edicion de una carga
   * @param dialog Referencia al dialogo del detalle
   * @param event Evento con datos de la carga a editar
   */
  onEditar(dialog, event) {
    if (this.permisosUsuario.cargas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.carga = {};
    let ultimo = this.data[this.data.length - 1];
    this.carga.CAR_ID = ultimo.CAR_ID + 1;
    this.carga = event.data;
    this.carga._id = event.data._id;
    this.dialogService
      .open(dialog, { context: 'this is some additional data passed to dialog' })
      .onClose.subscribe(() => {
        this.carga = {};
      });
  }

  /**
   * Abre el dialogo con los datos de la carga a editar
   * @param event Evento con los datos de la carga a editar
   */
  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Inicializa la creacion de una carga
   */
  crearCarga() {
    this.carga.CAR_ID = '';
    this.carga.COMODITY_ID = '';
    this.openDialogSave(this.dialog);
  }

  /**
   * Abre el dialogo del detalle para la creación de una carga
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.cargando = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.cargando = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Crera una carga en la BD
   */
  public crear() {
    this.enviado = false;
    if (this.carga.CAR_NOMBRE) {
      this.mainService.get('api/global_variable/CAR_ID').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.carga.CAR_ID = 72; //Primer valor del ID
          const cargaIdGlobalVariable = {
            key: 'CAR_ID',
            value: this.carga.CAR_ID,
          };
          this.mainService.post('api/global_variable', cargaIdGlobalVariable).subscribe((result) => {});
        } else {
          this.carga.CAR_ID = res.value;
        }

        this.mainService.put('api/global_variable/CAR_ID', {}).subscribe((result) => {});
        this.enviado = false;
        this.mainService.post('api/carga', this.carga).subscribe((result) => {
          if (result) {
            Swal.fire('Éxito', 'Se agregó el carga exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            Swal.fire('Error', 'No se pudo agregar el carga, verifique la información', 'warning');
          }
        });
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar la carga, verifique la información', 'warning');
    }
  }

  /**
   * Redirigue a la pantalla de visualizacion de la carga seleccionada
   * @event Evento con los datos de la carga a visualizar
   */
  onVer(event) {
    this.router.navigate(['home/carga-ver/' + event.data._id]);
  }

  /**
   * Actualiza una carga
   */
  onActualizar() {
    if (this.carga.CAR_NOMBRE) {
      let cargaActualizar = this.carga;
      let data = cargaActualizar;

      this.mainService.put('api/carga/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la carga exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
          this.carga = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar la carga, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar la carga, verifique la información', 'warning');
    }
  }

  /**
   * Carga los datos de la BD
   */
  onCargar() {
    this.cargando = true;

    let x = this;
    this.data = [];
    this.mainService.get('api/carga').subscribe((res) => {
      this.cargando = false;

      x.data = res;
      this.data = JSON.parse(JSON.stringify(res));
      x.data.forEach((element) => {
        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');

        // Commodity asociado a la carga
        element.COMODITY_NOMBRE = 'Cargando ...';
        if (!element.COMODITY_ID) {
          element.COMODITY_NOMBRE = 'N/A';
        } else {
          this.mainService.get(`api/commodity?ID=${element.COMODITY_ID}`).subscribe((resul) => {
            element.COMODITY_NOMBRE = resul.length > 0 ? resul[0].COMODITY : 'No encontrado';
            this.data = this.data.slice();
          });
        }
      });
    });
  }

  /**
   * Elimina una carga de la BD
   * @param event Evento con los datos de la carga a eliminar
   */
  onEliminar(event) {
    if (this.permisosUsuario.cargas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar la carga?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let cargaEliminar = event.data;
        this.mainService.delete('api/carga/' + cargaEliminar._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'carga eliminada con éxito', 'success');
            this.onCargar();
          }
        });
      }
    });
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.datos = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

      for (let i = 1; i < this.datos.length; i++) {
        /* let x = {}; */
        let excel = {
          CAR_ID: this.datos[i][0],
          CAR_NOMBRE: this.datos[i][1],
          COMODITY_ID: this.datos[i][2],
        };
        this.mainService.post('api/carga', excel).subscribe((res) => {});
      }
    };
    this.onCargar();
    reader.readAsBinaryString(target.files[0]);
  }

  abrirExcel() {
    const element: HTMLElement = document.getElementById('archivo');
    element.click();
  }

  /**
   * Limpia los datos al cerrar la ventana de dialogo
   */
  onCerrarDialogo() {
    this.carga = {};
  }

  /**
   * Maneja el evento cuando se hace click en el boton para recargar los commodites
   */
  onRecargarCommodities() {
    this.obtenerCommoditiesBD();
  }

  /**
   * Trae los commodities de la BD
   */
  obtenerCommoditiesBD(): void {
    this.commoditiesCargandose = true;

    this.mainService.get('api/commodity').subscribe((res) => {
      this.commodities = res;
      //Hay que quitar el puerto ALL de las opciones, este tiene el ID 0
      let i = 0;
      let encontroCommodity = false;
      while (i < this.commodities.length && !encontroCommodity) {
        if (this.commodities[i].PUE_ID == 0) {
          this.commodities.splice(i, 1);
          encontroCommodity = true;
        }

        i++;
      }
      this.commodities = _.orderBy(this.commodities, ['PUE_NOMBRE']);
      this.commoditiesCargandose = false;
    });
  }
}
