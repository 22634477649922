import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class MainService {
  // public baseURL: String = "https://naves-backend.herokuapp.com/";
  // public baseURL: String = "https://develop_naves.imagineapps.co/";
  public baseURL: String = environment.baseUrl;
  public headers: HttpHeaders;

  constructor(private http: HttpClient, public authService: AuthService) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.authService.getToken());
  }

  setHeaders() {
    const token = localStorage.getItem('id_token');
    return { Authorization: token };
  }

  get(object: String): Observable<any> {
    let header = this.setHeaders();
    return this.http.get(this.baseURL + '' + object, { headers: header });
  }

  post(object: String, data: any): Observable<any> {
    let header = this.setHeaders();
    return this.http.post(this.baseURL + '' + object, data, { headers: header });
  }

  delete(object: String): Observable<any> {
    let header = this.setHeaders();
    return this.http.delete(this.baseURL + '' + object, { headers: header });
  }

  put(object: String, data: any): Observable<any> {
    let header = this.setHeaders();
    return this.http.put(this.baseURL + '' + object, data, { headers: header });
  }

  patch(object: String, data: any): Observable<any> {
    let header = this.setHeaders();
    return this.http.patch(this.baseURL + '' + object, data, { headers: header });
  }

  download(endpoint: string) {
    return this.http.get(`${this.baseURL}${endpoint}`, { responseType: 'blob' });
  }

  downloadInfo(endpoint: string, jsonData: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const options = {
      headers: headers,
      responseType: 'blob',
    };

    return this.http.post(`${this.baseURL}${endpoint}`, jsonData, options as any);
  }
}
