import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-husbandry',
  templateUrl: './husbandry.component.html',
  styleUrls: ['./husbandry.component.scss'],
})
export class HusbandryComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  datosEdit = {
    _id: '',
    titulo: '',
    usuario: '',
    texto: '',
  };

  settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nvModified: {
        title: 'NV',
      },
      descripcion: {
        title: 'Descripción',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  viajes: any[] = [];
  husbandry = {
    nv: undefined,
    viaje: undefined,
    descripcion: undefined,
    _id: undefined,
    details: undefined,
  };
  editing: boolean = false;
  enviado: boolean = false;
  titulo: any;
  texto: any;
  public user: any;
  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  onCambioNV() {
    const viajeElegido = this.viajes.find((viaje) => viaje.nv == this.husbandry.nv);
    this.husbandry.viaje = viajeElegido._id;
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.husbandry === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /** Para modal **/

  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.dialog, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
    }
  }

  onEditar(dialog, event) {
    if (this.permisosUsuario.husbandry !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.editing = true;
    this.husbandry = Object.assign({}, event.data);

    if (!this.viajes.some((viaje) => viaje.nv == this.husbandry.nv)) {
      this.husbandry.details = 'notfound';
    }

    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  crearNotificacion() {
    this.enviado = false;
    this.editing = false;
    this.resetFields();
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/
  crear() {
    this.enviado = true;
    const idViaje = this.viajes.find((viaje) => viaje.nv == this.husbandry.nv);
    this.husbandry.viaje = idViaje;
    if (this.husbandry.descripcion && this.husbandry.nv) {
      this.mainService.post('api/husbandry', this.husbandry).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se agregó la solicitud exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.enviado = false;
          this.resetFields();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo agregar la solicitud, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se han completado todos los campos requeridos', 'error');
    }
  }

  resetFields() {
    this.husbandry = {
      nv: undefined,
      viaje: undefined,
      descripcion: undefined,
      _id: undefined,
      details: undefined,
    };
  }

  actualizar() {
    if (this.husbandry.descripcion && this.husbandry.nv) {
      this.mainService.put(`api/husbandry/${this.husbandry._id}`, this.husbandry).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se agregó la solicitud exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.enviado = false;
          this.editing = false;
          this.resetFields();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo agregar la solicitud, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se han completado todos los campos requeridos', 'error');
    }
  }

  onCargar() {
    this.mainService.get('api/nuevo-viaje').subscribe((res) => {
      this.viajes = res;
      this.setDataViajes();

      this.mainService.get('api/husbandry?activo=true').subscribe((res) => {
        this.data = res;
        this.data.forEach((element) => {
          const tempViaje = this.viajes.find((viaje) => viaje.nv == element.nv);
          element.nvModified = `${tempViaje.nv} - ${tempViaje.vessel.nombre} - ${tempViaje.voyageNumber || ''}`;
          // element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');
        });

        this.viajes = this.viajes.filter((viaje) => viaje.status == 'CONFIRMED');
      });
    });
  }

  handleInput(event) {
    console.log(event);
  }

  onEliminar(event) {
    if (this.permisosUsuario.husbandry !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar la solicitud?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinerGuardar = true;
        this.mainService.delete('api/husbandry/' + event.data._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'solicitud eliminada con éxito', 'success');
            this.onCargar();
          }
        });
      }
    });
  }

  setNVForHusbrandy(data: string) {
    this.husbandry.nv = data;
  }

  public dataCollectionToFindNV = [];
  setDataViajes() {
    this.dataCollectionToFindNV = [];
    this.viajes.forEach((viaje) => {
      this.dataCollectionToFindNV.push({
        key: viaje.nv,
        value: `${viaje.nv} - ${viaje.vessel.nombre} - ${viaje.voyageNumber || ''}`,
      });
    });
  }
}
