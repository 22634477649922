import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../../services/auth.service';
import { MainService } from '../../services/main.service';
import { of } from 'rxjs';


@Component({
	selector: 'app-commodity-ver',
	templateUrl: './commodity-ver.component.html',
	styleUrls: ['./commodity-ver.component.scss']
})
export class CommodityVerComponent implements OnInit {

	enviado: boolean = false;
	id: any;
	public commodity: any = {};
	public data: any;

	/** Productos activos por un commodity seleccionado */
	public elegibleProducts: any[] = [];
	/** Configuración de la tabla de productos */
	productsSettings = {
		actions: false,
		columns: {
			PRD_ID: {
				title: 'Id del producto',
				width: '20px',
			},
			PRD_NOMBRE: {
				title: 'Nombre del producto',
			},
			createdAt: {
				title: 'Fecha de creación',
				sortDirection: 'desc',
				valuePrepareFunction: this.timeService.formatStandardDate,
				filterFunction: this.timeService.filterByDate,
			},
		},
	};

	constructor(
		private toastrService: NbToastrService,
		private _location: Location,
		private route: ActivatedRoute,
		private mainService: MainService,
		private timeService: TimeService
	) {

	}

	ngOnInit() {

		this.id = this.route.snapshot.params['id'];
		this.onCargar()

	}

	onCargar() {

		this.commodity = {};

		this.mainService.get('api/commodity/' + this.id).subscribe(res => {
			this.commodity = res;
			this.mainService.get(`api/producto/commodity/${this.commodity.ID}`).subscribe((response) => {
				this.elegibleProducts = response;
			});
		});
	}

	back() {
		this._location.back();
	}

	/** Toas **/

	showToast(position, status, titulo, mensaje) {
		this.toastrService.show(
			mensaje,
			titulo,
			{ position, status });
	}

}
