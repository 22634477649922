import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-bussines-line',
  templateUrl: './bussines-line.component.html',
  styleUrls: ['./bussines-line.component.scss'],
})
export class BussinesLineComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  /** Business Line seleccionado */
  bussinesLine: any = {};

  /** Configuración de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      LIN_ID: {
        title: 'ID',
      },
      LIN_NOMBRE: {
        title: 'Nombre',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  datos: any;
  /** Indica si los datos fueron enviados */
  enviado: boolean = false;

  /** Usuario actual */
  public usuario: any;
  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private timeService: TimeService
  ) {}

  /**
   * Inicializa el usuairo actual y pone a cargar la pagina
   */
  ngOnInit() {
    this.data = [];
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.businessLines === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /**
   * Maneja las acciones posibles
   * @param event evento con informacion del Business Line
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  /**
   * Maneja la accion de editar
   */
  onEditar(dialog, event) {
    if (this.permisosUsuario.businessLines !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.bussinesLine = {};
    this.bussinesLine = Object.assign({}, event.data);
    this.dialogService
      .open(dialog, { context: 'this is some additional data passed to dialog' })
      .onClose.subscribe(() => {
        this.bussinesLine = {};
      });
  }

  /**
   * Abre la pantalla de dialogo para editar
   * @param edit Pantalla de dialogo para editar
   */
  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Crea una linea de negocio
   */
  crearBussines() {
    this.bussinesLine = {};
    this.bussinesLine.LIN_REGULAR = false;
    this.bussinesLine.LIN_TRAMP = true;
    this.openDialogSave(this.dialog);
  }

  /**
   * Abre la ventana para ceracion de una linea de negiocio
   */
  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Crear la linea de negocio
   */
  public crear() {
    this.enviado = false;
    if (
      this.bussinesLine.LIN_NOMBRE !== '' &&
      this.bussinesLine.LIN_NOMBRE !== undefined &&
      this.bussinesLine.LIN_REGULAR !== '' &&
      this.bussinesLine.LIN_REGULAR !== undefined &&
      this.bussinesLine.LIN_TRAMP !== '' &&
      this.bussinesLine.LIN_TRAMP !== undefined
    ) {
      this.mainService.get('api/global_variable/LIN_ID').subscribe((res) => {
        // Cuando la variable global no ha sido creada llega un mensaje
        if (res.message) {
          this.bussinesLine.LIN_ID = 43; //Primer valor del ID
          const bussinesLineIdGlobalVariable = {
            key: 'LIN_ID',
            value: this.bussinesLine.LIN_ID,
          };
          this.mainService.post('api/global_variable', bussinesLineIdGlobalVariable).subscribe((result) => {});
        } else {
          this.bussinesLine.LIN_ID = res.value;
        }

        this.mainService.put('api/global_variable/LIN_ID', {}).subscribe((result) => {});
        this.enviado = false;
        this.mainService.post('api/bussines_line', this.bussinesLine).subscribe((result) => {
          if (result) {
            Swal.fire('Éxito', 'Se agregó la linea de negocio exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
          } else {
            Swal.fire('Error', 'No se pudo agregar la linea de negocio, verifique la información', 'warning');
          }
        });
      });
    } else {
      this.enviado = true;
      Swal.fire('Error', 'No se pudo agregar la business line, verifique la información', 'warning');
    }
  }

  /**
   * Redirigue para visualizar la linea de negoico
   */
  onVer(event) {
    this.router.navigate(['home/bussines-line-ver/' + event.data._id]);
  }

  /**
   * Actualiza los datos de una linea de negocio
   */
  onActualizar() {
    if (
      this.bussinesLine.LIN_NOMBRE !== '' &&
      this.bussinesLine.LIN_NOMBRE != undefined &&
      this.bussinesLine.LIN_REGULAR !== '' &&
      this.bussinesLine.LIN_REGULAR !== undefined &&
      this.bussinesLine.LIN_TRAMP !== '' &&
      this.bussinesLine.LIN_TRAMP !== undefined
    ) {
      delete this.bussinesLine.createdAt;
      let data = this.bussinesLine;
      this.mainService.put('api/bussines_line/' + data._id, data).subscribe(
        (result) => {
          if (result) {
            Swal.fire('Éxito', 'Se actualizó la business line exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
            this.bussinesLine = {};
          } else {
            Swal.fire('Error', 'No se pudo actualizar la business line, verifique la información', 'warning');
          }
        },
        (err) => {
          console.error(err);
          Swal.fire('Error', err.error.message, 'error');
        }
      );
    } else {
      Swal.fire('Error', 'No se pudo actualizar la business line, verifique la información', 'warning');
    }
  }

  /**
   * Carga los datos del modulo
   */
  onCargar() {
    let x = this;
    this.data = [];
    this.mainService.get('api/bussines_line?activo=true').subscribe((res) => {
      x.data = res;
      this.data = new LocalDataSource(this.data);
    });
  }

  /**
   * Elimina una linea de negocio
   * @param event Evento con los datos de la linea de negocio a elminar
   */
  onEliminar(event) {
    if (this.permisosUsuario.businessLines !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar la business line?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let bussinesLineEliminar = event.data;
        this.spinerGuardar = true;
        this.mainService
          .delete(`api/bussines_line/${bussinesLineEliminar._id}?lineId=${bussinesLineEliminar.LIN_ID}`)
          .subscribe(
            (res) => {
              if (res) {
                this.spinerGuardar = false;
                if (res.message) {
                  Swal.fire({
                    title: '¡Error!',
                    text: res.message,
                    type: 'error',
                  });
                  return;
                }
                Swal.fire('Éxito', 'Business line eliminada con éxito', 'success');
                this.onCargar();
              }
            },
            (err) => {
              this.spinerGuardar = false;
              console.error(err);
              Swal.fire('Error', err.error.message, 'error');
            }
          );
      }
    });
  }

  public exchangeLineType(data, lineType) {
    this.bussinesLine[lineType] = data != 'true';
  }

  public exchangeTramp() {}
}
