import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-puerto-ver',
  templateUrl: './puerto-ver.component.html',
  styleUrls: ['./puerto-ver.component.scss'],
})
export class PuertoVerComponent implements OnInit {
  enviado: boolean = false;
  id: any;
  public puerto: any = {};
  public data: any;

  constructor(
    private toastrService: NbToastrService,
    private _location: Location,
    private route: ActivatedRoute,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.onCargar();
  }

  onCargar() {
    this.puerto = {};
    this.mainService.get('api/puerto/' + this.id).subscribe((res) => {
      this.puerto = res;
      console.log(this.puerto);
    });
  }

  back() {
    this._location.back();
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }
}
