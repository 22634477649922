import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { TimeService } from '../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

type AOA = any[][];

@Component({
  selector: 'app-commodity',
  templateUrl: './commodity.component.html',
  styleUrls: ['./commodity.component.scss'],
})
export class CommodityComponent implements OnInit {
  /* los modales */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;

  // Lista de commodities;
  commodities: any[] = [];
  // Nombre del commodity que aún no existe
  nuevoCommodity: string = '';
  /** Commodity actual */
  commodity: any = {};
  // Habilitar la opción de agregar un nuevo commodity
  newCommodity: boolean = false;
  // Habilitar la opción de agregar un nuevo producto
  newProduct: boolean = false;
  // Nuevo nombre de la carga / producto
  producto: any = {
    PRD_NOMBRE: '',
    PRD_ID: '',
    COMODITY: '',
    createdAt: null,
  };

  /** Determina si se está realizando la edición de un producto y activa botones para realizar sus funciones */
  public edicionProducto: boolean = false;

  // Lista de todos los productos
  allProducts: any[];

  // Listado de cargas relacionadas a un commodity
  public cargas: any[] = [];

  /** Configuracion de la tabla */
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      COMODITY: {
        title: 'Nombre',
      },
      ID: {
        title: 'Productos asignados',
        valuePrepareFunction: (value) => this.getProductsByCommodity(value),
        filterFunction: (value?: any, search?: any) => this.filterProductsByCommodity(value, search),
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  /** Configuración de la tabla de productos */
  productsSettings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose icon-table"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash icon-table"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose icon-table"></i> ' },
        { name: 'delete', title: '<i class="nb-trash icon-table"></i> ' },
      ],
    },
    columns: {
      PRD_ID: {
        title: 'Id del producto',
        width: '20px',
      },
      PRD_NOMBRE: {
        title: 'Nombre del producto',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  /** Productos activos por un commodity seleccionado */
  public elegibleProducts: any[] = [];

  /** Guarda el dialogo que se encuentra activo */
  public activeDialog: any;

  /** Indica si los datos del commodity fueron mandados */
  public enviado: boolean = false;
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = true;

  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private timeService: TimeService
  ) {}

  /**
   * Inicializa al usuario actual y carga los datos de la BD
   */
  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.commodities === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /** Carga la información primordial de la pantalla */
  async onCargar() {
    this.cargando = true;
    this.getAllProducts().then((value) => {
      this.setupTable();
    });
  }

  /** Obtiene todos los productos */
  getAllProducts() {
    return new Promise((resolve, reject) => {
      this.mainService.get('api/producto?activo=true').subscribe((response) => {
        this.allProducts = response;
        resolve(true);
      });
    });
  }

  /**
   * Maneja las acciones de la tabla general
   * @param event Evento con la informacion de la acción
   */
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  /**
   * Maneja las acciones de la tabla de productos por commodity
   */
  onCustomProductsTable(event) {
    switch (event.action) {
      case 'edit':
        this.editarProducto(event);
        break;
      case 'delete':
        this.eliminarProducto(event);
        break;
    }
  }

  /**
   * Inicializa la edicion de un commodity
   * @param dialog dialogo con el detalle de commodity
   * @param event Evento con los datos del commodity a editar
   */
  onEditar(dialog, event) {
    if (this.permisosUsuario.commodities !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.commodity = {};
    this.commodity = Object.assign({}, event.data);
    this.onSelectCommodity({ comodityId: event.data.ID });
    this.activeDialog = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  editarProducto(event: any) {
    this.edicionProducto = true;
    this.producto = JSON.parse(JSON.stringify(event.data));
  }

  eliminarProducto(event: any) {
    Swal.fire({
      title: 'Eliminar producto',
      text: '¿Deseas eliminar este producto?',
      type: 'warning',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No, cancelar',
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.value) {
        this.mainService
          .delete(`api/producto/${event.data._id}?PRD_ID=${event.data.PRD_ID}&COMMODITY_ID=${event.data.COMODITY_ID}`)
          .subscribe(
            (res) => {
              if (res) {
                this.onSelectCommodity({ comodityId: res.COMODITY_ID });
                this.onCargar();
                Swal.fire({
                  title: 'Éxito',
                  text: 'Se ha eliminado el producto',
                  type: 'success',
                });
              }
            },
            (response) => {
              Swal.fire({
                title: 'Error',
                text: `${response.error.message}`,
                type: 'error',
              });
            }
          );
      }
    });
  }

  /**
   * Abre el detalle para la edición de un Commodity
   */
  openDialogEdit(edit) {
    this.activeDialog = this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  closeDialogNew() {
    this.nuevoCommodity = '';
    this.activeDialog.close();
  }

  closeDialogEditar() {
    this.edicionProducto = false;
    this.producto = {
      PRD_NOMBRE: '',
      PRD_ID: '',
      COMODITY: '',
      createdAt: null,
    };
    this.activeDialog.close();
  }

  getProductsByCommodity(commodityId) {
    if (this.allProducts) {
      const productos = this.filterByCommodity(commodityId);
      const iguales = productos
        .map((product) => {
          return product.PRD_NOMBRE;
        })
        .join(', ');
      if (iguales) {
        return iguales;
      } else {
        return 'No tiene productos asignados';
      }
    }
  }

  /**
   * Filtra los productos que tiene asociados un commodity, y los devuelve en una lista separados por comas
   * @param commodityId El ID del commodity (no el de mongo) que se relaciona con los productos
   * @param search El término de búsqueda
   * @returns {string} Lista de productos que encuentra
   */
  filterProductsByCommodity(commodityId, search) {
    if (this.allProducts) {
      // Primero filtra a nivel general, solo busca si hay un producto con ese commodity
      const iguales = this.filterByCommodity(commodityId);
      if (iguales.length > 0) {
        // Si lo hay, busca si el nombre coincide con la búsqueda
        const productosCoinciden = iguales.filter((product) => {
          return product.PRD_NOMBRE.toLowerCase().includes(search.toLowerCase());
        });
        if (productosCoinciden.length > 0) {
          return true;
        }
      } else if (iguales.length === 0 && 'no tiene productos asignados'.includes(search.toLowerCase())) {
        // Si no lo hay, revisa si está buscando los que no tienen productos asignados
        return true;
      }
    }
    return false;
  }

  filterByCommodity(commodityId) {
    const respuesta = this.allProducts.filter((product) => {
      return product.COMODITY_ID == commodityId && product.activo;
    });
    return respuesta;
  }

  /**
   * Inicializa la cracion de un commodity
   */
  crearCommodity() {
    this.commodity = {};
    this.openDialogSave(this.dialog);
  }

  /**
   * Crea un nuevo commodity cuando se le asigna un nombre
   * @returns {void}
   */
  crearNuevoCommodity() {
    if (this.nuevoCommodity == undefined || this.nuevoCommodity == '') {
      Swal.fire('Error', 'No se ha agregado la información requerida', 'error');
      return;
    }
    const newCommodityObj = {
      COMODITY: this.nuevoCommodity,
      createdAt: new Date(),
    };
    this.mainService.post('api/commodity', newCommodityObj).subscribe((response) => {
      if (response) {
        Swal.fire('Éxito', 'Se ha agregado la información del commodity', 'success');
        this.nuevoCommodity = '';
        this.newCommodity = false;
        this.enviado = false;
        this.onCargar();
        this.activeDialog.close();
      }
    });
  }

  /**
   * Abre la ventana de detalle para crear un commodity
   * @param dialog Referencia al dialogo para la creacion de un commodity
   */
  openDialogSave(dialog) {
    this.activeDialog = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /**
   * Obtiene los productos asociados a un commodity, para rellenar la información de la tabla interna.
   * @param {string} comodityId el id del commodity
   */
  onSelectCommodity({ comodityId }) {
    this.mainService.get(`api/producto/commodity/${comodityId}`).subscribe((response) => {
      this.elegibleProducts = response;
    });
  }

  /**
   * Se encarga de gestionar la creación
   * @param esEdicion Indica si se requiere una edición o no
   */
  manageProduct(esEdicion: boolean) {
    if (this.producto.PRD_NOMBRE) {
      this.producto.createdAt = new Date();
      this.producto.COMODITY = this.commodity._id;
      this.producto.COMODITY_ID = this.commodity.ID;
      if (esEdicion) {
        this.editOldProduct();
      } else {
        this.producto.PRD_ID = this.createNewProductId();
        this.createNewProduct();
      }
    } else {
      Swal.fire('Error', 'No se pudo agregar el producto, verifique la información', 'warning');
    }
  }

  /**
   * Guarda los cambios que se hayan realizado en un producto creado
   */
  editOldProduct() {
    this.mainService.put(`api/producto/${this.producto._id}`, this.producto).subscribe((result) => {
      this.onSelectCommodity({ comodityId: result.COMODITY_ID });
      this.stopEditingProduct();

      this.mainService.put(`api/commodity/${result.COMODITY}`, this.commodity).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la commodity exitosamente', 'success');
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo actualizar la commodity, verifique la información', 'warning');
        }
      });
    });
  }

  /**
   * Termina la edición de un producto, para que se muestren otros botones
   */
  stopEditingProduct() {
    this.resetProductFields();
    this.edicionProducto = false;
  }

  /**
   * Crea un nuevo producto
   */
  createNewProduct() {
    this.mainService.post(`api/producto/`, this.producto).subscribe((result) => {
      this.stopEditingProduct();
      this.onSelectCommodity({ comodityId: result.COMODITY_ID });
      this.onCargar();
    });
  }

  /**
   * Resetea los campos del producto, muy útil para después de crear o actualizar.
   */
  resetProductFields() {
    this.producto = {
      PRD_NOMBRE: '',
      PRD_ID: '',
      COMODITY: '',
      createdAt: null,
    };
  }

  /**
   *
   * @returns Le asigna un id al nuevo producto a partir del último en la lista, para esto es importante que ningún dato se borre sino que se desactive.
   */
  createNewProductId() {
    const ultimo = this.allProducts[this.allProducts.length - 1];
    if (ultimo && ultimo.PRD_ID) {
      return Number(ultimo.PRD_ID) + 1;
    } else {
      return 1;
    }
  }

  /**
   * Redirigue a la ventana de visualizacion de un commodity
   * @param event Evento con la informacion del commodity a ver
   */
  onVer(event) {
    this.router.navigate(['home/commodity-ver/' + event.data._id]);
  }

  /**
   * Actualiza un commodity
   */
  onActualizar() {
    if (this.commodity.COMODITY != '' && this.commodity.ID != '') {
      const commodityActualizar = this.commodity;
      const data = commodityActualizar;
      this.mainService.put(`api/commodity/${data._id}`, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la commodity exitosamente', 'success');
          this.activeDialog.close();
          this.onCargar();
          this.commodity = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar la commodity, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar la commodity, verifique la información', 'warning');
    }
  }

  /**
   * Configura la tabla de los commodities según los requerimientos
   */
  setupTable() {
    let x = this;
    this.data = [];
    this.mainService.get('api/commodity?activo=true').subscribe((res) => {
      this.cargando = false;
      this.commodities = res;
      x.data = res;
      this.data = _.orderBy(this.data, ['createdAt'], ['desc']);
    });
  }

  /**
   * Permite eliminar un commodity siempre y cuando la persona tenga los permisos y este commodity no tenga productos asociados
   * @param event El evento creado al oprimir el botón de una fila de la tabla de commodities
   * @returns {void} Mensaje de éxito o fracaso
   */
  onEliminar(event) {
    if (this.permisosUsuario.commodities !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }

    Swal.fire({
      title: '<strong>¿Deseas eliminar la commodity?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let commodityEliminar = event.data;
        const commodityId = commodityEliminar._id;
        this.mainService.delete(`api/commodity/${commodityId}`).subscribe(
          (res) => {
            if (res) {
              if (res.message) {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  type: 'error',
                  showConfirmButton: true,
                  showCancelButton: true,
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No',
                }).then((res) => {
                  if (res.dismiss) return;
                  this.removeComodityAndProducts(commodityId);
                });

                return;
              }

              Swal.fire({
                title: 'Éxito',
                text: 'Commodity eliminado con éxito',
                type: 'success',
              });
              this.onCargar();
            }
          },
          (res) => {
            Swal.fire({
              title: '¡Error!',
              text: res.error.message,
              type: 'error',
            });
          }
        );
      }
    });
  }

  removeComodityAndProducts(commodityId: string) {
    this.mainService.delete(`api/commodity/products/${commodityId}`).subscribe((res) => {
      Swal.fire({
        title: 'Éxito',
        text: 'Commodity y productos eliminados.',
        type: 'success',
      });
    });
  }
}
