import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CaptianInterviewComponent } from './pages/captian-interview/captian-interview.component';

import { HomeComponent } from '../public/home/home.component';

const baseRoute = 'captian-interview';

const routes: Routes = [
  {
    path: baseRoute,
    component: HomeComponent,
    children: [
      {
        path: '',
        component: CaptianInterviewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CaptianInterviewRoutingModule {}
