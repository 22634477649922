import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { TimeService } from '../../services/time/time.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
/* Services */
import { MainService } from '../services/main.service';

type AOA = any[][];

@Component({
  selector: 'app-type-remarks',
  templateUrl: './type-remarks.component.html',
  styleUrls: ['./type-remarks.component.scss'],
})
export class TypeRemarksComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  settings = {
    edit: { confirmEdit: true, editButtonContent: '<i class="nb-compose"></i> ' },
    delete: { confirmDelete: true, deleteButtonContent: '<i class="nb-trash"></i> ' },
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      description: { title: 'Descripcion' },
      category: {
        title: 'Categoria',
        valuePrepareFunction: (data) => {
          return data ? data.description : undefined;
        },
        filterFunction(category?: any, search?: string): boolean {
          if (category.description) {
            const pos = category.description.toLowerCase().search(search.toLowerCase());
            if (pos !== -1) return true;
            return false;
          }
          return false;
        },
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  public source: LocalDataSource;
  public dataRemarks: any = [];
  public dataCategorias: any = [];
  public datos: any = [];
  public data: any = [];
  public remark: any = {};
  public usuario: any = {};
  public enviado: boolean = false;
  public permisosUsuario: any;

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private mainService: MainService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.data = [];
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.obtenerPermisos();
    this.onCargar();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.remarksSof === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      }
    });
  }

  /** Para modal **/
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
    }
  }

  onEditar(dialog, event) {
    if (this.permisosUsuario.remarksSof !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.remark = {};
      this.remark = Object.assign({}, event.data);
      this.dialogService
        .open(dialog, { context: 'this is some additional data passed to dialog' })
        .onClose.subscribe(() => {
          this.remark = {};
        });
    }
  }

  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  crearCategoria() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/

  public crear() {
    this.enviado = false;
    if (
      this.remark.category != undefined &&
      this.remark.category != '' &&
      this.remark.description != undefined &&
      this.remark.description != ''
    ) {
      this.enviado = false;
      this.mainService.post('api/type_remarks', this.remark).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se agregó el remark exitosamente', 'success');
          this.remark = {};
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo agregar el remark, verifique el información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar el remark, verifique el información', 'warning');
    }
  }

  onActualizar() {
    if (
      this.remark.category != undefined &&
      this.remark.category != '' &&
      this.remark.description != undefined &&
      this.remark.description != ''
    ) {
      let data = this.remark;
      this.mainService.put('api/type_remarks/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó el remark exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
          this.remark = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar el remark, verifique el información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar el remark, verifique el información', 'warning');
    }
  }

  onCargar() {
    this.data = [];
    this.mainService.get(`api/type_remarks?activo=true`).subscribe((result) => {
      this.dataRemarks = result;
      this.data = result;
      this.mainService.get('api/category_sof?activo=true').subscribe((res) => (this.dataCategorias = res));
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.remarksSof !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar el remark?</strong>',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          let remarkEliminar = event.data;
          this.spinerGuardar = true;
          this.mainService
            .delete(`api/type_remarks/${remarkEliminar._id}?descripcionRemark=${remarkEliminar.description}`)
            .subscribe((res) => {
              if (res) {
                if (res.message) {
                  Swal.fire({
                    title: 'Error',
                    text: res.message,
                    type: 'error',
                  });
                  return;
                }
                Swal.fire('Éxito', 'Remark eliminado con éxito', 'success');
                this.spinerGuardar = false;
                this.onCargar();
              }
            });
        }
      });
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      this.datos = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let i = 1; i < this.datos.length; i++) {
        /* let x = {}; */
        let excel = {
          description: this.datos[i][0],
          category: this.datos[i][1],
        };
        this.mainService.post('api/type_remarks', excel).subscribe((res) => {});
      }
      this.onCargar();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  abrirExcel() {
    const element: HTMLElement = document.getElementById('archivo');
    element.click();
  }
}
