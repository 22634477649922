import { Injectable } from '@angular/core';
import { MainService } from '../../../services/main.service';

@Injectable({
  providedIn: 'root',
})
export class ReportesService {
  constructor(private mainService: MainService) {}

  /**
   * Devuelve un string en el formao visual de fechas de los reportes
   * @param fecha Fecha la cual se quiere obtener el formato visual
   */
  obtenerFechaVisualReportes(fecha: Date): string {
    const fechaDate: Date = new Date(fecha);
    const dia = this.numeroMinimoDosDigitots(fechaDate.getUTCDate());
    const mes = this.numeroMinimoDosDigitots(fechaDate.getUTCMonth() + 1);
    const ano = fechaDate.getUTCFullYear();
    const horas = this.numeroMinimoDosDigitots(fechaDate.getUTCHours());
    const minutos = this.numeroMinimoDosDigitots(fechaDate.getUTCMinutes());
    return `${dia}/${mes}/${ano} ${horas}${minutos} Hrs`;
  }

  /**
   * Devuelve un string en el formao visual de fechas de los reportes
   * @param fecha Fecha la cual se quiere obtener el formato visual
   * @param horaEstimada Tiempo estimado (AM o PM) de llegada
   */
  obtenerFechaVisualReportesSinHora(fecha: Date, horaEstimada: string): string {
    if (!fecha) return;
    const fechaDate: Date = new Date(fecha);
    const dia = this.numeroMinimoDosDigitots(fechaDate.getUTCDate());
    const mes = this.numeroMinimoDosDigitots(fechaDate.getUTCMonth() + 1);
    const ano = fechaDate.getUTCFullYear();
    return `${dia}/${mes}/${ano} ${horaEstimada}`;
  }

  /**
   * Devuelve un string con la abreviatura del mes segun el numero ingresado
   * @param mes Numero del mes
   * @returns String con Abreviatura del mes pasado como argumento
   */
  obtenerAbreviasionMes(mes: number): string {
    /** String que sera retornado */
    let mesAbreviatura;

    switch (mes) {
      case 0:
        mesAbreviatura = 'Jan';
        break;
      case 1:
        mesAbreviatura = 'Feb';
        break;
      case 2:
        mesAbreviatura = 'Mar';
        break;
      case 3:
        mesAbreviatura = 'Apr';
        break;
      case 4:
        mesAbreviatura = 'May';
        break;
      case 5:
        mesAbreviatura = 'Jun';
        break;
      case 6:
        mesAbreviatura = 'Jul';
        break;
      case 7:
        mesAbreviatura = 'Agu';
        break;
      case 8:
        mesAbreviatura = 'Sep';
        break;
      case 9:
        mesAbreviatura = 'Oct';
        break;
      case 10:
        mesAbreviatura = 'Nov';
        break;
      case 11:
        mesAbreviatura = 'Dic';
        break;
      default:
        mesAbreviatura = 'No es un mes valido';
    }

    return mesAbreviatura;
  }

  /**
   * Convierte un numero a un string de mínimo dos digitos
   * @param num Numero del cual se quiere obtener el string
   */
  numeroMinimoDosDigitots(num: number): string {
    if (num > -9 && num <= 9) {
      return `0${num}`;
    } else return `${num}`;
  }

  /**
   * Coge un archivo y lo pasa a su base de 64
   * @param archivo archivo del cual se quiere obtener en base 64
   */
  archivoABase64(archivo: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = () => {
        const inputData: any = reader.result;
        const replaceValue: string = inputData.split(',')[0];
        resolve(inputData.replace(replaceValue + ',', ''));
      };
      reader.onerror = (error) => reject(error);
    });
  }

  /**
   * Coge un texto largo y lo convierte a HTML
   * @param texto Texto del cual se quiere obtener el HTML
   * @return El texto del parametro en formato HTML
   */
  obtenerTextoHTML(texto: string): string {
    if (!texto) return '';

    const textoPorParrafos = texto.split('\n');
    let textoHtml = '';

    textoPorParrafos.forEach((parrafo, index) => {
      textoHtml += `<p style="margin: 0;">${parrafo ? parrafo : '&nbsp;'}</p>`;
    });

    return textoHtml;
  }

  /**
   * De una fecha devuelve la fecha en el formato para un input de fechas
   * @param fecha Fecha la cual se quiere obtener el formato de input de fechas
   * @returns Fecha en formato de input de fechas
   */
  obtenerFechaFormulario(fecha: Date): string {
    return `${fecha.getUTCFullYear()}-${this.stringNumeroMinimoDosDigitos(
      fecha.getUTCMonth() + 1
    )}-${this.stringNumeroMinimoDosDigitos(fecha.getUTCDate())}`;
  }

  /**
   * De una hora devuelve la hora en el formato para un input de horas
   * @param fecha Fecha la cual se quiere obtener el formato de input de horas
   * @returns Fecha en formato de input de horas
   */
  obtenerHoraFormulario(fecha: Date): string {
    return `${this.stringNumeroMinimoDosDigitos(fecha.getUTCHours())}:${this.stringNumeroMinimoDosDigitos(
      fecha.getUTCMinutes()
    )}`;
  }

  /**
   * Coge un numero y lo vuelve en un string que tenga mínimo dos digitos
   * @param numero Numero que se quiere obtener el sitring de 2 diitos
   */
  stringNumeroMinimoDosDigitos(number: number): string {
    if (number < 10) return `0${number}`;
    else return `${number}`;
  }

  /**
   * Recibe el string de fecha y hora del input y devuelve la fecha UTC
   * @param fecha String con fecha en formato del input date
   * @param hora String con la hora en formato input time
   */
  obtenerFechaUTC(fecha: string, hora: string): number {
    const fechaPartes = `${fecha}`.split('-');
    const horasPartes = `${hora}`.split(':');
    return Date.UTC(
      Number(fechaPartes[0]),
      Number(fechaPartes[1]) - 1,
      Number(fechaPartes[2]),
      Number(horasPartes[0]),
      Number(horasPartes[1])
    );
  }

  /**
   * Recibe el string de fecha y hora del input y devuelve la fecha UTC
   * @param fecha String con fecha en formato del input date
   */
  obtenerFechaUTCSinHora(fecha: string): number {
    const fechaSeparada = `${fecha}`.split('-');
    return Date.UTC(Number(fechaSeparada[0]), Number(fechaSeparada[1]) - 1, Number(fechaSeparada[2]), 0, 0, 0);
  }

  //

  public combinePortLogsAndSOFActivities(portLogActivities, showSOFActivities = true) {
    const confirmedActivities = [];
    const estimatedActivites = [];

    const allActivites = [...portLogActivities];

    allActivites.forEach((activity) => {
      if (!activity.nombre) {
        activity.nombre = activity.type;
      }

      if (activity.type != undefined && !showSOFActivities) {
        return;
      }

      if (activity.status.toLowerCase() === 'confirmed') {
        confirmedActivities.push(activity);
        return;
      }

      estimatedActivites.push(activity);
    });

    return {
      confirmedActivities: this.sortActivities(confirmedActivities),
      estimatedActivites: this.sortActivities(estimatedActivites),
    };
  }

  private sortActivities(activities) {
    return activities.sort((a: any, b: any) => {
      return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
    });
  }
}
