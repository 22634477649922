import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../../../../../services/main.service';
import { Router } from '@angular/router';
import { ReportesService } from '../../../../reportes.service';

@Component({
  selector: 'vista-previa-repore-prospectos',
  templateUrl: './vista-previa-repore-prospectos.component.html',
  styleUrls: ['./vista-previa-repore-prospectos.component.scss'],
})
export class VistaPreviaReporeProspectosComponent implements OnInit {
  /** Viaje del reporte de prospectos */
  public viaje: any = {};
  /** Indica si algo se esta cargando en el componente */
  public cargando: boolean = true;
  /** Arreglo con los parrafos del remark*/
  public parrafosRemarks: string[] = [];
  /** Imagen del buque */
  public imagenBuque: string;
  public iconBuque: string;
  public companyName: string;

  /** Lista de correos con copia y copia oculta */
  public correosCopia: string[];
  public correosCopiaOculta: string[];

  public asunto: string;
  public attachments: any[];

  public anchoredInformation: string;
  public showAnchoredInformation: boolean;

  constructor(private mainService: MainService, public router: Router, private reportesService: ReportesService) {}

  ngOnInit() {
    this.obtenerViaje();
  }

  includeAnchored() {
    this.showAnchoredInformation = !this.showAnchoredInformation;
  }

  /**
   * A traves de la URL obtiene el viaje del reporte de prospectos e inicializa el viaje en el formato requerido para
   * el componente de reporte de prospectos
   */
  obtenerViaje(): void {
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe((res) => {
      this.viaje = res;

      this.obtenerNombresAsociacionesRelevantesViaje();
      this.obtenerTiemposEstimadosEnFormatoVisual();

      this.cargando = false;
    });
  }

  /**
   * Obtiene los nombres de las asociaciones del viaje que son relevantes al componente de reporte de prospectos
   */
  obtenerNombresAsociacionesRelevantesViaje(): void {
    // Nombre del buque
    if (this.viaje.vessel) {
      this.mainService.get(`api/buque?BUQ_ID=${this.viaje.vessel}`).subscribe((res) => {
        this.viaje.buqueNombre = res.length > 0 ? res[0].BUQ_NOMBRE : 'Not found';
      });
    } else this.viaje.buqueNombre = 'Not registered';
  }

  /**
   * Obtiene en formato visual los tiemos estimados
   */
  obtenerTiemposEstimadosEnFormatoVisual(): void {
    this.viaje.etaVisual =
      this.viaje && this.viaje.eta && !this.viaje.etaSinHora
        ? this.reportesService.obtenerFechaVisualReportes(this.viaje.eta)
        : this.reportesService.obtenerFechaVisualReportesSinHora(this.viaje.eta, this.viaje.etaAMPM);
    this.viaje.etbVisual =
      this.viaje && this.viaje.etb && !this.viaje.etbSinHora
        ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etb)
        : this.reportesService.obtenerFechaVisualReportesSinHora(this.viaje.etb, this.viaje.etbAMPM);
    this.viaje.etcVisual =
      this.viaje && this.viaje.etc && !this.viaje.etcSinHora
        ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etc)
        : this.reportesService.obtenerFechaVisualReportesSinHora(this.viaje.etc, this.viaje.etcAMPM);
    this.viaje.etdVisual =
      this.viaje && this.viaje.etd && !this.viaje.etdSinHora
        ? this.reportesService.obtenerFechaVisualReportes(this.viaje.etd)
        : this.reportesService.obtenerFechaVisualReportesSinHora(this.viaje.etd, this.viaje.etdAMPM);
  }
}
