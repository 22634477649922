import { Component, OnInit } from '@angular/core';
import { Menu, MenuOperadorCentral, MenuOperario } from '../../module/menus/menu.module';
import { NbMenuService } from '@nebular/theme';
import { NbSidebarService } from '@nebular/theme';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

type ChartData = [{ date; value; count }];
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public user = JSON.parse(localStorage.getItem('usuario'));
  logueado = false;
  public usuarioActivo: any = {};
  items = null;
  constructor(
    private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    public router: Router,
    public Au: AuthService
  ) {
    this.usuarioActivo = { nombre: `${this.user.nombre} ${this.user.apellido}`, cargo: this.user.cargo };
    this.items = Menu;
  }

  ngOnInit() {}

  toggle() {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  inicio() {
    this.router.navigate(['home/dashboard']);
  }

  salir() {
    this.Au.logout();
    this.router.navigate(['login']);
  }
}
