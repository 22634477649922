import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.scss']
})
export class RecuperarContrasenaComponent {

  public emailRegistrado: FormControl = new FormControl('', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);

  constructor(
    private authService: AuthService,
		private router: Router
  ) { }

  recuperar(){
    if(this.emailRegistrado.valid){
      this.authService.forgot(this.emailRegistrado.value).subscribe(response => {
        if(response.success){
          Swal.fire({
            title: 'Éxito',
            text: 'Correo enviado exitosamente',
            type: 'success'
          });
					this.router.navigate(['login']);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se ha podido enviar el correo',
            type: 'error'
          })
        }
      });
    }
  }

}
