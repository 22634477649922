import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ViajeDetalleService } from '../../viaje-detalle.service';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { MainService } from '../../../../../services/main.service';
import Swal from 'sweetalert2';
import { nuevoViaje } from '../../../../../interfaces/requests.interface';
import * as moment from 'moment';
@Component({
  selector: 'crear-proforma-card-viaje',
  templateUrl: './crear-proforma-card-viaje.component.html',
  styleUrls: ['./crear-proforma-card-viaje.component.scss'],
})
export class CrearProformaCardViajeComponent implements OnInit {
  @Input('confirmed') confirmed: boolean;
  /** Referencia del permiso de poder editar o solo ver */
  @Input('available') available: boolean;

  @Output() cambioEnProformas: EventEmitter<any> = new EventEmitter<any>();

  /** Viaje de la proforma */
  @Input('viaje') viaje: nuevoViaje;
  /** Indica si el modo actual es el de crear */
  public modoCrear: boolean = true;
  /** Datos de la proforma */
  public proforma: any = '';
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = false;

  /** Referencia al detalle de la proforma */
  @ViewChild('proformaWindow', { static: true }) proformaWindow: ElementRef;

  public proformas = [];

  public settings = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        // { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      clientName: {
        title: 'Cliente',
      },
      hoursAnchorage: {
        title: 'Hours Anchorage',
      },
      hoursDockage: {
        title: 'Hours Dockage',
      },
      total: {
        title: 'Total Proformado USD',
      },
      eta: {
        title: 'ETA',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      etb: {
        title: 'ETB',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      etc: {
        title: 'ETC',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      etd: {
        title: 'ETD',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
      createdAt: {
        title: 'Created At',
        valuePrepareFunction: (date: any) => {
          return moment.utc(date).format('DD-MM-YYYY HH:mm');
        },
      },
    },
  };

  public onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.proforma = event.data;
        this.onCrearEditarVerProforma();
        break;
    }
  }

  constructor(
    private mainService: MainService,
    private viajeDetalleService: ViajeDetalleService,
    private dialogService: NbDialogService,
    public router: Router
  ) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.viaje && changes.viaje.currentValue && this.viaje.recaladas) {
      this.viaje = changes.viaje.currentValue;
      this.cargando = true;
      this.confirmed = this.viaje.recaladas[0] && this.viaje.recaladas[0].prospectos.eta ? true : false;
      if (this.viaje.status == 'CANCELED') this.confirmed = false;

      this.initializeData();
    }

    this.getProforma();
  }

  /**
   * Inicializa los datos de la BD
   */
  initializeData() {
    this.cargando = true;
    this.proforma = '';
    this.getProforma();
  }

  public async getProforma() {
    try {
      if (this.viaje.recaladas && this.viaje.recaladas.length === 0) return;

      const proformas = await this.mainService
        .get(`api/proforma?activo=true&recaladaId=${this.viaje.recaladas[0]._id}`)
        .toPromise();

      if (proformas.length === 0) return;

      for (const proforma of proformas) {
        const client = await this.mainService.get(`api/empresa_sql?activo=true&EMP_ID=${proforma.client}`).toPromise();
        if (client.length === 0) continue;
        proforma.clientName = client[0].EMP_NOMBRE;
        proforma.total = this.getTotalProforma(proforma.serviciosProforma).toFixed(2);
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        const formattedValue = formatter.format(proforma.total);
        proforma.total = formattedValue;
      }

      this.proforma = proformas[0];
      this.proformas = proformas;
      this.modoCrear = false;
    } catch (err) {
    } finally {
      this.cargando = false;
    }
  }

  public getTotalProforma(services: []): number {
    return services.reduce((accumulator, service: any) => {
      return accumulator + service.valor;
    }, 0);
  }

  /**
   * Cambia internamente la proforma actual
   * @param proforma Nueva proforma actual
   */
  proformaCambiada(proforma) {
    this.proforma = proforma;
    this.modoCrear = false;

    this.getProforma();
    this.cambioEnProformas.emit();
  }

  /**
   * Inicializa la creación o edición de una proforma
   */
  onCrearEditarVerProforma() {
    this.openCrearProformaConNominacionDialog(this.proformaWindow);
  }

  public onCreateProforma() {
    this.modoCrear = true;
    this.openCrearProformaConNominacionDialog(this.proformaWindow);
  }

  /**
   * Abre el dialogo con el detalle de la proforma
   * @param dialog Referencia al dialogo del detalle
   */
  openCrearProformaConNominacionDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Borra la proforma de la BD
   */
  onDeleteProforma() {
    Swal.fire({
      title: '<strong>¿Deseas eliminar la proforma?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.mainService.delete('api/proforma/' + this.proforma._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'Proforma eliminada con éxito', 'success');
            this.proforma = '';
            this.modoCrear = false;
          }
        });
      } else {
        Swal.fire('Error', 'Problema eliminando la proforma', 'warning');
      }
    });
  }
}
