import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventDefault]'
})
export class PreventDefaultDirective {

  @HostListener("keydown.enter", ["$event"]) public onEnter(event: any){
    event.preventDefault();
  }

}
