import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import Swal from 'sweetalert2';
import { UtilsService } from './utils.service';
@Injectable({
  providedIn: 'root',
})
export class S3Service {
  constructor(private utilsService: UtilsService) {}

  private bucket: S3 = new S3({
    accessKeyId: 'AKIAUT7IXVID77RSYJ5F',
    secretAccessKey: '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',
    region: 'us-east-1',
  });

  getBucket() {
    return this.bucket;
  }

  /**
   * Genera un ID
   */
  generateId() {
    return this.utilsService.generateRandomId();
  }

  showErrorUploading() {
    Swal.fire({
      title: 'Error',
      text: 'Se generó un error al subir el archivo',
      type: 'error',
      showCancelButton: false,
      confirmButtonText: 'Continuar',
    });
  }

  /**
   * Muestra un mensaje de éxito para cuando se suba la imagen
   */
  showSuccessUploading() {
    Swal.fire({
      title: 'Éxito',
      text: 'Se subió la imagen exitosamente',
      type: 'success',
    });
  }

  getParamsForUpload(baseUrl, nombre, archivoSeleccionado) {
    return {
      Bucket: 'naves-recursos',
      ACL: 'public-read',
      Key: `${baseUrl}/${nombre}`,
      ContentType: archivoSeleccionado.type,
      Body: archivoSeleccionado,
    };
  }

  getOptionsForUpload() {
    return {
      // Part Size of 10mb
      partSize: 10 * 1024 * 1024,
      queueSize: 1,
    };
  }

  /**
   * Retorna la información que se necesita para utilizar s3 en subida de documentos.
   * @param baseUrl La url base a donde se subirá
   * @param archivoSeleccionado El archivo que se va a subir, de un input generalmente
   * @returns params y options necesarios para s3
   */
  getInfoForUpload(baseUrl: string, archivoSeleccionado: any) {
    const nombre = this.generateId();
    const params = this.getParamsForUpload(baseUrl, nombre, archivoSeleccionado);
    const options = this.getOptionsForUpload();
    return { nombre, params, options };
  }
}
