import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-captian-interview',
  templateUrl: './captian-interview.component.html',
  styleUrls: ['./captian-interview.component.scss'],
})
export class CaptianInterviewComponent implements OnInit {
  public titleTab = {
    dashboard: { title: 'Dashboard' },
    survies: { title: 'Survies' },
    questions: { title: 'Questions' },
  };

  constructor() {}

  ngOnInit() {}
}
