import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import { MainService } from '../../../services/main.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

@Component({
  selector: 'factor-arqueo',
  templateUrl: './factor-arqueo.component.html',
  styleUrls: ['./factor-arqueo.component.scss']
})
export class FactorArqueoComponent implements OnInit {
	/** Referencia de poder editar o solo ver */
	@Input() available: boolean; 
	/** Factores de arqueo traidos de la BD */
	public factoresArqueo: any = [];
	/** Factores de arqueo actualizador por el usuario */
	public factoresArqueoActualizado: any = [];
	/** Factor de arqueo que se va a agregar */
	public factorArqueoAAgegar: FormGroup;
	/** Indica si hay algo cargandose en el componente */
	public loading: boolean = false;
	
	/** Referencia al dialogo de editar factor de arqueo*/
	@ViewChild('editarFactorArqueo', {static: true}) editarFactorArqueo: ElementRef;
	/** Referencia al boton para cerrar el dialogo de editar factor de arqueo*/
	@ViewChild('closeButton', {static: true}) closeButton: ElementRef;
	
	constructor(private dialogService: NbDialogService, private mainService: MainService) { }


  ngOnInit() {
		this.initializeData();
	}

	/**
	 * Inicializa los datos traidos de la BD
	 */
	initializeData(){
		this.mainService.get(`api/factor_arqueo`).subscribe(res =>{   
			this.factoresArqueo = res;
			this.factoresArqueo = _.orderBy(res, ['rangoGRTMax'], ['asc']);

			this.factoresArqueo.forEach(element => {
				delete element.__v;
				delete element._id;
			});

			this.factoresArqueoActualizado = this.factoresArqueo.slice();

			this.initializeForm();
    });
	}

	/**
	 * Inicializa el formulario para agregar un factor de arqueo
	 */
	initializeForm(){
		const lengthFactorArqueoActualizado = this.factoresArqueoActualizado.length;
		
		this.factorArqueoAAgegar = new FormGroup({
      'factorArqueo' : new FormControl({value: '', disabled: false}, Validators.required),
			'rangoGRTMin' : new FormControl({value: lengthFactorArqueoActualizado == 0? 1: (this.factoresArqueoActualizado[lengthFactorArqueoActualizado-1].rangoGRTMax+1), disabled: false}, Validators.required),
			'rangoGRTMax' : new FormControl({value: '', disabled: false}, Validators.required)
    });
	}

	/**
	 * Maneja el evento de edicion de factores de arqueo
	 */
	onEditar() {
		this.openEditarDialog(this.editarFactorArqueo);
	}

	/**
	 * Abre el dialogo de edicion de factores de arqueo
	 */
	openEditarDialog(dialog) {
		this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

	/**
	 * Limpia los datos actuales del factor de arqueo actualizado para empezar uno nuevo
	 */
	onLimpiarDatos(){
		this.factoresArqueoActualizado = [];

		this.factorArqueoAAgegar.controls['factorArqueo'].setValue('');
		this.factorArqueoAAgegar.controls['rangoGRTMin'].setValue(1);
		this.factorArqueoAAgegar.controls['rangoGRTMax'].setValue('');
	}

	/**
	 * Verifica que los datos del factor de agregado sean correctos y lo agrega
	 */
	onAgregarFactorArqueo(){
		if(this.factorArqueoAAgegar.invalid){
			Swal.fire({
        title: 'No se puede agregar el factor de arqueo',
        text: 'No se han llenado todos los campos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
		} else if(this.factorArqueoAAgegar.get('rangoGRTMax').value <= this.factorArqueoAAgegar.get('rangoGRTMin').value){
			Swal.fire({
        title: 'No se puede agregar el factor de arqueo',
        text: 'El rango máximo es menor o igual al rango mínimo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
		} else {
			const formContent = this.factorArqueoAAgegar.value;
			const factorArqueo = JSON.parse(JSON.stringify(formContent));

			this.factoresArqueoActualizado.push(factorArqueo);
			
			this.factorArqueoAAgegar.controls['factorArqueo'].setValue('');
			const rangoMaxAgregado = this.factorArqueoAAgegar.get('rangoGRTMax').value;
			this.factorArqueoAAgegar.controls['rangoGRTMin'].setValue(rangoMaxAgregado + 1);
			this.factorArqueoAAgegar.controls['rangoGRTMax'].setValue('');
		}
	}

	/**
	 * Actualiza en la BD los datos de los factores de arqueo
	 */
	onActualizar(){
		if (this.factoresArqueoActualizado.length == 0){
			Swal.fire({
				title: 'No se pudo actualizar los factores de arqueo',
				text: 'Debe haber por lo menos un factor de arqueo',
				type: 'error',
				showCancelButton: false,
				confirmButtonText: 'Continuar',
			});
		} else {
			this.loading = true;
		
			//Manda un error cuando la colección no existe por lo que hay que crear un elemento antes para prevenir dicho error
			this.mainService.post('api/factor_arqueo', this.factoresArqueoActualizado[0]).subscribe(result => {
				
				this.mainService.get('api/factor_arqueo').subscribe(res => {
					const fa = res;

					fa.forEach(element => {
						this.mainService.delete('api/factor_arqueo/' + element._id).subscribe(res => {});
					})

					setTimeout(() => {this.loading = false}, 5000);

					this.factoresArqueoActualizado.forEach(element => {
							this.mainService.post('api/factor_arqueo', element).subscribe(result => {})
						});

						this.factoresArqueo = this.factoresArqueoActualizado.slice();
						
						Swal.fire({
							title: 'Se actualizó existosamente los factores de arqueo',
							type: 'success',
							showCancelButton: false,
							confirmButtonText: 'Continuar',
						}).then((result) => {
							if (result.value) {
								const closeButton = this.closeButton.nativeElement;
								closeButton.click();
							}
						});
				});
			})
		}
	}

	/**
	 * Maneja el cambio de un factor de arqueo
	 */
	onChangeValorFactorArqueo(index, value){
		this.factoresArqueoActualizado[index].factorArqueo = value;
	}

}
