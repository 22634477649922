import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { MainService } from '../../../services/main.service';
import { TimeService } from '../../../../services/time/time.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

@Component({
  selector: 'proformas-sin-nominacion',
  templateUrl: './proformas-sin-nominacion.component.html',
  styleUrls: ['./proformas-sin-nominacion.component.scss'],
})
export class ProformasSinNominacionComponent implements OnInit {
  /** Configuraciones de la tabla */
  settings: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'viewAndEdit', title: '<i class="nb-compose"></i>' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nombreBuque: {
        title: 'Buque',
      },
      nombrePuerto: {
        title: 'Puerto',
      },
      nombreTerminal: {
        title: 'Terminal',
      },
      nombreCliente: {
        title: 'Cliente',
      },
      createdAt: {
        title: 'Fecha de creación',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Referencia al dialogo del detall */
  dialogRef: any;
  /** Todasl las profrmas en la BD */
  public todasLasProformas: any = [];
  /** Proformas sin nominacion en la BD */
  public proformasSinNominacion: any = [];
  /** Indica si actualmente esta en modo crear proformas */
  public modoCrear: boolean = true;
  /** Proforma actualmente seleccionada */
  public proforma: any = '';
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = false;
  /** Buques en la BD */
  public buques: any = [];
  /** Terminales en la BD */
  public terminales: any = [];
  /** Puertos en la BD */
  public puertos: any = [];

  /** Referencia al detallde de la proforma */
  @Input('permisosUsuario') permisosUsuario: any;
  @ViewChild('proformaDetalle', { static: true }) proformaDetalle: ElementRef;

  constructor(private dialogService: NbDialogService, private mainService: MainService, private timeService: TimeService) {}

  ngOnInit() {
    this.initializeData();
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  initializeData() {
    this.cargando = true;
    this.proformasSinNominacion = [];

    this.mainService.get('api/proforma-no-nomination?activo=true').subscribe((res) => {
      this.todasLasProformas = res;

      if (this.todasLasProformas.length === 0) this.cargando = false;

      this.mainService.get('api/buque').subscribe((resul) => {
        this.buques = JSON.parse(JSON.stringify(resul));

        this.mainService.get('api/terminal').subscribe((resul) => {
          this.terminales = resul;

          this.mainService.get('api/puerto').subscribe((resul) => {
            this.puertos = resul;

            this.mainService.get('api/empresa_sql').subscribe((companies) => {
              this.todasLasProformas.forEach((element) => {
                if (!element.nv) {
                  const buqueAsociado = this.buques.find(({ BUQ_ID }) => BUQ_ID == element.vessel);
                  element.nombreBuque = buqueAsociado.BUQ_NOMBRE;
                  const puertoAsociado = this.puertos.find(({ PUE_ID }) => PUE_ID == element.port);
                  element.nombrePuerto = puertoAsociado.PUE_NOMBRE;
                  const armadorAsociado = this.terminales.find(({ TER_ID }) => TER_ID == element.terminal);
                  element.nombreTerminal = armadorAsociado.TER_NOMBRE;
                  element.nombreCliente = companies.find(({ EMP_ID }) => EMP_ID == element.client).EMP_NOMBRE;
                  this.proformasSinNominacion.push(element);
                }
              });

              this.proformasSinNominacion = _.orderBy(this.proformasSinNominacion, ['createdAt'], ['desc']);
              this.cargando = false;
            });
          });
        });
      });
    });
  }

  /**
   * Inicializa la creacion de una proforma
   */
  onCrearProforma() {
    if (this.permisosUsuario.proformas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.proforma = null;
      this.modoCrear = true;
      this.openProformaDetalleDialog(this.proformaDetalle);
    }
  }

  /**
   * Abre el dialogo del detalle de una proforma
   */
  openProformaDetalleDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Evento con la accion a ejecutar
   */
  onCustom(event) {
    switch (event.action) {
      case 'delete':
        this.onDelete(event);
        break;
      case 'viewAndEdit':
        this.viewAndEdit(event);
        break;
    }
  }

  /**
   * Elimina una proforma de la BD
   */
  onDelete(event) {
    if (this.permisosUsuario.proformas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar la proforma?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.mainService.delete(`api/proforma-sin-nominacion/${event.data._id}`).subscribe(
            (res) => {
              if (res) {
                if (res.message) {
                  Swal.fire({
                    title: '¡Error!',
                    text: res.message,
                    type: 'error',
                  });
                  return;
                }
                Swal.fire('Éxito', 'Proforma eliminada con éxito', 'success');
                this.proformasSinNominacion = [];
                this.initializeData();
              }
            },
            (err) => {
              console.error(err);
              Swal.fire('Error', err.error.message, 'error');
            }
          );
        }
      });
    }
  }

  /**
   * Inicializa la visualizacion o edicion de una proforma
   * @param event Evento con los datos de la proforma
   */
  viewAndEdit(event) {
    if (this.permisosUsuario.proformas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.proforma = event.data;
      this.modoCrear = false;
      this.openProformaDetalleDialog(this.proformaDetalle);
    }
  }
}
