import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';

import { MainService } from '../../public/services/main.service';
import { NbDialogService } from '@nebular/theme';
import { AlertService } from '../../services/alertService/alert-service.service';
import * as moment from 'moment';
import _ from 'lodash';
import { RolService } from '../../services/rol/rol.service';
import Chart from 'chart.js';
import { ActivatedRoute } from '@angular/router';

enum Form {
  SURVY = 'survy',
  QUESTION = 'question',
}

enum FormMode {
  GET = 'get',
  EDIT = 'edit',
  FILL = 'fill',
  CREATE = 'create',
  DELETE = 'delete',
}

type FormCollection = {
  [key in Form]: {
    data: string;
    formMode: {
      [key in FormMode]?: {
        endpoint: string;
      };
    };
    dependencies?: {
      [key: string]: {
        endpoint: string;
        value: string;
        key: string;
        data: [];
        formatter: any;
      };
    };
  };
};

interface FormSelected {
  form: Form;
  formMode: FormMode;
}

type DataCollection = {
  [key in Form]: [];
};

@Component({
  selector: 'app-captian-interview',
  templateUrl: './captian-interview.component.html',
  styleUrls: ['./captian-interview.component.scss'],
})
export class CaptianInterviewComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialogRef: ElementRef;

  public settingsSurvies = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    fill: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-edit"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: FormMode.EDIT, title: '<i class="nb-compose"></i>' },
        { name: FormMode.DELETE, title: '<i class="nb-trash"></i>' },
        { name: FormMode.FILL, title: '<i class="nb-edit"></i>' },
      ],
    },
    columns: {
      nvFormatted: {
        title: 'NV',
      },
      stateFormatted: {
        title: 'Estado',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
    },
  };

  public settingsQuestions = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: FormMode.EDIT, title: '<i class="nb-compose"></i>' },
        { name: FormMode.DELETE, title: '<i class="nb-trash"></i>' },
      ],
    },
    columns: {
      question: {
        title: 'Pregunta',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
    },
  };

  public dialog;
  public dialogModalLoading = false;

  public Form = Form;
  public FormMode = FormMode;

  private selectedRecord;

  private messagePopUp = {
    [Form.QUESTION]: {
      [FormMode.CREATE]: 'Pregunta creada',
      [FormMode.DELETE]: 'Pregunta eliminada',
      [FormMode.EDIT]: 'Pregunta actualizada',
      choiceDelete: '¿Desea eliminar esta pregunta?',
      missingFields: 'Faltan campos por llenar',
    },
    [Form.SURVY]: {
      [FormMode.CREATE]: 'Encuenta creada',
      [FormMode.DELETE]: 'Encuesta eliminada',
      [FormMode.EDIT]: 'Encuesta actualizada',
      [FormMode.FILL]: 'Encuesta diligenciada',
      choiceDelete: '¿Desea eliminar esta encuesta?',
      missingFields: 'Faltan campos por llenar',
    },
  };

  public dataCollection: DataCollection = {
    [Form.SURVY]: [],
    [Form.QUESTION]: [],
  };

  private dataForm = {
    [Form.SURVY]: {
      nv: undefined,
      questions: [
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
      ],
      description: undefined,
    },
    [Form.QUESTION]: {
      question: undefined,
      default: 0,
    },
  };

  private formSelected: FormSelected = {
    form: undefined,
    formMode: undefined,
  };

  public formCollection: FormCollection = {
    [Form.SURVY]: {
      data: 'survies',
      formMode: {
        [FormMode.GET]: {
          endpoint: 'captian-interview',
        },
        [FormMode.CREATE]: {
          endpoint: 'captian-interview',
        },
        [FormMode.EDIT]: {
          endpoint: 'captian-interview',
        },
        [FormMode.DELETE]: {
          endpoint: 'captian-interview',
        },
        [FormMode.FILL]: {
          endpoint: 'captian-interview-fullfill',
        },
      },
      dependencies: {
        nv: {
          endpoint: 'nuevo-viaje?activo=true',
          value: 'nv',
          key: '_id',
          data: [],
          formatter: this.formatDataForInputSearch,
        },
        questions: {
          endpoint: 'captian-question?active=true',
          value: 'question',
          key: '_id',
          data: [],
          formatter: undefined,
        },
      },
    },
    [Form.QUESTION]: {
      data: 'questions',
      formMode: {
        [FormMode.GET]: {
          endpoint: 'captian-question',
        },
        [FormMode.CREATE]: {
          endpoint: 'captian-question',
        },
        [FormMode.EDIT]: {
          endpoint: 'captian-question',
        },
        [FormMode.DELETE]: {
          endpoint: 'captian-question',
        },
      },
    },
  };

  constructor(
    private routeService: ActivatedRoute,
    private mainService: MainService,
    private alertService: AlertService,
    private rolService: RolService,
    private dialogService: NbDialogService,
    private location: Location
  ) {}

  ngOnInit() {
    this.rolChecking();
    this.listenQueries();
    this.getBaseDependencies();
  }

  public specifiedTabSurvy = false;

  private listenQueries() {
    this.routeService.queryParams.subscribe((res) => {
      if (res.survy) {
        this.setDefaultSurvy(res.survy);
      }
    });
  }

  private removeQueries() {
    const urlWithoutParams = this.location.path().split('?')[0];
    this.location.replaceState(urlWithoutParams);
  }

  private async setDefaultSurvy(idSurvy: string) {
    try {
      this.specifiedTabSurvy = true;
      const res = await this.mainService.get(`api/captian-interview/${idSurvy}`).toPromise();

      const customEvent = {
        data: res,
        action: 'edit',
      };

      this.selectForm(Form.SURVY, FormMode.EDIT);
      this.onCustome(customEvent);
    } catch (err) {}
  }

  public busyQuestions = {};
  public setBusyQuestions() {
    this.busyQuestions = {};
    const questions = this.dataForm[Form.SURVY].questions;

    questions.forEach((question: any) => {
      if (!question.idQuestion) return;
      this.busyQuestions[question.idQuestion] = true;
    });
  }

  public changeQuestion(event, index) {
    const id = event;

    const foundQuestion = this.formCollection[Form.SURVY].dependencies.questions.data.find(
      (question: any) => question._id === id
    ) as any;

    this.dataForm[Form.SURVY].questions[index].question = foundQuestion.question;
    this.dataForm[Form.SURVY].questions[index].idQuestion = foundQuestion._id;

    this.setBusyQuestions();
  }

  public titleTab = {
    dashboard: { title: 'Dashboard', form: undefined, formMode: undefined },
    survies: { title: 'Survies', form: Form.SURVY, formMode: FormMode.GET },
    questions: { title: 'Questions', form: Form.QUESTION, formMode: FormMode.GET },
  };

  private async rolChecking() {
    await this.rolService.checkRolAccess('survy');
    this.rolService.checkByPermissions('accces');
  }

  public onChangeTab(event: { tabTitle: string }) {
    const tabKey = event.tabTitle.toLowerCase();
    const infoRequest = this.titleTab[tabKey];

    if (tabKey === 'dashboard') {
      this.resetInfoDashBoard();
      this.getInfoDashBoard();
    }

    if (!infoRequest.form) return;

    this.formSelected.form = infoRequest.form;
    this.formSelected.formMode = infoRequest.formMode;

    this.getData();
  }

  private async getBaseDependencies() {
    this.getVoyages();
    this.getQuestions();
    this.getInfoDashBoard();
  }

  private async getVoyages() {
    const endpoint = this.formCollection.survy.dependencies.nv.endpoint;
    const formatter = this.formCollection.survy.dependencies.nv.formatter;

    try {
      const data = await this.mainService.get(`api/${endpoint}`).toPromise();
      this.formCollection.survy.dependencies.nv.data = formatter(data, '_id', ['nv', 'vessel.nombre', 'port.nombre']);
    } catch (err) {}
  }

  private async getQuestions() {
    const endpoint = this.formCollection.survy.dependencies.questions;

    try {
      const data = await this.mainService.get(`api/${endpoint}`).toPromise();
      this.formCollection.survy.dependencies.questions.data = data;
    } catch (err) {}
  }

  // dashboard

  public infToShowDashBoard = {
    select: {
      port: [],
      boarding: [],
    },
    selected: {
      port: undefined,
      boarding: undefined,
    },
  };

  private infToShowDashBoardEmpty = _.cloneDeep(this.infToShowDashBoard);

  public infoDashBoard = {
    totalPerElement: {
      port: [],
      boarding: [],
    },
    totalEachElement: {
      port: {
        amount: 0,
        average: 0,
      },
      boarding: {
        amount: 0,
        average: 0,
      },
    },
    totalAveragePerElement: {
      port: {},
      boarding: {},
    },
  };

  private infoDashBoardEmpty = _.cloneDeep(this.infoDashBoard);

  @ViewChild('graphicPorts', { static: false }) graphicPortsElement: ElementRef;
  @ViewChild('graphicBoarding', { static: false }) graphicBoardingsElement: ElementRef;
  @ViewChild('graphicPortQuestion', { static: false }) graphicQuestionport: ElementRef;
  @ViewChild('graphicBoardingQuestion', { static: false }) graphicQuestionboarding: ElementRef;

  private async getInfoDashBoard() {
    try {
      const data = await this.mainService.get(`api/captian-interview-dashboard`).toPromise();
      this.infoDashBoard = data;
    } catch (err) {
    } finally {
      this.buildGraphics();
    }
  }

  private buildGraphics() {
    this.graphicByElement();
    this.setGraphicSelect();
  }

  private resetInfoDashBoard() {
    this.infToShowDashBoard = _.cloneDeep(this.infToShowDashBoardEmpty);
    this.infoDashBoard = _.cloneDeep(this.infoDashBoardEmpty);
  }

  private setGraphicSelect() {
    const select = ['port', 'boarding'];

    select.forEach((option) => {
      const collection = Object.values(this.infoDashBoard.totalAveragePerElement[option]);
      if (collection.length === 0) this.setGraphicQuestion(option);

      collection.forEach((item: any) => {
        const value = item[0].key;
        const content = item[0].element;

        this.infToShowDashBoard.select[option].push({
          value,
          content,
        });

        this.infToShowDashBoard.selected[option] = value;
        this.setGraphicQuestion(option, value);
      });
    });
  }

  private graphicByElementIns = {
    port: {
      path: 'port',
      element: 'graphicPortsElement',
      title: 'Promedio por puerto',
    },
    boarding: {
      path: 'boarding',
      element: 'graphicBoardingsElement',
      title: 'Promedio por boarding agent',
    },
  };

  private graphicByElement() {
    Object.values(this.graphicByElementIns).forEach((element) => {
      const labels = [];
      const data = [];

      this.infoDashBoard.totalPerElement[element.path].forEach((item) => {
        labels.push(item.label);
        data.push(item.average);
      });

      const cxt = this[element.element].nativeElement.getContext('2d');
      new Chart(cxt, {
        type: 'horizontalBar',
        data: {
          labels: labels,
          datasets: [
            {
              label: element.title,
              data: data,
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (value) {
                    if (!value) return;
                    if (value.length < 5) return value;
                    return value.substr(0, 6) + '...';
                  },
                },
              },
            ],
          },
          onResize: (canva, _size) => {
            canva.canvas.style.width = '100%';
            canva.canvas.style.height = '400px';
          },
        },
      });
    });
  }

  public setGraphicQuestion(type?: string, id?: string) {
    const labels = [];
    const data = [];

    const collectionType = this.infoDashBoard.totalAveragePerElement[type];
    const collection = collectionType && collectionType[id];

    if (collection) {
      collection.forEach((item) => {
        labels.push(item.label);
        data.push(item.average);
      });
    }

    const cxt = this[`graphicQuestion${type}`].nativeElement.getContext('2d');
    new Chart(cxt, {
      type: 'horizontalBar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Preguntas',
            data: data,
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value) {
                  if (value.length < 5) return value;
                  return value.substr(0, 6) + '...';
                },
              },
            },
          ],
        },
        onResize: (canva, _size) => {
          canva.canvas.style.width = '100%';
          canva.canvas.style.height = '400px';
        },
      },
    });
  }

  private requestCollection = {
    [FormMode.CREATE]: this.postData,
    [FormMode.EDIT]: this.patchData,
    [FormMode.FILL]: this.patchData,
    [FormMode.DELETE]: this.deleteData,
  };

  // seting request type based on selected form and formMode

  public async setRequest() {
    if (!this.rolService.checkByPermissions('writing')) return;

    // not dynamic, change it
    if (this.selectedRecord && this.selectedRecord.state && this.selectedRecord.state === 'fullfilled') {
      this.alertService.simpleAlertWarning('No puedes editar una encuesta diligenciada');
      return;
    }

    const request = this.requestCollection[this.formSelected.formMode].bind(this);
    if (!request) return;

    await request();
    await this.getData();
  }

  // form crud

  private async getData() {
    const endpoint = this.formCollection[this.formSelected.form].formMode[this.formSelected.formMode].endpoint;

    try {
      const data = await this.mainService.get(`api/${endpoint}?active=true`).toPromise();
      this.dataCollection[this.formSelected.form] = data;
      this.formatData();
    } catch (err) {
      // this.dataCollection[this.formSelected.form] = [];
    }
  }

  private async postData() {
    const endpoint = this.formCollection[this.formSelected.form].formMode[this.formSelected.formMode].endpoint;
    const dataForm = this.dataForm[this.formSelected.form];

    try {
      await this.mainService.post(`api/${endpoint}`, dataForm).toPromise();

      if (this.formSelected.form === Form.QUESTION) {
        this.getQuestions();
      } else {
        await this.getData();
      }

      this.alertService.simpleAlertConfirm(this.messagePopUp[this.formSelected.form][FormMode.CREATE]);

      this.closeDialog();
    } catch (err) {
      if (err.error && err.error.message) {
        this.alertService.simpleAlertError(err.error.message);
        return;
      }

      this.alertService.simpleAlertError(this.messagePopUp[this.formSelected.form].missingFields);
    }
  }

  private async patchData() {
    const id = this.selectedRecord._id;
    const endpoint = this.formCollection[this.formSelected.form].formMode[this.formSelected.formMode].endpoint;
    let dataForm = _.cloneDeep(this.dataForm[this.formSelected.form] as any);

    if (this.formSelected.formMode === FormMode.FILL) {
      const cloneData = _.cloneDeep(dataForm);
      cloneData.questions.forEach((question) => {
        delete question.question;
      });

      dataForm = {
        questions: cloneData.questions,
      };
    }

    try {
      await this.mainService.patch(`api/${endpoint}/${id}`, dataForm).toPromise();
      this.closeDialog();
      this.formSelected.formMode = FormMode.GET;
      await this.getData();
      this.alertService.simpleAlertConfirm(this.messagePopUp[this.formSelected.form][FormMode.EDIT]);
    } catch (err) {
      this.alertService.simpleAlertError(this.messagePopUp[this.formSelected.form].missingFields);
    }
  }

  private async deleteData() {
    const id = this.selectedRecord._id;
    const endpoint = this.formCollection[this.formSelected.form].formMode[this.formSelected.formMode].endpoint;

    try {
      await this.mainService.delete(`api/${endpoint}/${id}`).toPromise();
      this.getData();
      this.alertService.simpleAlertConfirm(this.messagePopUp[this.formSelected.form][FormMode.DELETE]);
    } catch (err) {}
  }

  public async selectForm(form: Form, formMode: FormMode) {
    this.formSelected.form = form;
    this.formSelected.formMode = formMode;

    try {
      this.dialogModalLoading = true;

      if (this.formSelected.form === Form.QUESTION) {
        this.setMapperDefault();
      }

      if (!this.formCollection[form].dependencies) return;
      await this.getDependencies();

      if (this.formSelected.form === Form.SURVY) {
        this.setBusyQuestions();
      }
    } catch (err) {
    } finally {
      this.dialogModalLoading = false;
    }
  }

  // dialog actions

  public openDialog() {
    this.dialog = this.dialogService.open(this.dialogRef as any);
    this.dialog.onClose.subscribe((e) => {
      this.removeQueries();
      this.resetData();
    });
  }

  public closeDialog() {
    if (!this.rolService.checkByPermissions('writing')) return;
    this.dialog.close();
  }

  // smart table actions

  private customeSelect = {
    [FormMode.EDIT]: this.editElement,
    [FormMode.DELETE]: this.deleteElement,
    [FormMode.FILL]: this.fillElement,
  };

  public onCustome(event) {
    // if (!this.rolService.checkByPermissions('writing')) return;

    this.formSelected.formMode = event.action;
    this.selectedRecord = event.data;

    if (!this.customeSelect[this.formSelected.formMode]) return;
    this.customeSelect[this.formSelected.formMode].bind(this)();
  }

  private editElement() {
    Object.keys(this.dataForm[this.formSelected.form]).forEach((key) => {
      if (Array.isArray(this.selectedRecord[key])) {
        this.dataForm[this.formSelected.form][key] = _.map(this.selectedRecord[key], (obj) =>
          _.pick(obj, ['question', '_id', 'idQuestion'])
        ); // this is not dynamic, change it
        return;
      }

      if (key === 'nv') {
        this.dataForm[this.formSelected.form][key] = this.selectedRecord[key]._id;
        return;
      }

      this.dataForm[this.formSelected.form][key] = this.selectedRecord[key];
    });

    this.openDialog();
  }

  private fillElement() {
    Object.keys(this.dataForm[this.formSelected.form]).forEach((key) => {
      if (Array.isArray(this.selectedRecord[key])) {
        this.dataForm[this.formSelected.form][key] = _.map(this.selectedRecord[key], (obj) =>
          _.pick(obj, ['question', '_id', 'answer'])
        ); // this is not dynamic, change it
        return;
      }
      this.dataForm[this.formSelected.form][key] = this.selectedRecord[key];
    });

    this.openDialog();
  }

  private async deleteElement() {
    try {
      const choice = await this.alertService.optionsAlertWarning(
        this.messagePopUp[this.formSelected.form].choiceDelete
      );
      if (choice.dismiss) return;
      this.deleteData();
    } catch (err) {}
  }

  // default data for solving reference problem

  private templateDataFormDefault = {
    [Form.QUESTION]: {
      question: undefined,
      default: undefined,
    },
    [Form.SURVY]: {
      nv: undefined,
      questions: [
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
        { question: undefined, idQuestion: undefined },
      ],
      description: undefined,
    },
  };

  // handler data

  private resetData() {
    this.dataForm[this.formSelected.form] = _.cloneDeep(this.templateDataFormDefault[this.formSelected.form]);
  }

  private formatData() {
    const states = {
      fullfilled: 'Diligenciado',
      created: 'Creado',
    };

    this.dataCollection[this.formSelected.form].forEach((data: any) => {
      data.createdAt = moment(data.createdAt).format('DD/MM/YYYY HH:mm');
      if (data.nv) data.nvFormatted = data.nv.nv;
      if (data.state) data.stateFormatted = states[data.state];
    });
  }

  // Dependencies

  private async getDependencies() {
    const dependencies = this.formCollection[this.formSelected.form].dependencies;
    const dependencyKeys = Object.keys(dependencies);

    for (const dependencyKey of dependencyKeys) {
      await this.getDataDependency(dependencyKey);
    }
  }

  private async getDataDependency(dependencyKey) {
    const dependency = this.formCollection[this.formSelected.form].dependencies[dependencyKey];

    try {
      let data = await this.mainService.get(`api/${dependency.endpoint}`).toPromise();

      if (dependency.formatter) {
        data = dependency.formatter.bind(this)(data, '_id', ['nv', 'vessel.nombre', 'port.nombre']);
      }

      dependency.data = data;

      if (this.formSelected.form === Form.SURVY && this.formSelected.formMode === FormMode.CREATE) {
        this.defaultQuestionsSurvy();
      }
    } catch (err) {}
  }

  // defaultValues

  private defaultQuestionsSurvy() {
    const questions = this.formCollection[this.formSelected.form].dependencies.questions.data;
    questions.forEach((question: any) => {
      if (!question.default) return;
      this.dataForm[Form.SURVY].questions[question.default - 1].question = question.question;
      this.dataForm[Form.SURVY].questions[question.default - 1].idQuestion = question._id;
    });
  }

  // checks used default

  public mapperDefault = {};
  private setMapperDefault() {
    this.mapperDefault = {};

    this.dataCollection[Form.QUESTION].forEach((question: any) => {
      if (question.default == 0) {
        return;
      }

      this.mapperDefault[question.default] = true;
    });
  }

  //...

  setInputSearchData(data: string, key) {
    if (data === undefined) data = null;
    this.dataForm[this.formSelected.form][key] = data;
  }

  formatDataForInputSearch(data, key, attributes) {
    const dataFormatted = [];

    data.forEach((item) => {
      let value = undefined;

      attributes.forEach((attribute) => {
        if (!value) {
          value = _.get(item, attribute);
          return;
        }

        value = `${value} - ${_.get(item, attribute)}`;
      });

      dataFormatted.push({
        key: item[key],
        value,
      });
    });

    return dataFormatted;
  }

  public validateNumber(input, object) {
    input.target.value = input.target.value.replace(/\D/g, '');
    input.target.value = input.target.value[0];

    const value = parseInt(input.target.value, 10);
    input.target.value = value;
    if (isNaN(value) || value < 1 || value > 5) {
      input.target.value = '';
    }

    object.answer = input.target.value;
  }
}
