import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { TimeService } from '../../services/time/time.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
/* Servicios */
import { MainService } from '../services/main.service';

type AOA = any[][];

@Component({
  selector: 'app-medida',
  templateUrl: './medida.component.html',
  styleUrls: ['./medida.component.scss'],
})
export class MedidaComponent implements OnInit {
  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      MED_ID: {
        title: 'ID',
        width: '120px',
      },
      MED_NOMBRE: {
        title: 'Nombre',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };

  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  public medida: any = {};
  public dataMedidas: any = [];
  public medidaExistente: any = {};

  public datos: any = [];
  public enviado: boolean = false;

  public data: any;
  public source: LocalDataSource;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private mainService: MainService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.medidas === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /** Para modal **/
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
    }
  }

  onEditar(dialog, event) {
    if (this.permisosUsuario.medidas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.medida = {};
    this.medida = Object.assign({}, event.data);
    this.dialogService
      .open(dialog, { context: 'this is some additional data passed to dialog' })
      .onClose.subscribe(() => {
        this.medida = {};
      });
  }

  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  crearMedida() {
    let ultimo = this.data[this.data.length - 1];
    this.medida.MED_ID = ultimo.MED_ID + 1;
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/

  public crear() {
    this.enviado = false;
    if (
      this.medida.MED_NOMBRE != '' &&
      this.medida.MED_NOMBRE != undefined &&
      this.medida.MED_ID != undefined &&
      this.medida.MED_ID != ''
    ) {
      let ultimo = this.data[this.data.length - 1];
      this.medida.MED_ID = ultimo.MED_ID + 1;
      this.enviado = false;
      this.mainService.post('api/medida', this.medida).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se agregó el medida exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          Swal.fire('Error', 'No se pudo agregar el medida, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar el medida, verifique la información', 'warning');
    }
  }

  onActualizar() {
    if (
      this.medida.MED_NOMBRE != '' &&
      this.medida.MED_NOMBRE != undefined &&
      this.medida.MED_ID != undefined &&
      this.medida.MED_ID != ''
    ) {
      let data = this.medida;
      this.mainService.put('api/medida/' + data._id, data).subscribe(
        (result) => {
          if (result) {
            Swal.fire('Éxito', 'Se actualizó el medida exitosamente', 'success');
            const element: HTMLElement = document.getElementById('btn-close');
            element.click();
            this.onCargar();
            this.medida = {};
          } else {
            Swal.fire('Error', 'No se pudo actualizar el medida, verifique la información', 'warning');
          }
        },
        (err) => {
          console.error(err);
          Swal.fire('Error', err.error.message, 'error');
        }
      );
    } else {
      Swal.fire('Error', 'No se pudo actualizar el medida, verifique la información', 'warning');
    }
  }

  onCargar() {
    let x = this;
    this.data = [];
    this.mainService.get('api/medida?activo=true').subscribe((res) => {
      x.data = res;
      this.dataMedidas = JSON.parse(JSON.stringify(res));
      x.data.forEach((element) => {
        element.MED_ID = Number(element.MED_ID);
      });
      this.data = _.orderBy(this.data, ['MED_ID'], ['asc']);
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.medidas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el medida?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let medidaEliminar = event.data;
        this.spinerGuardar = true;
        this.mainService.delete(`api/medida/${medidaEliminar._id}?medidaId=${medidaEliminar.MED_ID}`).subscribe(
          (res) => {
            if (res) {
              this.spinerGuardar = false;
              if (res.message) {
                Swal.fire({
                  title: '¡Error!',
                  text: res.message,
                  type: 'error',
                });
                return;
              }
              Swal.fire('Éxito', 'medida eliminado con éxito', 'success');
              this.onCargar();
            }
          },
          (err) => {
            console.error(err);
            Swal.fire('Error', err.error.message, 'error');
          }
        );
      }
    });
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.datos = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

      for (let i = 1; i < this.datos.length; i++) {
        /* let x = {}; */
        let excel = {
          MED_ID: this.datos[i][0],
          MED_NOMBRE: this.datos[i][1],
        };
        this.medida = {};
        this.medida = this.getMedidaByID(excel.MED_ID);
        if (excel.MED_ID > 1) {
          if (this.medida == null) {
            this.mainService.post('api/medida', excel).subscribe((res) => {});
          } else {
            this.mainService.put('api/medida/' + this.medida._id, excel).subscribe((res) => {
              console.log('ACTUALIZADO');
            });
          }
        }
      }
      this.onCargar();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  public getMedidaByID(evt) {
    let x = _.find(this.dataMedidas, { MED_ID: evt });
    if (x) return x;
    else return null;
  }

  abrirExcel() {
    const element: HTMLElement = document.getElementById('archivo');
    element.click();
  }
}
