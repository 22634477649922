import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-empresas',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
})
export class AgentsComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  /*****/

  datosEdit = {
    _id: '',
    razonSocial: '',
    nit: '',
    telefonoContacto: '',
    emailContacto: '',
    nombreContacto: '',
    notas: '',
    tipo: '',
  };

  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      razonSocial: {
        title: 'Nombre',
      },
      nit: {
        title: 'NIT',
      },
      nombreContacto: {
        title: 'Contacto',
      },
      tipo: {
        title: 'Tipo',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
      notas: {
        title: 'Notas',
      },
    },
  };

  datos: any;
  enviado: boolean = false;
  razonSocial: any;
  nit: any;
  telefonoContacto: any;
  emailContacto: any;
  nombreContacto: any;
  notas: any;
  tipo: any;
  public user: any;
  public data: any;
  public source: LocalDataSource;
  /** Indica si algo se esta cargando en el modulo */
  public cargando: boolean = true;

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    public router: Router,
    private dialogService: NbDialogService,
    private rutaActiva: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.data = [];
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.agents === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /** Para modal **/
  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  onEditar(dialog, event) {
    if (this.permisosUsuario.agents !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.enviado = false;
    this.datosEdit._id = event.data._id;
    this.datosEdit.razonSocial = event.data.razonSocial;
    this.datosEdit.nit = event.data.nit;
    this.datosEdit.telefonoContacto = event.data.telefonoContacto;
    this.datosEdit.emailContacto = event.data.emailContacto;
    this.datosEdit.nombreContacto = event.data.nombreContacto;
    this.datosEdit.notas = event.data.notas;
    this.datosEdit.tipo = event.data.tipo;

    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  openDialogEdit(edit) {
    this.dialogService.open(edit, { context: 'this is some additional data passed to dialog' });
  }

  crearAgent() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.enviado = true;
    this.cargando = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.cargando = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  /** CRUD **/

  public crear() {
    this.enviado = false;
    if (
      this.razonSocial &&
      this.nit &&
      this.telefonoContacto &&
      _.includes(this.emailContacto, '@') &&
      this.nombreContacto &&
      this.notas &&
      this.tipo
    ) {
      this.enviado = false;
      let empresa = {
        razonSocial: this.razonSocial,
        nit: this.nit,
        telefonoContacto: this.telefonoContacto,
        emailContacto: this.emailContacto,
        nombreContacto: this.nombreContacto,
        notas: this.notas,
        tipo: this.tipo,
      };

      this.mainService.post('api/empresa', empresa).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se agregó la empresa exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          this.enviado = true;
          Swal.fire('Error', 'No se pudo agregar la empresa, verifique la información', 'warning');
        }
      });
    } else {
      this.enviado = true;
      Swal.fire('Error', 'No se pudo agregar la empresa, verifique la información', 'warning');
    }
  }

  onVer(event) {
    this.router.navigate(['home/agent-ver/' + event.data._id]);
  }

  onActualizar() {
    this.enviado = false;
    if (
      this.datosEdit.razonSocial &&
      this.datosEdit.nit &&
      this.datosEdit.telefonoContacto &&
      _.includes(this.datosEdit.emailContacto, '@') &&
      this.datosEdit.nombreContacto &&
      this.datosEdit.notas &&
      this.datosEdit.tipo
    ) {
      this.enviado = false;
      let empresa = {
        _id: this.datosEdit._id,
        razonSocial: this.datosEdit.razonSocial,
        nit: this.datosEdit.nit,
        telefonoContacto: this.datosEdit.telefonoContacto,
        emailContacto: this.datosEdit.emailContacto,
        nombreContacto: this.datosEdit.nombreContacto,
        notas: this.datosEdit.notas,
        tipo: this.datosEdit.tipo,
      };
      let data = empresa;
      this.mainService.put('api/empresa/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la empresa exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargar();
        } else {
          this.enviado = true;
          Swal.fire('Error', 'No se pudo actualizar la empresa, verifique la información', 'warning');
        }
      });
    } else {
      this.enviado = true;
      Swal.fire('Error', 'No se pudo actualizar la empresa, verifique la información', 'warning');
    }
  }

  onCargar() {
    this.cargando = true;

    let x = this;
    this.data = [];
    this.mainService.get('api/empresa').subscribe((res) => {
      this.cargando = false;

      x.data = res;
      x.data.forEach((element) => {
        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');
      });
      this.data = _.orderBy(this.data, ['createdAt'], ['desc']);
      this.data = new LocalDataSource(this.data);
    });
  }

  onEliminar(event) {
    if (this.permisosUsuario.agents !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar la empresa?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let user = event.data;
        this.cargando = true;
        this.mainService.delete('api/empresa/' + user._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'Empresa eliminada con éxito', 'success');
            this.onCargar();
          }
        });
        this.cargando = false;
      } else {
        Swal.fire('Error', 'Problema eliminando la empresa', 'warning');
      }
    });
  }

  onCerrarDialogo() {
    this.razonSocial = '';
    this.nit = '';
    this.telefonoContacto = '';
    this.emailContacto = '';
    this.nombreContacto = '';
    this.notas = '';
    this.tipo = '';
  }
}
