import { Component, OnInit } from '@angular/core';

import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../../services/auth.service';
import { MainService } from '../../services/main.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-noticia-ver',
  templateUrl: './noticia-ver.component.html',
  styleUrls: ['./noticia-ver.component.scss'],
})
export class NoticiaVerComponent implements OnInit {
  id: any;
  public noticia: any = {};
  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    this.onCargar();
  }

  onCargar() {
    this.noticia = {};
    this.mainService.get('api/noticia/' + this.id).subscribe((res) => {
      this.noticia = res;
    });
  }

  back() {
    this._location.back();
  }
}
