import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.scss']
})
export class ConfiguracionesComponent implements OnInit {

  /*  Para el modal */
  @ViewChild('dialog', {static: true}) dialog: ElementRef;
  spinerEdit = false;
  /*****/
  settings = {
    hideSubHeader: true,
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        {name: 'edit', title: '<i class="nb-compose"></i> ',},
        {name: 'delete', title: '<i class="nb-trash"></i> ',},
        {name: 'detalle', title: '<i class="nb-search"></i>'}
      ],
    },
    columns: {
      cmp1: {title: 'Nombre configuración'},
      cmp2: {title: 'Valor'},
    }
  };

  datos: any[];

  public source: LocalDataSource;
  constructor(private toastrService: NbToastrService,
              public router: Router,
              private dialogService: NbDialogService,
              private rutaActiva: ActivatedRoute) {
    let arreglo = [];
    for (let i = 1; i <= 3; i++){
      let aux = { cmp1: 'nombre '+i+'1', cmp2: 'Valor '+i+'2'};
      arreglo.push(aux);
    }
    this.datos = arreglo;
    this.source = new LocalDataSource(this.datos);
  }

  ngOnInit() {
  }

  /** Para modal **/

  crear() {
    this.openDialogSave(this.dialog);
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  accion() {
    this.spinerEdit = true;
    const TIME_IN_MS = 3000;
    setTimeout(() => {
      this.spinerEdit = false;
      this.showToast('top-right', 'success', 'Éxito!', 'Se ejecuto éxito!');
      const element: HTMLElement = document.getElementById('btn-close');
      element.click();
    }, TIME_IN_MS);
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(
        mensaje,
        titulo,
        { position, status });
  }
}
