import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { tokenNotExpired } from 'angular2-jwt';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
  public authToken: any;
  public user: any;
  public headers: Headers;
  // public baseUrl: string = "https://naves-backend.herokuapp.com/";
  // public baseUrl: string = "https://develop_naves.imagineapps.co/";
  public baseUrl: String = environment.baseUrl;

  constructor(public http: Http) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
  }

  public authenticateUser(user) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    return this.http
      .post(this.baseUrl + 'users/authenticate', JSON.stringify(user), { headers: this.headers })
      .pipe(map((res) => res.json()));
  }

  public registerUser(user) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    return this.http
      .post(this.baseUrl + 'users/register', JSON.stringify(user), { headers: this.headers })
      .pipe(map((res) => res.json()));
  }

  public forgot(email) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    const obj = { email };
    return this.http
      .post(this.baseUrl + 'users/forgot', JSON.stringify(obj), { headers: this.headers })
      .pipe(map((res) => res.json()));
  }

  public reset(newPassword, token) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    const obj = { token, newPassword };
    return this.http
      .post(this.baseUrl + 'users/reset', JSON.stringify(obj), { headers: this.headers })
      .pipe(map((res) => res.json()));
  }

  public change(email, password, passwordNew) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    const obj = { email, password, passwordNew };
    return this.http
      .post(this.baseUrl + 'users/change', JSON.stringify(obj), { headers: this.headers })
      .pipe(map((res) => res.json()));
  }

  public storeUserData(token, user) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    localStorage.setItem('id_token', token);
    localStorage.setItem('usuario', JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  public loadToken() {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  public logout() {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    this.authToken = null;
    this.user = {};
    localStorage.clear();
  }

  public getToken() {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    this.loadToken();
    return this.authToken;
  }

  public loggedIn() {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    return tokenNotExpired('id_token');
  }

  public getUser() {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
    return this.user;
  }
}
