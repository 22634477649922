import { Injectable } from '@angular/core';

/**
 * Las fechas llegan en zona horaria UTC de la BD, hay que transformarlas a zona horaria Colombiana para visualizarla
 * Y hay que mandarla nuevamente a la BD en UTC para asegurar que se guarden las fechas reales
 */
@Injectable({
  providedIn: 'root',
})
export class DateAndTimeService {
  /** Posibles valores de meses en string */
  public mesesString: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  /** Posibles valores de dias en string */
  public diasString: string[] = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ];
  /** Posibles valores de horas en string */
  public horasString: string[] = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];
  /** Posibles valores de minutos o segundos en string */
  public minutosOSegundosString: string[] = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
  ];

  /**
   * Convierte una fecha guardada en la BD al formato del input type=String
   * @param fecha String ISO de una fecha como llega cuando se trae de la BD
   * @returns String en formato de un input type = date
   */
  convertirFechaAFormatoFormulario(fecha): string {
    const fechaMilisegundosVisual: number = this.fechaColombiaAUtcVisual(fecha);
    const fechaISOVisual: string = new Date(fechaMilisegundosVisual).toISOString();

    return fechaISOVisual.split('T')[0];
  }

  /**
   * Convierte una hora guardada en la BD (UTC) a su hora en la zona horaria colombiana
   * @param fecha String ISO de una fecha como llega cuando se trae de la BD
   * @returns String en formato de los selects de hora del formulario
   */
  convertirHoraAFormatoFormulario(fecha): string {
    const fechaMilisegundosVisual: number = this.fechaColombiaAUtcVisual(fecha);
    const fechaISOVisual: string = new Date(fechaMilisegundosVisual).toISOString();
    const tiempoISOVisual = fechaISOVisual.split('T')[1];

    return tiempoISOVisual.split(':')[0];
  }

  /**
   * Convierte una minuto guardada en la BD (UTC) a su minuto en la zona minutoria colombiana
   * @param fecha String ISO de una fecha como llega cuando se trae de la BD
   * @returns String en formato de los selects de minuto del formulario
   */
  convertirMinutoAFormatoFormulario(fecha): string {
    const fechaMilisegundosVisual: number = this.fechaColombiaAUtcVisual(fecha);
    const fechaISOVisual: string = new Date(fechaMilisegundosVisual).toISOString();
    const tiempoISOVisual = fechaISOVisual.split('T')[1];

    return tiempoISOVisual.split(':')[1];
  }

  /**
   * Convierte una fecha que esta en el formato del formulario a como dería guardarse en la BD
   * @param fecha Fecha en formato de un input string (yyyy-mm-dd)
   * @param hora Hora en el formato de los selects de los formularios (hh)
   * @param minuto Minuto en el formato de los selects de los formularios (mm)
   * @return La fecha en formato ISO como debe quedar guardada en la BD
   */
  convertirFechaFormatoFormularioAFormatoBD(fecha: string, hora: string, minuto: string): string {
    const partesFecha: string[] = fecha.split('-');
    const fechaConDesfaceUtcEnMilisegundos: number = Date.UTC(
      Number(partesFecha[0]),
      Number(partesFecha[1]) - 1,
      Number(partesFecha[2]),
      Number(hora),
      Number(minuto)
    );
    // Colombia esta a -5 horas (-18,000,000 milisegundos) de UTC
    const fechaRealEnMilisegundos: number = fechaConDesfaceUtcEnMilisegundos + 18000000;

    return new Date(fechaRealEnMilisegundos).toISOString();
  }

  /**
   * Convierte una fecha en zona horaria colombiana a su equivalente (no temporal pero visual) UTC
   * @param fecha Fecha en formato ISO String en zona horaria Colomviana
   * @returns fecha en milisegundos UTC
   */
  fechaColombiaAUtcVisual(fecha): number {
    const fechaMilisegundosUTC: number = new Date(fecha).getTime();

    // Colombia esta a -5 horas (-18,000,000 milisegundos) de UTC
    return fechaMilisegundosUTC - 18000000;
  }

  /**
   * Obtiene el principio del día de hoy en hora colombiana
   * @return Devuelve el inicio del día de hoy de colombia en milisegundos
   */
  obtenerInicioDeDiaColombia(): number {
    const inicioDiaUTC: number = new Date().setUTCHours(0, 0, 0, 0);

    // Colombia esta a -5 horas (-18,000,000 milisegundos) de UTC
    return inicioDiaUTC + 18000000;
  }

  /**
   * Coge un número del mes y devuelve la abreviacion del mes en ingles
   * @param numMes Numero entre 1 y 12 del mes del cual se quiere obtener la abreviacion
   * @return String con la abreviacion del mes
   */
  obtenerAbreviacionMesEnInglesPorNumero(numMes: number): string {
    switch (numMes) {
      case 1:
        return 'Jan';
      case 2:
        return 'Feb';
      case 3:
        return 'Mar';
      case 4:
        return 'Apr';
      case 5:
        return 'May';
      case 6:
        return 'Jun';
      case 7:
        return 'Jul';
      case 8:
        return 'Aug';
      case 9:
        return 'Sep';
      case 10:
        return 'Oct';
      case 11:
        return 'Nov';
      case 12:
        return 'Dec';
    }
  }

  public convertDateToDateView(date: any) {
    date = new Date(date);

    return `${date.getUTCFullYear()}-${this.toMinimumTwoDigitNumber(
      date.getUTCMonth() + 1
    )}-${this.toMinimumTwoDigitNumber(date.getUTCDate())}`;
  }

  public convertDateToHoursView(date: any) {
    date = new Date(date);

    return {
      hours: `${this.toMinimumTwoDigitNumber(date.getUTCHours())}`,
      minutes: `${this.toMinimumTwoDigitNumber(date.getUTCMinutes())}`,
    };
  }

  private toMinimumTwoDigitNumber(number) {
    if (number < 10) return `0${number}`;
    else return `${number}`;
  }
}
