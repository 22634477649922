import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Location } from '@angular/common';
import * as S3 from 'aws-sdk/clients/s3';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
/* Servicios */
import { MainService } from '../../../services/main.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-barco-ver',
  templateUrl: './barco-ver.component.html',
  styleUrls: ['./barco-ver.component.scss'],
})
export class BarcoVerComponent implements OnInit {
  /* Arrays data of selects HTML */
  public dataBussinesLine: any = [];
  public dataTypeProducts: any = [];
  public dataVesselType: any = [];
  public dataEmpresaSQL: any = [];
  public dataTerminales: any = [];
  public dataCommodity: any = [];
  public dataMeasures: any = [];
  public dataTipoOpe: any = [];
  public dataPuertos: any = [];
  public dataBuques: any = [];
  public dataDWT: any = [];
  public dataKim: any = [];
  /*****/
  public statementAdmin: any = [];
  public dataProductos: any = [];
  public dataUsuarios: any = [];
  public servicios: any = [];
  public novedades: any = [];
  public statement: any = [];
  public novedad: any = [];
  public data: any = [];
  public newStatement: any = {};
  public documentos: any = {};
  public datosEdit: any = {};
  public producto: any = {};
  public barco: any = {};
  public selectedFile: any;
  public documento: any;
  public service: any;
  public account: any;
  public amount: any;
  public imagen: any;
  public id: any;
  public publishStatement: boolean = false;
  public enviado: boolean = false;
  public selectDetalle: string = 'Nominador'; //Nominador Prospectos Arrival & Depature Conditions Mas
  public newSof: any = {};
  /* S3 Credentials */
  bucket: S3 = new S3({
    accessKeyId: 'AKIAUT7IXVID77RSYJ5F',
    secretAccessKey: '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',
    region: 'us-east-1',
  });

  settingsProductos = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nv: { title: 'NV' },
      PRV_ID: { title: 'ID' },
      PRD_ID: { title: 'Producto' },
      PRV_ACUMULADO: { title: 'Acumulado' },
      PRV_PROD_12H: { title: 'Prod_12H' },
      PRV_CANTIDAD: { title: 'Cantidad' },
      PRV_ROB: { title: 'ROB' },
      MED_ID: { title: 'Medida' },
      createdAt: { title: 'Fecha de creación' },
    },
  };

  settingsUno = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      nv: { title: 'Nave' },
      service: { title: 'Service' },
      account: { title: 'Account' },
      amount: { title: 'Amount' },
      createdAt: { title: 'Fecha de creación', sort: 'true', sortDirection: 'desc' },
    },
  };

  settingsDos = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
      ],
    },
    columns: {
      contenido: { title: 'Contenido' },
      boardingAgent: { title: 'Creador' },
      vessel: { title: 'Barco' },
      createdAt: { title: 'Fecha de creación', sort: 'true', sortDirection: 'desc' },
    },
  };

  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
    private dialogService: NbDialogService,
    private utilsService: UtilsService
  ) {}

  /*  Para el modal */
  spinerEdit = false;
  spinerGuardar = false;
  /* Producto */
  @ViewChild('editProducto', { static: true }) editProducto: ElementRef;
  /* Novedad */
  @ViewChild('dialogNovedad', { static: true }) dialogNovedad: ElementRef;
  @ViewChild('editNovedad', { static: true }) editNovedad: ElementRef;
  /* Servicio */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  /*****/
  /* Novedad */
  @ViewChild('editSof', { static: true }) editSof: ElementRef;
  /*****/

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.loadSelects();
  }

  /**
   * Funcion para seleccionar el detalle a visualizar
   * @param categoria opcion escogida
   */
  public seleccionarDetalle(categoria) {
    this.selectDetalle = categoria;
  }

  /**
   * Funcion para actualizar el barco
   */
  public actualizaBarco() {
    this.mainService.put('api/barco/' + this.id, this.barco).subscribe((res) => {
      if (res) {
        Swal.fire('Éxito', 'Se actualizó el barco exitosamente', 'success');
      } else {
        Swal.fire('Error', 'No se pudo actualizar el barco, verifique la información', 'warning');
      }
    });
  }

  /**
   * Function to load all information in arrays of selects
   */
  public loadSelects() {
    this.mainService.get('api/buque').subscribe((resul) => {
      this.dataBuques = JSON.parse(JSON.stringify(resul));
      this.dataDWT = _.uniqBy(this.dataBuques, 'BUQ_DWT');
      this.dataVesselType = _.uniqBy(this.dataBuques, 'BUQ_ESPECIALIDAD');
      this.dataDWT = _.orderBy(this.dataDWT, ['BUQ_DWT'], ['asc']);
      this.dataVesselType = _.orderBy(this.dataVesselType, ['BUQ_ESPECIALIDAD'], ['asc']);
    });
    this.mainService.get('api/producto').subscribe((res) => (this.dataTypeProducts = res));
    this.mainService.get('api/medida').subscribe((res) => (this.dataMeasures = res));
    this.mainService.get('api/bussines_line').subscribe((resul) => (this.dataBussinesLine = resul));
    this.mainService.get('api/empresa_sql').subscribe((resul) => (this.dataEmpresaSQL = resul));
    this.mainService.get('api/terminal').subscribe((res) => {
      this.dataTerminales = res;
      this.dataTerminales = _.orderBy(this.dataTerminales, ['TER_NOMBRE']);
      //Hay que quitar la terminal ALL de las opciones, esta tiene el ID 0
      let i = 0;
      let encontroTerminal = false;
      while (i < this.dataTerminales.length && !encontroTerminal) {
        if (this.dataTerminales[i].TER_ID == 0) {
          this.dataTerminales.splice(i, 1);
          encontroTerminal = true;
        }

        i++;
      }
    });
    this.mainService.get('api/commodity').subscribe((resul) => (this.dataCommodity = resul));
    this.mainService.get('api/operacion').subscribe((resul) => (this.dataTipoOpe = resul));
    this.mainService.get('api/puerto').subscribe((resul) => (this.dataPuertos = resul));
    this.mainService.get('api/kim').subscribe((resul) => (this.dataKim = resul));
    this.load();
  }

  /**
   * Funcion para cargar la data de todos los arrays y objetos
   */
  public load() {
    this.barco = {};
    console.log('Cargando');
    this.mainService.get('api/barco/' + this.id).subscribe((res) => {
      this.barco = res;

      this.documentos = res;
      res.statementOfFactsAdmin = _.orderBy(res.statementOfFactsAdmin, ['date'], ['desc']);
      res.statementOfFacts = _.orderBy(res.statementOfFacts, ['date'], ['desc']);
      res.statementOfFactsAdmin.forEach((obj) => (obj.date = moment(obj.date).format('DD-MMM-YY')));
      if (res.statementOfFactsAdmin.length <= 0) {
        this.publishStatement = false;
      } else {
        this.publishStatement = true;
        this.statementAdmin = res.statementOfFactsAdmin;
      }
      res.statementOfFacts.forEach((obj) => (obj.createdAt = moment(obj.createdAt).format('DD-MMM-YY')));
      if (res.statementOfFacts.length > 0) {
        let x: any = JSON.stringify(this.barco);
        x = JSON.parse(x);
        this.statement = x.statementOfFacts;
      } else {
        this.statement = [];
      }
      this.mainService.get(`api/producto_completo/via_id/${this.barco.via_id}?activo=true`).subscribe((productos) => {
        this.dataProductos = productos;
      });
    });
    this.mainService.get('api/user/tipo/operario').subscribe((res) => (this.dataUsuarios = res));
    this.cargarServicios();
    this.cargarNovedades();
  }

  public cargarServicios() {
    this.data = [];
    this.mainService.get('api/servicio/barco/' + this.id).subscribe((res) => {
      res.forEach((element) => (element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm')));
      this.data = res;
      this.data = _.orderBy(this.data, ['createdAt'], ['desc']);
    });
  }

  public cargarNovedades() {
    this.mainService.get('api/novedad/barco/' + this.id).subscribe((res) => {
      res.forEach((element) => {
        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');
        element.vessel = this.barco.vessel;
        let x = this.dataUsuarios.findIndexOf((user) => user._id == element.operario);
        element.boardingAgent = x >= 0 ? this.dataUsuarios[x].nombre + ' ' + this.dataUsuarios[x].apellido : 'N/A';
      });

      this.novedades = _.orderBy(res, ['createdAt'], ['desc']);
    });
  }

  onEditar(dialog, event) {
    this.datosEdit.nv = event.data.nv;
    this.datosEdit.amount = event.data.amount;
    this.datosEdit.account = event.data.account;
    this.datosEdit.service = event.data.service;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }
  public crear() {
    this.enviado = false;
    if (this.barco.nv != '' && this.service != '') {
      let servicio = {
        nv: this.barco.nv,
        service: this.service,
        account: this.account,
        amount: this.amount,
      };
      this.mainService.post('api/servicio', servicio).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se agregó el servicio exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.load();
        } else {
          Swal.fire('Error', 'No se pudo agregar el servicio, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar el servicio, verifique la información', 'warning');
    }
  }

  onVer(event) {
    this.router.navigate(['home/novedad-ver/' + event.data._id]);
  }

  onEliminar(event) {
    Swal.fire({
      title: '<strong>¿Deseas eliminar el servicio?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let servicio = event.data;
        this.spinerGuardar = true;
        this.mainService.delete('api/servicio/' + servicio._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'servicio eliminado con éxito', 'success');
            this.load();
          }
        });
      } else {
        Swal.fire('Error', 'Problema eliminando el servicio', 'warning');
      }
    });
  }

  onActualizarNovedad() {
    if (this.novedad.contenido != '' && this.novedad.imagen != '' && this.novedad.operario != '') {
      let data = this.novedad;
      this.mainService.put('api/novedad/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó la novedad exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.load();
          this.novedad = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar la novedad, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar la novedad, verifique la información', 'warning');
    }
  }

  onEliminarNovedad(event) {
    Swal.fire({
      title: '<strong>¿Deseas eliminar la novedad?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let novedadEliminar = event.data;
        this.spinerGuardar = true;
        this.mainService.delete('api/novedad/' + novedadEliminar._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'novedad eliminada con éxito', 'success');
            this.load();
          }
        });
      } else {
        Swal.fire('Error', 'Problema eliminando la novedad', 'warning');
      }
    });
  }

  onActualizar() {
    if (this.datosEdit.nv != '' && this.datosEdit.service != '') {
      let servicio = {
        _id: this.datosEdit._id,
        nv: this.datosEdit.nv,
        service: this.datosEdit.service,
        account: this.datosEdit.account,
        amount: this.datosEdit.amount,
      };
      let data = servicio;
      console.log(data);
      this.mainService.put('api/servicio/' + data._id, data).subscribe((result) => {
        console.log(result);
        if (result) {
          Swal.fire('Éxito', 'Se actualizó el servicio exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.load();
        } else {
          Swal.fire('Error', 'No se pudo actualizar el servicio, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar el servicio, verifique la información', 'warning');
    }
  }

  crearServicio() {
    this.openDialogSave(this.dialog);
  }

  makeid() {
    return this.utilsService.generateRandomId();
  }

  abrirFile(num) {
    if (num == 1) {
      const element: HTMLElement = document.getElementById('archivo1');
      element.click();
    } else if (num == 2) {
      const element: HTMLElement = document.getElementById('archivo2');
      element.click();
    } else if (num == 3) {
      const element: HTMLElement = document.getElementById('archivo3');
      element.click();
    } else if (num == 4) {
      const element: HTMLElement = document.getElementById('archivo4');
      element.click();
    } else if (num == 5) {
      const element: HTMLElement = document.getElementById('imagenEditar');
      element.click();
    } else if (num == 6) {
      const element: HTMLElement = document.getElementById('imagenCrear');
      element.click();
    }
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  public onFileSelect(event, num) {
    this.selectedFile = event.target.files[0];
    let self = this;
    console.log('Entro a S3');
    if (this.selectedFile !== undefined) {
      var file = this.selectedFile;
      console.log(file);
      let nombre = this.makeid();
      if (num == 5) {
        var params = {
          Bucket: 'naves-recursos',
          ACL: 'public-read',
          Key: 'novedades/' + nombre + '.png',
          ContentType: file.type,
          Body: file,
        };
      } else {
        var params = {
          Bucket: 'naves-recursos',
          ACL: 'public-read',
          Key: 'documentos/' + nombre + '.pdf',
          ContentType: file.type,
          Body: file,
        };
      }

      var options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        // Give the owner of the bucket full control
      };

      var uploader = this.bucket.upload(params, options, function (err, data) {
        if (err) {
          console.log('PERDIDAS');
        }
        console.log('SUCCESS');

        self.documento = data.Location.toString();
        console.log(self.documento);

        if (num == 1) {
          console.log('es el primer archivo');
          self.documentos.archivoDepartureDocuments = self.documento;
        } else if (num == 2) {
          console.log('es el segundo archivo');
          self.documentos.archivoDischargeLoandingPlan = self.documento;
        }

        return true;
      });
      uploader.on('httpUploadProgress', function (event) {
        console.log('UPDATE');
        console.log(event);
        console.log((event.loaded * 100) / event.total);
      });
    }
  }

  onActualizarDocumentos() {
    this.mainService.put('api/barco/' + this.documentos._id, this.documentos).subscribe((res) => {
      console.log(res, 'actualizar');
      if (res) {
        Swal.fire('Éxito', 'Se agregó los documentos exitosamente', 'success');
      } else {
        Swal.fire('Error', 'No se pudieron agregar los documentos, verifique la información', 'warning');
      }
    });
  }

  guardarStatements() {
    this.mainService.put('api/barco/' + this.id, { statementOfFactsAdmin: this.statementAdmin }).subscribe((res) => {
      Swal.fire('Éxito', 'Se actualizó el statements of facts', 'success');
    });
  }

  actualizarStatement() {
    this.mainService.put('api/barco/' + this.id, { statementOfFactsAdmin: this.statementAdmin }).subscribe((res) => {
      Swal.fire('Éxito', 'Se actualizó el statements of facts', 'success');
    });
  }

  public addStatement() {
    this.newStatement.date = moment();
    console.log(this.newStatement);
    this.statementAdmin = _.orderBy(this.statementAdmin, ['date'], ['desc']);
    this.statementAdmin.push(this.newStatement);
    this.mainService.put('api/barco/' + this.id, { statementOfFactsAdmin: this.statementAdmin }).subscribe((res) => {
      Swal.fire('Éxito', 'Se actualizó el statements of facts', 'success');
      this.statementAdmin.forEach((element) => (element.date = moment(element.date).format('DD-MMM-YYYY')));
      this.statementAdmin = _.orderBy(this.statementAdmin, ['date'], ['desc']);
      this.newStatement = {};
    });
  }

  public deleteStatement(i) {
    Swal.fire({
      title: '<strong>¿Deseas eliminar el contenido?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.statementAdmin.splice(i, 1);
        this.mainService
          .put('api/barco/' + this.id, { statementOfFactsAdmin: this.statementAdmin })
          .subscribe((res) => {
            Swal.fire('Éxito', 'Se actualizó el statements of facts', 'success');
          });
      } else {
        Swal.fire('Error', 'Problema eliminando el servicio', 'warning');
      }
    });
  }

  public openStatement() {
    if (this.barco.statementOfFactsAdmin.length <= 0) {
      this.statementAdmin = this.barco.statementOfFacts;
      this.publishStatement = true;
    } else {
      this.barco.statementOfFactsAdmin.forEach((element) => {
        element.date = moment(element.date).format('DD-MMM-YY');
      });
      this.statementAdmin = this.barco.statementOfFactsAdmin;
      this.publishStatement = true;
    }
  }

  pasoCrearNovedad() {
    this.openDialogSave(this.dialogNovedad);
  }
  public crearNovedad() {
    this.enviado = true;
    if (
      this.novedad.contenido != '' &&
      this.novedad.imagen != '' &&
      this.novedad.operario != '' &&
      this.novedad.barco != ''
    ) {
      let data = {
        contenido: this.novedad.contenido,
        operario: this.novedad.operario,
        barco: this.barco._id,
        imagen: this.imagen,
      };

      this.mainService.post('api/novedad', data).subscribe((res) => {
        if (res) {
          Swal.fire('Éxito', 'Se agregó la novedad exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-closeNovedad');
          element.click();
          this.novedad = {};
          this.load();
        } else {
          Swal.fire('Error', 'No se pudo agregar la novedad, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo agregar la novedad, verifique la información', 'warning');
    }
  }

  back() {
    this._location.back();
  }
  onEditarNovedad(dialog, event) {
    this.novedad = {};
    this.novedad = event.data;
    this.imagen = event.data.imagen;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  public openSof() {
    this.openDialogSave(this.editSof);
  }

  public createNewSof() {
    this.newSof.via_id = this.barco.via_id;
    this.newSof.nv = this.barco.nv;
    this.mainService.post('api/sof', this.newSof).subscribe(
      (res) => {
        this.barco.sof = this.barco.sof ? this.barco.sof : [];
        this.barco.sof.push(res._id);
        this.mainService.put(`api/barco/${this.barco._id}`, this.barco).subscribe(
          (res) => {
            alert('Se creo y asocio el SOF correctamente');
            this.closeSofModal();
            this.load();
            this.newSof = {};
          },
          (err) => {
            alert('Hubo un error al asociar el SOF creado');
          }
        );
      },
      (err) => {
        alert('Hubo un error al crear el SOF');
      }
    );
  }

  public closeSofModal() {
    const element: HTMLElement = document.getElementById('btn-close-sof');
    element.click();
  }

  public goToSof(id) {
    this.router.navigate([`home/sof-ver/${id}`]);
    this.closeSofModal();
  }

  /**
   * Function to choose the action
   * @param event option
   */
  public productCustomActions(event) {
    switch (event.action) {
      case 'edit':
        this.editProduct(this.editProducto, event);
        break;
      case 'delete':
        this.deleteProduct(event);
        break;
    }
  }

  onCustom2(event) {
    switch (event.action) {
      case 'edit':
        this.onEditarNovedad(this.editNovedad, event);
        break;
      case 'delete':
        this.onEliminarNovedad(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
    console.log(event);
  }

  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
    }
  }

  /**
   * Function to charge data in product object and open the modal
   * @param dialog Element ref of modal
   * @param event Data of object
   */
  public editProduct(dialog, event) {
    this.producto = {};
    this.producto = event.data;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Function to delete object in DB
   * @param event Data of object
   */
  public deleteProduct(event) {
    Swal.fire({
      title: '<strong>¿Deseas eliminar el producto?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let productoEliminar = event.data;
        this.spinerGuardar = true;
        this.mainService.delete('api/producto_completo/' + productoEliminar._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'producto eliminado con éxito', 'success');
            this.load();
          }
        });
      } else {
        Swal.fire('Error', 'Problema eliminando el producto', 'warning');
      }
    });
  }

  /**
   * Function to update product in DB
   */
  updateProduct() {
    if (this.producto.PRD_ID != '') {
      let data = this.producto;
      this.mainService.put('api/producto_completo/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó el producto exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-editProducto');
          element.click();
          this.load();
          this.producto = {};
        } else {
          Swal.fire('Error', 'No se pudo actualizar el producto, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar el producto, verifique la información', 'warning');
    }
  }

  public onFileImagen(event) {
    this.selectedFile = event.target.files[0];
    let self = this;
    console.log('Entro a S3');
    if (this.selectedFile !== undefined) {
      var file = this.selectedFile;
      console.log(file);
      let nombre = this.makeid();
      var params = {
        Bucket: 'naves-recursos',
        ACL: 'public-read',
        Key: 'novedad/' + nombre + '.jpg',
        ContentType: file.type,
        Body: file,
      };
      var options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        // Give the owner of the bucket full control
      };

      var uploader = this.bucket.upload(params, options, function (err, data) {
        if (err) {
          console.log('PERDIDAS');
        }
        console.log('SUCCESS');

        self.imagen = data.Location.toString();
        console.log(self.imagen);
        return true;
      });
      uploader.on('httpUploadProgress', function (event) {
        console.log('UPDATE');
        console.log(event);
        console.log((event.loaded * 100) / event.total);
      });
    }
  }
}
