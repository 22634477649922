import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { MainService } from '../../services/main.service';

@Component({
	selector: 'app-operacion-ver',
	templateUrl: './operacion-ver.component.html',
	styleUrls: ['./operacion-ver.component.scss']
})
export class OperacionVerComponent implements OnInit {
	id: any;
	public operacion: any = {};
	public data: any;

	constructor(
		private toastrService: NbToastrService,
		private _location: Location,
		private route: ActivatedRoute,
		private mainService: MainService,) {

	}

	ngOnInit() {

		this.id = this.route.snapshot.params['id'];
		console.log(this.id);
		this.onCargar()

	}

	onCargar() {

		this.operacion = {};
		console.log("Cargando");

		this.mainService.get('api/operacion/' + this.id).subscribe(res => {
			this.operacion = res;
			console.log(this.operacion);
		});
	}

	back() {
		this._location.back();
	}

	/** Toas **/

	showToast(position, status, titulo, mensaje) {
		this.toastrService.show(
			mensaje,
			titulo,
			{ position, status });
	}

}
