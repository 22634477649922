import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../services/main.service';
import { PqrsService } from './pqrs.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'pqrs',
  templateUrl: './pqrs.component.html',
  styleUrls: ['./pqrs.component.scss']
})
export class PqrsComponent implements OnInit {

  public usuario: any = JSON.parse(localStorage.getItem('usuario'));
	public permisosUsuario: any;
  
  constructor(
    private pqrsService: PqrsService,
    private mainService: MainService,
    private router: Router
  ) { }

  ngOnInit() {
    this.obtenerPermisos();
  }

	/**
	 * Determina si el usuario puede acceder a este módulo y sus permisos
	 */
  obtenerPermisos(){
		this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe(res => {
			this.permisosUsuario = res;
			if(this.permisosUsuario.pqrs === 'NINGUNO'){
				Swal.fire({
					title: 'No se tiene permisos de acceso al módulo',
					type: 'error',
					showCancelButton: false,
					confirmButtonText: 'Continuar'
				});
				this.router.navigate(['home/dashboard']);
			} else {
        this.pqrsService.obtenerPqrs();
			}
		})
	}
}
