import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { AlertService } from '../../../../services/alertService/alert-service.service';

@Component({
  selector: 'lista-de-chequeo',
  templateUrl: './lista-de-chequeo.component.html',
  styleUrls: ['./lista-de-chequeo.component.scss'],
})
export class ListaDeChequeoComponent implements OnInit {
  /** Viaje de la lista de chequeo */
  public viaje: any;
  /** Buque del viaje de la lista de chequeo */
  public buque: any;
  /** Nombre del buque del viaje */
  public buqueNombre: string = 'Cargando ...';
  /** Puerto del viaje de la lista de chequeo */
  public puerto: any;
  /** Nombre del puerto del viaje */
  public puertoNombre: string = 'Cargando ...';
  /** Terminal del viaje de la lista de chequeo */
  public terminal: any;
  /** Nombre de la terminal del viaje */
  public terminalNombre: string = 'Cargando ...';
  /** Nominador del viaje de la lista de chequeo */
  public nominador: any;
  /** Nombre del nominador del viaje */
  public nominadorNombre: string = 'Cargando ...';
  /** Valores de la lista de chequeo */
  public listaDeChequeo: {
    instruccionesNominacion: boolean;
    situacionRestriccionPuerto: boolean;
    caladoPuerto: boolean;
    otrasRestricciones: boolean;
    isps: boolean;
    caladoArriboZarpe: boolean;
    secuenciaCargueDescargue: boolean;
    distribucionCargaEnTierra: boolean;
    tiposProductosYRitmos: boolean;
    prospectosYProgramacion: boolean;
    almacenamientoYTransporte: boolean;
    oblsODraftBls: boolean;
    shipperDeclaration: boolean;
    msds: boolean;
    matesReceipt: boolean;
    cargoManifest: boolean;
    dian: boolean;
    capitania: boolean;
    ica: boolean;
    sanidad: boolean;
    migracion: boolean;
    muellaje: boolean;
    poilotos: boolean;
    remolacadores: boolean;
    estibadores: boolean;
    serviciosNave: boolean;
    productividadRealVsProgramada: boolean;
    sofYLaytime: boolean;
    cuellosDeBotella: boolean;
    pda: boolean;
    ordenesDeServicios: boolean;
    closeToReal: boolean;
    fda: boolean;
    survyCaptian: boolean;
  } = {
    instruccionesNominacion: false,
    situacionRestriccionPuerto: false,
    caladoPuerto: false,
    otrasRestricciones: false,
    isps: false,
    caladoArriboZarpe: false,
    secuenciaCargueDescargue: false,
    distribucionCargaEnTierra: false,
    tiposProductosYRitmos: false,
    prospectosYProgramacion: false,
    almacenamientoYTransporte: false,
    oblsODraftBls: false,
    shipperDeclaration: false,
    msds: false,
    matesReceipt: false,
    cargoManifest: false,
    dian: false,
    capitania: false,
    ica: false,
    sanidad: false,
    migracion: false,
    muellaje: false,
    poilotos: false,
    remolacadores: false,
    estibadores: false,
    serviciosNave: false,
    productividadRealVsProgramada: false,
    sofYLaytime: false,
    cuellosDeBotella: false,
    pda: false,
    ordenesDeServicios: false,
    closeToReal: false,
    fda: false,
    survyCaptian: false,
  };
  /** Indica si hay algo cargandose en la lista de chequeo */
  public cargando: boolean = true;
  /** Indica si los campos de nominacion estan completos */
  public camposNominacion: boolean = false;
  /** Indica si los campos de condiciones de arribo de la nave estan completos */
  public camposCondicionesDeArriboDeLaNave: boolean = false;
  /** Indica si los campos del plan de operacion portuaria estan completos */
  public camposPlanDeOperacionPortuaria: boolean = false;
  /** Indica si los campos de documentacion estan completos */
  public camposDocumentacion: boolean = false;
  /** Indica si los campos de gestion de autoridades estan completos */
  public camposGestionAutoridades: boolean = false;
  /** Indica si los campos de coordinacion de servicios del puerto estan completos */
  public camposCoordinacionServiciosPuerto: boolean = false;
  /** Indica si los campos de desarrolllo de la operacion estan completos */
  public camposDesarrolloOperacion: boolean = false;
  /** Indica si los campos de costos portuarios estan completos */
  public camposCostosPortuarios: boolean = false;
  /** Indica si todos los campos estan completos */
  public todosLosCampos: boolean = false;

  public observation: string;
  public selectedObservation: any;

  /** Referencia a la casilla de la tabla indicando la completitud de los campos de nominacion*/
  @ViewChild('camposNominacionElement', { static: true }) camposNominacionElement: ElementRef;
  /** Referencia a la casilla de la tabla indicando la completitud de los campos de condiciones de arribo de la nave*/
  @ViewChild('camposCondicionesDeArriboDeLaNaveElement', { static: true })
  camposCondicionesDeArriboDeLaNaveElement: ElementRef;
  /** Referencia a la casilla de la tabla indicando la completitud de los campos de condiciones de arribo de la nave*/
  @ViewChild('camposPlanDeOperacionPortuariaElement', { static: true })
  camposPlanDeOperacionPortuariaElement: ElementRef;
  /** Referencia a la casilla de la tabla indicando la completitud de los campos de documentacion*/
  @ViewChild('camposDocumentacionElement', { static: true }) camposDocumentacionElement: ElementRef;
  /** Referencia a la casilla de la tabla indicando la completitud de los campos de gestion de autoridades*/
  @ViewChild('camposGestionAutoridadesElement', { static: true }) camposGestionAutoridadesElement: ElementRef;
  /** Referencia a la casilla de la tabla indicando la completitud de los campos de coordinacion de servicio del puerto*/
  @ViewChild('camposCoordinacionServiciosPuertoElement', { static: true })
  camposCoordinacionServiciosPuertoElement: ElementRef;
  /** Referencia a la casilla de la tabla indicando la completitud de los campos de desarrollo de la operacion*/
  @ViewChild('camposDesarrolloOperacionElement', { static: true }) camposDesarrolloOperacionElement: ElementRef;
  /** Referencia a la casilla de la tabla indicando la completitud de los campos de costos portuarios*/
  @ViewChild('camposCostosPortuariosElement', { static: true }) camposCostosPortuariosElement: ElementRef;
  @ViewChild('allFieldSurvy', { static: true }) allFieldSurvy: ElementRef;
  /** Referencia a la casilla de la tabla indicando la completitud de todos los campos de la lista de chequeo*/
  @ViewChild('todosLosCamposElement', { static: true }) todosLosCamposElement: ElementRef;

  public observationsListInstructions = {
    observationsList: [],
  };

  public permisosUsuario: any;
  public usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    public router: Router,
    private mainService: MainService,
    private _location: Location,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.obtenerPermisos();
  }

  /**
   * Determina si el usuario puede acceder a este módulo y sus permisos
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.analisisOperativo === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.obtenerViaje();
      }
    });
  }

  /**
   * A traves de la URL obtiene el viaje de la lista de chequeo actual y inicializa los datos acrodemente
   */
  obtenerViaje() {
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe((res) => {
      if (res.message) {
        Swal.fire({
          title: '¡Error!',
          text: res.message,
          type: 'error',
        });
        return this.router.navigate(['home/dashboard']);
      }
      this.viaje = res;
      this.listaDeChequeo = this.viaje.listaDeChequeo;
      this.buqueNombre = this.viaje.buqueNombre;
      this.puertoNombre = this.viaje.puertoNombre;
      this.terminalNombre = this.viaje.terminalNombre;
      this.nominadorNombre = this.viaje.nominadorNombre;
      this.verificarCampos();
      this.viaje.etaVisual = this.fechaAFormatoVisual(this.viaje.eta);
      this.viaje.etbVisual = this.fechaAFormatoVisual(this.viaje.etb);
      this.viaje.etcVisual = this.fechaAFormatoVisual(this.viaje.etc);

      this.observationsListInstructions = {
        ...this.observationsListInstructions,
        observationsList: [...this.viaje.checkListObservations],
      };

      this.cargando = false;

      this.mainService.get(`api/captian-interview-nv/${this.viaje._id}`).subscribe(
        (res) => {
          this.captianInterview = res;

          if (res.nv === this.viaje._id) {
            this.containsCaptianInterview = true;
          }

          const dateFullFilled = res.history.fullfilled.date;
          if (!dateFullFilled) {
            this.allFieldSurvy.nativeElement.classList.add('pendiente');
            return;
          }

          this.listaDeChequeo.survyCaptian = true;
          this.allFieldSurvy.nativeElement.classList.add('completo');
        },
        (err) => {
          this.allFieldSurvy.nativeElement.classList.add('pendiente');
        }
      );
    });
  }

  public captianInterview;

  public containsCaptianInterview = false;
  public async generateCaptianInterview() {
    try {
      const captianInterview = await this.mainService
        .post(`api/captian-interview-default/${this.viaje._id}`, {})
        .toPromise();

      if (captianInterview.nv === this.viaje._id) {
        this.containsCaptianInterview = true;
      }

      this.captianInterview = captianInterview;

      this.alertService.simpleAlertConfirm('Encuesta creada');
    } catch (err) {
      this.alertService.simpleAlertError(err.error.message);
    }
  }

  public goToCaptianInterview() {
    this.router.navigate([`home/captian-survies`], {
      queryParams: {
        survy: this.captianInterview._id,
      },
    });
  }

  public goToFullfillCaptianInterview() {}

  getObservations() {
    // Partes de la URL separadas por '/'
    const partesURL = this.router.url.split('/');

    this.mainService.get(`api/analisis-operativo/${partesURL[3]}/${partesURL[4]}`).subscribe((res) => {
      if (res.message) {
        Swal.fire({
          title: '¡Error!',
          text: res.message,
          type: 'error',
        });
        return this.router.navigate(['home/dashboard']);
      }

      this.observationsListInstructions = {
        ...this.observationsListInstructions,
        observationsList: [...res.checkListObservations],
      };

      this.cargando = false;
    });
  }

  private addObservation() {
    return {
      observation: this.observation,
      author: `${this.usuario.nombre} ${this.usuario.apellido}`,
      authorId: this.usuario._id,
    };
  }

  public selectObservation(itemObservation: any) {
    if (itemObservation.authorId !== this.usuario._id) {
      this.alertService.simpleAlertWarning('No puedes editar esta observación');
      return;
    }

    this.selectedObservation = itemObservation;
    this.observation = itemObservation.observation;
  }

  /**
   * Convierte una fecha a un String para ser visualizada
   * @param fecha Fecha de la cual se quiere obtener el formato visual
   * @returns String con la fecha en el formaot visual
   */
  fechaAFormatoVisual(fecha: Date): string {
    /** Fecha ingresada en tipo Date */
    const fechaDate = new Date(fecha);

    return `${fechaDate.getUTCDate()}-${this.obtenerAbreviasionMes(fechaDate.getUTCMonth())}`;
  }

  /**
   * Devuelve un string con la abreviatura del mes segun el numero ingresado
   * @param mes Numero del mes
   * @returns String con Abreviatura del mes pasado como argumento
   */
  obtenerAbreviasionMes(mes: number): string {
    /** String que sera retornado */
    let mesAbreviatura;

    switch (mes) {
      case 0:
        mesAbreviatura = 'Ene';
        break;
      case 1:
        mesAbreviatura = 'Feb';
        break;
      case 2:
        mesAbreviatura = 'Mar';
        break;
      case 3:
        mesAbreviatura = 'Abr';
        break;
      case 4:
        mesAbreviatura = 'May';
        break;
      case 5:
        mesAbreviatura = 'Jun';
        break;
      case 6:
        mesAbreviatura = 'Jul';
        break;
      case 7:
        mesAbreviatura = 'Ago';
        break;
      case 8:
        mesAbreviatura = 'Sep';
        break;
      case 9:
        mesAbreviatura = 'Oct';
        break;
      case 10:
        mesAbreviatura = 'Nov';
        break;
      case 11:
        mesAbreviatura = 'Dic';
        break;
      default:
        mesAbreviatura = 'No es un mes valido';
    }

    return mesAbreviatura;
  }

  /**
   * Hace los cambios necesarios en la lista de chequeo segun el cambio por el usuario
   * @param datoListaChequeo Nombre del dato qu fue cambiado en la lista de chequeo
   */
  onCambioDatoListaDeChequeo(datoListaChequeo: string) {
    this.listaDeChequeo[datoListaChequeo] = !this.listaDeChequeo[datoListaChequeo];

    this.verificarCampos();
  }

  /**
   * Verifica que campos de la lista de chequeo estan completos y que campos estan pendientes
   */
  verificarCampos() {
    // 1. Nominacion
    this.camposNominacion =
      this.listaDeChequeo.instruccionesNominacion &&
      this.listaDeChequeo.situacionRestriccionPuerto &&
      this.listaDeChequeo.caladoPuerto &&
      this.listaDeChequeo.otrasRestricciones;
    if (this.camposNominacion) {
      this.camposNominacionElement.nativeElement.classList.remove('pendiente');
      this.camposNominacionElement.nativeElement.classList.add('completo');
    } else {
      this.camposNominacionElement.nativeElement.classList.remove('completo');
      this.camposNominacionElement.nativeElement.classList.add('pendiente');
    }

    // 2. Condiciones de arribo de la nave
    this.camposCondicionesDeArriboDeLaNave = this.listaDeChequeo.isps && this.listaDeChequeo.caladoArriboZarpe;
    if (this.camposCondicionesDeArriboDeLaNave) {
      this.camposCondicionesDeArriboDeLaNaveElement.nativeElement.classList.remove('pendiente');
      this.camposCondicionesDeArriboDeLaNaveElement.nativeElement.classList.add('completo');
    } else {
      this.camposCondicionesDeArriboDeLaNaveElement.nativeElement.classList.remove('completo');
      this.camposCondicionesDeArriboDeLaNaveElement.nativeElement.classList.add('pendiente');
    }

    // 3. Plan de operacion portuaria
    this.camposPlanDeOperacionPortuaria =
      this.listaDeChequeo.secuenciaCargueDescargue &&
      this.listaDeChequeo.distribucionCargaEnTierra &&
      this.listaDeChequeo.tiposProductosYRitmos &&
      this.listaDeChequeo.prospectosYProgramacion &&
      this.listaDeChequeo.almacenamientoYTransporte;
    if (this.camposPlanDeOperacionPortuaria) {
      this.camposPlanDeOperacionPortuariaElement.nativeElement.classList.remove('pendiente');
      this.camposPlanDeOperacionPortuariaElement.nativeElement.classList.add('completo');
    } else {
      this.camposPlanDeOperacionPortuariaElement.nativeElement.classList.remove('completo');
      this.camposPlanDeOperacionPortuariaElement.nativeElement.classList.add('pendiente');
    }

    // 4. Documentación
    this.camposDocumentacion =
      this.listaDeChequeo.oblsODraftBls &&
      this.listaDeChequeo.shipperDeclaration &&
      this.listaDeChequeo.msds &&
      this.listaDeChequeo.matesReceipt &&
      this.listaDeChequeo.cargoManifest;
    if (this.camposDocumentacion) {
      this.camposDocumentacionElement.nativeElement.classList.remove('pendiente');
      this.camposDocumentacionElement.nativeElement.classList.add('completo');
    } else {
      this.camposDocumentacionElement.nativeElement.classList.remove('completo');
      this.camposDocumentacionElement.nativeElement.classList.add('pendiente');
    }

    // 5. Gestión autoridades
    this.camposGestionAutoridades =
      this.listaDeChequeo.dian &&
      this.listaDeChequeo.capitania &&
      this.listaDeChequeo.ica &&
      this.listaDeChequeo.sanidad &&
      this.listaDeChequeo.migracion;
    if (this.camposGestionAutoridades) {
      this.camposGestionAutoridadesElement.nativeElement.classList.remove('pendiente');
      this.camposGestionAutoridadesElement.nativeElement.classList.add('completo');
    } else {
      this.camposGestionAutoridadesElement.nativeElement.classList.remove('completo');
      this.camposGestionAutoridadesElement.nativeElement.classList.add('pendiente');
    }

    // 6. Coordinación servicios en puerto
    this.camposCoordinacionServiciosPuerto =
      this.listaDeChequeo.muellaje &&
      this.listaDeChequeo.poilotos &&
      this.listaDeChequeo.remolacadores &&
      this.listaDeChequeo.estibadores &&
      this.listaDeChequeo.serviciosNave;
    if (this.camposCoordinacionServiciosPuerto) {
      this.camposCoordinacionServiciosPuertoElement.nativeElement.classList.remove('pendiente');
      this.camposCoordinacionServiciosPuertoElement.nativeElement.classList.add('completo');
    } else {
      this.camposCoordinacionServiciosPuertoElement.nativeElement.classList.remove('completo');
      this.camposCoordinacionServiciosPuertoElement.nativeElement.classList.add('pendiente');
    }

    // 7. Desarrollo de la operación
    this.camposDesarrolloOperacion =
      this.listaDeChequeo.productividadRealVsProgramada &&
      this.listaDeChequeo.sofYLaytime &&
      this.listaDeChequeo.cuellosDeBotella;
    if (this.camposDesarrolloOperacion) {
      this.camposDesarrolloOperacionElement.nativeElement.classList.remove('pendiente');
      this.camposDesarrolloOperacionElement.nativeElement.classList.add('completo');
    } else {
      this.camposDesarrolloOperacionElement.nativeElement.classList.remove('completo');
      this.camposDesarrolloOperacionElement.nativeElement.classList.add('pendiente');
    }

    // 8. Costos portuarios y gestión de recaudo
    this.camposCostosPortuarios =
      this.listaDeChequeo.pda &&
      this.listaDeChequeo.ordenesDeServicios &&
      this.listaDeChequeo.closeToReal &&
      this.listaDeChequeo.fda;
    if (this.camposCostosPortuarios) {
      this.camposCostosPortuariosElement.nativeElement.classList.remove('pendiente');
      this.camposCostosPortuariosElement.nativeElement.classList.add('completo');
    } else {
      this.camposCostosPortuariosElement.nativeElement.classList.remove('completo');
      this.camposCostosPortuariosElement.nativeElement.classList.add('pendiente');
    }

    // Todos los campos
    this.todosLosCampos =
      this.camposNominacion &&
      this.camposCondicionesDeArriboDeLaNave &&
      this.camposPlanDeOperacionPortuaria &&
      this.camposDocumentacion &&
      this.camposGestionAutoridades &&
      this.camposCoordinacionServiciosPuerto &&
      this.camposDesarrolloOperacion &&
      this.camposCostosPortuarios;
    if (this.todosLosCampos) {
      this.todosLosCamposElement.nativeElement.classList.remove('pendiente');
      this.todosLosCamposElement.nativeElement.classList.add('completo');
    } else {
      this.todosLosCamposElement.nativeElement.classList.remove('completo');
      this.todosLosCamposElement.nativeElement.classList.add('pendiente');
    }
  }

  /**
   * Actualiza la lista de chequeo en la BD
   */
  onActualizarListaDeChequeo() {
    const dataToSend = {
      listaDeChequeo: this.listaDeChequeo,
    };

    this.mainService.put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, dataToSend).subscribe((result) => {
      Swal.fire({
        title: 'Se actualizó la lista de chequeo exitosamente',
        type: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.obtenerViaje();
    });
  }

  onAddObservations() {
    if (!this.observation) {
      this.alertService.simpleAlertWarning('No hay informacón por añadir');
      return;
    }

    const dataToSend = {
      observationToAdd: this.addObservation(),
    };

    this.mainService.put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, dataToSend).subscribe((result) => {
      Swal.fire({
        title: 'Se actualizó la lista de observaciones',
        type: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.observation = '';
      this.getObservations();
    });
  }

  public onEditObservation() {
    const dataToSend = {
      observationToEdit: this.addObservation(),
    };

    dataToSend.observationToEdit['_id'] = this.selectedObservation._id;

    this.mainService.put(`api/analisis-operativo-recalada/${this.viaje.recaladaId}`, dataToSend).subscribe((result) => {
      this.alertService.simpleAlertConfirm('Se actualizó tu observación');

      this.selectedObservation = null;
      this.observation = '';
      this.getObservations();
    });
  }

  /**
   * Devuelve al usuario a la pagina anerior
   */
  onDevolverse() {
    Swal.fire({
      title: '¿Deseas regresar?',
      text: 'Se perderá la información diligenciada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this._location.back();
      }
    });
  }
}
