import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { MainService } from '../../services/main.service';

@Component({
	selector: 'app-kim-ver',
	templateUrl: './kim-ver.component.html',
	styleUrls: ['./kim-ver.component.scss']
})
export class KimVerComponent implements OnInit {

	id: any;
	public kim: any = {};
	public data: any;

	constructor(
		private toastrService: NbToastrService,
		private _location: Location,
		private route: ActivatedRoute,
		private mainService: MainService,) {

	}

	ngOnInit() {

		this.id = this.route.snapshot.params['id'];
		this.onCargar()

	}

	onCargar() {

		this.kim = {};

		this.mainService.get('api/kim/' + this.id).subscribe(res => {
			this.kim = res;
		});
	}

	back() {
		this._location.back();
	}

	/** Toas **/

	showToast(position, status, titulo, mensaje) {
		this.toastrService.show(
			mensaje,
			titulo,
			{ position, status });
	}

}
