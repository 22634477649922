import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NbTabsetModule, NbCardModule, NbButtonModule } from '@nebular/theme';
import { NbCardModuleComponent } from './nb-card-module/nb-card-module.component';
import { SmartTableComponent } from './smart-table/smart-table.component';

const localComponents = [NbCardModuleComponent];
const nebularModules = [NbTabsetModule, NbCardModule, NbButtonModule];

@NgModule({
  declarations: [...localComponents, SmartTableComponent],
  imports: [CommonModule, ...nebularModules],
  exports: [...localComponents, ...nebularModules],
})
export class SharedModule {}
