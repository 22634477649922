import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {FormGroup, FormControl} from '@angular/forms';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'proforma-carta',
  templateUrl: './proforma-carta.component.html',
  styleUrls: ['./proforma-carta.component.scss']
})
export class ProformaCartaComponent implements OnInit {
	/** Referencia del poder de editar */
	/** Formulario reactivo con las partes de la carta */
  public partesCarta: FormGroup;
	/** Datos traidos de la BD de la carta proforma */
	public datosCartaProforma: any = [];
	/** Indica si hay algo cargandose en el modulo */
	public cargando : boolean = false;
	
	@Input('permisosUsuario') permisosUsuario: any;

	constructor(private mainService: MainService) { }

  ngOnInit() {
		this.cargando = true;
		this.initializeData();
		this.initializeForm();
	}

	/**
	 * Inicializa los datos traidos de la BD
	 */
	initializeData(){
		this.mainService.get('api/carta_proforma').subscribe(res => {
			this.datosCartaProforma = res;
			this.llenarDatosFormulario();

			this.cargando = false;
		})
	}

	/**
	 * Inicializa el formulario reactivo con las parted de la carta
	 */
	initializeForm(){
		this.partesCarta = new FormGroup({
			'direccion' : new FormControl({value: '', disabled: false}),
			'texto1' : new FormControl({value: '', disabled: false}),
			'texto2' : new FormControl({value: '', disabled: false}),
			'datosBancarios' : new FormControl({value: '', disabled: false}),
			'firma' : new FormControl({value: '', disabled: false}),
		});
	}

	llenarDatosFormulario(){
		if(this.datosCartaProforma){
			this.partesCarta.controls['direccion'].setValue(this.datosCartaProforma.direccion);
			this.partesCarta.controls['texto1'].setValue(this.datosCartaProforma.texto1);
			this.partesCarta.controls['texto2'].setValue(this.datosCartaProforma.texto2);
			this.partesCarta.controls['datosBancarios'].setValue(this.datosCartaProforma.datosBancarios);
			this.partesCarta.controls['firma'].setValue(this.datosCartaProforma.firma);
		}
	}

	/**
	 * Envia los datos a la BD
	 */
	onSubmit(){
		if(this.permisosUsuario.proformas !== 'ESCRITURA'){
			Swal.fire({
				title: 'No se tiene permisos de escritura en el modulo',
				type: 'error',
				showCancelButton: false,
				confirmButtonText: 'Continuar',
			})
			return;
		} else {
			Swal.fire({
				title: '¿Deseas actualizar los datos de la carta?',
				text: 'Se perderan los datos anteriores',
				type: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Si',
				cancelButtonText: 'No'
			}).then((result) => {
				if (result.value) {
					const formContent = this.partesCarta.value;
					const datosCartaProforma = JSON.parse(JSON.stringify(formContent));
	
					this.mainService.put('api/carta_proforma', datosCartaProforma).subscribe(result => {
						Swal.fire(
							'Éxito',
							'Se actualizaron los datos de la carta proforma exitosamente',
							'success'
						)
					})
				}
			})
		}
	}

}
