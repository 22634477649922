import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../services/main.service';
import * as _ from 'lodash';

@Component({
  selector: 'contacto-detalle',
  templateUrl: './contacto-detalle.component.html',
  styleUrls: ['./contacto-detalle.component.scss'],
})
export class ContactoDetalleComponent implements OnInit {
  /** Referencia del permiso de edición */
  @Input() available: boolean;
  /** Referencia al dialogo del detalle */
  @Input() dialogRef: any;
  /** Modo de la acción actual del detalle */
  @Input() modo: string;
  /** Contacto actual */
  @Input() contactoEmpresa: any;
  /** Indica si se genero un cambio en la BD */
  @Output() cambioEnContactoEmpresa: EventEmitter<any> = new EventEmitter<any>();
  /** Empresa Pre seleccionada */
  @Input() empresa: any;
  /** Area Pre seleccionada */
  @Input() area: any;

  /** Formulario reactivo para un contacto*/
  public contactoEmpresaFormulario: FormGroup;
  /** Empresas en la BD */
  public empresas: any = [];
  /** Areas de empresas en la BD */
  public areasEmpresas: any = [];
  /** Areas de empresas filtradas por la empresa seleccionada */
  public areasEmpresasFiltradas: any = [];
  /** Posibles modos para el detalle */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Indica si las empresas se estan cargando */
  public cargandoEmpresas = true;
  /** Indica si las areas se estan cargando*/
  public cargandoAreas = true;
  /** Indica que le modulo se esta cargando */
  public cargandoModulo = false;

  constructor(private mainService: MainService) {}

  ngOnInit() {
    this.obtenerEmpresas();
    this.inicializarFormulairoSegunModo();
  }

  @Input() disabledInputSearch = false;

  /**
   * Inicializa el formulario segun el modo
   */
  inicializarFormulairoSegunModo() {
    switch (this.modo) {
      case this.modos[0]:
        this.inicializarFormularioModoCrear();
        break;
      case this.modos[1]:
        this.disabledInputSearch = true;
        this.inicializarFormularioModoEditar();
        break;
      case this.modos[2]:
        this.inicializarFormualrioModoVer();
        break;
    }
  }

  /**
   * Carga los datos de las empresas de la BD
   */
  obtenerEmpresas() {
    this.cargandoEmpresas = true;

    this.mainService.get('api/empresa_sql?activo=true').subscribe((res) => {
      try {
        this.empresas = res;
        this.empresas = _.orderBy(this.empresas, ['EMP_NOMBRE']);
        this.cargandoEmpresas = false;

        this.setDataCollectionEmpresa();

        this.obtenerAreas();
      } catch (err) {
        console.error(err);
      }
    });
  }

  /**
   * Carga los datos de las areas de la BD
   */
  obtenerAreas() {
    this.cargandoAreas = true;
    const empresa = this.contactoEmpresaFormulario.value.empresa || this.empresa;

    this.mainService.get(`api/area_empresa/empresa/${empresa}`).subscribe((res) => {
      try {
        this.areasEmpresas = res;
        this.areasEmpresas = _.orderBy(this.areasEmpresas, ['nombre']);
        this.areasEmpresasFiltradas = this.contactoEmpresaFormulario.value.empresa
          ? this.areasEmpresas.filter((area) => area.empresa == this.contactoEmpresaFormulario.value.empresa)
          : [];

        this.cargandoAreas = false;
      } catch (err) {
        console.log(err);
      }
    });
  }

  /**
   * Inicializa el formulario en el modo crear
   */
  inicializarFormularioModoCrear() {
    this.contactoEmpresaFormulario = new FormGroup({
      nombre: new FormControl({ value: '', disabled: false }, Validators.required),
      correo: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
      cargo: new FormControl({ value: '', disabled: false }),
      locacion: new FormControl({ value: '', disabled: false }),
      pais: new FormControl({ value: '', disabled: false }),
      telefono: new FormControl({ value: '', disabled: false }),
      movil: new FormControl({ value: '', disabled: false }),
      ciudad: new FormControl({ value: '', disabled: false }),
      comentarios: new FormControl({ value: '', disabled: false }),
      extra: new FormControl({ value: '', disabled: false }),
      empresa: new FormControl({ value: this.empresa ? this.empresa : '', disabled: false }, Validators.required),
      areaEmpresa: new FormControl({ value: '', disabled: false }, Validators.required),
    });
  }

  /**
   * Inicializa el formulario en modo editar
   */
  inicializarFormularioModoEditar() {
    this.contactoEmpresaFormulario = new FormGroup({
      nombre: new FormControl({ value: this.contactoEmpresa.nombre, disabled: false }, Validators.required),
      correo: new FormControl({ value: this.contactoEmpresa.correo, disabled: false }, [
        Validators.required,
        Validators.email,
      ]),
      cargo: new FormControl({ value: this.contactoEmpresa.cargo, disabled: false }),
      locacion: new FormControl({ value: this.contactoEmpresa.locacion, disabled: false }),
      pais: new FormControl({ value: this.contactoEmpresa.pais, disabled: false }),
      telefono: new FormControl({ value: this.contactoEmpresa.telefono, disabled: false }),
      movil: new FormControl({ value: this.contactoEmpresa.movil, disabled: false }),
      ciudad: new FormControl({ value: this.contactoEmpresa.ciudad, disabled: false }),
      comentarios: new FormControl({ value: this.contactoEmpresa.comentarios, disabled: false }),
      extra: new FormControl({ value: this.contactoEmpresa.extra, disabled: false }),
      empresa: new FormControl({ value: this.contactoEmpresa.empresa, disabled: false }, Validators.required),
      areaEmpresa: new FormControl({ value: this.contactoEmpresa.areaEmpresa, disabled: false }, Validators.required),
    });

    this.obtenerAreas();
  }

  /**
   * Inicializa los datos en el modo de ver
   */
  inicializarFormualrioModoVer() {
    this.contactoEmpresaFormulario = new FormGroup({
      nombre: new FormControl({ value: this.contactoEmpresa.nombre, disabled: true }, Validators.required),
      correo: new FormControl({ value: this.contactoEmpresa.correo, disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      cargo: new FormControl({ value: this.contactoEmpresa.cargo, disabled: true }),
      locacion: new FormControl({ value: this.contactoEmpresa.locacion, disabled: true }),
      pais: new FormControl({ value: this.contactoEmpresa.pais, disabled: true }),
      telefono: new FormControl({ value: this.contactoEmpresa.telefono, disabled: true }),
      movil: new FormControl({ value: this.contactoEmpresa.movil, disabled: true }),
      ciudad: new FormControl({ value: this.contactoEmpresa.ciudad, disabled: true }),
      comentarios: new FormControl({ value: this.contactoEmpresa.comentarios, disabled: true }),
      extra: new FormControl({ value: this.contactoEmpresa.extra, disabled: true }),
      empresa: new FormControl({ value: this.contactoEmpresa.empresa, disabled: true }, Validators.required),
      areaEmpresa: new FormControl({ value: this.contactoEmpresa.areaEmpresa, disabled: true }, Validators.required),
    });
  }

  /**
   * Verifica que los datos del formulario sean correctos y envia los datos a la BD
   */
  onEnviarFormulario() {
    this.cargandoModulo = true;
    if (
      this.contactoEmpresaFormulario.invalid ||
      !this.contactoEmpresaFormulario.value.areaEmpresa ||
      !this.contactoEmpresaFormulario.value.empresa
    ) {
      Swal.fire({
        title: `No se puede ${this.modo.toLocaleLowerCase()} el contacto`,
        text: 'Hay campos requeridos vacíos o campos con valores incorrectos',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });

      this.cargandoModulo = false;
    } else {
      // Contenido del formulario
      const contenidoFormulario = this.contactoEmpresaFormulario.value;
      const areaAsociada = this.areasEmpresas.find((area) => area.AEM_ID == contenidoFormulario.areaEmpresa);
      contenidoFormulario.referenciaArea = areaAsociada._id || null;
      const empresaAsociada = this.empresas.find((empresa) => empresa.EMP_ID == contenidoFormulario.empresa);
      contenidoFormulario.referenciaEmpresa = empresaAsociada._id || null;
      // Contenido del formulario en formato JSON
      const contactoEmpresa = JSON.parse(JSON.stringify(contenidoFormulario));

      if (this.modo == this.modos[0]) {
        this.mainService.get('api/global_variable/CON_ID').subscribe((res) => {
          // Cuando la variable global no ha sido creada llega un mensaje
          if (res.message) {
            contactoEmpresa.CON_ID = 20816; //Primer valor del ID
            // Objeto para crear la variable global
            const contactoEmpresaIdGlobalVariable = {
              key: 'CON_ID',
              value: contactoEmpresa.CON_ID,
            };
            this.mainService.post('api/global_variable', contactoEmpresaIdGlobalVariable).subscribe((result) => {});
          } else {
            contactoEmpresa.CON_ID = res.value;
          }

          this.mainService.put('api/global_variable/CON_ID', {}).subscribe((result) => {});
          this.mainService.post('api/contacto_empresa', contactoEmpresa).subscribe((result) => {
            if (result) {
              this.cambioEnContactoEmpresa.emit(result);

              Swal.fire('Éxito', 'Se agregó el contacto exitosamente', 'success').then((result) => {
                if (result.value) {
                  this.cerrarDialog();
                }
              });
            } else {
              Swal.fire('Error', 'No se pudo agregar el contacto, verifique la información', 'warning');
            }

            this.cargandoModulo = false;
          });
        });
      } else {
        this.mainService
          .put('api/contacto_empresa/' + this.contactoEmpresa._id, contactoEmpresa)
          .subscribe((result) => {
            if (result) {
              this.cambioEnContactoEmpresa.emit(result);

              Swal.fire('Éxito', 'Se actualizó el contacto exitosamente', 'success').then((result) => {
                if (result.value) {
                  this.cerrarDialog();
                }
              });
            } else {
              Swal.fire('Error', 'No se pudo actualizar el contacto, verifique la información', 'warning');
            }
          });
      }
    }
  }

  /** Cierra el detalle del contacto */
  cerrarDialog() {
    this.dialogRef.close();
  }

  /**
   * Recarga las empresas trayendolas de la BD
   */
  onRecargarEmpresas() {
    this.obtenerEmpresas();
  }

  /**
   * Recarga las areas trayendolas de la BD
   */
  onRecargarAreas() {
    this.obtenerAreas();
  }

  /**
   * Maneja el cambio de la empresa seleccionada
   */
  onCambioEmpresa() {
    this.contactoEmpresaFormulario.value.areaEmpresa = undefined;
    this.contactoEmpresaFormulario.value.referenciaArea = undefined;
    this.areasEmpresasFiltradas = this.areasEmpresas.filter(
      (area) => area.empresa == this.contactoEmpresaFormulario.value.empresa
    );
  }

  setEmpresa(data: string) {
    if (data === undefined) data = null;
    this.contactoEmpresaFormulario.patchValue({
      empresa: data,
    });

    this.obtenerAreas();
  }

  public dataCollectionToFindAreaEmpresa = [];
  setDataCollectionEmpresa() {
    this.dataCollectionToFindAreaEmpresa = [];
    this.empresas.forEach((empresa) => {
      if (!empresa.EMP_NOMBRE) return;
      this.dataCollectionToFindAreaEmpresa.push({
        key: empresa.EMP_ID,
        value: empresa.EMP_NOMBRE,
      });
    });
  }
}
