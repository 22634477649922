import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rso',
  templateUrl: './rso.component.html',
  styleUrls: ['./rso.component.scss']
})
export class RsoComponent implements OnInit {
	/** Referencia del permiso de poder editar o solo ver */
  @Input() available: boolean;
  /** Datos del Receiver, Shipper o Other */
	@Input() rso;
	/** Posicion en el arreglo de receivers, shippers o others del viaje */
  @Input() rsoId: String;
	/** Evento que indica que el Receiver, Shipper o Other fue eliminado */
  @Output() onDeleteRSO: EventEmitter<any>=new EventEmitter<any>();

  ngOnInit() {}

	/**
	 * Emite el evento para informar que el Receiver, Shipper o Other fue eliminado
	 */
  deleteRSO(){
    this.onDeleteRSO.emit(this.rsoId)
  }

}
