import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ViajeDetalleService } from '../../viaje-detalle.service';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { MainService } from '../../../../../services/main.service';
import Swal from 'sweetalert2';
import STATUSEXTRA from '../../../../../../constants/statusExtra.const';

@Component({
  selector: 'liquidacion-card-viaje',
  templateUrl: './liquidacion-card-viaje.component.html',
  styleUrls: ['./liquidacion-card-viaje.component.scss'],
})
export class LiquidacionCardViajeComponent implements OnInit {
  @Input('confirmed') confirmed = false;
  /** Referencia del permiso de poder editar o solo ver */
  @Input('available') available: boolean;

  @Output() liquidationChanging: EventEmitter<any> = new EventEmitter<any>();

  /** Viaje actual */
  @Input('viaje') viaje: any = {};
  /** Indica si el viaje no tiene liquidacion y por lo tanto esta en modo crear */
  public modoCrear: boolean = true;
  /** Liquidacion actual */
  public liquidacion: any = '';
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Indica si hay algo en el modulo que se este cargando */
  public cargando: boolean = false;

  /** Referencia al detalle de la liquidacion */
  @ViewChild('liquidacionWindow', { static: true }) liquidacionWindow: ElementRef;

  public proforma = {};

  constructor(
    private mainService: MainService,
    private viajeDetalleService: ViajeDetalleService,
    private dialogService: NbDialogService,
    public router: Router
  ) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.viaje) {
      this.initializeData();
    }
  }

  /**
   * Inicializa los datos de la BD segun el viaje actual
   */
  initializeData() {
    this.cargando = true;
    if (this.viaje.recaladas) {
      this.liquidacion = '';
      this.getLiquidacion();
      this.getProforma();
    }
  }

  public cambioEnLiquidaciones() {
    this.liquidationChanging.emit();
  }

  getLiquidacion() {
    this.mainService.get(`api/liquidacion?activo=true&idRecalada=${this.viaje.recaladas[0]._id}`).subscribe((res) => {
      if (res.length > 0) {
        this.liquidacion = res[0];
        this.modoCrear = false;
        // this.confirmed = true;
      }
    });
  }

  includesProspects(): boolean {
    const { ata, atb, atc, atd } = this.viaje.recaladas[0].prospectos;
    return ata && atb && atc && atd;
  }

  getProforma() {
    if (this.viaje.recaladas.length === 1) {
      this.mainService.get(`api/proforma?activo=true&recaladaId=${this.viaje.recaladas[0]._id}`).subscribe((res) => {
        if (res.length === 0) this.cargando = false;
        this.proforma = res[0];

        if (
          this.viaje.recaladas[0].informacionExtra.statusExtra === STATUSEXTRA.SHIPPED &&
          this.includesProspects() &&
          res.length > 0
        ) {
          this.confirmed = true;
        } else {
          this.confirmed = false;
        }

        if (this.viaje.status === 'CANCELED') {
          this.confirmed = false;
        }

        this.cargando = false;
      });
    }
  }

  /**
   * Evento que indica que hubo cambios en la BD referentes a liquidaciones
   * @param liquidacion Datos de la liquidacion cambiada o creada
   */
  liquidacionCambiada(liquidacion) {
    this.liquidacion = liquidacion;
    this.modoCrear = false;
    this.initializeData();
    this.liquidationChanging.emit();
  }

  /**
   * Inicializa la creacion, edicion o visualizacion de una liquidacion
   */
  onCrearEditarVerLiquidacion() {
    this.openCrearLiquidacionConNominacionDialog(this.liquidacionWindow);
  }

  /**
   * Abre el dialogo del detalle de la liquidacion
   * @param dialog Referencia al dialogo del detalle de la liquidacion
   */
  openCrearLiquidacionConNominacionDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Elimina la liquidacion de la BD
   */
  onDeleteLiquidacion() {
    Swal.fire({
      title: '<strong>¿Deseas eliminar la liquidación?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.mainService.delete(`api/liquidacion/${this.liquidacion.nv}/${this.liquidacion._id}`).subscribe(
          (res) => {
            if (res) {
              if (res.message) {
                Swal.fire({
                  title: '¡Error!',
                  text: res.message,
                  type: 'error',
                });
                return;
              }
              Swal.fire('Éxito', 'Liquidación eliminada con éxito', 'success');
              this.liquidacion = '';
              this.modoCrear = true;
              this.liquidationChanging.emit();
            }
          },
          (err) => {
            Swal.fire({
              title: '¡Error!',
              text: err.error.message,
              type: 'error',
            });
          }
        );
      }
    });
  }
}
