import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AnalisisOperativoService } from '../../analisis-operativo.service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from '../../../../services/alertService/alert-service.service';
import STATUSEXTRACOLOR from '../../../../constants/colorStatus.const';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'tabla-viajes-analisis-operativo',
  templateUrl: './tabla-viajes-analisis-operativo.component.html',
  styleUrls: ['./tabla-viajes-analisis-operativo.component.scss'],
})
export class TablaViajesAnalisisOperativoComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialogRef: ElementRef;
  private dialog: any;

  public observationCheckList = [];

  public observationsListInstructions = {
    observationsList: [],
  };

  /** Viajes sin facturar aplicables al filtro actual */
  public viajesFiltrados: any = [];
  /** Subscripcion a los viajes filtrados del servicio */
  public viajesFiltradosSubscripcion: Subscription;
  /** Indica que se estan cargando los datos de los viajes */
  public viajesCargandose = true;
  /** Subscripcion al indicativo de carga de buques del sevicio*/
  public viajesCargandoseSubscripcion: Subscription;
  /** */
  public viajesCargandoseSubject: Subject<boolean> = new Subject<boolean>();

  // Colors for status
  public STATUSEXTRACOLOR = STATUSEXTRACOLOR;

  constructor(
    private analisisOperativoService: AnalisisOperativoService,
    public router: Router,
    private alertService: AlertService,
    private diagoService: NbDialogService
  ) {}

  ngOnInit() {
    this.inicializarSubscripciones();
  }

  ngOnDestroy(): void {
    this.detenerSubscripciones();
  }

  /**
   * Subscribrse a los datos necesarios del servicio
   */
  inicializarSubscripciones(): void {
    this.viajesCargandoseSubscripcion = this.analisisOperativoService.viajesCargandose.subscribe((cargandose) => {
      this.viajesCargandose = cargandose;
      this.viajesCargandoseSubject.next(cargandose);
    });
    this.viajesFiltradosSubscripcion = this.analisisOperativoService.viajesFiltradosCambiados.subscribe((viajes) => {
      this.viajesFiltrados = viajes.sort((a, b) => b.nv - a.nv);
    });
  }

  /**
   * Detener Subscripciones del servicio
   */
  detenerSubscripciones(): void {
    this.viajesFiltradosSubscripcion.unsubscribe();
    this.viajesCargandoseSubscripcion.unsubscribe();
  }

  /**
   * Redirigue al usuario a la pagina del detalle del viaje seleccionado
   * @param idViaje ID del viaje seleccionado
   */
  onIrAViaje(idViaje: string, idRecalada: string) {
    this.router.navigate([`home/viaje/view-and-edit/${idViaje}/${idRecalada}`]);
  }

  /**
   * Redirigue al usuario a la pagina del los reportes del viaje seleccionado
   * @param idViaje ID del viaje seleccionado
   */
  onIrAListaDeChequeo(idViaje: string, idRecalada: string) {
    const viaje = this.viajesFiltrados.find((viaje) => viaje._id === idViaje);

    if (!viaje) return this.alertService.simpleAlertError('Primero debe tener un Boarding Agent');
    if (!viaje.boardingAgent) return this.alertService.simpleAlertError('Primero debe tener un Boarding Agent');

    this.router.navigate([`home/lista-de-chequeo/${idViaje}/${idRecalada}`]);
  }
  /**
   * Redirigue al usuario a la pagina del detalle del viaje seleccionado
   * @param idViaje ID del viaje seleccionado
   */
  onIrAReportes(idViaje: string, idRecalada: string) {
    const viaje = this.viajesFiltrados.find((viaje) => viaje._id === idViaje);

    if (!viaje) return this.alertService.simpleAlertError('Primero debe tener un Boarding Agent');
    if (!viaje.boardingAgent) return this.alertService.simpleAlertError('Primero debe tener un Boarding Agent');

    this.router.navigate([`home/reportes/${idViaje}/${idRecalada}`]);
  }

  /**
   * Verifica si la lista de chequeo del viaje esta completa
   * @viaje Viaje del cual se quiere saber la completitud de la lista de chequeo
   */
  listaDeChequeoCompleta(viaje): boolean {
    return (
      viaje.listaDeChequeo.instruccionesNominacion &&
      viaje.listaDeChequeo.situacionRestriccionPuerto &&
      viaje.listaDeChequeo.caladoPuerto &&
      viaje.listaDeChequeo.otrasRestricciones &&
      viaje.listaDeChequeo.isps &&
      viaje.listaDeChequeo.caladoArriboZarpe &&
      viaje.listaDeChequeo.secuenciaCargueDescargue &&
      viaje.listaDeChequeo.distribucionCargaEnTierra &&
      viaje.listaDeChequeo.tiposProductosYRitmos &&
      viaje.listaDeChequeo.prospectosYProgramacion &&
      viaje.listaDeChequeo.almacenamientoYTransporte &&
      viaje.listaDeChequeo.oblsODraftBls &&
      viaje.listaDeChequeo.shipperDeclaration &&
      viaje.listaDeChequeo.msds &&
      viaje.listaDeChequeo.matesReceipt &&
      viaje.listaDeChequeo.cargoManifest &&
      viaje.listaDeChequeo.dian &&
      viaje.listaDeChequeo.capitania &&
      viaje.listaDeChequeo.ica &&
      viaje.listaDeChequeo.sanidad &&
      viaje.listaDeChequeo.migracion &&
      viaje.listaDeChequeo.muellaje &&
      viaje.listaDeChequeo.poilotos &&
      viaje.listaDeChequeo.remolacadores &&
      viaje.listaDeChequeo.estibadores &&
      viaje.listaDeChequeo.serviciosNave &&
      viaje.listaDeChequeo.productividadRealVsProgramada &&
      viaje.listaDeChequeo.sofYLaytime &&
      viaje.listaDeChequeo.cuellosDeBotella &&
      viaje.listaDeChequeo.pda &&
      viaje.listaDeChequeo.ordenesDeServicios &&
      viaje.listaDeChequeo.closeToReal &&
      viaje.listaDeChequeo.fda
    );
  }

  public openObservationDialog() {
    this.dialog = this.diagoService.open(this.dialogRef as any);
  }

  public selectObservationsCheckList(observatiosList: any): void {
    if (observatiosList.length === 0) {
      this.alertService.simpleAlertWarning('No tienes observaciones');
      return;
    }

    this.observationsListInstructions.observationsList = observatiosList;

    this.observationCheckList = observatiosList;
    this.openObservationDialog();
    console.log(observatiosList);
  }

  public closeObservationDialog(): void {
    this.dialog.close();
  }
}
