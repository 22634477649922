import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import { MainService } from '../../../services/main.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'parametro',
  templateUrl: './parametro.component.html',
  styleUrls: ['./parametro.component.scss']
})
export class ParametroComponent implements OnInit {
	/** Referencia a poder editar o no */
	@Input() available: boolean;
	/** Nombre del parametro del sistema */
	@Input() nombreParametro: string;
	
	/** Unidad del parametro */
	public unidad: string;
	/** Valor actual del parametro */
	public valor: number;
	/** Valor en formato separado por commas del parametro */
	public valorFormateado: String;
	/** Formulario reactivo */
	public form: FormGroup;

	/** Referencia para cerrar el detalle del parametro */
	@ViewChild('closeButton', {static: true}) closeButton: ElementRef;
	/** Referencia a la ventana de edicion del parametro */
	@ViewChild('editar', {static: true}) editar: ElementRef;

  constructor(private dialogService: NbDialogService, private mainService: MainService,) {}

	/**
	 * Innicializa los datos del parametro traidos de la BD
	 */
  ngOnInit() {

		this.mainService.get(`api/parametro_sistema/${this.nombreParametro}`).subscribe(res =>{   
			this.valor = res.valor;
			this.initializeForm();
			this.valorFormateado = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(this.valor);
    });
		
		switch (this.nombreParametro) {
			case 'SMLVM':
				this.unidad = 'COP'
				break;
			case 'SMLVD':
				this.unidad = 'COP'
				break;
			case 'TRM':
				this.unidad = 'COP/USD'
				break;
			case 'UVT':
				this.unidad = 'COP'
				break;
			default:
				this.unidad = 'ERROR'
		}
  }
	/**
	 * Inicializa el formulario de edicion del parametro 
	 */
	initializeForm(){
		this.form = new FormGroup({
      'valor' : new FormControl({value: this.valor, disabled: false}, Validators.required)
    });
	}

	/**
	 * Inicializa la edicion del parametro
	 */
	onEditar() {
		this.openEditarDialog(this.editar);
	}

	/**
	 * Abre la ventana para editar el parametro
	 * @param dialog Referencia al dialogo para editar
	 */
	openEditarDialog(dialog) {
		this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

	/**
	 * Verifica que los datos sean correctos y los envia a la BD
	 */
	onSubmit(){
		if(this.form.invalid){
      Swal.fire({
        title: `No se puede editar el ${this.nombreParametro}`,
        text: 'Debe tener un valor',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
		} else {
			Swal.fire({
				title: `¿Seguro desea editar el ${this.nombreParametro}?`,
				text: 'Se perderá el valor anterior',
				type: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Si',
				cancelButtonText: 'No'
			}).then((result) => {
				if (result.value) {
					const formContent = this.form.value;
					const form = JSON.parse(JSON.stringify(formContent));
					
					this.mainService.put(`api/parametro_sistema/${this.nombreParametro}`, form).subscribe(result => {
						this.valor = this.form.get('valor').value;
						this.valorFormateado = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(this.valor);

						Swal.fire({
              title: `Se actualizó el ${this.nombreParametro} exitosamente`,
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'Continuar',
            }).then((result) => {
              if (result.value) {
                const closeButton = this.closeButton.nativeElement;
          			closeButton.click();
              }
            })
					});
				}
			})
		}
	}

}
