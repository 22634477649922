import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { NbDialogService } from '@nebular/theme';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-empresa-sql-ver',
  templateUrl: './empresa-sql-ver.component.html',
  styleUrls: ['./empresa-sql-ver.component.scss'],
})
export class EmpresaSqlVerComponent implements OnInit {
  id: any;
  tiposEmpresa: string[] = ['PROVEEDOR', 'CLIENTE'];
  public empresa: any = {};
  public data: any;

  //------AREAS
  /** Configuracion de la tabla */
  settingsAreas: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
        { name: 'borrar', title: '<i class="nb-trash"></i> ' },
        { name: 'ver', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      AEM_ID: {
        title: 'ID',
      },
      nombre: {
        title: 'Nombre',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
    },
  };
  /** Referencia al dialogo del detalle */
  dialogRefArea: any;
  /** Datos de areas de empresa en la BD */
  public areasEmpresa: any = [];
  /** Dato seleccionado por el usuario*/
  public areaEmpresa: any;
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = false;
  /** Indica si las áreas de empresas están cargando */
  public cargandoEmpresas: boolean = false;
  /** Datos de las empresas en la BD*/
  public empresas: any = [];

  /** Referencia al detalle*/
  @ViewChild('areaEmpresaDetalle', { static: true }) areaEmpresaDetalle: ElementRef;
  //------AREAS

  //------CONTACTOS
  /** Configuracion de la tabla */
  settingsContactos: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
        { name: 'borrar', title: '<i class="nb-trash"></i> ' },
        { name: 'ver', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      nombre: {
        title: 'Nombre',
      },
      correo: {
        title: 'Correo',
      },
      referenciaArea: {
        title: 'Área',
        valuePrepareFunction: (area) => {
          return area.nombre;
        },
        filterFunction: (area, search: string) => {
          if (area.nombre && search) {
            return area.nombre.toLowerCase().includes(search.toLowerCase());
          }
          return false;
        },
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
    },
  };
  /** Referencia al dialogo del detalle */
  dialogRefContacto: any;
  /** Contactos de empresas en la BD */
  public contactosEmpresa: any = [];
  /** Posibles modos para el detalle del contacto */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Modo actual para el detalle de contacto */
  public modo: String = 'CREAR';
  /** Contacto de empresa actualmente seleccionado */
  public contactoEmpresa: any;
  /** Areas de empresas en la BD*/
  public areasEmpresas: any = [];

  /** Detalle de contacto de empresa */
  @ViewChild('contactoEmpresaDetalle', { static: true }) contactoEmpresaDetalle: ElementRef;
  //------CONTACTOS

  permisosUsuario: any;
  usuario: any = JSON.parse(localStorage.getItem('usuario'));

  constructor(
    private toastrService: NbToastrService,
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuario.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.empresas === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.onCargar();
      }
    });
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  inicializarDatos() {
    this.cargando = true;
    this.cargandoEmpresas = true;
    this.areasEmpresa = [];
    this.areasEmpresas = [];
    this.contactosEmpresa = [];

    this.mainService.get(`api/area_empresa/empresa/${this.empresa.EMP_ID}?activo=true`).subscribe((resul) => {
      this.areasEmpresa = JSON.parse(JSON.stringify(resul));
      this.areasEmpresa.forEach((area) => {
        area.createdAt = moment(area.createdAt).format('DD-MM-YYYY HH:mm');
      });
      this.cargandoEmpresas = false;
    });

    this.mainService.get(`api/contacto_empresa?activo=true&empresa=${this.empresa.EMP_ID}`).subscribe((res) => {
      this.contactosEmpresa = res;
      this.contactosEmpresa.forEach((element) => {
        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');
      });
      this.cargando = false;
    });
  }

  onCargar() {
    this.mainService.get('api/empresa_sql/' + this.id).subscribe((res) => {
      this.empresa = res;
      this.inicializarDatos();
    });
  }

  back() {
    this._location.back();
  }

  /** Toas **/
  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  //---CONTACTO
  /**
   * Inincializa la creacion de un contacto de una empresa
   */
  onCrearContactoEmpresa() {
    this.contactoEmpresa = null;
    this.modo = this.modos[0];
    this.abrirContactoEmpresaDetalleDialog(this.contactoEmpresaDetalle);
  }

  /**
   * Abre el detalle del contacto de la empresa
   * @param dialog Referencia al dialogo del detalle
   */
  abrirContactoEmpresaDetalleDialog(dialog) {
    this.dialogRefContacto = this.dialogService.open(dialog, {
      context: 'this is some additional data passed to dialog',
    });
  }

  /**
   * Maneja las posibles acciones
   * @param event Evento con la información de la accion a ejecutar
   */
  onCustomContactos(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarContactoEmpresa(event);
        break;
      case 'editar':
        this.editarContactoEmpresa(event);
        break;
      case 'ver':
        this.verContactoEmpresa(event);
        break;
    }
  }

  /**
   * Borra un contacto de la BD
   * @param event Evento con los datos del contacto a borrar
   */
  borrarContactoEmpresa(event) {
    if (this.permisosUsuario.empresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar el área?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.inicializarDatos();
          this.mainService.delete('api/contacto_empresa/' + event.data._id).subscribe((res) => {
            if (res) {
              Swal.fire('Éxito', 'Contacto eliminado con éxito', 'success');
              this.contactosEmpresa = [];
              this.inicializarDatos();
            }
          });
        }
      });
    }
  }

  /**
   * Inicializa la edicion de un contacto
   * @param event Evento con los datos del contacto a editar
   */
  editarContactoEmpresa(event) {
    if (this.permisosUsuario.empresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.contactoEmpresa = event.data;
      this.modo = this.modos[1];
      this.abrirContactoEmpresaDetalleDialog(this.contactoEmpresaDetalle);
    }
  }
  /**
   * Inicializa la visualizacion de un contacto
   * @param event Evento con los datos del contacto a ver
   */
  verContactoEmpresa(event) {
    this.contactoEmpresa = event.data;
    this.modo = this.modos[2];
    this.abrirContactoEmpresaDetalleDialog(this.contactoEmpresaDetalle);
  }
  //---- CONTACTO

  //------AREA
  /**
   * Inicializar creacion de un area de una empresa
   */
  onCrearAreaEmpresa() {
    this.areaEmpresa = null;
    this.modo = this.modos[0];
    this.abrirAreaEmpresaDetalleDialog(this.areaEmpresaDetalle);
  }

  /**
   * Abre el detalle de una area empresa
   * @param dialog Referencia al detalle
   */
  abrirAreaEmpresaDetalleDialog(dialog) {
    this.dialogRefArea = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Crear, Ver o editar
   */
  onCustomAreas(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarAreaEmpresa(event);
        break;
      case 'editar':
        this.editarAreaEmpresa(event);
        break;
      case 'ver':
        this.verAreaEmpresa(event);
        break;
    }
  }

  /**
   * Borra una area de una empresa
   */
  borrarAreaEmpresa(event) {
    if (this.permisosUsuario.empresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      Swal.fire({
        title: '<strong>¿Deseas eliminar el área?</strong>',
        type: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.mainService.delete('api/area_empresa/' + event.data.AEM_ID).subscribe(
            (res) => {
              if (res) {
                if (res.message) {
                  Swal.fire('Error', res.message, 'error');
                  return;
                }
                Swal.fire('Éxito', 'Área eliminada con éxito', 'success');
                this.areasEmpresa = [];
                this.inicializarDatos();
              }
            },
            (res) => {
              Swal.fire('¡Error!', `${res.error.message}`, 'error');
            }
          );
        }
      });
    }
  }

  /**
   * Inicializa la edicion de un area de una empresa
   * @param event Seleccion del area empresa que se quiere editar
   */
  editarAreaEmpresa(event) {
    if (this.permisosUsuario.empresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    } else {
      this.areaEmpresa = event.data;
      this.modo = this.modos[1];
      this.abrirAreaEmpresaDetalleDialog(this.areaEmpresaDetalle);
    }
  }

  /**
   * Inicializa la visualización de un area de una empresa
   * @param event Seleccion del area empresa que se quiere editar
   */
  verAreaEmpresa(event) {
    this.areaEmpresa = event.data;
    this.modo = this.modos[2];
    this.abrirAreaEmpresaDetalleDialog(this.areaEmpresaDetalle);
  }
  //-------AREA
}
