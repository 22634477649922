import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { evaluate } from 'mathjs';

@Component({
  selector: 'proforma-servicio',
  templateUrl: './proforma-servicio.component.html',
  styleUrls: ['./proforma-servicio.component.scss'],
})
export class ProformaServicioComponent implements OnInit {
  /** Referencia del permiso de poder editar o solo ver */
  @Input() available: boolean;
  /** Datos del servicio */
  @Input() servicio: any;
  /** Posicion del servicio en el arreglo de servicios de la proforma */
  @Input() servicioId: String;
  /** Terminales traidas de la BD */
  @Input() terminals: any;
  /** Buques traidos de la BD */
  @Input() buque: any;
  /** Operadores traidos de la BD */
  @Input() operadores: any;
  /** Parametros del sistema traidos de la BD */
  @Input() parametrosSistema: any;
  /** Factores de arqueo traidos de la BD */
  @Input() factoresArqueo: any;
  /** Evento para indicar que el servicio fue borrado */
  @Output() onDeleteServicio: EventEmitter<any> = new EventEmitter<any>();
  /** Servicio para indicar que el servicio fue cambiado */
  @Output() servicioChanged: EventEmitter<any> = new EventEmitter<any>();

  /** Valor del servicio en formato separado por commas */
  public valorFormateado: string;

  /** Referencia al input de la cantidad del servicio */
  @ViewChild('cantidadElement', { static: true }) cantidadElement: ElementRef;

  ngOnInit() {
    this.actualizarValorFormateado(this.servicio.valor);
  }

  /**
   * Elimina el servicio
   */
  deleteServicio() {
    this.onDeleteServicio.emit(this.servicioId);
  }

  /**
   * Maneja el cambio de cantidades del servicio
   * @param valorIngresado Cantidad ingresada por el usuario
   */
  onChangeCantidad(valorIngresado) {
    if (!valorIngresado) {
      valorIngresado = 0;
      const cantidadElementInput = this.cantidadElement.nativeElement;
      cantidadElementInput.value = 0;
    }

    this.servicio.cantidad = Number(valorIngresado);

    let valorTarifaUSD = this.obtenerValorTarifaUSD();
    this.servicio.valor = evaluate(this.reemplazarVariablesFormulaTarifa(valorIngresado, valorTarifaUSD));
    this.actualizarValorFormateado(this.servicio.valor);

    this.servicioChanged.emit({
      servicioId: this.servicioId,
      servicio: this.servicio,
    });
  }

  onChangeTotalValue(event) {
    if (event.target.value === '') {
      event.target.value = 0;
    }

    const value = event.target.value;
    const stringWithoutCommas = value.replace(/,/g, '');

    if (!this.onlyNumbersAndDots(stringWithoutCommas)) {
      event.target.value = this.values.valorFormateado;
      return;
    }

    this.servicio.valor = parseFloat(stringWithoutCommas);
    this.actualizarValorFormateado(stringWithoutCommas);

    event.target.value = this.values.valorFormateado;

    this.servicioChanged.emit({
      servicioId: this.servicioId,
      servicio: this.servicio,
    });
  }

  onlyNumbersAndDots(input) {
    const regex = /^[0-9.]+$/;
    return regex.test(input);
  }

  /**
   * Actualiza el valor del servicio en formato separado por comas
   * @param valor Valor del servicio
   */

  values = {
    valorFormateado: undefined,
  };

  actualizarValorFormateado(valor) {
    this.values.valorFormateado = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valor);

    this.values = {
      ...this.values,
    };
  }

  /**
   * Calcula el valor de la tarifa en dolares
   * @returns Valor de la tarifa en dolares
   */
  obtenerValorTarifaUSD() {
    let valorTarifaUSD = 0;

    if (this.servicio.tarifa.tipoTarifa == 'DINAMICA') {
      let formulaTarifaUSD = this.servicio.tarifa.tarifaAplicarUSD.slice();

      this.parametrosSistema.forEach((element) => {
        formulaTarifaUSD = formulaTarifaUSD.replace(element.nombre, element.valor);
      });
      formulaTarifaUSD = formulaTarifaUSD.replace('COP', this.servicio.tarifa.tarifaCOP);

      valorTarifaUSD = evaluate(formulaTarifaUSD);
    } else {
      valorTarifaUSD = evaluate(this.servicio.tarifa.tarifaAplicarUSD);
    }

    return valorTarifaUSD;
  }

  /**
   * Remplaza las variables de la formula por su valor numerico
   * @return string con formula del servicio en numeros
   */
  reemplazarVariablesFormulaTarifa(valorIngresado, valorTarifaUSD) {
    let formulaServicio = this.servicio.tarifa.formula.slice();

    //Variables de tarifa y del buque
    formulaServicio = formulaServicio.replace('CANT', valorIngresado);
    formulaServicio = formulaServicio.replace('USD', valorTarifaUSD);
    formulaServicio = formulaServicio.replace('DWT', this.buque.BUQ_DWT);
    formulaServicio = formulaServicio.replace('GRT', this.buque.BUQ_GT);
    formulaServicio = formulaServicio.replace('LOA', this.buque.BUQ_ESLORA);

    //Variable factor de arqueo
    let i = 0;
    let encontroFactorArqueo = false;

    while (i < this.factoresArqueo.length && !encontroFactorArqueo) {
      if (
        this.factoresArqueo[i].rangoGRTMin < this.buque.BUQ_GT &&
        this.factoresArqueo[i].rangoGRTMax > this.buque.BUQ_GT
      ) {
        formulaServicio = formulaServicio.replace('FA', this.factoresArqueo[i].factorArqueo);
        encontroFactorArqueo = true;
      }

      i++;
    }

    // Variables de parametros de sistema
    this.parametrosSistema.forEach((element) => {
      formulaServicio = formulaServicio.replace(element.nombre, element.valor);
    });

    return formulaServicio;
  }
}
