import { Component, OnInit } from '@angular/core';

import {Location} from '@angular/common';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../../services/auth.service';
import { MainService } from '../../services/main.service';
import { of } from 'rxjs';


@Component({
  selector: 'app-usuario-ver',
  templateUrl: './usuario-ver.component.html',
  styleUrls: ['./usuario-ver.component.scss']
})
export class UsuarioVerComponent implements OnInit {
	
  id: any;
  public usuario:any = {
    empresa: {
      nombre: ''
    }
  };
  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.onCargar()
  }

  onCargar(){
    this.usuario = {
      empresa: {},
    };
    this.mainService.get('api/user/'+ this.id).subscribe(res =>{
      this.usuario = res;
    });
  }

  back(){
    this._location.back();
  }
}