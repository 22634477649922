import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import * as moment from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { AuthService } from '../../services/auth.service';
import { MainService } from '../../services/main.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-empresa-ver',
  templateUrl: './agent-ver.component.html',
  styleUrls: ['./agent-ver.component.scss'],
})
export class AgentVerComponent implements OnInit {
  /*  Para el modal */
  @ViewChild('dialog', { static: true }) dialog: ElementRef;
  @ViewChild('edit', { static: true }) edit: ElementRef;
  spinerEdit = false;
  spinerGuardar = false;
  /*****/

  datosEdit = {
    _id: '',
    nombre: '',
    apellido: '',
    email: '',
    password: '',
    empresa: '',
    telefono: '',
    cargo: '',
    tipo: '',
    estado: '',
  };

  settings = {
    edit: {
      confirmEdit: true,
      editButtonContent: '<i class="nb-compose"></i> ',
    },
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<i class="nb-trash"></i> ',
    },
    actions: {
      delete: false,
      edit: false,
      add: false,

      custom: [
        { name: 'edit', title: '<i class="nb-compose"></i> ' },
        { name: 'delete', title: '<i class="nb-trash"></i> ' },
        { name: 'detalle', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      nombre: {
        title: 'Nombre',
      },
      apellido: {
        title: 'Apellido',
      },
      cargo: {
        title: 'Cargo',
      },
      tipo: {
        valuePrepareFunction: (data) => {
          return data.nombre;
        },
        filterFunction: (value?: any, search?: string) => {
          if (value && search) {
            return true;
          }
        },
        title: 'Tipo',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
      },
      empresa: {
        title: 'Empresa',
        valuePrepareFunction: (data) => {
          return data.razonSocial;
        },
      },
    },
  };

  enviado: boolean = false;
  id: any;
  public empresa: any = {};
  public data: any;
  constructor(
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.data = [];
    this.id = this.route.snapshot.params['id'];
    this.onCargar();
    this.onCargarUsuario();
  }

  onCargar() {
    this.empresa = {};
    console.log('Cargando');
    this.mainService.get('api/empresa/' + this.id).subscribe((res) => {
      this.empresa = res;
    });
  }

  onCargarUsuario() {
    let x = this;
    this.data = [];
    console.log('Cargando');
    this.mainService.get('api/user?activo=true').subscribe((res) => {
      x.data = res;
      x.data = x.data.filter((obj) => obj.empresa && obj.empresa._id == this.id);
      x.data.forEach((element) => {
        element.createdAt = moment(element.createdAt).format('DD-MM-YYYY HH:mm');
      });
      this.data = _.orderBy(this.data, ['createdAt'], ['desc']);
      this.data = new LocalDataSource(this.data);
    });
  }

  back() {
    this._location.back();
  }

  /** Para modal **/

  /** Toas **/

  showToast(position, status, titulo, mensaje) {
    this.toastrService.show(mensaje, titulo, { position, status });
  }

  onCustom(event) {
    switch (event.action) {
      case 'edit':
        this.onEditar(this.edit, event);
        break;
      case 'delete':
        this.onEliminar(event);
        break;
      case 'detalle':
        this.onVer(event);
        break;
    }
  }

  onEditar(dialog, event) {
    this.datosEdit.nombre = event.data.nombre;
    this.datosEdit.apellido = event.data.apellido;
    this.datosEdit.telefono = event.data.telefono;
    this.datosEdit.email = event.data.email;
    this.datosEdit.password = event.data.password;
    this.datosEdit.empresa = event.data.empresa.razonSocial;
    this.datosEdit.tipo = event.data.tipo;
    this.datosEdit.cargo = event.data.cargo;
    this.datosEdit._id = event.data._id;
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /* CRUD Usuarios Table */

  onVer(event) {
    this.router.navigate(['home/usuario-ver/' + event.data._id]);
  }

  onActualizar() {
    this.enviado = true;
    if (
      this.datosEdit.nombre != '' &&
      this.datosEdit.apellido != '' &&
      this.datosEdit.cargo != '' &&
      this.datosEdit.tipo != '' &&
      this.datosEdit.telefono != ''
    ) {
      let user = {
        _id: this.datosEdit._id,
        nombre: this.datosEdit.nombre,
        apellido: this.datosEdit.apellido,
        telefono: this.datosEdit.telefono,
        cargo: this.datosEdit.cargo,
        tipo: this.datosEdit.tipo,
        estado: this.datosEdit.estado,
      };
      let data = user;
      this.mainService.put('api/user/' + data._id, data).subscribe((result) => {
        if (result) {
          Swal.fire('Éxito', 'Se actualizó el usuario exitosamente', 'success');
          const element: HTMLElement = document.getElementById('btn-close');
          element.click();
          this.onCargarUsuario();
        } else {
          Swal.fire('Error', 'No se pudo actualizar el usuario, verifique la información', 'warning');
        }
      });
    } else {
      Swal.fire('Error', 'No se pudo actualizar el usuario, verifique la información', 'warning');
      this.enviado = false;
    }
  }

  onEliminar(event) {
    Swal.fire({
      title: '<strong>¿Deseas eliminar el usuario?</strong>',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let user = event.data;
        this.spinerGuardar = true;
        this.mainService.delete('api/user/' + user._id).subscribe((res) => {
          if (res) {
            Swal.fire('Éxito', 'Usuario eliminado con éxito', 'success');
            this.onCargar();
          }
        });
      } else {
        Swal.fire('Error', 'Problema eliminando el usuario', 'warning');
      }
    });
  }
}
