import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MainService } from '../../../../../services/main.service';
import { UtilsService } from '../../../../../services/utils.service';

@Component({
  selector: 'cargar-documentos-card-viaje',
  templateUrl: './cargar-documentos-card-viaje.component.html',
  styleUrls: ['./cargar-documentos-card-viaje.component.scss'],
})
export class CargarDocumentosCardViajeComponent implements OnInit, OnChanges {
  /** Referencia del permiso de poder editar o solo ver */
  @Input('available') available: boolean;
  @Input('viaje') viaje: any;

  /** Archivo selecciohnado en el equipo del usuario */
  public selectedFile: any;
  /** Buquet de S3 */
  public bucket = new S3({
    accessKeyId: 'AKIAUT7IXVID77RSYJ5F',
    secretAccessKey: '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',
    region: 'us-east-1',
  });
  /** URL del documento de salida del viaje */
  public departureDocumentsURL: string;
  /** URL del documento de cargue o descargue del viaje */
  public dischargeOrLoadingPlanURL: string;
  /** Viaje actual */
  /** Indica si algo se esta cargando en el modulo */
  public cargando: boolean = false;

  /** Referemcoa al archivo de salida */
  @ViewChild('departureFile', { static: true }) departureFile: ElementRef;
  /** Referencia al archivo de cargue o descargue */
  @ViewChild('dischargeOrLoadinPlanFile', { static: true }) dischargeOrLoadinPlanFile: ElementRef;
  /** Referencia al link para ver el documento de salida */
  @ViewChild('departureDocumentViewLink', { static: true }) departureDocumentViewLink: ElementRef;
  /** Referencia al link de cargue o descague */
  @ViewChild('dischargeOrLoadinPlanViewLink', { static: true }) dischargeOrLoadinPlanViewLink: ElementRef;

  constructor(public router: Router, private mainService: MainService, private utilsService: UtilsService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viaje) {
      this.departureDocumentsURL = this.viaje.recaladas[0].archivoDepartureDocuments;
      this.dischargeOrLoadingPlanURL = this.viaje.recaladas[0].archivoDischargeLoandingPlan;
    }
  }

  /**
   * Selecciona un archivo del equipo del usuario
   * @param event Evento con los datos del archivo a subir
   * @param fileSelected Indica si es el archivo de caruge y descargue o de salida
   */
  public fileSelect(event, fileSelected) /*fileSelected = departure || dischargeOrLoadinPlan*/ {
    this.cargando = true;
    this.selectedFile = event.target.files[0];
    if (this.selectedFile !== undefined) {
      const file = this.selectedFile;
      const nombre = this.makeid();
      const params = {
        Bucket: 'naves-recursos',
        ACL: 'public-read',
        Key: 'instruccionViaje/' + nombre + '.pdf',
        ContentType: file.type,
        Body: file,
      };
      const options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
      };

      const uploader = this.bucket.upload(params, options, (err, data) => {
        if (err) {
          Swal.fire({
            title: '¡Error!',
            text: 'Se generó un error al subir el archivo.',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'Continuar',
          });

          this.cargando = false;
        } else {
          if (fileSelected == 'departure') this.departureDocumentsURL = data.Location.toString();
          else this.dischargeOrLoadingPlanURL = data.Location.toString();
          this.cargando = false;
          Swal.fire('Exito!', 'El archivo se subió exitosamente', 'success');
          return true;
        }
      });

      uploader.on('httpUploadProgress', (event) => {
        if (event.loaded == event.total) {
          //
        }
      });
    }
  }

  /**
   * Crea id al azar
   */
  makeid() {
    return this.utilsService.generateRandomId();
  }

  /**
   * Sube el archivo a S3
   * @param file Indica si es el documento de carga y descarga o el de salida
   */
  onUploadFile(file) {
    const uploadFile = file == 'departure' ? this.departureDocumentsURL : this.dischargeOrLoadingPlanURL;
    if (uploadFile) {
      Swal.fire({
        title: '¿Deseas cambiar el archivo?',
        text: 'Se perderá la versión anterior',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const nativeElement =
            file == 'departure' ? this.departureFile.nativeElement : this.dischargeOrLoadinPlanFile.nativeElement;
          nativeElement.click();
        }
      });
    } else {
      const nativeElement =
        file == 'departure' ? this.departureFile.nativeElement : this.dischargeOrLoadinPlanFile.nativeElement;
      nativeElement.click();
    }
  }

  /**
   * Redirigue al usuario para ver un documento
   * @param file INdica si es el documento de salida o el de carga y descarga
   */
  onViewFile(file) /*file = departure || dischargeOrLoadinPlan*/ {
    const viewLink =
      file == 'departure'
        ? this.departureDocumentViewLink.nativeElement
        : this.dischargeOrLoadinPlanViewLink.nativeElement;
    viewLink.click();
  }

  /**
   * Guarda las URLS de los documentos en la BD
   */
  guardarDocumentos() {
    this.cargando = true;
    if (!this.departureDocumentsURL && !this.dischargeOrLoadingPlanURL) {
      Swal.fire({
        title: 'No se puede guardar los documentos',
        text: 'No se ha ingresado ningún documento',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      this.cargando = false;
    } else {
      let documentos = {
        archivoDepartureDocuments: this.departureDocumentsURL,
        archivoDischargeLoandingPlan: this.dischargeOrLoadingPlanURL,
      };
      if (!documentos.archivoDepartureDocuments) delete documentos.archivoDepartureDocuments;
      if (!documentos.archivoDischargeLoandingPlan) delete documentos.archivoDischargeLoandingPlan;
      this.mainService.put(`api/nuevo-viaje/${this.viaje.recaladas[0]._id}/documents`, documentos).subscribe(
        (result) => {
          Swal.fire('Exito!', 'Los archivos fueron guardados exitosamente', 'success');
          this.cargando = false;
        },
        (err) => {
          this.cargando = false;
          Swal.fire('Error!', 'Los archivos no fueron guardados', 'error');
        }
      );
    }
  }
}
