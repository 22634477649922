import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { MainService } from '../services/main.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TimeService } from '../../services/time/time.service';

@Component({
  selector: 'areas-empresa',
  templateUrl: './areas-empresa.component.html',
  styleUrls: ['./areas-empresa.component.scss'],
})
export class AreasEmpresaComponent implements OnInit {
  /** Configuracion de la tabla */
  settings: any = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [
        { name: 'editar', title: '<i class="nb-compose"></i> ' },
        { name: 'borrar', title: '<i class="nb-trash"></i> ' },
        { name: 'ver', title: '<i class="nb-search"></i>' },
      ],
    },
    columns: {
      AEM_ID: {
        title: 'ID',
      },
      nombre: {
        title: 'Nombre',
        width: '400px',
      },
      nombreEmpresa: {
        title: 'Empresa',
      },
      createdAt: {
        title: 'Fecha de creación',
        sort: 'true',
        sortDirection: 'desc',
        valuePrepareFunction: this.timeService.formatStandardDate,
        filterFunction: this.timeService.filterByDate,
      },
    },
  };
  /** Referencia al dialogo del detalle */
  dialogRef: any;
  /** Datos de areas de empresa en la BD */
  public areasEmpresa: any = [];
  /** Tipos de modos para el detalle */
  public modos: string[] = ['CREAR', 'EDITAR', 'VER'];
  /** Modo actual para el detalle */
  public modo: String = 'CREAR';
  /** Dato seleccionado por el usuario*/
  public areaEmpresa: any;
  /** Indica si hay algo cargandose en el modulo */
  public cargando: boolean = false;

  public usuarioActual = JSON.parse(localStorage.getItem('usuario'));
  public permisosUsuario: any;

  /** Referencia al detalle*/
  @ViewChild('areaEmpresaDetalle', { static: true }) areaEmpresaDetalle: ElementRef;

  constructor(private dialogService: NbDialogService, private mainService: MainService, private router: Router, private timeService: TimeService) {}

  ngOnInit() {
    this.obtenerPermisos();
  }

  /**
   * Obtiene los permisos del módulo de roles y bloquea o deja pasar
   */
  obtenerPermisos() {
    this.mainService.get(`api/rol/${this.usuarioActual.tipo}`).subscribe((res) => {
      this.permisosUsuario = res;
      if (this.permisosUsuario.areasEmpresas === 'NINGUNO') {
        Swal.fire({
          title: 'No se tiene permisos de acceso al módulo',
          type: 'error',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['home/dashboard']);
      } else {
        this.inicializarDatos();
      }
    });
  }

  /**
   * Inicializa los datos traidos de la BD
   */
  async inicializarDatos() {
    try {
      this.cargando = true;
      this.areasEmpresa = [];
      this.areasEmpresa = await this.mainService.get('api/area_empresa').toPromise();

      this.areasEmpresa.forEach((area) => {
        area.nombreEmpresa = area.nombreEmpresa[0];
      });

      this.cargando = false;
    } catch (err) {}
  }

  /**
   * Inicializar creacion de un area de una empresa
   */
  onCrearAreaEmpresa() {
    this.areaEmpresa = null;
    this.modo = this.modos[0];
    this.abrirAreaEmpresaDetalleDialog(this.areaEmpresaDetalle);
  }

  /**
   * Abre el detalle de una area empresa
   * @param dialog Referencia al detalle
   */
  abrirAreaEmpresaDetalleDialog(dialog) {
    this.dialogRef = this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  /**
   * Maneja las diferentes acciones de la tabla
   * @param event Crear, Ver o editar
   */
  onCustom(event) {
    switch (event.action) {
      case 'borrar':
        this.borrarAreaEmpresa(event);
        break;
      case 'editar':
        this.editarAreaEmpresa(event);
        break;
      case 'ver':
        this.verAreaEmpresa(event);
        break;
    }
  }

  /**
   * Borra una area de una empresa
   */
  borrarAreaEmpresa(event) {
    if (this.permisosUsuario.areasEmpresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    Swal.fire({
      title: '<strong>¿Deseas eliminar el área?</strong>',
      type: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.mainService.delete('api/area_empresa/' + event.data.AEM_ID).subscribe(
          (res) => {
            if (res.message) {
              Swal.fire({
                title: 'Error',
                text: res.message,
                type: 'error',
              });
              return;
            }
            Swal.fire('Éxito', 'Área eliminada con éxito', 'success');
            this.areasEmpresa = [];
            this.inicializarDatos();
          },
          ({ error }) => {
            Swal.fire({
              title: 'Error',
              text: error.message,
              type: 'error',
            });
          }
        );
      }
    });
  }

  /**
   * Formatea el valor de la fecha de acuerdo a los requerimientos de la plataforma, en hora militar.
   * @param value El valor de la fecha sin formatear.
   * @returns El valor formateado de la fecha.
   */
  formatDates(value) {
    if (value) {
      return moment(value).format('DD-MM-YYYY HH:mm');
    }
  }

  /**
   * Inicializa la edicion de un area de una empresa
   * @param event Seleccion del area empresa que se quiere editar
   */
  editarAreaEmpresa(event) {
    if (this.permisosUsuario.areasEmpresas !== 'ESCRITURA') {
      Swal.fire({
        title: 'No se tiene permisos de escritura en el modulo',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
      });
      return;
    }
    this.areaEmpresa = event.data;
    this.modo = this.modos[1];
    this.abrirAreaEmpresaDetalleDialog(this.areaEmpresaDetalle);
  }

  /**
   * Inicializa la visualización de un area de una empresa
   * @param event Seleccion del area empresa que se quiere editar
   */
  verAreaEmpresa(event) {
    this.areaEmpresa = event.data;
    this.modo = this.modos[2];
    this.abrirAreaEmpresaDetalleDialog(this.areaEmpresaDetalle);
  }
}
